import {useCallback, useMemo} from 'react';
import {ERecentItemType, TRecentItem} from 'types/Search';

import SearchListIcon from 'components/search/SearchListIcon';
import CheckboxIcon from 'components/CheckboxIcon';

import s from 'styles/components/search/SearchRecentEditItem.module.scss';

type TProps = {
  data: TRecentItem;
  isSelect?: boolean;
  onSelect?: (id: string) => void;
};

const RECENT_ITEM_TYPE: Record<ERecentItemType, string> = {
  [ERecentItemType.POI]: '길찾기',
  [ERecentItemType.QUERY]: '검색',
  [ERecentItemType.PUBLIC_POI]: '대중교통 경로',
  [ERecentItemType.HOME]: '즐겨찾기 집',
  [ERecentItemType.OFFICE]: '즐겨찾기 회사',
  [ERecentItemType.FAVORITE]: '즐겨찾기',
  [ERecentItemType.FAVORITE_STATION]: '대중교통 정류장 즐겨찾기',
  [ERecentItemType.FAVORITE_SUBWAY]: '대중교통 역 즐겨찾기',
  [ERecentItemType.BUS_STATION]: '정류장',
  [ERecentItemType.SUBWAY]: '지하철역',
};

const SearchRecentEditItem = ({data, isSelect, onSelect}: TProps) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onSelect?.(data.id);
    },
    [data.id, onSelect]
  );

  const itemLabel = useMemo(() => {
    return `${data.name} ${RECENT_ITEM_TYPE[data.type]}`;
  }, [data.name, data.type]);

  return (
    <li
      className={s.search_recent_edit_item}
      onClick={handleClick}
      role={'checkbox'}
      aria-label={itemLabel}
      aria-checked={isSelect}
    >
      <SearchListIcon srcType={data.type} className={s.icon} />
      <div className={s.name}>{data.name}</div>

      <CheckboxIcon on={isSelect} />
    </li>
  );
};

export default SearchRecentEditItem;
