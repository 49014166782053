import {SEARCH_BANNER_AD_STEP} from 'constant/Ads';
import {devLog} from 'utils/dev';

export function* AdStepper(adStep = SEARCH_BANNER_AD_STEP) {
  for (let nowStep of adStep) {
    yield nowStep;
  }
}

export const sendExtUrl = (url: string) => {
  const img = new Image(1, 1);
  img.onload = () => {
    devLog(`[Banner Ext Log Send] ${url}`);
  };
  img.onerror = () => {
    devLog(`[Banner Ext Log error] ${url}`);
  };
  img.src = url;
};

export const getAsumNetwork = (connectionType: string) => {
  const checkNetwork = connectionType.toLowerCase().replace(/\s/g, '');

  if (checkNetwork.includes('wifi')) {
    return 1;
  } else if (checkNetwork.includes('cellular')) {
    return 2;
  }

  return 0;
};

export const getAsumCarrierCode = (carrierName: string) => {
  const checkCarrier = carrierName.toLowerCase().replace(/\s/g, '');

  if (checkCarrier.includes('skt')) {
    return 1;
  } else if (checkCarrier.includes('kt') || checkCarrier.includes('olleh')) {
    return 2;
  } else if (checkCarrier.includes('lg')) {
    return 3;
  }

  return 0;
};
