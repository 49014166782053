import {useEffect, useRef} from 'react';

export const useOnce = (validate, callback) => {
  const refTrigger = useRef(false);

  useEffect(() => {
    if (validate && !refTrigger.current) {
      refTrigger.current = true;
      callback(Date.now());
    }
  }, [validate, callback]);
};
