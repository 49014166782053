import {useCallback, useEffect, useState} from 'react';
import {ERecentItemType, TRecentItem, TSuggestPoi, TSuggestQuery} from 'types/Search';
import {TPersonalPlaceItem} from 'ducks/userInfo/types';

import {TQueryItem} from '@lcc/tmap-inapp';

import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';

import {getSearchRegExp} from 'utils/search';
import {debounce} from 'utils/lodash';
import {sendSearchClickLog} from 'utils/logManager';
import {TLegacyRecentItem} from 'types/LegacySearch';

import FavoriteItem from 'components/legacySearch/FavoriteItem';
import RecentItem from 'components/legacySearch/RecentItem';
import SuggestItem from 'components/legacySearch/SuggestItem';
import SuggestPoiItem from 'components/legacySearch/SuggestPoiItem';

import {EMPTY_ARRAY} from 'constant/Vars';

import s from 'styles/components/legacySearch/SuggestResult.module.scss';

const DEBOUNCE_DELAY = 150;

type TSuggestResult = {
  filteredFavorite: TPersonalPlaceItem[];
  filteredRecentDestinations: TLegacyRecentItem[];
  filteredRecentQueries: TLegacyRecentItem[];
  suggestQueries: TSuggestQuery[];
  suggestPois: TSuggestPoi[];
};

const defaultSuggestResult: TSuggestResult = {
  filteredFavorite: [],
  filteredRecentDestinations: [],
  filteredRecentQueries: [],
  suggestQueries: [],
  suggestPois: [],
};

type TProps = {
  query?: string;
  favoriteItems?: TPersonalPlaceItem[];
  recentItems?: TPersonalPlaceItem[];
  recentQueries?: TQueryItem[];
  suggestQueries?: TSuggestQuery[];
  suggestPois?: TSuggestPoi[];
  onSearchBlur?: VoidFunction;
  onHide?: VoidFunction;
  onShow?: VoidFunction;
};

const SuggestResult = ({
  query = '',
  favoriteItems = EMPTY_ARRAY,
  recentItems = EMPTY_ARRAY,
  recentQueries = EMPTY_ARRAY,
  suggestQueries = EMPTY_ARRAY,
  suggestPois = EMPTY_ARRAY,
  onSearchBlur,
  onHide,
  onShow,
}: TProps) => {
  const [howHighlightRegExp, setHighlightRegExp] = useState<RegExp>();
  const [nowResult, setResult] = useState<TSuggestResult>(defaultSuggestResult);
  const [resultHide, setHide] = useState<boolean>(false);

  const {moveToSearch, moveToSelectDestinationAction} = useMoveToTarget({
    from: EFromType.SUGGEST,
  });

  const setDebounceResult = useCallback(
    debounce(({hide, highlightRegExp, ...result}) => {
      setHide(hide);
      setHighlightRegExp(highlightRegExp);
      if (hide) {
        setResult(defaultSuggestResult);
      } else {
        setResult({
          ...result,
        } as TSuggestResult);
      }
    }, DEBOUNCE_DELAY),
    []
  );

  useEffect(() => {
    const {searchRegExp, highlightRegExp} = getSearchRegExp(query);
    let filteredFavorite: TPersonalPlaceItem[] = [];
    let filteredRecentDestinations: TRecentItem[] = [];
    let filteredRecentQueries: TRecentItem[] = [];

    if (searchRegExp) {
      filteredFavorite = favoriteItems
        .filter((n) => searchRegExp.test(n.customName))
        .sort((a, b) => ((a.regDateTime || '') < (b.regDateTime || '') ? 1 : -1));

      filteredRecentDestinations = recentItems
        .filter((n) => searchRegExp.test(n.customName))
        .map(
          (n, i): TRecentItem => ({
            id: `${n.poiId}-${i}`,
            type: ERecentItemType.POI,
            name: n.customName,
            date: n.recentDateTime || '',
            poiInfo: n,
          })
        );

      filteredRecentQueries = recentQueries
        .filter((n) => searchRegExp.test(n.query))
        .map(
          (n, i): TRecentItem => ({
            id: `${n.query}-${i}`,
            type: ERecentItemType.QUERY,
            name: n.query,
            date: n.date || '',
          })
        );
    }

    const hide =
      !query.trim() ||
      (filteredFavorite.length === 0 &&
        filteredRecentDestinations.length === 0 &&
        filteredRecentQueries.length === 0 &&
        suggestPois.length === 0 &&
        suggestQueries.length === 0);

    setDebounceResult({
      hide,
      highlightRegExp,
      filteredFavorite,
      filteredRecentDestinations,
      filteredRecentQueries,
      suggestPois,
      suggestQueries,
    });
  }, [
    query,
    favoriteItems,
    recentItems,
    recentQueries,
    suggestPois,
    suggestQueries,
    setDebounceResult,
  ]);

  useEffect(() => {
    if (resultHide) {
      onHide?.();
    } else {
      onShow?.();
    }
  }, [resultHide]);

  if (resultHide) {
    return null;
  }

  return (
    <div className={s.suggest_wrap} onTouchStart={onSearchBlur}>
      {(nowResult.filteredFavorite.length > 0 ||
        nowResult.filteredRecentDestinations.length > 0 ||
        nowResult.filteredRecentQueries.length > 0) && (
        <div className={s.fav_recent_wrap}>
          {nowResult.filteredFavorite.slice(0, 2).map((n, i) => (
            <FavoriteItem
              key={`${n.poiId}-${i}`}
              data={n}
              inSuggest={true}
              onClick={(poiDataJson) => {
                sendSearchClickLog('tap.bookmark', {
                  user_query: query,
                  pkey: poiDataJson.pkey,
                  list_num: nowResult.filteredFavorite.slice(0, 2).length,
                  list_seq: i,
                });
                moveToSelectDestinationAction(poiDataJson);
              }}
              highlightRegExp={howHighlightRegExp}
            />
          ))}

          {[
            ...nowResult.filteredRecentDestinations.slice(0, 1),
            ...nowResult.filteredRecentQueries.slice(0, 1),
          ].map((n) => (
            <RecentItem
              key={n.id}
              data={n}
              inSuggest={true}
              onClickDestination={(poiDataJson) => {
                sendSearchClickLog('tap.lastD', {
                  user_query: query,
                  list_num: nowResult.filteredRecentDestinations.slice(0, 1).length,
                  list_seq: 0,
                  pkey: poiDataJson.pkey,
                });
                moveToSelectDestinationAction(poiDataJson);
              }}
              onClickSearch={(clickedQuery) => {
                sendSearchClickLog('tap.lastA', {
                  user_query: query,
                  list_num: nowResult.filteredRecentQueries.slice(0, 1).length,
                  list_seq: 0,
                  search_query: clickedQuery,
                });
                moveToSearch(clickedQuery);
              }}
              highlightRegExp={howHighlightRegExp}
            />
          ))}
        </div>
      )}

      {nowResult.suggestQueries.map((n, i) => (
        <SuggestItem
          key={`${n.keyword}-${i}`}
          data={n}
          onClick={(q) => {
            sendSearchClickLog('tap.auto', {
              user_query: query,
              search_query: q,
              list_num: nowResult.suggestQueries.length,
              list_seq: i,
            });
            moveToSearch(q, false);
          }}
          highlightRegExp={howHighlightRegExp}
        />
      ))}

      {nowResult.suggestPois.map((n, i) => (
        <SuggestPoiItem
          key={`${n.keyword}-${i}`}
          data={n}
          onClick={(poiDataJson) => {
            sendSearchClickLog('tap.auto_poi', {
              user_query: query,
              list_num: nowResult.suggestPois.length,
              list_seq: i,
              pkey: poiDataJson.pkey,
            });
            moveToSelectDestinationAction(poiDataJson);
          }}
          highlightRegExp={howHighlightRegExp}
        />
      ))}
    </div>
  );
};

export default SuggestResult;
