import {PropsWithChildren} from 'react';
import {IcoArrowRight} from '../@tmds/icons/IcoArrowRight';

import s from '../../styles/pages/SearchMainPage.module.scss';

type TProps = {
  title: string;
  handleClick?: () => void;
} & PropsWithChildren<{}>;

const SearchMainSection = ({title, children, handleClick}: TProps) => {
  return (
    <section className={s.section}>
      <div className={s.header}>
        <h1 className={s.title}>{title}</h1>
        {handleClick && (
          <button type="button" className={s.more} onClick={handleClick}>
            전체보기
            <IcoArrowRight width={14} height={14} color={'tmobiBlue600'} />
          </button>
        )}
      </div>
      {children}
    </section>
  );
};

export default SearchMainSection;
