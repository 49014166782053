import {setComma} from '../../utils/formatter';
import {IcStarFill} from '../@tmds/icons/v1.2/IcStarFill';
import {ReactComponent as IconDotBetween} from 'resource/images/ico_dot_between.svg';

import s from '../../styles/components/search/SearchPoiItemInfo.module.scss';

type TProps = {
  starPoint?: number | null;
  reviewCount?: number | null;
  category?: string;
};

const SearchPoiItemInfo = (props: TProps) => {
  const {starPoint, reviewCount, category} = props;

  if (!starPoint && !reviewCount && !category) {
    return null;
  }

  return (
    <div className={s.wrap}>
      {!!starPoint && (
        <em className={s.star_point}>
          <IcStarFill width={14} height={14} color={'tmobiTeal400'} /> {starPoint}
        </em>
      )}
      {!!reviewCount && (
        <span className={s.review}>
          {starPoint ? `(${setComma(reviewCount)})` : `리뷰 ${setComma(reviewCount)}`}
        </span>
      )}
      {category && (
        <>
          {starPoint || reviewCount ? (
            <>
              <IconDotBetween width={12} height={12} className={s.dot} />
              <span className={s.info}>{category}</span>
            </>
          ) : (
            <span className={s.info}>{category}</span>
          )}
        </>
      )}
    </div>
  );
};

export default SearchPoiItemInfo;
