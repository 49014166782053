import {useMemo, Fragment} from 'react';

export const useJSXMultiline = (str: string | JSX.Element, options = {separator: '\n'}) => {
  const jsx = useMemo(() => {
    if (typeof str === 'string') {
      const [first, ...multiline] = str.split(options?.separator);

      return (
        <>
          {first}
          {multiline.map((line, idx) => (
            <Fragment key={`${line}_${idx}`}>
              <br />
              {line}
            </Fragment>
          ))}
        </>
      );
    }

    return str;
  }, [str, options?.separator]);

  return jsx;
};
