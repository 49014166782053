import {useAppSelector} from 'ducks/hooks';
import {EMarkerType, TMarker} from 'types/Map';
import {useMemo} from 'react';
import {getGroupCodeByColor} from 'components/map/personalMarkerLayer/utils';

const useCalloutInfoMarker = () => {
  const {calloutInfo} = useAppSelector((state) => ({
    userPosition: state.map.userPosition,
    calloutInfo: state.userInteraction.calloutInfo,
    mapStyle: state.map.style,
    tRanking: state.tRank.data.list,
  }));

  const calloutInfoMarker: Nullable<TMarker<{}>> = useMemo(() => {
    if (!calloutInfo?.markerType) {
      return null;
    }

    const lonLat = {
      lon: calloutInfo.lon,
      lat: calloutInfo.lat,
    };

    if (calloutInfo.personalPoiKey) {
      const label = calloutInfo.publicTransportName || calloutInfo.customName;
      switch (calloutInfo.markerType) {
        case EMarkerType.SAVE_POI:
          // @ts-ignore
          const color = calloutInfo.color;
          const groupName = getGroupCodeByColor(color);
          return {
            type: `ACTIVE_SAVE_POI_${groupName.toUpperCase()}` as EMarkerType, // Todo 로직 고민 필요
            clickable: false,
            lonLat: {
              lon: calloutInfo.lon,
              lat: calloutInfo.lat,
            },
            anchor: 'bottom',
            label,
          };
        case EMarkerType.FAVORITE:
          return {
            type: EMarkerType.ACTIVE_FAVORITE,
            clickable: false,
            lonLat: {
              lon: calloutInfo.lon,
              lat: calloutInfo.lat,
            },
            anchor: 'bottom',
            label,
          };
        case EMarkerType.FAVORITE_HOME:
          return {
            type: EMarkerType.ACTIVE_FAVORITE_HOME,
            clickable: false,
            lonLat: {
              lon: calloutInfo.lon,
              lat: calloutInfo.lat,
            },
            anchor: 'bottom',
            label,
          };
        case EMarkerType.FAVORITE_OFFICE:
          return {
            type: EMarkerType.ACTIVE_FAVORITE_OFFICE,
            clickable: false,
            lonLat: {
              lon: calloutInfo.lon,
              lat: calloutInfo.lat,
            },
            anchor: 'bottom',
            label,
          };
        case EMarkerType.FAVORITE_PUBLIC_TRANS:
          return {
            type: EMarkerType.ACTIVE_FAVORITE_PUBLIC_TRANS,
            clickable: false,
            lonLat: {
              lon: calloutInfo.lon,
              lat: calloutInfo.lat,
            },
            anchor: 'bottom',
            label,
          };
        case EMarkerType.RECENT_DESTINATION:
          return {
            type: EMarkerType.ACTIVE_RECENT_DESTINATION,
            clickable: false,
            lonLat: {
              lon: calloutInfo.lon,
              lat: calloutInfo.lat,
            },
            anchor: 'bottom',
            label,
          };
      }
    }

    // 대중교통인경우
    if (calloutInfo.publicTransportType) {
      switch (calloutInfo?.publicTransportType) {
        case 'busstop':
          return {
            type: EMarkerType.ACTIVE_BUS_STATION,
            clickable: false,
            lonLat: {
              lon: calloutInfo.lon,
              lat: calloutInfo.lat,
            },
            anchor: 'bottom',
          };
        case 'subway':
          return {
            type: EMarkerType.ACTIVE_SUBWAY,
            clickable: false,
            lonLat: {
              lon: calloutInfo.lon,
              lat: calloutInfo.lat,
            },
            anchor: 'bottom',
          };
        default:
          return null;
      }
    }

    return {
      type: calloutInfo.markerType,
      clickable: false,
      lonLat,
      anchor: calloutInfo.markerType === EMarkerType.ACTIVE ? 'bottom' : 'center',
    };
  }, [calloutInfo]);

  return calloutInfoMarker;
};

export default useCalloutInfoMarker;
