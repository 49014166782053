// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcHomeFillSvg} from 'resource/@tmdsicon/v1.2/ic_home_fill.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcHomeFill = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcHomeFillSvg {...props} />} />;
};

export {IcHomeFill};
