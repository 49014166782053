import {AD_CLEANUP_WAIT_TIME} from 'constant/Ads';
import {useAppSelector} from 'ducks/hooks';
import {PropsWithChildren, useEffect, useState} from 'react';

type TProps = PropsWithChildren<{
  active?: boolean;
}>;

export const ReloadResume = ({active = true, children}: TProps) => {
  const {resumeKey} = useAppSelector((state) => ({
    resumeKey: state.userInteraction.resumeKey,
  }));
  const [activeTime, setActiveTime] = useState(active ? Date.now() : 0);

  useEffect(() => {
    if (active && resumeKey) {
      setActiveTime(0);
      setTimeout(() => {
        setActiveTime(Date.now());
      }, AD_CLEANUP_WAIT_TIME);
    }

    if (!active) {
      setActiveTime(0);
    }
  }, [active, resumeKey]);

  if (activeTime > 0) {
    return <>{children}</>;
  }

  return <></>;
};
