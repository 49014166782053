import Flicking from '@egjs/react-flicking';
import actions from 'ducks/actions';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {useCallback, useEffect, useRef} from 'react';

import Discovery from './discovery/Discovery';
import {ETPlaceTab} from 'ducks/tplacehome/types';
import AroundMe from './aroundMe/AroundMe';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import usePlaceHome from 'hooks/usePlaceHome';
import {useOnce} from 'hooks/useOnce';

import s from 'styles/components/tplacehome/TPlaceMain.module.scss';

const TPlaceMain = () => {
  const {tplacehome, layout} = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const placehomeHook = usePlaceHome();
  const {tailParam} = useParseQueryLocation();

  const refFlicking = useRef<Flicking>(null);
  const wrapRef = useRef<HTMLDivElement>(null);

  const handleFlickingChange = useCallback(
    (index: number) => {
      switch (index) {
        case 0:
          if (tplacehome.currentTab !== ETPlaceTab.AROUND_ME) {
            placehomeHook.sendEvent('swipe.tab_conversion', {from_to: 1});
          }
          dispatch(actions.tplacehome.setCurrentTab(ETPlaceTab.AROUND_ME));
          break;
        case 1:
          if (tplacehome.currentTab !== ETPlaceTab.DISCOVERY) {
            placehomeHook.sendEvent('swipe.tab_conversion', {from_to: 0});
          }
          dispatch(actions.tplacehome.setCurrentTab(ETPlaceTab.DISCOVERY));
          break;
      }
    },
    [dispatch, placehomeHook, tplacehome.currentTab]
  );
  const handleFlickingMoveStart = useCallback(() => {
    if (!tplacehome.isFlickingMoved) {
      dispatch(actions.tplacehome.setIsFlickingMoved());
    }
  }, [dispatch, tplacehome.isFlickingMoved]);

  useEffect(() => {
    const index = tplacehome.currentTab === ETPlaceTab.AROUND_ME ? 0 : 1;
    if (refFlicking.current?.animating) {
      refFlicking.current.updateAnimation(index);
    } else {
      refFlicking.current?.moveTo(index);
    }
  }, [tplacehome.currentTab]);

  // 가로 스크롤 엘리먼트 이벤트 차단.
  useEffect(() => {
    wrapRef.current?.addEventListener('touchstart', (e) => {
      const targetEl = e.target as HTMLElement;
      const ignoreEl = targetEl.closest('[data-flicking-ignore]');
      if (ignoreEl) {
        refFlicking.current?.disableInput();
      }
    });
    wrapRef.current?.addEventListener('touchend', (e) => {
      refFlicking.current?.enableInput();
    });
  }, []);

  useEffect(() => {
    refFlicking.current?.resize();
  }, [layout.windowSize.width]);

  // 탭변경시 광고 impression호출을 위함
  useEffect(() => {
    dispatch(actions.tplacehome.updatePlaceAdsTickKey());
  }, [dispatch, tplacehome.currentTab]);

  useOnce(!!tailParam?.current_tab, () => {
    const isDiscovery = tailParam.current_tab.toUpperCase() === ETPlaceTab.DISCOVERY;

    if (isDiscovery) {
      dispatch(actions.tplacehome.setCurrentTab(ETPlaceTab.DISCOVERY));
    }
  });

  return (
    <div className={s.wrap} ref={wrapRef}>
      <Flicking
        ref={refFlicking}
        align="prev"
        onMoveStart={handleFlickingMoveStart}
        onWillChange={(e) => handleFlickingChange(e.index)}
        interruptable={false}
        defaultIndex={tplacehome.currentTab === ETPlaceTab.AROUND_ME ? 0 : 1}
      >
        <div
          className={s.flicking_item_wrap}
          data-ignore-tmap-ad-impression={tplacehome.currentTab !== ETPlaceTab.AROUND_ME}
        >
          <AroundMe />
        </div>
        <div
          className={s.flicking_item_wrap}
          data-ignore-tmap-ad-impression={tplacehome.currentTab !== ETPlaceTab.DISCOVERY}
        >
          <Discovery />
        </div>
      </Flicking>
    </div>
  );
};

export default TPlaceMain;
