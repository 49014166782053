import {useCallback} from 'react';
import TMapSender, {TPoiDataJson} from '@lcc/tmap-inapp';
import useThrottle from 'hooks/useThrottle';
import {ReactComponent as IconShare} from 'resource/images/@tmds_basic/ico_share.svg';

import s from 'styles/components/ShareButton.module.scss';

type TProps = {
  disabled?: boolean;
  onClick?: VoidFunction;
  poiDataJson?: Nullable<TPoiDataJson>;
};

const ShareButton = ({disabled, onClick, poiDataJson}: TProps) => {
  const ableToRun = useThrottle();

  const handleClickShare = useCallback(() => {
    if (ableToRun()) {
      onClick?.();
      poiDataJson && TMapSender.shareWithPoiData(poiDataJson);
    }
  }, [ableToRun, poiDataJson, onClick]);

  return (
    <button disabled={disabled} className={s.share} onClick={handleClickShare}>
      <IconShare />
    </button>
  );
};

export default ShareButton;
