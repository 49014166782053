import TMapSender, {init, ECallbackKeys, TMapReceiver} from '@lcc/tmap-inapp';
import {HybridBridge} from 'utils/searchBar';
import {datadogRum} from '@datadog/browser-rum';
import TinyEmitter from 'tiny-emitter';

import {TLonLat} from 'types/Map';
import {EAddressType, TMapContext} from 'ducks/userInfo/types';

import actions from 'ducks/actions';

import {isLocalEnv, isProdEnv} from 'constant/Env';
import {POI_DETAIL_SERVICE_NAME} from 'constant/DeepLink';
import Storage from '@lcc/web-storage';
import ua from 'utils/uaParser';
import {TPoiDetailParam, TShareData} from 'types/App';
import {parseDeepLink} from './url';
import {StorageKey} from 'constant/Storage';

let getUserKeyRetryCount = 0;

export const ReceiverEmitter = new (TinyEmitter as any)();
export enum EReceiverEmitterActions {
  DISPATCH_STORE = 'DISPATCH_STORE',
}

export const getCurrentMapContext = () => {
  return new Promise<TMapContext>((resolve, reject) => {
    let timeout = setTimeout(() => {
      if (timeout) {
        clearTimeout(timeout);
        reject();
      }
    }, 30);

    TMapReceiver.setCallback(ECallbackKeys.CURRENT_MAP_CONTEXT, (data) => {
      resolve(data);
      clearTimeout(timeout);
    });
    TMapSender.getCurrentMapContext();
  });
};

export const getLastPosition = () => {
  return new Promise<TLonLat>((resolve, reject) => {
    let timeout = setTimeout(() => {
      if (timeout) {
        clearTimeout(timeout);
        reject();
      }
    }, 10000);

    TMapReceiver.setCallback(ECallbackKeys.GET_POSITION_FAIL, () => {
      if (timeout) {
        clearTimeout(timeout);
        reject();
      }
    });

    TMapReceiver.setCallback(ECallbackKeys.LAST_POSITION, (nowPosition: TLonLat) => {
      resolve(nowPosition);
      clearTimeout(timeout);
    });
    TMapSender.getLastPosition();
  });
};

export const getCurrentPosition = () => {
  return new Promise<TLonLat>((resolve, reject) => {
    let timeout = setTimeout(() => {
      if (timeout) {
        clearTimeout(timeout);
        reject();
      }
    }, 10000);

    TMapReceiver.setCallback(ECallbackKeys.GET_POSITION_FAIL, () => {
      if (timeout) {
        clearTimeout(timeout);
        reject();
      }
    });

    TMapReceiver.setCallback(ECallbackKeys.NOW_POSITION, (nowPosition: TLonLat) => {
      resolve(nowPosition);
      clearTimeout(timeout);
    });
    TMapSender.getCurrentPosition();
  });
};

init({
  isInApp: ua.isInApp,
  isLocalEnv: false && isLocalEnv,
  isProdEnv,
  isIos: ua.isIos,
  isAndroid: ua.isAndroid,
  appVersion: ua.tmapAppVersion,
  isSkIOS: ua.isSkIOS,
  isKuIOS: ua.isKuIOS,

  setTmapInfo: true,

  onBeforeSend: (funcName, data) => {
    datadogRum.addAction('TMapSender', {
      message: funcName,
      data,
    });
  },
  onBeforeReceive: (funcName, data) => {
    datadogRum.addAction('TMapReceiver', {
      message: funcName,
      data,
    });
  },
  onReceive: (type, data) => {
    switch (type) {
      case ECallbackKeys.DISPLAY_INFO: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.layout.setLandscape(data.isLandscape)
        );
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.layout.setStatusBarHeight(data.nowStatusBarHeight)
        );
        if (data.displayHeight > 0) {
          ReceiverEmitter.emit(
            EReceiverEmitterActions.DISPATCH_STORE,
            actions.layout.setDisplayHeight(data.displayHeight)
          );
        }
        break;
      }

      case ECallbackKeys.ACCESS_KEY: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setAccessKey(data)
        );
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setSessionKey(`${data}_${Date.now()}`)
        );

        break;
      }

      case ECallbackKeys.USER_KEY: {
        if (!data && getUserKeyRetryCount < 2) {
          window.setTimeout(() => {
            TMapSender.getUserKey();
            getUserKeyRetryCount++;
          }, 300);

          return;
        }

        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setUserKey(data)
        );

        break;
      }

      case ECallbackKeys.DEVICE_AD_ID: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setAdId(data || '')
        );
        break;
      }

      case ECallbackKeys.DEVICE_DEVICE_ID: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setDeviceId(data)
        );
        break;
      }

      case ECallbackKeys.DEVICE_SERVICE_VENDOR_ID: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setDeviceServiceVendorId(data)
        );
        break;
      }

      case ECallbackKeys.MAP_FONT_SIZE: {
        ReceiverEmitter.emit(EReceiverEmitterActions.DISPATCH_STORE, actions.map.setFontSize(data));
        break;
      }

      case ECallbackKeys.IS_SHOW_FAVORITE: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setShowFavorite(data)
        );
        break;
      }

      case ECallbackKeys.DEVICE_CARRIER_NAME: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setCarrierName(data)
        );
        break;
      }

      case ECallbackKeys.IS_ROAD_ADDRESS_TYPE: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setAddressType(data ? EAddressType.ROAD : EAddressType.JIBUN)
        );
        break;
      }

      case ECallbackKeys.IS_SHOW_RECENTLY_DESTINATION: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setRecentlyDestination(data)
        );
        break;
      }

      case ECallbackKeys.SESSION_ID: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setSessionId(data)
        );
        break;
      }

      case ECallbackKeys.USER_SETTING: {
        // eslint-disable-next-line no-console
        console.log('====', 'ECallbackKeys.USER_SETTING');
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setSendDestinationToCar(data)
        );
        break;
      }

      case ECallbackKeys.REMOTE_CONFIG: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.remote.setRawConfig(data)
        );
        break;
      }

      case ECallbackKeys.GET_USER_CAR_FUEL: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setUserCarFuel(data)
        );
        break;
      }

      case ECallbackKeys.GET_USER_CAR_MODEL: {
        ReceiverEmitter.emit(
          EReceiverEmitterActions.DISPATCH_STORE,
          actions.userInfo.setUserCarModel(data)
        );
        break;
      }
    }
  },
});

TMapReceiver['onResume'] = () => {
  ReceiverEmitter.emit(
    EReceiverEmitterActions.DISPATCH_STORE,
    actions.userInteraction.setResumeKey(Date.now())
  );

  getIsHybrid() && HybridBridge.getSearchContext();
};
TMapReceiver['onRefresh'] = () => {
  ReceiverEmitter.emit(
    EReceiverEmitterActions.DISPATCH_STORE,
    actions.userInteraction.setRefreshKey(Date.now())
  );
};
TMapReceiver['onPause'] = () => {
  ReceiverEmitter.emit(
    EReceiverEmitterActions.DISPATCH_STORE,
    actions.userInteraction.setPauseKey(Date.now())
  );
};

if (!isProdEnv) {
  window['TMapSender'] = TMapSender;
  window['TMapReceiver'] = TMapReceiver;
}

export const getIsHybrid = () => {
  return window.location.href.includes('search/hybrid');
};

export const getDefaultStatusBarHeight = () => {
  if (getIsHybrid()) {
    const cachedStatusBarHeight = Storage.getItem(StorageKey.STATUSBAR_HEIGHT);
    const numberHeight = parseInt(cachedStatusBarHeight, 10);

    if (numberHeight > 0) {
      return numberHeight;
    }
    return ua.isIos ? 44 : 24;
  }
  return 0;
};

export const callShareInterface = ({title, text, url}: TShareData, {onSuccess, onFail}) => {
  TMapSender.share(title, text || '티맵 모빌리티', url);
};

export const getPoiDetailDataFromDeepLink = (deeplink): Nullable<TPoiDetailParam> => {
  const {serviceName, serviceData} = parseDeepLink(deeplink);

  if (serviceName === POI_DETAIL_SERVICE_NAME && !isNaN(Number(serviceData?.poiid))) {
    return {
      ...serviceData,
      poiId: Number(serviceData.poiid),
    };
  }

  return null;
};
