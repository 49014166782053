import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {HybridBridge} from 'utils/searchBar';

import {EAddressMode, TAddressItem} from 'types/Search';
import {TAddressSearch} from 'ducks/search/types';

import {SEARCH_INPUT_MAX_LENGTH} from 'constant/Search';
import {EMPTY_ARRAY} from 'constant/Vars';
import {EAddressCode} from 'constant/Place';

import useSearchAddress from 'hooks/useSearchAddress';
import {useAppSelector} from 'ducks/hooks';

import {filteredJoinText} from 'utils/string';
import {sendSearchClickLog} from 'utils/logManager';

import {ReactComponent as LoadingCircular} from 'resource/images/loading-circular.svg';
import CateItem from 'components/legacySearch/CateItem';
import AddressItem from 'components/legacySearch/AddressItem';
import ErrorReload from 'components/ErrorReload';

import {ReactComponent as IconDot} from 'resource/images/@tmds_element/ico_dot_between.svg';
import {ReactComponent as IconRemove} from 'resource/images/@tmds_solid/ico_cancel_solid.svg';

import s from 'styles/components/legacySearch/AddressTab.module.scss';

type TProps = {
  onChange?: (address: string, addressOptions: Nullable<TAddressSearch>) => void;
  onSearch?: () => void;
  loadKey?: number;
};

const AddressTab = ({onChange, onSearch, loadKey}: TProps) => {
  const [nowMode, setMode] = useState<EAddressMode>(EAddressMode.CATE1);
  const [cate1, setCate1] = useState<Nullable<TAddressItem>>(null);
  const [cate2, setCate2] = useState<Nullable<TAddressItem>>(null);
  const [cate3, setCate3] = useState<Nullable<TAddressItem>>(null);
  const [isRoadType, setRoadType] = useState<boolean>(true);
  const [detail, setDetail] = useState<string>('');
  const [detailInputFocused, setDetailInputFocus] = useState<boolean>(false);
  const [nowResult, setNowResult] = useState<TAddressItem[]>(EMPTY_ARRAY);

  const detailInputRef = useRef<HTMLInputElement>(null);
  const {isHybrid, addressSearch, isLandscape} = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
    addressSearch: state.search.addressSearch,
    isLandscape: state.layout.appSize.isLandscape,
  }));
  const refAddress = useRef<string>('');

  const ERROR_TOP = useMemo(() => (isLandscape ? 30 : 150), [isLandscape]);

  const result = useSearchAddress({
    depth: nowMode !== EAddressMode.DETAIL ? parseInt(nowMode, 10) : 0,
    regionId: cate2?.areaId || cate1?.areaId,
    isRoad: isRoadType,
  });

  useEffect(() => {
    if (!addressSearch?.cate1) {
      setMode(EAddressMode.CATE1);
      setDetail('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadKey]);

  useEffect(() => {
    if (nowMode === EAddressMode.DETAIL) {
      return;
    }

    setDetail('');
    setCate3(null);

    if (nowMode === EAddressMode.CATE3) {
      return;
    }

    setCate2(null);

    if (nowMode === EAddressMode.CATE2) {
      return;
    }

    setCate1(null);
  }, [nowMode]);

  useEffect(() => {
    if (result.loaded && result.data) {
      setNowResult(result.data);
    }
  }, [result]);

  useEffect(() => {
    if (isHybrid && detailInputFocused) {
      if (isLandscape) {
        HybridBridge.showSearchBar(false);
      } else {
        HybridBridge.showSearchBar(true);
      }
    }
  }, [isLandscape]);

  useEffect(() => {
    const options = cate1 && cate2 && cate3 ? {cate1, cate2, cate3, detail, isRoadType} : null;
    const text = filteredJoinText([
      // 세종시의 경우 2뎁스에서 세종시 선택시 "세종 세종시" 를 "세종시"로 입력되도록 수정
      cate2?.areaId === EAddressCode.D_2_SEJONG ? '' : cate1?.areaName,
      cate2?.areaName,
      cate3?.areaName,
      detail,
    ]);

    refAddress.current = text;
    onChange?.(text, options);
  }, [onChange, cate1, cate2, cate3, detail, isRoadType]);

  const handleClickAddressItem = useCallback(
    (data: TAddressItem) => {
      setNowResult(EMPTY_ARRAY);
      if (nowMode === EAddressMode.CATE1) {
        setMode(EAddressMode.CATE2);
        setCate1(data);
        sendSearchClickLog('tap.addr_depth1', {
          search_query: data,
        });
      } else if (nowMode === EAddressMode.CATE2) {
        setMode(EAddressMode.CATE3);
        setCate2(data);
        sendSearchClickLog('tap.addr_depth2', {
          search_query: data,
        });
      } else if (nowMode === EAddressMode.CATE3) {
        setMode(EAddressMode.DETAIL);
        setCate3(data);
        sendSearchClickLog('tap.addr_depth3', {
          search_query: data,
          // road: 도로명, village: 읍/면/동명
          addr_type: isRoadType ? 'road' : 'village',
        });
        detailInputRef.current?.focus();
      }
    },
    [nowMode, isRoadType]
  );

  const isTileMode = useMemo(
    () => !!(nowMode !== EAddressMode.CATE3 || !isRoadType),
    [nowMode, isRoadType]
  );

  const handleSubmit = useCallback(
    (e, fromButton?: boolean) => {
      e?.preventDefault();

      detailInputRef.current?.blur();

      const inputValue = refAddress.current || '';

      if (fromButton) {
        sendSearchClickLog('tap.searchbtn', {
          search_query: inputValue,
        });
      } else {
        sendSearchClickLog('ime_tap.searchbtn', {
          search_query: inputValue,
          user_query: inputValue,
        });
      }

      onSearch?.();
    },
    [onSearch]
  );

  const handleClickMode = useCallback((targetMode) => {
    setNowResult(EMPTY_ARRAY);
    setMode(targetMode);
  }, []);

  const handleFocusDetail = useCallback(() => {
    setDetailInputFocus(true);

    if (isHybrid && isLandscape) {
      HybridBridge.showSearchBar(false);
    }
  }, [isLandscape, isHybrid]);

  const handleBlurDetail = useCallback(() => {
    window.setTimeout(() => setDetailInputFocus(false), 100);

    if (isHybrid) {
      HybridBridge.showSearchBar(true);
    }
  }, [isHybrid]);

  if (result.loaded && result.error) {
    return (
      <ErrorReload
        top={ERROR_TOP}
        onReload={() => {
          window.location.reload();
          sendSearchClickLog('tap.fail_refresh');
        }}
      />
    );
  }

  return (
    <div className={s.search_address_wrap}>
      <div className={s.cate_list_wrap} role="list">
        <div className={s.vertical_scroll_wrap}>
          <CateItem
            data={cate1}
            onClick={() => {
              handleClickMode(EAddressMode.CATE1);
              sendSearchClickLog('tab_tap.addr_depth1');
            }}
            placeholder="광역시/도"
          />

          <CateItem
            data={cate2}
            onClick={() => {
              handleClickMode(EAddressMode.CATE2);
              sendSearchClickLog('tab_tap.addr_depth2');
            }}
            placeholder="시/군/구"
          />

          <CateItem
            data={cate3}
            onClick={() => {
              handleClickMode(EAddressMode.CATE3);
              sendSearchClickLog('tab_tap.addr_depth3');
            }}
            placeholder="도로/읍/면/동"
          />
        </div>
      </div>
      {nowMode === EAddressMode.CATE3 && (
        <div className={s.type_wrap} role="radiogroup">
          <button
            className={classNames(s.type_btn, {
              [s.active]: isRoadType,
            })}
            aria-selected={isRoadType}
            role="radio"
            aria-controls="address_item_list"
            onClick={() => {
              if (isRoadType) {
                return;
              }

              setRoadType(true);
              setNowResult(EMPTY_ARRAY);
              sendSearchClickLog('tab_tap.road');
            }}
          >
            도로명
          </button>

          <IconDot className={s.icon_dot} />

          <button
            className={classNames(s.type_btn, {
              [s.active]: !isRoadType,
            })}
            role="radio"
            aria-selected={!isRoadType}
            aria-controls="address_item_list"
            onClick={() => {
              if (!isRoadType) {
                return;
              }

              setRoadType(false);
              setNowResult(EMPTY_ARRAY);
              sendSearchClickLog('tab_tap.village');
            }}
          >
            지번
          </button>
        </div>
      )}

      {result.loading && (
        <div className={s.spinner} style={{height: '100%'}}>
          <LoadingCircular className={s.rotate_icon} />
        </div>
      )}

      <div
        className={classNames(s.address_result_wrap, {
          [s.smooth_list]: nowMode !== EAddressMode.DETAIL,
          [s.need_first_line]: nowMode === EAddressMode.CATE3,
        })}
        style={result.loading ? {display: 'none'} : {}}
        role="menu"
      >
        {nowMode !== EAddressMode.DETAIL && (
          <ul
            className={classNames({
              [s.tile_wrap]: isTileMode,
            })}
            id="address_item_list"
            role="radiogroup"
          >
            {nowResult?.map((n, idx) => (
              <AddressItem
                key={n.areaId}
                data={n}
                isTile={isTileMode}
                onClick={handleClickAddressItem}
              />
            ))}
          </ul>
        )}
        <form
          className={classNames(s.detail_address_wrap, {
            [s.show]: nowMode === EAddressMode.DETAIL,
            [s.focused]: detailInputFocused || false,
          })}
          onSubmit={handleSubmit}
        >
          <div className={s.detail_contents_wrap}>
            <div className={s.input_wrap}>
              <input
                ref={detailInputRef}
                onChange={(e) => setDetail(e.target.value.slice(0, SEARCH_INPUT_MAX_LENGTH))}
                value={detail}
                aria-placeholder="상세 주소 입력"
                placeholder="상세 주소 입력"
                onFocus={handleFocusDetail}
                onBlur={handleBlurDetail}
              />

              {detail && (
                <IconRemove
                  className={s.btn_remove}
                  onClick={() => {
                    setDetail('');
                    sendSearchClickLog('tap.x');
                  }}
                />
              )}
            </div>

            <button
              className={s.btn_search}
              onClick={(e) => {
                handleSubmit(e, true);
              }}
            >
              검색
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddressTab;
