import fetcher from 'utils/fetcher';
import {useCallback, useMemo, useRef} from 'react';
import {getApiTime} from 'utils/date';
import {ETMapBannerCode} from 'constant/Ads';
import {EProjectEnv} from 'constant/Env';
import {v4 as uuidv4} from 'uuid';
import qs from 'query-string';

const API_REQUEST_PATH = {
  [EProjectEnv.PROD]: 'https://frontman.tmobiapi.com/proxy/ads/serving/ads',
  [EProjectEnv.RTG]: 'https://frontman.tmobiapi.com/proxy/ads/serving/ads',
  [EProjectEnv.STAGE]: 'https://frontman-stg.tmobiapi.com/proxy/ads/serving/ads',
  [EProjectEnv.DTG]: 'https://frontman-stg.tmobiapi.com/proxy/ads/serving/ads',
  [EProjectEnv.DEV]: 'https://frontman-dev.tmobiapi.com/proxy/ads/serving/ads',
  [EProjectEnv.LOCAL]: 'https://frontman-dev.tmobiapi.com/proxy/ads/serving/ads',
};

type TTMapAdsLogType = 'vimp' | 'click';

enum ETMapAdsLogReplacer {
  MATERIALID = '${MATERIALID}',
  ORIENTATION = '${ORIENTATION}',
  VIMPRESSIONID = '${VIMPRESSIONID}',
}

type TTMapFrontManHeader = {
  'req-time'?: string; // YYYYMMDDHH24MISS
  'os-type': string;
  'os-version'?: string;
  'app-version': string;
  'app-code': string;
  'device-id'?: string;
  'App-Session-Id': string;
  Carrier?: string;
  UserKey: string;
  euk: string;
  Using: 'WEB';
};

type TTMapAdsRequestParam = {
  inventoryCode: string;
  w?: number;
  h?: number;
  posTime?: string;
  lat?: string;
  lon?: string;
  keyword?: string;
  lmt?: 0 | 1;
  adid?: string;
  idfa?: string;
  idfv?: string;
  language?: string;
};

export type TTMapAdUnitMaterial = {
  id: string;
  type: 'image' | 'video' | 'thumbnail' | 'logo';
  subType?: 'bgColor' | 'ms';
  url: string;
  sizeFormat?: {
    w: number;
    h: number;
    wratio?: number;
    hratio?: number;
    wmin?: number;
  };
  text?: string;
};

type TTMapAdUnit = {
  adId: string;
  materials: Array<TTMapAdUnitMaterial>;
  landingUrl?: string;
  trackingEventUrls: Array<{
    key: TTMapAdsLogType;
    value: string;
  }>;
  adType?: string;
  startDate?: string;
  endDate?: string;
};

type TTMapAdsResponse = {
  requestId: string;
  ads?: Array<TTMapAdUnit>;
  message?: string;
};

export enum EAdsErrorMessage {
  INVAILD_ENDPOINT = 'invalid endpoint',
  RESOURCE_LOAD_FAIL = 'invalid resource',
  NOT_SUPPORT_APP = 'not support app version',
}

export type TRequestSetting = {
  env: EProjectEnv;
  osType: 'AND' | 'IOS' | 'WIN' | 'ETC';
  osVersion: string;
  appVersion: string;
  appCode: string;
  deviceId: string;
  carrier: string;
  userKey: string;
  inventoryCode: ETMapBannerCode;
  lmt: 0 | 1;
  adid: string;
  idfa: string;
  idfv: string;
  language: string;
  deviceWidth: number;
  deviceHeight: number;
  orientation: 'portrait' | 'landscape';
  sessionId: string;
};

export type TRequestVariableParams = Required<
  Pick<TTMapAdsRequestParam, 'posTime' | 'lat' | 'lon' | 'keyword'>
>;

export type TAdsItem = {
  landingUrl?: string;
  imageUrl: string;
  logs: Record<TTMapAdsLogType, string>;
  originAd: TTMapAdUnit;
};

const convertTmapAdsToServiceAds = (ad): TAdsItem => {
  const firstHitMaterial: TTMapAdUnitMaterial | undefined = ad.materials.find(
    (m) => m.type !== 'video' && !!m.url
  );
  const logs = ad.trackingEventUrls.reduce(
    (prev, cur) => {
      return {
        ...prev,
        [cur.key]: cur.value.replace(ETMapAdsLogReplacer.MATERIALID, firstHitMaterial?.id || ''),
      };
    },
    {vimp: '', click: ''}
  );

  return {
    landingUrl: ad.landingUrl,
    imageUrl: firstHitMaterial?.url || '',
    logs,
    originAd: ad,
  };
};

export const useTMapAds = (requestOptions: TRequestSetting) => {
  const refLogId = useRef(uuidv4());
  const apiEndPoint = useMemo(() => API_REQUEST_PATH[requestOptions.env], [requestOptions.env]);
  const requestHeaders: Required<Omit<TTMapFrontManHeader, 'req-time'>> = useMemo(() => {
    return {
      'os-type': requestOptions.osType,
      'os-version': requestOptions.osVersion,
      'app-version': requestOptions.appVersion,
      'app-code': requestOptions.appCode,
      'device-id': requestOptions.deviceId,
      'App-Session-Id': requestOptions.sessionId,
      Carrier: requestOptions.carrier,
      UserKey: '$userKey',
      euk: requestOptions.userKey,
      Using: 'WEB',
    };
  }, [requestOptions]);

  const requestStaticParams: Required<Omit<TTMapAdsRequestParam, keyof TRequestVariableParams>> =
    useMemo(() => {
      return {
        inventoryCode: requestOptions.inventoryCode,
        w: requestOptions.deviceWidth,
        h: requestOptions.deviceHeight,
        lmt: requestOptions.lmt,
        adid: requestOptions.adid,
        idfa: requestOptions.idfa,
        idfv: requestOptions.idfv,
        language: requestOptions.language,
      };
    }, [requestOptions]);

  const callCompleteLog = useCallback(
    (targetUrl, callOption = {updateId: false}) => {
      if (callOption.updateId) {
        refLogId.current = uuidv4();
      }

      const headers: Required<TTMapFrontManHeader> = {
        ...requestHeaders,
        'req-time': getApiTime(),
      };
      const logEndpoint = targetUrl
        .replace(ETMapAdsLogReplacer.ORIENTATION, requestOptions.orientation)
        .replace(ETMapAdsLogReplacer.VIMPRESSIONID, refLogId.current);

      fetcher.get(logEndpoint, {
        headers,
      });
    },
    [requestOptions.orientation, requestHeaders]
  );

  const getTmapAds = useCallback(
    (variableParams: TRequestVariableParams) => {
      const headers: Required<TTMapFrontManHeader> = {
        ...requestHeaders,
        'req-time': getApiTime(),
      };
      const params: Required<TTMapAdsRequestParam> = {
        ...requestStaticParams,
        ...variableParams,
      };

      if (!apiEndPoint) {
        return Promise.reject(new Error(EAdsErrorMessage.INVAILD_ENDPOINT));
      }

      return fetcher
        .get(apiEndPoint, {
          headers,
          params,
          paramsSerializer: (p) => {
            return qs.stringify(p);
          },
        })
        .then<TAdsItem[]>((e) => {
          const response: TTMapAdsResponse = e.data;

          const adsResponse: TAdsItem[] = (response.ads || [])
            .map(convertTmapAdsToServiceAds)
            .filter((i) => !!i.imageUrl);

          if (adsResponse.length > 0) {
            return adsResponse;
          } else {
            throw new Error(response.message);
          }
        });
    },
    [apiEndPoint, requestHeaders, requestStaticParams]
  );

  return {getTmapAds, callCompleteLog};
};
