// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcoVehicleBusFilledSvg} from 'resource/@tmdsicon/ico_vehicle_bus_f.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcoVehicleBusFilled = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcoVehicleBusFilledSvg {...props} />} />;
};

export {IcoVehicleBusFilled};
