import {useState, useCallback} from 'react';

const DEFAULT_THROTTLE_TIME = 800;

const useThrottle = (globalDuration: number = DEFAULT_THROTTLE_TIME) => {
  const [lastTime, setTimer] = useState<number>(0);

  const ableToRun = useCallback(
    (duration: number = globalDuration) => {
      const nowTime = Date.now();

      if (nowTime - lastTime > duration) {
        setTimer(nowTime);

        return true;
      }

      return false;
    },
    [lastTime, globalDuration]
  );

  return ableToRun;
};

export default useThrottle;
