import {CSSProperties, useCallback, useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';
import LocalStorage from '@lcc/web-storage';
import TmdsVerticalPopup from 'components/@tmds/TmdsVerticalPopup';
import {StorageKey} from 'constant/Storage';
import useDimmedQuery, {EDimmedLayerId} from 'hooks/useDimmedQuery';
import {LAYER_ID} from 'types/App';
import useElement from 'hooks/useElement';
import {useAppSelector} from 'ducks/hooks';
import {moveByLandingType} from 'utils/url';

import s from 'styles/components/place/PlaceVerticalPopup.module.scss';

type TStoredPopupData = {
  version: number;
  lastUpdateTime: number;
};

const PlaceVerticalPopup = () => {
  const {statusBarHeight, remoteConfig, isLandscape} = useAppSelector((state) => ({
    statusBarHeight: state.layout.appSize.statusBarHeight,
    remoteConfig: state.remote,
    isLandscape: state.layout.appSize.isLandscape,
  }));

  const verticalPopup = useDimmedQuery({id: EDimmedLayerId.VERTICAL_POPUP});
  const layerContainer = useElement({id: LAYER_ID});
  const storedPopupData = useMemo<Nullable<TStoredPopupData>>(
    () => LocalStorage.getItem(StorageKey.TNOW_VERTICAL_POPUP),
    []
  );

  const popupVersion = Number(remoteConfig.tnowPopupVersion);
  const popupList = remoteConfig.tnowPopupList.map((p) => ({
    ...p,
    onClick: () => {
      verticalPopup.close();

      moveByLandingType(p.type, p.landingUrl);
    },
  }));

  const isNeverShowAgain = storedPopupData ? !!storedPopupData.lastUpdateTime : false;
  const isVersionChanged =
    !!remoteConfig.lastUpdateTime &&
    !!popupVersion &&
    popupVersion > 0 &&
    storedPopupData?.version !== popupVersion;
  const isShowPopup =
    (popupList.length > 0 && !isNeverShowAgain) || (popupList.length > 0 && isVersionChanged);

  const handleHide = useCallback(() => {
    verticalPopup.close();

    LocalStorage.setItem(StorageKey.TNOW_VERTICAL_POPUP, {
      version: popupVersion,
      lastUpdateTime: Date.now(),
    });
  }, [verticalPopup, popupVersion]);

  useEffect(() => {
    if (isShowPopup) {
      verticalPopup.open();
    }
  }, [isShowPopup]);

  useEffect(() => {
    if (verticalPopup.isOpen && isLandscape) {
      verticalPopup.close();
    }
  }, [verticalPopup.isOpen, isLandscape]);

  if (!verticalPopup.isOpen) {
    return null;
  }

  if (!isShowPopup) {
    return null;
  }

  return (
    layerContainer &&
    createPortal(
      <div
        style={
          {
            '--statusbar-height': `${statusBarHeight}px`,
          } as CSSProperties
        }
      >
        <TmdsVerticalPopup
          popupList={popupList}
          className={s.popup_wrap}
          onHide={handleHide}
          onClose={() => verticalPopup.close()}
          onClickDimmed={() => verticalPopup.close()}
        />
      </div>,
      layerContainer
    )
  );
};

export default PlaceVerticalPopup;
