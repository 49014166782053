export const MOCK_DATA = {
  center: {
    centerX: 126.97794644999755,
    centerY: 37.56629241769975,
  },
  list: [
    {
      poiId: 219475,
      name: '롯데백화점 본점',
      centerX: 126.98176067478202,
      centerY: 37.564740800166675,
      cnt: 36,
    },
    {
      poiId: 334736,
      name: '신세계백화점 본점신관',
      centerX: 126.980788583502,
      centerY: 37.5603246382955,
      cnt: 23,
    },
    {
      poiId: 1436708,
      name: '신세계백화점 본점',
      centerX: 126.981149656007,
      centerY: 37.5609356809609,
      cnt: 13,
    },
    {
      poiId: 218661,
      name: '조계사',
      centerX: 126.981816135952,
      centerY: 37.5739064069448,
      cnt: 10,
    },
    {
      poiId: 219045,
      name: '조선일보미술관',
      centerX: 126.975261232304,
      centerY: 37.5678515113201,
      cnt: 10,
    },
    {
      poiId: 3860171,
      name: 'SFC몰',
      centerX: 126.978288730572,
      centerY: 37.5684347958752,
      cnt: 9,
    },
    {
      poiId: 1441966,
      name: '올레스퀘어',
      centerX: 126.977844289781,
      centerY: 37.5721843593885,
      cnt: 8,
    },
    {
      poiId: 1109052,
      name: '라세느 롯데호텔서울',
      centerX: 126.981010736381,
      centerY: 37.5653796105326,
      cnt: 6,
    },
    {
      poiId: 551556,
      name: '낙원상가',
      centerX: 126.988176683413,
      centerY: 37.5727121399808,
      cnt: 6,
    },
    {
      poiId: 8662644,
      name: '산채향 [한식]',
      centerX: 126.988815581467,
      centerY: 37.5658518263676,
      cnt: 5,
    },
    {
      poiId: 8649499,
      name: '스윗밸런스파인애비뉴점',
      centerX: 126.988565605158,
      centerY: 37.5657407265615,
      cnt: 5,
    },
    {
      poiId: 8632538,
      name: '하루엔소쿠 [일식]',
      centerX: 126.988565605158,
      centerY: 37.5657407265615,
      cnt: 5,
    },
    {
      poiId: 5779670,
      name: '밥상의 신',
      centerX: 126.982288375593,
      centerY: 37.5677404564547,
      cnt: 5,
    },
    {
      poiId: 5452573,
      name: '인생 한방찻집',
      centerX: 126.982288375593,
      centerY: 37.5677404564547,
      cnt: 5,
    },
    {
      poiId: 4709022,
      name: '섬',
      centerX: 126.982288375593,
      centerY: 37.5677404564547,
      cnt: 5,
    },
    {
      poiId: 4484688,
      name: '우동테이',
      centerX: 126.982288375593,
      centerY: 37.5677404564547,
      cnt: 5,
    },
    {
      poiId: 1245588,
      name: '이영자삼계탕',
      centerX: 126.982288375323,
      centerY: 37.5677682310197,
      cnt: 5,
    },
    {
      poiId: 6429067,
      name: '스시미토',
      centerX: 126.982288375593,
      centerY: 37.5677404564547,
      cnt: 5,
    },
    {
      poiId: 5673258,
      name: '바오차이 [중식]',
      centerX: 126.988760031477,
      centerY: 37.5657962768938,
      cnt: 5,
    },
    {
      poiId: 5676611,
      name: '창고43 명동점',
      centerX: 126.988760031206,
      centerY: 37.565824051459,
      cnt: 5,
    },
  ],
};
