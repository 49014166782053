export enum EPublicTransportType {
  PUBLIC_POI = 'PLACE',
  BUS_STATION = 'BUSSTATION',
  SUBWAY = 'SUBWAY',
  ROUTE = 'ROUTE',
  BUS_LINE = 'BUSLINE',
}

export enum ESubwayExpressType {
  SUBWAY_GENERAL = 'SUBWAY_GENERAL', // 일반
  SUBWAY_EXPRESS = 'SUBWAY_EXPRESS', // 급행
  SUBWAY_SUPER_EXPRESS = 'SUBWAY_SUPER_EXPRESS', // 특급
}

// 첫차 막차 여부
export enum ESubwayScheduleType {
  NORMAL = 'NORMAL',
  FIRST = 'FIRST', // 첫차
  LAST = 'LAST', // 막차
}

export enum ECrowdType {
  RELAX = 'RELAX', //여유
  USUAL = 'USUAL', // 보통
  CROWD = 'CROWD', // 혼잡
}

export type TSubwaySchedule = {
  time: {
    hour: number;
    minute: number;
    second: number;
  };
  startPointName: string;
  headSign: string;
  expressCode: ESubwayExpressType;
  first: boolean;
  last: boolean;
  remainTimes: number;
  lastStationId: number;
};

export type TSubwayStation = {
  stationId: number;
  stationName: string;
  cityCode: number;
};

export type TSubwayArrival = {
  remainSeconds: number;
  arrivalText: string;
  headSign: string;
  expressType: ESubwayExpressType;
  scheduleType: ESubwayScheduleType;
  close?: boolean;
  order: number;
  firstStationId: number;
  lastStationId: number;
  trainId: string;
  crowdType?: ECrowdType;
  isCircular?: boolean; // 순환
  expectedArrivalTime: string;
  subwayLineId: number;
  last: Nullable<boolean>; // 막차
  start: Nullable<boolean>; // 첫차
};

// https://tmobi.atlassian.net/wiki/spaces/TARDIS/pages/358252991/station
export type TSubwayArrivalInfo = {
  stationId: number;
  upScheduleList: TSubwaySchedule[];
  downScheduleList: TSubwaySchedule[];
  prevStationList: TSubwayStation[];
  nextStationList: TSubwayStation[];
  upRealtimeArrivalList: TSubwayArrival[];
  downRealtimeArrivalList: TSubwayArrival[];
  lastUpSchedule: TSubwaySchedule;
  lastDownSchedule: TSubwaySchedule;
  isRealTime?: boolean;
};

// https://tmobi.atlassian.net/wiki/spaces/TARDIS/pages/358252991/V2+station#BisArrivalDto
export type TBusArrivalInfo = {
  stationId: number;
  busStationDetail: Nullable<{
    name: string;
    displayId: string;
    direction: string;
  }>;
};

export type TPoiMyFavoriteDto = {
  homePkey: string;
  homePoiId: string;
  homeNavSeq: string;
  homeCustName: string;
  homeNoorX: string;
  homeNoorY: string;
  homeNoorLon: string;
  homeNoorLat: string;
  homeCenterX: string;
  homeCenterY: string;
  homeCenterLon: string;
  homeCenterLat: string;
  homeLcdName: string;
  homeMcdName: string;
  homeScdName: string;
  homeDcdName: string;
  homePrimaryBun: string;
  homeSecondaryBun: string;
  homeMlClass: string;
  homeRoadName: string;
  homeRoadScdName: string;
  homeBldNo1: string;
  homeBldNo2: string;
  homeRepClsName: string;
  homeClsAName: string;
  homeClsBName: string;
  homeClsCName: string;
  homeClsDName: string;
  homeRpFlag: string;
  homeTelNo: string;
  homeAddInfo: string;
  homeFixedIndex: string;
  homeInsDatetime: string;
  officePkey: string;
  officePoiId: string;
  officeNavSeq: string;
  officeCustName: string;
  officeNoorX: string;
  officeNoorY: string;
  officeNoorLon: string;
  officeNoorLat: string;
  officeCenterX: string;
  officeCenterY: string;
  officeCenterLon: string;
  officeCenterLat: string;
  officeLcdName: string;
  officeMcdName: string;
  officeScdName: string;
  officeDcdName: string;
  officePrimaryBun: string;
  officeSecondaryBun: string;
  officeMlClass: string;
  officeRoadName: string;
  officeRoadScdName: string;
  officeBldNo1: string;
  officeBldNo2: string;
  officeRepClsName: string;
  officeClsAName: string;
  officeClsBName: string;
  officeClsCName: string;
  officeClsDName: string;
  officeRpFlag: string;
  officeTelNo: string;
  officeAddInfo: string;
  officeFixedIndex: string;
  officeInsDatetime: string;
  noorX: string;
  noorY: string;
};

export type TPoiRecentDto = {
  pkey: string;
  poiId: string;
  navSeq: string;
  custName: string;
  noorX: string;
  noorY: string;
  noorLon: string;
  noorLat: string;
  centerX: string;
  centerY: string;
  centerLon: string;
  centerLat: string;
  lcdName: string;
  mcdName: string;
  scdName: string;
  mlClass: string;
  clsAName: string;
  clsBName: string;
  clsCName: string;
  clsDName: string;
  rpFlag: number;
  totalCnt: string;
  svcDate: string;
  dayOffYn: string;
  parkYn: string;
  famousFoodYn: string;
  callCntTerm: string;
  highHhSale: string;
  minOilYn: string;
  hhPrice: number;
  ggPrice: number;
  llPrice: number;
  highHhPrice: number;
  highGgPrice: number;
  asctCardYn: string;
  fixedIndex: string;
  fixRecommandYn: string;
};

export type TPoiFavoriteDto = {
  dbSeq: string;
  pkey: string;
  poiId: string;
  navSeq: string;
  custName: string;
  noorX: string;
  noorY: string;
  noorLon: string;
  noorLat: string;
  centerX: string;
  centerY: string;
  centerLon: string;
  centerLat: string;
  lcdName: string;
  mcdName: string;
  scdName: string;
  mlClass: string;
  clsAName: string;
  clsBName: string;
  clsCName: string;
  clsDName: string;
  rpFlag: number;
  totalCnt: string;
  svcDate: string;
  dayOffYn: string;
  parkYn: string;
  famousFoodYn: string;
  callCntTerm: string;
  highHhSale: string;
  minOilYn: string;
  hhPrice: number;
  ggPrice: number;
  llPrice: number;
  highHhPrice: number;
  highGgPrice: number;
  asctCardYn: string;
  fixedIndex: string;
  fixRecommandYn: string;
};

export type TRouteMyFavoriteDto = {
  routeTitle: string;
  insDatetime: string;
  startRouteDto: {
    name: string;
    poiId: string;
    navX: string;
    navY: string;
    rpFlag: number;
  };
  endRouteDto: {
    name: string;
    poiId: string;
    navX: string;
    navY: string;
    rpFlag: number;
  };
  midRouteDtos: {
    name: string;
    poiId: string;
    navX: string;
    navY: string;
    rpFlag: number;
  }[];
};

export type TPublicTransportFavoriteDto = {
  favoriteId: string;
  favoriteType: EPublicTransportType;
  details: TFavoriteRouteDetail | TFavoriteSubwayDetail | TFavoriteBusDetail;
  insDatetime?: string;
  updDatetime?: string;
  registerDatetime?: string;
  updateDateTime?: string;
};

export type TFavoriteRouteDetail = {
  favoriteType: EPublicTransportType;
  origin: TRouteInfo;
  destination: TRouteInfo;
};

export type TRouteInfo = {
  pkey: string;
  poiId: string;
  poiName: string;
  navSeq: string;
  noorX: string;
  noorY: string;
  lcdName: string;
  mcdName: string;
  scdName: string;
  primaryBun: string;
  secondaryBun: string;
  roadName: string;
  bldNo1: string;
  bldNo2: string;
};

export type TGeoCoordinate = {
  noorX: number;
  noorY: number;
  centerX: number;
  centerY: number;
  // wgs84
  centerLat?: number;
  centerLon?: number;
  noorLat?: number;
  noorLon?: number;
};

export type TFavoriteSubwayDetail = {
  favoriteType: EPublicTransportType;
  stationId: number;
  stationName: string;
  poiId: string;
  geoCoordinate: TGeoCoordinate;
  lineMapTypeName: string;
  lineTypeCode: number;
};

export type TFavoriteBusDetail = {
  favoriteType: EPublicTransportType;
  stationId: number;
  stationName: string;
  poiId: string;
  geoCoordinate: {
    noorX: number;
    noorY: number;
    centerX: number;
    centerY: number;
  };
  stationLocalDisplayId: string;
  toBusStationName: string;
  busLines: {
    favoriteType: EPublicTransportType.BUS_LINE;
    busLineId: number;
    busLineTypeName: string;
    busNumber: string;
    cityUpperName: string;
    startStationName: string;
    endStationName: string;
  }[];
};

export type TPublicTransportRecentDto = {
  transportId: string;
  transportType: EPublicTransportType;
  details: TPublicTransPlaceDetail | TBusStationDetails | TSubwayDetails;
  svcDate: string;
};

export type TPublicTransPlaceDetail = {
  destinationType: EPublicTransportType.PUBLIC_POI;
  place: TPlace;
};

export type TBusStationDetails = {
  destinationType: EPublicTransportType.BUS_STATION;
  busStation: TStation;
};

export type TSubwayDetails = {
  destinationType: EPublicTransportType.SUBWAY;
  subway: TStation & {
    lineMapTypeName: string;
    lineTypeCode: number;
  };
};

export type TPlace = {
  pkey: string;
  poiId: string;
  poiName: string;
  noorX: string;
  noorY: string;
  centerX: string;
  centerY: string;
  noorLon: number;
  noorLat: number;
  centerLon: number;
  centerLat: number;
  lcdName: string;
  mcdName: string;
  scdName: string;
  mlClass: string;
};

export type TStation = {
  stationId: number;
  poiId: string;
  name: string;
  geoCoordinate: TGeoCoordinate;
};

export type TPublicTransportFavoriteResponse = {
  favoriteId: string;
  favoriteType: EPublicTransportType.SUBWAY;
  registerDatetime: string;
  updateDateTime: string;
  details?: TPublicTransPlaceDetail | TBusStationDetails | TSubwayDetails;
};
