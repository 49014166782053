import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {datadogLogs} from '@datadog/browser-logs';
import {frontmanFetcher} from 'utils/fetcher';
import {FRONTMAN_API_PATH} from 'constant/Api';
import actions from 'ducks/actions';
import {useAppSelector} from 'ducks/hooks';
import useInitApiHeader from './useInitApiHeader';

const useUserPublicTransData = () => {
  const dispatch = useDispatch();
  const {favoritesPublic} = useAppSelector((state) => state.userInfo.personalPlace.data);

  const isHeaderInitialized = useInitApiHeader();

  const init = useCallback(() => {
    if (!isHeaderInitialized) {
      datadogLogs.logger.error(
        'Frontman Header Initialize Error',
        frontmanFetcher.defaults?.headers
      );
      return;
    }

    frontmanFetcher.get(FRONTMAN_API_PATH.GET_PUBLIC_TRANS_FAVORITE).then((res) => {
      const favoriteMap = (res.data.publicTransportFavorites || []).reduce((obj, v) => {
        const d = v.details;
        const stationId = d?.stationId;

        if (!stationId) {
          return obj;
        }
        return {
          ...obj,
          [stationId]: {
            ...d,
            ...d.details,
          },
        };
      }, {});

      const newList = favoritesPublic.map((f) => {
        const item = f.stationId ? favoriteMap[f.stationId] : null;
        if (item) {
          const coord = item.geoCoordinate;

          return {
            ...f,
            centerX: coord.centerX,
            centerY: coord.centerY,
            navX: coord.noorX,
            navY: coord.noorY,
            wgs84CenterX: coord.centerLon,
            wgs84CenterY: coord.centerLat,
            wgs84NavX: coord.noorLon,
            wgs84NavY: coord.noorLat,
            geoCoordinate: coord,
            publicTransportName: item.stationName,
          };
        }
        return f;
      });

      dispatch(actions.userInfo.updatePublicTransFavorite(newList));
    });
  }, [favoritesPublic, dispatch, isHeaderInitialized]);

  return {init};
};

export default useUserPublicTransData;
