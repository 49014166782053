import {PropsWithChildren, useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation} from 'react-router-dom';

import {TRouter} from 'types/Router';
import {LAYER_ID} from 'types/App';
import {EOrientation} from 'types/Device';

import {useAppSelector} from 'ducks/hooks';

import {PAGE_TITLE_PREFIX} from 'constant/Text';

import renderNestedRouter from 'utils/renderNestedRouter';
import {getPageTitle} from 'utils/formatter';

import {DimmedLayer} from 'components/DimmedLayer';

type TProps = PropsWithChildren<{
  className?: string;
  routes?: TRouter[];
}>;

const CommonLayout = ({className, children, routes = []}: TProps) => {
  const {pathname} = useLocation();
  const title = useMemo(() => getPageTitle(routes, pathname), [routes, pathname]);
  const isLandscape = useAppSelector((state) => state.layout.appSize.isLandscape);

  useEffect(() => {
    document.body.setAttribute(
      'data-orientation',
      isLandscape ? EOrientation.LANDSCAPE : EOrientation.PORTRAIT
    );
  }, [isLandscape]);

  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLE_PREFIX}
          {title ? ` - ${title}` : ''}
        </title>
      </Helmet>

      <div className={className}>
        {children}
        {renderNestedRouter(routes)}
        <div id={LAYER_ID} />
        <DimmedLayer />
      </div>
    </>
  );
};

export default CommonLayout;
