import {EAccommodationProviderType, TPoiItem, TSearchPoi} from 'types/App';
import useDimmedQuery from 'hooks/useDimmedQuery';
import {useAppSelector} from 'ducks/hooks';
import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import useMoveToTarget from 'hooks/useMoveToTarget';
import {isOver10_6Version} from 'utils/tmapUtils';
import AppVersionPopup from './AppVersionPopup';
import TMapSender from '@lcc/tmap-inapp';
import useLogger from 'hooks/useLogger';
import {sendSearchClickLog} from 'utils/logManager';
import {parsePoiInfoToNavInfo} from 'utils/search';
import useAddress from 'hooks/useAddress';

import {ReactComponent as IconDotBetween} from 'resource/images/ico_dot_between.svg';

import s from 'styles/components/CatchTableReservationButton.module.scss';

type TProps = {
  poiData: TPoiItem | TSearchPoi;
  onClick?: () => void;
  onClickReservation?: () => void;
  onClickWaiting?: () => void;
};

export enum EOnlineWaitingDisableReason {
  PAUSED = 'PAUSED',
  AUTO_PAUSED_TIME = 'AUTO_PAUSED_TIME',
  BY_PASS = 'BY_PASS',
  CLOSED_DAY = 'CLOSED_DAY',
  CLOSED_MANUALLY = 'CLOSED_MANUALLY',
  NON_OPERATING_HOURS = 'NON_OPERATING_HOURS',
  ONLY_OFFLINE_OPERATION_HOURS = 'ONLY_OFFLINE_OPERATION_HOURS',
  OFFLINE_ONLY = 'OFFLINE_ONLY',
  UNDER_AVAILABLE_TEAMS = 'UNDER_AVAILABLE_TEAMS',
  NOT_OPEN_WAITING_OPERATION = 'NOT_OPEN_WAITING_OPERATION',
  LOADING = 'LOADING',
}

enum EClickedType {
  WAITING = 'waiting',
  RESERVE = 'reserve',
}

// 장소상세 enum EPopupId과 동일하게 sync
enum EPopupId {
  OCCUPANCY_BOTTOM_SHEET = 'OCCUPANCY_BOTTOM_SHEET',
}

const onlineWaitingDisableReasonMap: Record<EOnlineWaitingDisableReason, string> = {
  [EOnlineWaitingDisableReason.PAUSED]: '지금은 웨이팅이 어려워요',
  [EOnlineWaitingDisableReason.AUTO_PAUSED_TIME]: '브레이크 타임이에요',
  [EOnlineWaitingDisableReason.BY_PASS]: '바로 입장 가능해요',
  [EOnlineWaitingDisableReason.CLOSED_DAY]: '휴무일 입니다',
  [EOnlineWaitingDisableReason.CLOSED_MANUALLY]: '웨이팅이 마감되었습니다',
  [EOnlineWaitingDisableReason.NON_OPERATING_HOURS]: '운영 시간이 아닙니다',
  [EOnlineWaitingDisableReason.ONLY_OFFLINE_OPERATION_HOURS]: '지금은 현장 웨이팅만 가능해요',
  [EOnlineWaitingDisableReason.OFFLINE_ONLY]: '현장 웨이팅만 가능해요',
  [EOnlineWaitingDisableReason.UNDER_AVAILABLE_TEAMS]: '2팀 이상일 때 온라인 웨이팅 가능해요',
  [EOnlineWaitingDisableReason.NOT_OPEN_WAITING_OPERATION]: '지금은 현장 웨이팅만 가능해요',
  [EOnlineWaitingDisableReason.LOADING]: '웨이팅 정보를 불러오는 중입니다',
};

const onlineWaitingDisableReasonMapForButton: Record<string, string> = {
  [EOnlineWaitingDisableReason.OFFLINE_ONLY]: '현장 웨이팅만 가능',
  [EOnlineWaitingDisableReason.UNDER_AVAILABLE_TEAMS]: '현재 1팀 이하',
  [EOnlineWaitingDisableReason.ONLY_OFFLINE_OPERATION_HOURS]: '현장 웨이팅만 가능',
};

const CatchTableReservationButton = ({poiData, onClickReservation, onClickWaiting}: TProps) => {
  const {catchTableWaitingResponse} = poiData?.special;
  const {moveToDetail} = useMoveToTarget();
  const {sendClickLog} = useLogger();
  const {selectAddress} = useAddress();
  const euk = useAppSelector((state) => state.userInfo.euk);
  const [clickedType, setClickedType] = useState<Nullable<EClickedType>>(null);

  const {
    isAvailableReservation,
    isAvailableWaiting,
    isAvailableOnlineWaiting,
    onlineWaitingDisableReason,
    unit,
  } = catchTableWaitingResponse || {};
  const appVersionPopup = useDimmedQuery({id: 'APP_VERSION_POPUP'});

  const isWaitingBtnActive = useMemo(
    () =>
      isAvailableOnlineWaiting ||
      [
        EOnlineWaitingDisableReason.OFFLINE_ONLY,
        EOnlineWaitingDisableReason.UNDER_AVAILABLE_TEAMS,
        EOnlineWaitingDisableReason.BY_PASS,
        EOnlineWaitingDisableReason.ONLY_OFFLINE_OPERATION_HOURS,
      ].includes(onlineWaitingDisableReason as EOnlineWaitingDisableReason),
    [isAvailableOnlineWaiting, onlineWaitingDisableReason]
  );

  const parseNavInfo = useCallback(
    (v: TSearchPoi) => {
      return parsePoiInfoToNavInfo({
        pkey: v.pkey || '',
        poiId: v.poiId || '',
        navSeq: `${v.navSeq || ''}`,
        navX: `${v.navX || ''}`,
        navY: `${v.navY || ''}`,
        centerX: `${v.centerX || ''}`,
        centerY: `${v.centerY || ''}`,
        rpFlag: v.rpFlag,
        poiName: v.listName || v.poiName,
        address: selectAddress({jibun: v.fullJibunAddr, road: v.fullRoadAddr}),
        tel: v.displayPhoneNumber,
        ...(v.stationInternalId && {
          stationId: v.stationInternalId,
          publicTransportType: v.stationType,
        }),
      });
    },
    [selectAddress]
  );

  const handleClickReservation = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setClickedType(EClickedType.RESERVE);

      onClickReservation?.();

      if (!isOver10_6Version()) {
        appVersionPopup.open();
        return;
      }

      moveToDetail(parseNavInfo(poiData as TSearchPoi), {
        popupId: EPopupId.OCCUPANCY_BOTTOM_SHEET,
        reserve: true,
      });
    },
    [moveToDetail, poiData, appVersionPopup, onClickReservation, parseNavInfo]
  );

  const handleClickWaiting = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setClickedType(EClickedType.WAITING);

      onClickWaiting?.();

      if (!isOver10_6Version()) {
        appVersionPopup.open();
        return;
      }

      if (!isAvailableOnlineWaiting) {
        TMapSender.makeDialogPopup(
          'waitingDisableReasonPopup',
          onlineWaitingDisableReasonMap[onlineWaitingDisableReason as EOnlineWaitingDisableReason],
          '',
          '확인'
        );
        return;
      }

      moveToDetail(parseNavInfo(poiData as TSearchPoi), {
        popupId: EPopupId.OCCUPANCY_BOTTOM_SHEET,
        wait: true,
      });
    },
    [
      isAvailableOnlineWaiting,
      moveToDetail,
      poiData,
      appVersionPopup,
      onlineWaitingDisableReason,
      onClickWaiting,
      parseNavInfo,
    ]
  );

  const handleClickPopup = useCallback(
    (actionId) => {
      sendSearchClickLog(actionId, {
        pkey: poiData.pkey,
        categorycode: poiData.mainCategory,
        reserve_waiting_type: clickedType,
        euk,
      });
    },
    [poiData, euk, clickedType]
  );

  if (
    !catchTableWaitingResponse ||
    poiData.special?.accommodationInfo?.provider === EAccommodationProviderType.ONDA
  ) {
    return null;
  }

  return (
    <>
      <div className={s.wrap}>
        {isAvailableReservation && (
          <button className={s.reservation_btn} onClick={handleClickReservation}>
            <span>예약하기</span>
          </button>
        )}
        {isAvailableWaiting && (
          <button
            onClick={handleClickWaiting}
            className={classNames(s.waiting_btn, {
              [s.inactive]: !isWaitingBtnActive,
            })}
          >
            <div className={s.content_wrap}>
              <span>웨이팅하기</span>
              {isWaitingBtnActive && (
                <>
                  <IconDotBetween />
                  <span className={s.reason}>
                    {onlineWaitingDisableReasonMapForButton[onlineWaitingDisableReason as string] ||
                      `현재 ${unit?.count ?? 0}팀`}
                  </span>
                </>
              )}
            </div>
          </button>
        )}
      </div>
      {appVersionPopup.isOpen && (
        <AppVersionPopup
          detailComponent={
            <>
              예약/웨이팅 서비스를 이용을 위해
              <br />
              최신 버전으로 업데이트 해주세요.
            </>
          }
          onClickClose={() => appVersionPopup.close()}
          onClickNext={() => {
            handleClickPopup('tap.catch_version_popup.next_time');
            appVersionPopup.close();
          }}
          onClickConfirm={() => {
            handleClickPopup('tap.catch_version_popup.update');
            appVersionPopup.close();
          }}
          onMount={() => {
            sendClickLog('impression.catch_version_popup', {
              pkey: poiData.pkey,
              categorycode: poiData.mainCategory,
              reserve_waiting_type: clickedType,
              euk,
            });
          }}
        />
      )}
    </>
  );
};

export default CatchTableReservationButton;
