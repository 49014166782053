import Vsm from '@vsm/vsm';
import {createContext, useCallback, useState, useRef, useContext} from 'react';

type TContext = {
  map?: Vsm.Map;
  camera?: Vsm.MapCamera;
  transform?: Vsm.MapTransform;
  initialize?: (instance: Vsm.Map) => void;
  destroy?: VoidFunction;
  firstRenderTime?: number;
};

const VSMInterfaceContext = createContext<TContext>({
  initialize: undefined,
  destroy: undefined,
  map: undefined,
  camera: undefined,
  transform: undefined,
  firstRenderTime: undefined,
});

export const useVSMInterfaceConsumer = () => useContext(VSMInterfaceContext);

export const VSMInterfaceProvider = ({children}) => {
  const [, setInitTime] = useState(0);
  const [firstRenderTime, setFirstRenderTime] = useState(0);
  const refMap = useRef<Vsm.Map>();
  const refCamera = useRef<Vsm.MapCamera>();
  const refTransform = useRef<Vsm.MapTransform>();

  const initialize = useCallback((instance) => {
    if (instance) {
      refMap.current = instance;
      refCamera.current = instance.getCamera();
      refTransform.current = instance.getTransform();

      (window as any).____map = refMap.current;

      refMap.current?.once(Vsm.Map.EventNames.RenderComplete, () => {
        setFirstRenderTime(Date.now());
      });

      setInitTime(Date.now());
    }
  }, []);

  const destroy = useCallback(() => {
    refMap.current?.destroy();
    refMap.current = undefined;
    refCamera.current = undefined;
    refTransform.current = undefined;

    setInitTime(0);
  }, []);

  return (
    <VSMInterfaceContext.Provider
      value={{
        initialize,
        destroy,
        firstRenderTime,
        map: refMap.current,
        camera: refCamera.current,
        transform: refTransform.current,
      }}
    >
      {children}
    </VSMInterfaceContext.Provider>
  );
};
