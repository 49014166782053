import {useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {EMarkerType} from 'types/Map';
import {TPersonalMarker} from './types';
import {isLocalEnv} from 'constant/Env';

const useUserInfoMarker = () => {
  const {favorite, favoriteHome, favoriteOffice, recentDestination, favoritePublic} =
    useAppSelector((state) => ({
      showFavorite: isLocalEnv ? true : state.userInfo.showFavorite,
      showRecentlyDestination: isLocalEnv ? true : state.userInfo.showRecentlyDestination,
      favorite: state.userInfo.personalPlace.data.favorites,
      favoriteHome: state.userInfo.personalPlace.data.home,
      favoriteOffice: state.userInfo.personalPlace.data.office,
      recentDestination: state.userInfo.personalPlace.data.recentDestination,
      favoritePublic: state.userInfo.personalPlace.data.favoritesPublic,
    }));
  const favoriteMarker = useMemo<TPersonalMarker[]>(
    () =>
      favorite.map((v) => ({
        properties: v,
        // clickable: true,
        type: EMarkerType.FAVORITE,
        lonLat: {
          // @ts-ignore
          lon: v.centerLon,
          // @ts-ignore
          lat: v.centerLat,
        },
      })),
    [favorite]
  );

  const favoriteHomeMarker = useMemo<Nullable<TPersonalMarker>>(
    () =>
      favoriteHome
        ? {
            properties: favoriteHome,
            // clickable: true,
            type: EMarkerType.FAVORITE_HOME,
            lonLat: {
              // @ts-ignore
              lon: favoriteHome.centerLon,
              // @ts-ignore
              lat: favoriteHome.centerLat,
            },
          }
        : null,
    [favoriteHome]
  );

  const favoriteOfficeMarker = useMemo<Nullable<TPersonalMarker>>(
    () =>
      favoriteOffice
        ? {
            properties: favoriteOffice,
            // clickable: true,
            type: EMarkerType.FAVORITE_OFFICE,
            lonLat: {
              // @ts-ignore
              lon: favoriteOffice.centerLon,
              // @ts-ignore
              lat: favoriteOffice.centerLat,
            },
          }
        : null,
    [favoriteOffice]
  );

  const recentDestinationMarker = useMemo<TPersonalMarker[]>(
    () =>
      recentDestination.map((v) => ({
        properties: v,
        // clickable: true,
        type: EMarkerType.RECENT_DESTINATION,
        lonLat: {
          // @ts-ignore
          lon: v.centerLon,
          // @ts-ignore
          lat: v.centerLat,
        },
      })),
    [recentDestination]
  );

  const favoritePublicMarker = useMemo<TPersonalMarker[]>(
    () =>
      (favoritePublic || []).reduce((arr, v) => {
        if (!v.wgs84CenterX && !v.wgs84CenterY) {
          return arr;
        }
        return [
          ...arr,
          {
            properties: v as any,
            // clickable: true,
            type: EMarkerType.FAVORITE_PUBLIC_TRANS,
            lonLat: {
              lon: v.wgs84CenterX || 0,
              lat: v.wgs84CenterY || 0,
            },
          },
        ];
      }, [] as TPersonalMarker[]),
    [favoritePublic]
  );

  return {
    favoriteMarker,
    favoriteHomeMarker,
    favoriteOfficeMarker,
    recentDestinationMarker,
    favoritePublicMarker,
  };
};

export default useUserInfoMarker;
