// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcSearchBoldSvg} from 'resource/@tmdsicon/v1.2/ic_search_bold.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcSearchBold = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcSearchBoldSvg {...props} />} />;
};

export {IcSearchBold};
