import {Paths} from 'constant/RoutePath';
import {useAppSelector} from 'ducks/hooks';
import {useMemo} from 'react';
import {generateUrl} from 'utils/url';
import {useParseQueryLocation} from './useParseQueryLocation';
import {TPlaceCategoryParam} from 'types/Page';
import {SEARCH_HOST} from 'constant/Path';
import useMoveToTarget from './useMoveToTarget';

export type TPlaceCategoryLinkProps = {
  type: string;
  queries?: Omit<TPlaceCategoryParam, 'type'> & {redirect_from?: string};
};

export const usePlaceCategoryLink = (linkProps: TPlaceCategoryLinkProps) => {
  const {type, queries} = linkProps;
  const {originQueries} = useParseQueryLocation();
  const {reqMode, reqType, extra} = useMoveToTarget();

  const {centerLat, centerLon, userLatLon, userFuel, lastZoom} = useAppSelector((state) => ({
    centerLatLon: state.map.nowCenter ||
      state.map.lastCachedCenter ||
      state.map.userPosition || {lat: undefined, lon: undefined},
    userLatLon: state.map.userPosition || {lat: undefined, lon: undefined},
    userFuel: state.userInfo.carInfo?.fuel,
    lastZoom: state.map.zoom || 17,
    centerLat:
      state.map.nowCenter?.lat || state.map.lastCachedCenter?.lat || state.map.userPosition?.lat,
    centerLon:
      state.map.nowCenter?.lon || state.map.lastCachedCenter?.lon || state.map.userPosition?.lon,
  }));

  const url = useMemo(() => {
    const {tailParam, ...extraQueries} = queries || {};

    const nextTailParam = {
      ...tailParam,
      reqMode: originQueries.reqMode,
      reqType: originQueries.reqType,
      extra: originQueries.extra,
    };

    return generateUrl(SEARCH_HOST + Paths.PlaceCategory, {
      type,
      centerLat,
      centerLon,
      userLat: userLatLon.lat,
      userLon: userLatLon.lon,
      defaultZoom: lastZoom,
      userFuel: userFuel,
      tailParam: encodeURIComponent(JSON.stringify(nextTailParam)),
      reqMode,
      reqType,
      extra,
      ...extraQueries,
    });
  }, [
    queries,
    originQueries.reqMode,
    originQueries.reqType,
    originQueries.extra,
    type,
    centerLat,
    centerLon,
    userLatLon.lat,
    userLatLon.lon,
    lastZoom,
    userFuel,
    reqMode,
    reqType,
    extra,
  ]);

  return url;
};
