import useMap from 'hooks/useMap';
import {getComparedPrice, getOndaPrefix} from 'utils/formatter';
import {EAccommodationProviderType, TAccommodationInfo} from 'types/App';
import s from 'styles/components/VSMMarker.module.scss';

type TProps = {
  data: TAccommodationInfo;
};

const AccommodationPriceMarker = ({data}: TProps) => {
  const {style: mapStyle} = useMap();

  if (
    !data?.roomMinPrice ||
    !data?.roomMaxPrice ||
    data?.provider !== EAccommodationProviderType.ONDA
  ) {
    return null;
  }

  return (
    <span className={s.price} data-style={mapStyle}>
      {getComparedPrice(data.roomMinPrice, data.roomMaxPrice, getOndaPrefix(data))}
    </span>
  );
};

export default AccommodationPriceMarker;
