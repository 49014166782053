import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {useCallback, useEffect, useState} from 'react';
import actions from 'ducks/actions';

const useListScroll = ({list, apiStatus}) => {
  const drawerMode = useAppSelector((state) => state.userInteraction.drawerMode);
  const dispatch = useAppDispatch();
  const [isScrollOver, setIsScrollOver] = useState<boolean>(true);

  const handleListScrollTop = useCallback(() => {
    dispatch(
      actions.userInteraction.setInteraction({
        activePoi: list[0]?.listId,
        trigger: 'rotate',
        drawerMode: drawerMode,
        dragMap: false,
      })
    );

    setIsScrollOver(false);
  }, [list, drawerMode, dispatch]);

  useEffect(() => {
    if (!apiStatus.loaded || isScrollOver) {
      return;
    }

    dispatch(actions.userInteraction.resetActivePoi());
    setIsScrollOver(true);
  }, [isScrollOver, dispatch, apiStatus.loaded]);

  return {
    handleListScrollTop,
    isScrollOver,
    setIsScrollOver,
  };
};

export default useListScroll;
