import {useCallback, useEffect, useRef, useState} from 'react';
import axios, {CancelTokenSource} from 'axios';
import {TApiStatus} from 'types/Api';
import {frontmanFetcher} from 'utils/fetcher';
import {getDefaultApiStatus} from 'utils/apis';
import {FRONTMAN_API_PATH} from 'constant/Api';
import {TBusArrivalInfo, TSubwayArrivalInfo} from 'types/PublicTrans';
import useInitApiHeader from 'hooks/useInitApiHeader';
import {TVsmPublicTransportType} from 'ducks/userInteraction/types';

type TPublicTransResponse = TSubwayArrivalInfo | TBusArrivalInfo;
const DEFAULT_STATUS = getDefaultApiStatus<Nullable<TPublicTransResponse>>(null);

const CancelToken = axios.CancelToken;

type TProps = {
  publicTransportType?: TVsmPublicTransportType;
  stationSktId?: string;
};

// https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/356498575/Tardis+Sis
const usePublicTransArrivalInfo = (props: TProps) => {
  const [state, setState] = useState<TApiStatus<Nullable<TPublicTransResponse>>>(DEFAULT_STATUS);
  const refCancelToken = useRef<Nullable<CancelTokenSource>>(null);

  useInitApiHeader();

  const fetchApi = useCallback(({publicTransportType, stationSktId: stationId}: TProps) => {
    if (publicTransportType && stationId) {
      const isSubway = publicTransportType === 'subway';
      const isBusStop = publicTransportType === 'busstop';

      setState({
        ...DEFAULT_STATUS,
        loading: true,
      });
      refCancelToken.current = CancelToken.source();

      frontmanFetcher
        .post(
          FRONTMAN_API_PATH.GET_PUBLIC_TRANS_ARRIVAL,
          {
            ...(isBusStop && {
              busStations: [
                {
                  stationId,
                },
              ],
            }),
            ...(isSubway && {
              subwayStations: [
                {
                  stationId,
                },
              ],
            }),
          },
          {
            cancelToken: refCancelToken.current.token,
          }
        )
        .then((e) => {
          if (e.data) {
            const data = isSubway ? e.data.subwayArrivals : e.data.busArrivals;

            setState({
              loaded: true,
              loading: false,
              data: Array.isArray(data) ? data[0] : data,
              error: undefined,
            });
          } else {
            throw new Error('No Data');
          }
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            return;
          }
          setState({
            loaded: true,
            loading: false,
            data: null,
            error: e.message,
          });
        });
    } else {
      setState({
        loaded: true,
        loading: false,
        data: null,
        error: new Error('invalid sId'),
      });
    }
  }, []);

  useEffect(() => {
    fetchApi(props);

    return () => {
      refCancelToken.current?.cancel();
      refCancelToken.current = null;
    };
  }, [fetchApi, props]);

  return {state, fetchApi};
};

export default usePublicTransArrivalInfo;
