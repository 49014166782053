import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../ducks/hooks';
import {ESkeletonType} from '../../types/App';
import Skeleton from '../Skeleton';
import SearchRecommendKeyword from './SearchRecommendKeyword';
import SearchRecommendRanking from './SearchRecommendRanking';
import SearchRecommendFestival from './SearchRecommendFestival';
import {useOnce} from '../../hooks/useOnce';
import {getDefaultApiStatus} from '../../utils/apis';
import ErrorReload from '../ErrorReload';
import {fetchMainFestival, fetchRank} from '../../ducks/searchRecommend/slice';

import s from '../../styles/pages/SearchMainPage.module.scss';

const SearchMainRecommend = () => {
  const {userPosition, rank, festival} = useAppSelector((state) => ({
    userPosition: state.map.userPosition,
    rank: state.searchRecommend.rankData.result,
    festival: state.searchRecommend.mainFestivalData.result,
  }));

  const dispatch = useAppDispatch();

  const [apiStatus, setApiStatus] = useState(getDefaultApiStatus({}));

  useOnce(userPosition && !rank.loaded && !festival.loaded, () => {
    dispatch(fetchRank({lat: userPosition?.lat, lon: userPosition?.lon, size: 9}));
    dispatch(fetchMainFestival({size: 3}));
  });

  useEffect(() => {
    if (rank.loaded && festival.loaded) {
      if (rank.data.list.length === 0 && festival.data.festivalInfos.length === 0) {
        setApiStatus((prev) => ({
          ...prev,
          loaded: true,
          loading: false,
          error: {},
        }));
      } else {
        setApiStatus((prev) => ({
          ...prev,
          loaded: true,
          loading: false,
        }));
      }
    } else if (rank.error && festival.error) {
      setApiStatus((prev) => ({
        ...prev,
        loaded: true,
        loading: false,
        error: rank.error || festival.error,
      }));
    }
  }, [rank, festival]);

  if (apiStatus.error) {
    return (
      <div className={s.error}>
        <ErrorReload top={0} onReload={() => window.location.reload()} />
      </div>
    );
  }

  return (
    <Skeleton className={s.skeleton} type={ESkeletonType.SEARCH_MAIN} apiStatus={apiStatus}>
      <div className={s.recommend_wrap}>
        <SearchRecommendKeyword />
        <SearchRecommendRanking />
        <SearchRecommendFestival />
      </div>
    </Skeleton>
  );
};

export default SearchMainRecommend;
