import {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useAppDispatch} from 'ducks/hooks';
import useDimmedQuery from 'hooks/useDimmedQuery';
import actions from 'ducks/actions';
import CustomSelectLayer from 'components/CustomSelectLayer';
import s from 'styles/components/place/PlaceListHeader.module.scss';
import {ReactComponent as IconArrowDown} from 'resource/images/@tmds_element/ico_arrow_down.svg';
import classNames from 'classnames';
import useLogger from 'hooks/useLogger';
import {EFilterActionId, EToggle} from 'constant/Log';
import usePlaceFilter, {DEFAULT_FILTER} from 'hooks/usePlaceFilter';
import {EOilType, EPlaceCategoryType, EPlaceFeatureOperator, TToggler} from 'types/App';
import {PlaceListHeaderSort} from './PlaceListHeaderSort';
import {PlaceListHeaderEVFilter} from './PlaceListHeaderEVFilter';
import {PlaceListHeaderOilGasFilter} from './PlaceListHeaderOilGasFilter';
import {PlaceListHeaderAccommodationFilter} from './PlaceListHeaderAccommodationFilter';
import {PlaceListReservationWaitFilter} from './PlaceListReservationWaitFilter';
import {ReactComponent as IconDivider} from 'resource/images/divider.svg';

type TProps = {
  onChange: (param) => void;
  hasFilter: boolean;
};

const PlaceListHeader = ({onChange, hasFilter}: TProps) => {
  const dispatch = useAppDispatch();
  const filterDimmed = useDimmedQuery({place: 'filter'});

  const {sendFilterClickLog, sendClickLogWithMapView, sendClickLog} = useLogger();

  const {
    nowType,
    nowPlaceQuery,
    placeQueryList = [],
    resetToggler,
    tmapPayToggler,
    parkPublicToggler,
    popularFilterList,
  } = usePlaceFilter();

  const handleClickSortItem = useCallback(
    (newItem) => {
      onChange({sort: newItem.id});
    },
    [onChange]
  );

  const handleClickFilterItem = useCallback(
    (newItem) => {
      const isSelectAll = newItem.id === DEFAULT_FILTER.id;
      const id = isSelectAll ? undefined : newItem.id;

      dispatch(actions.place.updateFilter(id));
      onChange({filter: id});
      filterDimmed.close();

      sendFilterClickLog(EFilterActionId.FILTER_ITEM_1, {filter1: id || DEFAULT_FILTER.id});
    },
    [onChange, filterDimmed, dispatch, sendFilterClickLog]
  );

  const handleClickToggle = useCallback(
    (e, toggler: TToggler) => {
      e.preventDefault();

      const param = toggler.params[toggler.isOn ? 0 : 1];

      if (toggler?.clickCode) {
        sendClickLogWithMapView(toggler.clickCode, {
          status: toggler.isOn ? EToggle.OFF : EToggle.ON,
        });
      }

      Object.keys(param).includes('tmapPaymentYn') &&
        dispatch(actions.place.updateTMapPaymentYn(param.tmapPaymentYn));
      Object.keys(param).includes('parkType') &&
        dispatch(actions.place.updateParkType(param.parkType));

      param?.filter && dispatch(actions.place.updateFilter(param.filter));
      onChange(param);
    },
    [dispatch, onChange, sendClickLogWithMapView]
  );

  return (
    <>
      <div className={classNames(s.header_wrap, {[s.has_filter]: hasFilter})}>
        <PlaceListHeaderSort type={nowType} onChange={handleClickSortItem} />
        <div className={s.filter_wrap}>
          {nowType === EPlaceCategoryType.OIL_EV_ALL && (
            <PlaceListHeaderEVFilter onChange={(param) => onChange?.(param)} />
          )}
          {nowType === EPlaceCategoryType.OIL_GAS_ALL && (
            <PlaceListHeaderOilGasFilter
              onChange={(param) =>
                onChange?.({
                  ...param,
                  placeFeatureOperator: EPlaceFeatureOperator.AND,
                })
              }
            />
          )}
          {nowType === EPlaceCategoryType.HOTL_ALL && (
            <PlaceListHeaderAccommodationFilter onChange={(param) => onChange?.(param)} />
          )}
          {popularFilterList?.map((filter) => (
            <Link
              key={filter.label}
              className={classNames(s.filter, {
                [s.on]: filter.isOn,
              })}
              to={'#'}
              onClick={(e) => {
                e.preventDefault();
                const nextFilter = filter.params[filter.isOn ? 0 : 1];

                dispatch(actions.place.updateCategoryTypes(nextFilter.categoryTypes));
                onChange(nextFilter);

                filter.clickCode &&
                  sendClickLog(filter.clickCode, {
                    status: filter.isOn ? EToggle.OFF : EToggle.ON,
                  });
              }}
            >
              {filter.label}
            </Link>
          ))}
          {[
            EPlaceCategoryType.FOOD_ALL,
            EPlaceCategoryType.FOOD_CAFE,
            EPlaceCategoryType.POPULAR,
          ].includes(nowType) && (
            <>
              {nowType === EPlaceCategoryType.POPULAR && <IconDivider className={s.divider} />}
              <PlaceListReservationWaitFilter
                nowType={nowType}
                onChange={(param) => onChange?.(param)}
              />
            </>
          )}
          {resetToggler && (
            <Link
              className={classNames(s.filter, {[s.on]: resetToggler.isOn})}
              to={'#'}
              onClick={(e) => {
                e.preventDefault();

                sendClickLogWithMapView('tap.all', {
                  status: EToggle.ON,
                });
                dispatch(actions.place.resetAllFilters({}));
                onChange?.(resetToggler.params[1]);
              }}
            >
              {resetToggler.label}
            </Link>
          )}
          {tmapPayToggler && (
            <Link
              className={classNames(s.filter, {[s.on]: tmapPayToggler?.isOn})}
              to={'#'}
              onClick={(e) => handleClickToggle(e, tmapPayToggler)}
            >
              {tmapPayToggler.label}
            </Link>
          )}
          {parkPublicToggler && (
            <Link
              className={classNames(s.filter, {[s.on]: parkPublicToggler?.isOn})}
              to={'#'}
              onClick={(e) => handleClickToggle(e, parkPublicToggler)}
            >
              {parkPublicToggler.label}
            </Link>
          )}
          {placeQueryList.length > 0 && (
            <Link
              className={classNames(s.filter, {
                [s.on]: nowPlaceQuery && nowPlaceQuery?.id !== placeQueryList[0]?.id,
              })}
              to={filterDimmed.openLocation}
              onClick={() => {
                sendClickLogWithMapView(EFilterActionId.FILTER_1);
              }}
            >
              <span className={s.text}>
                {nowPlaceQuery?.id === EOilType.ALL ? '유종' : nowPlaceQuery?.label || '전체'}
              </span>
              <IconArrowDown className={s.icon_fold} />
            </Link>
          )}
        </div>
      </div>
      <CustomSelectLayer
        isOpen={filterDimmed.isOpen}
        options={placeQueryList}
        selectedItem={nowPlaceQuery || placeQueryList[0]}
        onClickItem={handleClickFilterItem}
      />
    </>
  );
};

export default PlaceListHeader;
