import classNames from 'classnames';
import {IcoArrowDown} from 'components/@tmds/icons/IcoArrowDown';
import s from 'styles/components/tplacehome/TPlacePopupBase.module.scss';

type Tprops = {
  name: string;
  onClick: VoidFunction;
  disabled?: boolean;
};

const RegionSelectButton = ({name, onClick, disabled}: Tprops) => {
  return (
    <button
      className={classNames(s.region_select_button, {[s.disabled]: disabled})}
      onClick={() => !disabled && onClick()}
    >
      <span className={s.name}>{name}</span>
      <span className={s.icon}>
        <IcoArrowDown width={16} height={16} color={disabled ? '#BEC5CC' : '#171819'} />
      </span>
    </button>
  );
};

export default RegionSelectButton;
