import classNames from 'classnames';
import ImageLoader from 'components/ImageLoader';
import s from 'styles/components/ranking/RankingPoiImageList.module.scss';

type TProps = {
  images: {url: string; url2: string}[];
  poiName: string;
  className?: string;
  isCardMode?: boolean;
};

const RankingPoiImageList = ({images, poiName, className, isCardMode}: TProps) => {
  return (
    <div
      className={classNames(s.image_wrap, className)}
      style={{'--image-count': images.length || 1} as React.CSSProperties}
    >
      {images.map((image, imgIdx) => (
        <li
          key={imgIdx}
          className={classNames(s.image, {
            [s.round_left]: imgIdx === 0,
            [s.round_right]: imgIdx === images.length - 1,
            [s.is_card_mode]: isCardMode,
          })}
        >
          <ImageLoader
            toggleClass={s.fade_in}
            src={image.url || image.url2}
            alt={`${poiName} 이미지`}
          />
        </li>
      ))}
    </div>
  );
};

export default RankingPoiImageList;
