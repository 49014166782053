import {useEffect, useState} from 'react';

import {TNaverSAAd, TNaverSARequest, TNaverSAResponse} from 'types/Ads';

import {NAVER_SA_CHANNEL, NAVER_SA_FUNCTION_NAME, NAVER_SA_JS_PATH} from 'constant/Ads';
import {isProdEnv} from 'constant/Env';

type TProps = {
  query?: string;
  displayPath?: string;
  onStart?: (params: TNaverSARequest) => void;
  onError?: (error: any) => void;
  onSdkLoad?: VoidFunction;
  onSuccess?: (ad: TNaverSAAd) => void;
  isMock?: boolean;
  holdLoad?: boolean;
};

const useNaverKeywordAd = ({
  query,
  displayPath,
  onStart,
  onError,
  onSdkLoad,
  onSuccess,
  isMock,
  holdLoad = false,
}: TProps) => {
  const [sdkLoaded, setSdkLoaded] = useState<boolean>(false);
  const [adData, setAdData] = useState<TNaverSAAd>();
  const [adError, setAdError] = useState<Error>();

  useEffect(() => {
    if (holdLoad) {
      return;
    }

    if (!window[NAVER_SA_FUNCTION_NAME]) {
      const scriptEl = document.createElement('script');
      scriptEl.type = 'text/javascript';
      scriptEl.src = NAVER_SA_JS_PATH;
      scriptEl.onload = () => {
        setSdkLoaded(true);
        onSdkLoad?.();
      };
      scriptEl.onerror = () => {
        const nowError = new Error('NAVER SA SDK load fail');

        setAdError(nowError);
        onError?.(nowError);
      };

      document.body.appendChild(scriptEl);
    } else {
      setSdkLoaded(true);
    }
  }, [holdLoad]);

  useEffect(() => {
    if (sdkLoaded && query) {
      const callbackName = `naver_sa_callback_${Date.now()}`;

      setAdData(undefined);
      setAdError(undefined);

      window[callbackName] = (response: TNaverSAResponse) => {
        const ads = response?.ads || [];

        if (ads.length) {
          onSuccess?.(ads[0]);
          setAdData(ads[0]);
          return;
        }

        const nowError = new Error(response?.error?.message || 'NAVER SA ads count 0');

        setAdError(nowError);
        onError?.(nowError);

        return;
      };

      const requestParams: TNaverSARequest = {
        channel: NAVER_SA_CHANNEL,
        url: displayPath || '', // '/tmap/search/result',
        pageSize: 1,
        query,
        test: !isProdEnv,
        mock: isMock,
        callback: callbackName,
      };

      onStart?.(requestParams);

      window[NAVER_SA_FUNCTION_NAME](requestParams);
    }
  }, [sdkLoaded, query, isMock]);

  return {
    sdkLoaded,
    adData,
    adError,
  };
};

export default useNaverKeywordAd;
