import axios, {CancelTokenSource} from 'axios';
import {API_PATH} from 'constant/Api';
import {useEffect, useRef, useState} from 'react';
import {TApiStatus} from 'types/Api';
import {EReverseGeocodingError, TReverseGeocoding} from 'types/App';
import {TLonLat} from 'types/Map';
import {getDefaultApiStatus} from 'utils/apis';
import fetcher from 'utils/fetcher';

export type TReverseGeocodingApiStatus = TApiStatus<
  Nullable<TReverseGeocoding>,
  EReverseGeocodingError
>;

const DEFAULT_STATUS = getDefaultApiStatus<TReverseGeocoding, EReverseGeocodingError>({
  regionName1: '',
  regionName2: '',
  regionName3: '',
  regionName4: '',
  bunji: '',
  roadName: '',
  roadBunji: '',
  buildingName: '',
  centerX: '',
  centerY: '',
  currentArea: '',
  jibunPkey: '',
  roadPkey: '',
  areaDepth1Code: '',
  areaDepth2Code: '',
  areaDepth3Code: '',
  jibunAddrCoordination: {
    centerX: '',
    centerY: '',
    navX: '',
    navY: '',
    wgs84CenterX: 0,
    wgs84CenterY: 0,
    wgs84NavX: 0,
    wgs84NavY: 0,
  },
  roadAddrCoordination: {
    centerX: '',
    centerY: '',
    navX: '',
    navY: '',
    wgs84CenterX: 0,
    wgs84CenterY: 0,
    wgs84NavX: 0,
    wgs84NavY: 0,
  },
});

const CancelToken = axios.CancelToken;

type TProps = Partial<TLonLat> & {
  refreshKey?: number;
  isEnable?: boolean;
};

export const useReverseGeocoding = ({lon, lat, refreshKey, isEnable = true}: TProps = {}) => {
  const [state, setState] = useState<TReverseGeocodingApiStatus>(DEFAULT_STATUS);
  const refCancelToken = useRef<Nullable<CancelTokenSource>>(null);

  useEffect(() => {
    if (!isEnable) {
      return;
    }

    if (lon && lat) {
      setState({
        ...DEFAULT_STATUS,
        loading: true,
      });

      refCancelToken.current = CancelToken.source();

      fetcher
        .get(API_PATH.GET_REVERSE_GEOCODE, {
          params: {lon, lat},
          cancelToken: refCancelToken.current.token,
        })
        .then((e) => {
          if (e.data.data) {
            setState({
              loaded: true,
              loading: false,
              data: e.data.data,
              error: undefined,
            });
          } else {
            throw new Error(EReverseGeocodingError.NO_DATA);
          }
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            return;
          }
          setState({
            loaded: true,
            loading: false,
            data: null,
            error: e.message,
          });
        });
    }

    return () => {
      refCancelToken.current?.cancel();
      refCancelToken.current = null;
    };
  }, [lon, lat, refreshKey, isEnable]);

  return state;
};

export default useReverseGeocoding;
