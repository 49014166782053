import {useEffect, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import classNames from 'classnames';

import {EAppActivity, EAppPackage} from 'types/App';
import {useAppSelector} from 'ducks/hooks';

import {EAsumAdUnitId, EAsumErrorCode} from 'constant/Ads';

import ua from 'utils/uaParser';
import {getAsumCarrierCode, getAsumNetwork} from 'utils/ads';

import s from 'styles/modules/AsumBanner.module.scss';

type TProps = {
  isShow?: boolean;
  onStart?: (requestInfo?: TAsumRequestInfo) => void;
  onShow?: VoidFunction;
  onLoad?: (isDefaultAd?: boolean) => void;
  onError?: (error?: Error) => void;
  onClickAd?: (url?: string) => void;
  adUnitId?: EAsumAdUnitId;
};

type TAsumUserInfo = {
  activity: EAppActivity;
  package_name: EAppPackage;
  package_ver: string;
  adid?: string;
  idfa?: string;
  vendor: string;
  network: number;
  carrier: number;
  locale: string;
};
type TAsumLoadOption = {
  targetId: string;
  adUnitId: EAsumAdUnitId;
  adid: string;
  idfa: string;
};

type TAsumRequestInfo = Partial<{
  userInfo: Partial<TAsumUserInfo>;
  loadOption: Partial<TAsumLoadOption>;
}>;

const TEMP_ADID = uuidv4();

const AsumBanner = ({isShow, onStart, onShow, onLoad, onError, onClickAd, adUnitId}: TProps) => {
  const [sdkLoaded, setLoaded] = useState<boolean>(false);
  const [{userInfoAssigned, userInfo}, setUserInfo] = useState<{
    userInfoAssigned: boolean;
    userInfo?: TAsumUserInfo;
  }>({userInfoAssigned: false});
  const [targetId] = useState<string>(`asumBanner_${uuidv4()}`);
  const [sdkLoadChecker, checkSdkLoaded] = useState<number>();
  const bannerRef = useRef();
  const refContainer = useRef<HTMLDivElement>(null);

  const {isLandscape, windowSize, adId, device} = useAppSelector((state) => ({
    isLandscape: state.layout.appSize.isLandscape,
    windowSize: state.layout.windowSize,
    adId: state.userInfo.adId || TEMP_ADID,
    device: state.userInfo.device,
  }));

  useEffect(() => {
    // setAsumUserInfo 대신 임시 사용
    if (adId && device) {
      const asumUserInfo = {
        activity: ua.isIos ? EAppActivity.IOS : EAppActivity.AOS,
        package_name: ua.isIos ? EAppPackage.IOS : EAppPackage.AOS,
        package_ver: ua.tmapAppVersion,
        adid: !ua.isIos ? adId : undefined,
        idfa: ua.isIos ? adId : undefined,
        vendor: ua.device.vendor,
        network: getAsumNetwork(window.navigator['connection']?.type || ''),
        carrier: getAsumCarrierCode(device.carrierName || ''),
        locale: navigator.language || 'ko-KR',
      };
      window['asumUserInfo'] = asumUserInfo;

      setUserInfo({userInfoAssigned: true, userInfo: asumUserInfo});
    }
  }, [adId, device]);

  useEffect(() => {
    if (!window['ASUMSDK']) {
      window.setTimeout(() => {
        checkSdkLoaded(Date.now());
      }, 0);
    } else {
      setLoaded(true);
    }
  }, [sdkLoadChecker]);

  useEffect(() => {
    if (isShow && sdkLoaded && userInfoAssigned && !bannerRef.current) {
      try {
        bannerRef.current = new window['ASUMSDK']();

        const loadOption = {
          targetId: targetId,
          adUnitId: adUnitId,
          adid: adId,
          idfa: adId,
        };

        onStart?.({userInfo, loadOption});

        (bannerRef.current as any)?.setRequestTimeout?.(500);

        // https://tmobi.atlassian.net/browse/LCC-13
        (bannerRef.current as any)?.loadAd({
          ...loadOption,
          callback: {
            // 광고 소재 load 완료. isDefaultAd 만 광고가 없어 default로 올린 광고가 나옴
            onAdLoaded: (isDefaultAd: boolean) => onLoad?.(isDefaultAd),

            // 광고 load 및 노출 완료
            onAdShow: () => onShow?.(),

            // 광고 load 및 노출 과정에서 에러
            onAdFailed: (error: EAsumErrorCode) => {
              const message = EAsumErrorCode[error];

              onError?.(new Error(message));
            },

            // 광고 클릭. false시 업체 동작 수행. true 리턴시 업체 동작 막음
            onAdClicked: (url: string) => {
              onClickAd?.(url);
              return true;
            },
          },
        });
      } catch (e) {
        onError?.(new Error('sdk load error') as any);
      }
    }

    return () => {
      (bannerRef.current as any)?.stopAd();
      bannerRef.current = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, sdkLoaded, adId, userInfoAssigned, userInfo]);

  useEffect(() => {
    (bannerRef.current as any)?.resizeAd();
  }, [isLandscape, windowSize, refContainer.current?.clientWidth]);

  return (
    <div
      id={targetId}
      className={classNames(s.asum_banner, {[s.show]: isShow})}
      ref={refContainer}
    />
  );
};

export default AsumBanner;
