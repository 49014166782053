import {useAppDispatch} from 'ducks/hooks';
import {useCallback} from 'react';
import actions from 'ducks/actions';
import useLogger from 'hooks/useLogger';
import s from 'styles/components/ranking/RankingFilterPopup.module.scss';

const RankingFilterPopup = () => {
  const dispatch = useAppDispatch();
  const {sendClickLog} = useLogger();

  const handleClick = useCallback(() => {
    dispatch(actions.tRank.resetFilter());
    sendClickLog('tap.filter_reset');
  }, [dispatch]);

  // DrawerContainer에서 listBottomPadding 값을 주기 위해 data-type='popup' 추가
  return (
    <div className={s.wrap} data-type="popup">
      <div className={s.text}>주변에 장소가 없습니다.</div>
      <div className={s.button_wrap}>
        <div className={s.button} onClick={handleClick}>
          필터 초기화
        </div>
      </div>
    </div>
  );
};

export default RankingFilterPopup;
