import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useParseQueryLocation} from './useParseQueryLocation';
import {ESearchTabs} from 'types/Search';
import {useHistory} from 'react-router';
import {generateUrl} from 'utils/url';
import {EAppRequestMode} from 'types/App';

export const useSearchMainTab = () => {
  const {pathname, queries} = useParseQueryLocation();
  const history = useHistory();

  const currentTab = useMemo(() => queries.currentTab, [queries]);
  const prevTab = useRef('');

  const changeTab = useCallback(
    (tab, {pushHistory}) => {
      const newQuery = {
        ...queries,
        currentTab: tab,
      };
      const newUrl = generateUrl(pathname, newQuery);

      if (pushHistory) {
        history.push(newUrl);
      } else {
        history.replace(newUrl);
      }
    },
    [history, pathname, queries]
  );

  const isSearchMain = useMemo(
    () => currentTab === ESearchTabs.RECENT || currentTab === ESearchTabs.RECOMMEND,
    [currentTab]
  );
  const isEventTab = useMemo(
    () => queries.reqMode === EAppRequestMode.EVENT_PAGE,
    [queries.reqMode]
  );
  const isFromTPlaceHome = useMemo(
    () => queries.reqMode === EAppRequestMode.TPLACE_HOME,
    [queries.reqMode]
  );

  const nearbyQueryParam = useMemo(() => {
    let tailParam = {} as any;

    try {
      tailParam = JSON.parse(queries.tailParam) || {};
    } catch {}

    if (tailParam.pageId === 'nearby' && !!tailParam.reqKey) {
      return {
        reqKey: tailParam.reqKey,
      };
    }

    return null;
  }, [queries]);

  const moveBack = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    prevTab.current = currentTab;
  }, [currentTab]);

  return {
    currentTab,
    prevTab: prevTab.current,
    isSearchMain,
    isEventTab,
    isFromTPlaceHome,
    nearbyQueryParam,
    changeTab,
    moveBack,
  };
};
