import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TCategoryPageProperty, TCategoryState} from './types';

const initialState: TCategoryState = {
  validCategoryPageList: [],
  visibleRedDotItemName: [],
  currentCategory: undefined,
  showShortAddress: false,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setValidCategoryPageList: (state, action: PayloadAction<TCategoryPageProperty[]>) => {
      state.validCategoryPageList = action.payload;
    },
    setVisibleRedDotItemName: (state, action: PayloadAction<string[]>) => {
      state.visibleRedDotItemName = action.payload;
    },
    setCurrentCategory: (state, action: PayloadAction<string>) => {
      state.currentCategory = action.payload;
    },
    setShowShortAddress: (state, action) => {
      state.showShortAddress = !!action.payload;
    },
  },
});

export default categorySlice;
