import classNames from 'classnames';

import {TAddressItem} from 'types/Search';

import s from 'styles/components/legacySearch/CateItem.module.scss';
import {removeSpecialCharacter} from 'utils/string';

type TProps = {
  data: Nullable<TAddressItem>;
  placeholder: string;
  onClick?: VoidFunction;
};

const CateItem = ({data, onClick, placeholder = ''}: TProps) => {
  return (
    <button
      className={classNames(s.cate_item, {
        [s.active]: data,
      })}
      onClick={() => data && onClick?.()}
      disabled={!data}
      role="button"
      aria-label={`${data?.areaName || removeSpecialCharacter(placeholder)} 초기화`}
    >
      {data?.areaName || placeholder}
    </button>
  );
};

export default CateItem;
