import {TAddressItem} from 'types/Search';

import {useJSXMultiline} from 'hooks/useJSXMultiline';

import s from 'styles/components/legacySearch/AddressItem.module.scss';

type TProps = {
  data: TAddressItem;
  isTile?: boolean;
  onClick?: (data: TAddressItem) => void;
};

const AddressItem = ({data, isTile, onClick}: TProps) => {
  const multiline = useJSXMultiline(data.areaName, {separator: ' '});

  return (
    <li role="radio" className={isTile ? s.tile_item : s.list_item} onClick={() => onClick?.(data)}>
      {isTile ? multiline : data.areaName}
    </li>
  );
};

export default AddressItem;
