import {TDrawerOptions} from 'types/App';

export enum EParallaxMoveMode {
  WAIT = 'WAIT',
  MOVE = 'MOVE',
  GESTURE = 'GESTURE',
}

export enum EListMode {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  CENTER = 'CENTER',
}

export enum EDirection {
  CENTER = 0,
  UP = -1,
  DOWN = 1,
}

export type TPositionResult = {
  map: number;
  list: number;
  percent: number;
  moveDelay: number;
  nextMoveDelay: number;
};

export type TSizeResult = {
  statusBarHeight: number;
  isLandscape: boolean;
  windowHeight: number;

  topPoint: number;
  centerPoint: number;
  bottomPoint: number;

  mapTopPoint: number;
  mapBottomPoint: number;
};

export type TModeResult = {
  isTop: boolean;
  isBottom: boolean;
  isCenter: boolean;
  isStarted: boolean;

  moveMode: EParallaxMoveMode;
  mode: EListMode;
};

export type TProps = TDrawerOptions & {
  windowHeight: number;
  statusBarHeight?: number;
  isLandscape?: boolean;
  initListMode?: EListMode;
};

export type TListDrawerResult = {
  position: TPositionResult;
  size: TSizeResult;
  mode: TModeResult;

  basePoint: number;
  isDragging: boolean;

  handlers: any;
  direction: EDirection;

  init: VoidFunction;
  moveTopMode: (duration?: number) => void;
  moveCenterMode: (duration?: number) => void;
  moveBottomMode: (duration?: number) => void;
};
