import {CSSProperties} from 'react';
import classNames from 'classnames';
import s from 'styles/components/@tmds/TmdsTag.module.scss';

type TTmdsTagType = 'bold' | 'capsule';
type TProps = {
  // default : regular tag
  type?: TTmdsTagType;
  filled?: boolean;
  color: string;
  backgroundColor?: string;
  label: string;
  fontSize?: number;
  className?: string;
};

// tmds v1.3
// https://www.figma.com/file/3iYNmwNPYqaCrdajOvRo5h/TMDS_Library_v1.3.0?type=design&node-id=4512-22436&mode=design&t=jKLc4q271vjc0gpP-4
const TmdsTag = ({
  type,
  filled,
  color,
  backgroundColor,
  label,
  fontSize = 10,
  className,
}: TProps) => {
  return label ? (
    <div
      style={
        {
          '--color': color,
          '--background': filled ? backgroundColor : undefined,
          '--font-size': `${fontSize}px`,
        } as CSSProperties
      }
      className={classNames(s.tag, className, {
        [s.filled]: filled,
        [s.bold]: type === 'bold',
        [s.capsule]: type === 'capsule',
      })}
    >
      <span
        className={s.text}
        data-tmds-color={color}
        data-tmds-border-color={color}
        data-tmds-bg-color={filled ? backgroundColor : undefined}
      >
        {label}
      </span>
    </div>
  ) : null;
};

export default TmdsTag;
