import {useMemo} from 'react';
import classNames from 'classnames';
import {TEVImageResource, TEvChargerInfo} from 'types/App';
import {MARKER_LABEL_MAX} from 'constant/Map';
import useMap from 'hooks/useMap';
import {EMarkerType} from 'types/Map';
import {DEFAULT_CPO_IMAGE_RESOURCES} from 'constant/Poi';
import {getEVStationBiImage} from 'utils/general';

import MarkerImages from 'resource/pubImages/markers/@markerPath';

import s from 'styles/components/EVMarker.module.scss';

type TProps = {
  active: boolean;
  name: string;
  isTesla: boolean;
} & TEvChargerInfo;

export const EVMarker = ({active, name, isTesla, ...evChargerInfo}: TProps) => {
  const brandImage: TEVImageResource = useMemo(() => {
    const biImages = getEVStationBiImage(evChargerInfo, isTesla);

    return biImages[0] || DEFAULT_CPO_IMAGE_RESOURCES;
  }, [evChargerInfo, isTesla]);
  const {style: mapStyle} = useMap();

  const chargerType = useMemo(() => {
    const {icon, marker} = MarkerImages.mapmarker.ev;

    if (evChargerInfo.superFastChargerCount > 0) {
      return {
        count: evChargerInfo.superFastAvailableCount,
        OffTag: active ? marker.speed.super.fast.off : icon.speed.super.fast.off,
        OnTag: active ? marker.speed.super.fast.on : icon.speed.super.fast.on,
      };
    }

    if (evChargerInfo.fastChargerCount > 0) {
      return {
        count: evChargerInfo.fastAvailableCount,
        OffTag: active ? marker.speed.fast.off : icon.speed.fast.off,
        OnTag: active ? marker.speed.fast.on : icon.speed.fast.on,
      };
    }

    if (evChargerInfo.slowChargerCount > 0) {
      return {
        count: evChargerInfo.slowAvailableCount,
      };
    }

    return null;
  }, [active, evChargerInfo]);

  return (
    <div
      className={s.ev_marker_wrap}
      data-type={active ? EMarkerType.ACTIVE : EMarkerType.INACTIVE}
    >
      <span className={s.bi}>
        {brandImage.icon ? (
          <>
            <img
              className={s.base_marker}
              key="marker"
              src={active ? MarkerImages.custom.base.marker : MarkerImages.custom.base.icon}
              alt="전기차 충전소 마커"
            />
            <img className={s.logo} src={brandImage.icon} alt={brandImage.label} />
          </>
        ) : (
          <img
            key="marker"
            className={s.brand_marker}
            src={active ? brandImage.activeMarker : brandImage.poiMarker}
            alt={name}
          />
        )}
      </span>
      {chargerType?.OnTag && chargerType?.OffTag && (
        <span className={classNames(s.charger_type, {})}>
          <img
            src={chargerType.count > 0 ? chargerType.OnTag : chargerType.OffTag}
            width="100%"
            height="100%"
            alt={`${chargerType.count}대 사용가능`}
          />
        </span>
      )}
      {active && (
        <div className={s.label} data-style={mapStyle}>
          <p>
            {(name.length || 0) > MARKER_LABEL_MAX ? `${name.slice(0, MARKER_LABEL_MAX)}...` : name}
          </p>
          {chargerType && (
            <p
              className={classNames(s.status, {
                [s.invalid]: chargerType.count < 1,
              })}
            >
              {chargerType.count}대 사용가능
            </p>
          )}
        </div>
      )}
    </div>
  );
};
