import imgDataUrl from 'resource/images/event_main@3x.png';
import s from 'styles/components/search/SearchEventTab.module.scss';

const SearchEventTab = () => {
  return (
    <div className={s.wrap}>
      <div className={s.image}>
        <img src={imgDataUrl} alt="이벤트 메인 이미지" />
      </div>
      <div className={s.title}>
        참여할 음식점, 카페, 술집을 검색 후
        <br />
        목록에서 [확인]을 눌러주세요.
      </div>
      <div className={s.desc}>
        일부 맛집의 경우 선택이 불가할 수 있어요.
        <br />
        (프렌차이즈 포함)
      </div>
    </div>
  );
};

export default SearchEventTab;
