import {useCallback, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {ESearchLocationType} from 'types/Search';
import {UserSearchLocationType} from 'utils/search';

export const LOCATION_SORT_LIST = [
  {id: ESearchLocationType.USER, label: '내위치중심', actionId: 'tap.search_mylocation'},
  {id: ESearchLocationType.MAP, label: '지도중심', actionId: 'tap.search_maplocation'},
];

const useLocationSort = () => {
  const dispatch = useAppDispatch();
  const nowLocationType = useAppSelector((state) => state.search.locationType);

  const nowLocationItem = useMemo(() => {
    return LOCATION_SORT_LIST.find((v) => v.id === nowLocationType);
  }, [nowLocationType]);

  const updateLocationItem = useCallback(
    (type: ESearchLocationType) => {
      dispatch(actions.search.updateLocationType(type));
      UserSearchLocationType.set(type);
    },
    [dispatch]
  );

  return {
    nowLocationItem,
    updateLocationItem,
  };
};

export default useLocationSort;
