import {useEffect, useMemo, useState} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {useOnce} from 'hooks/useOnce';

import classNames from 'classnames';

import s from '../../styles/pages/SearchMainPage.module.scss';

import {ReactComponent as IconCoach} from 'resource/images/search_recommend_coach_mark.svg';
import {SESSION_STORAGE_KEY} from './SearchMainTab';

// TODO: firebase tooltip list 추가되면 이동
export enum ETooltipType {
  SEARCH_MAIN_RECOMMEND = 'SEARCH_MAIN_RECOMMEND',
}

const EXPOSE_TIME = 4000;

type TProps = {
  active: boolean;
};

const SearchMainTabCoachMark = ({active}: TProps) => {
  const {tooltipList} = useAppSelector((state) => state.remote);
  const hasSearchMainTabTooltip = useMemo(
    () => tooltipList.some((tooltip) => tooltip.type === ETooltipType.SEARCH_MAIN_RECOMMEND),
    [tooltipList]
  );
  const [isActive, setIsActive] = useState(false);
  const [isTransitionOver, setIsTransitionOver] = useState(false);
  const isRendered = sessionStorage.getItem(SESSION_STORAGE_KEY);

  useOnce(hasSearchMainTabTooltip && active && !isRendered, () => {
    setIsActive(true);
    sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
  });

  useEffect(() => {
    if (active && !isRendered) {
      return;
    }

    setIsActive(false);
    setIsTransitionOver(true);
  }, [active]);

  return (
    <>
      {hasSearchMainTabTooltip && (isActive || !isTransitionOver) && (
        <p
          className={classNames(s.coach_mark, {
            [s.active]: isActive,
          })}
          onClick={(e) => {
            e.stopPropagation();
            setIsActive(false);
          }}
          onTransitionEnd={() => {
            if (!isActive) {
              setIsTransitionOver(true);
            } else {
              setTimeout(() => {
                setIsActive(false);
              }, EXPOSE_TIME);
            }
          }}
        >
          <IconCoach />
        </p>
      )}
    </>
  );
};

export default SearchMainTabCoachMark;
