import {useCallback} from 'react';
import TMapSender from '@lcc/tmap-inapp';

import {EAccommodationProviderType, TPoiItem, TSearchPoi} from 'types/App';

import useThrottle from 'hooks/useThrottle';
import useDimmedQuery from 'hooks/useDimmedQuery';
import {getTmapOndaBookingUrl} from 'utils/url';
import {isOndaSupportVersion} from 'utils/tmapUtils';
import AppVersionPopup from './AppVersionPopup';

import s from 'styles/components/AccommodationReservationButton.module.scss';

type TProps = {
  poiData: TPoiItem | TSearchPoi;
  onClick?: () => void;
};

export const AccommodationReservationButton = ({poiData, onClick}: TProps) => {
  const ableToRun = useThrottle();
  const propertyId = poiData.special?.accommodationInfo?.accommodationPropertyId;
  const appVersionPopup = useDimmedQuery({id: 'APP_VERSION_POPUP'});

  const handleClickReservation = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!ableToRun()) {
        return;
      }

      if (!isOndaSupportVersion()) {
        appVersionPopup.open();
        return;
      }

      const url = getTmapOndaBookingUrl(propertyId, {
        poiId: Number(poiData.poiId),
      });

      onClick?.();
      TMapSender.openServiceByUrl(url);
    },
    [ableToRun, poiData.poiId, propertyId, appVersionPopup, onClick]
  );

  if (poiData.special?.accommodationInfo?.provider !== EAccommodationProviderType.ONDA) {
    return null;
  }

  return (
    <div className={s.reservation_wrap}>
      <div className={s.reservation_button} onClick={handleClickReservation}>
        <span>예약하기</span>
      </div>
      {appVersionPopup.isOpen && (
        <AppVersionPopup
          detailComponent={
            <>
              예약하기 서비스 이용을 위해
              <br />
              최신 버전으로 업데이트 해주세요.
            </>
          }
          onClickClose={() => appVersionPopup.close()}
          onClickNext={() => {
            appVersionPopup.close();
          }}
          onClickConfirm={() => {
            appVersionPopup.close();
          }}
        />
      )}
    </div>
  );
};
