import {ERecentItemType} from 'types/Search';

import {ReactComponent as IcMapRouteRight2} from 'resource/images/ic_map_route_right_2.svg';
import {IcMapRouteRight1Fill} from '../@tmds/icons/v1.2/IcMapRouteRight1Fill';
import {IcSearchBold} from '../@tmds/icons/v1.2/IcSearchBold';
import {IcHomeFill} from '../@tmds/icons/v1.2/IcHomeFill';
import {IcCompanyFill} from '../@tmds/icons/v1.2/IcCompanyFill';
import {IcStarFill} from '../@tmds/icons/v1.2/IcStarFill';
import {IcBusstopFill} from '../@tmds/icons/v1.2/IcBusstopFill';
import {IcMobilityMetroFill} from '../@tmds/icons/v1.2/IcMobilityMetroFill';

type TProps = {
  srcType: ERecentItemType;
  className?: string;
  color?: string;
};

type TIconProps = Partial<{
  width: number;
  height: number;
  color: string;
}>;

const BASE_ICON_MAP: Record<ERecentItemType, React.ComponentType<any>> = {
  [ERecentItemType.POI]: IcMapRouteRight1Fill,
  [ERecentItemType.QUERY]: IcSearchBold,
  [ERecentItemType.PUBLIC_POI]: IcMapRouteRight2,
  [ERecentItemType.HOME]: IcHomeFill,
  [ERecentItemType.OFFICE]: IcCompanyFill,
  [ERecentItemType.FAVORITE]: IcStarFill,
  [ERecentItemType.FAVORITE_STATION]: IcStarFill,
  [ERecentItemType.FAVORITE_SUBWAY]: IcStarFill,
  [ERecentItemType.BUS_STATION]: IcBusstopFill,
  [ERecentItemType.SUBWAY]: IcMobilityMetroFill,
};

const DEFAULT_PROPS: Partial<Record<ERecentItemType, TIconProps>> = {
  [ERecentItemType.POI]: {width: 20, height: 20, color: 'gray400'},
  [ERecentItemType.QUERY]: {width: 20, height: 20, color: 'gray400'},
  [ERecentItemType.HOME]: {width: 20, height: 20, color: 'gray400'},
  [ERecentItemType.OFFICE]: {width: 20, height: 20, color: 'gray400'},
  [ERecentItemType.FAVORITE]: {width: 20, height: 20, color: 'yellow500'},
  [ERecentItemType.FAVORITE_STATION]: {width: 20, height: 20, color: 'tmobiBlue300'},
  [ERecentItemType.FAVORITE_SUBWAY]: {width: 20, height: 20, color: 'tmobiBlue300'},
  [ERecentItemType.BUS_STATION]: {width: 20, height: 20, color: 'gray400'},
  [ERecentItemType.SUBWAY]: {width: 20, height: 20, color: 'gray400'},
};

const SearchListIcon = ({srcType, className, color}: TProps): JSX.Element => {
  const IconComponent = BASE_ICON_MAP[srcType];
  const defaultProps = DEFAULT_PROPS[srcType] || {};

  const iconProps = {
    ...defaultProps,
    ...(color && {color}),
  };

  return (
    <i className={className} data-type={srcType}>
      <IconComponent {...iconProps} />
    </i>
  );
};

export default SearchListIcon;
