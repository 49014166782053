import classnames from 'classnames';
import style from 'styles/components/DetailActionButton.module.scss';
import {PropsWithChildren, HTMLAttributes} from 'react';

type TProps = PropsWithChildren<HTMLAttributes<HTMLButtonElement>>;

export const DetailActionButton = ({className, children, ...buttonProps}: TProps) => {
  return (
    <button className={classnames(style.detail_action, className)} {...buttonProps}>
      {children}
    </button>
  );
};
