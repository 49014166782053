import {useCallback, useMemo} from 'react';
import {TPoiDataJson} from '@lcc/tmap-inapp';
import classNames from 'classnames';
import {ELegacyRecentItemType, TLegacyRecentItem} from 'types/LegacySearch';
import {ERPFlag} from 'types/App';
import {EDateFormat} from 'types/DateTime';
import {getSafeDateFormat} from 'utils/date';
import {highlightQuery} from 'utils/search';
import CheckboxIcon from 'components/CheckboxIcon';

import {ReactComponent as IconPoi} from 'resource/images/@tmds_basic/ico_location.svg';
import {ReactComponent as IconSearch} from 'resource/images/@tmds_basic/ico_search.svg';

import s from 'styles/components/legacySearch/RecentItem.module.scss';

type TProps = {
  data: TLegacyRecentItem;
  isEdit?: boolean;
  isSelect?: boolean;
  onSelect?: (id: string) => void;
  onClickSearch?: (query: string) => void;
  onClickDestination?: (poiDataJson: TPoiDataJson) => void;
  highlightRegExp?: RegExp;
  inSuggest?: boolean;
};

const RecentItem = ({
  data,
  isEdit,
  isSelect,
  onSelect,
  onClickSearch,
  onClickDestination,
  highlightRegExp,
  inSuggest,
}: TProps) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (isEdit) {
        onSelect?.(data.id);
      } else {
        if (data.type === ELegacyRecentItemType.POI && data.poiInfo?.centerX && data.poiInfo.navX) {
          onClickDestination?.({
            pkey: `${data.poiInfo.pkey || ''}`,
            poiId: `${data.poiInfo.poiId || ''}`,
            navSeq: `${data.poiInfo.navSeq || ''}`,
            poiName: data.poiInfo.customName || '',
            navX: `${data.poiInfo.navX}`,
            navY: `${data.poiInfo.navY}`,
            centerX: `${data.poiInfo.centerX}`,
            centerY: `${data.poiInfo.centerY}`,
            address: data.poiInfo.addInfo || '',
            tel: '',
            rpFlag: data.poiInfo.rpFlag || ERPFlag.N_G000,
          });
        } else if (data.type === ELegacyRecentItemType.QUERY && data.name) {
          onClickSearch?.(data.name);
        }
      }
    },
    [data, isEdit, onSelect, onClickSearch, onClickDestination]
  );

  const itemLabel = useMemo(() => {
    return `${data.name} ${
      {
        [ELegacyRecentItemType.POI]: '길찾기',
        [ELegacyRecentItemType.QUERY]: '검색',
      }[data.type]
    }`;
  }, [data.name, data.type]);

  return (
    <li
      className={classNames(s.search_recent_item, {
        [s.in_suggest]: inSuggest,
      })}
      onClick={handleClick}
      role={isEdit ? 'checkbox' : 'button'}
      aria-label={itemLabel}
      aria-checked={isEdit ? isSelect : undefined}
    >
      <i className={s.icon}>
        {data.type === ELegacyRecentItemType.POI && <IconPoi />}
        {data.type === ELegacyRecentItemType.QUERY && <IconSearch />}
      </i>

      <div className={s.name}>{highlightQuery(data.name, highlightRegExp)}</div>

      {isEdit ? (
        <CheckboxIcon on={isSelect} />
      ) : (
        <div className={s.date}>{getSafeDateFormat(data.date, EDateFormat.Mdd)}</div>
      )}
    </li>
  );
};

export default RecentItem;
