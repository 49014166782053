import {useCallback, useMemo} from 'react';
import {TPoiDataJson} from '@lcc/tmap-inapp';
import classNames from 'classnames';

import {EFavoriteType} from 'types/Search';
import {ERPFlag, TPoiDetailParam} from 'types/App';
import {TPersonalPlaceItem} from 'ducks/userInfo/types';

import {highlightQuery} from 'utils/search';
import {ReactComponent as IconFavoriteHome} from 'resource/images/@tmds_solid/ico_home_solid.svg';
import {ReactComponent as IconFavoriteOffice} from 'resource/images/@tmds_solid/ico_company_solid.svg';
import {ReactComponent as IconFavoritePoi} from 'resource/images/@tmds_solid/ico_star_solid.svg';

import s from 'styles/components/legacySearch/FavoriteItem.module.scss';
import useAddress from 'hooks/useAddress';

type TProps = {
  data: TPersonalPlaceItem;
  inSuggest?: boolean;
  onClick?: (poiDataJson: TPoiDataJson) => void;
  onClickDetail?: (data: TPoiDetailParam) => void;
  type?: EFavoriteType;
  highlightRegExp?: RegExp;
};

const FAVORITE_ICON = {
  [EFavoriteType.HOME]: <IconFavoriteHome />,
  [EFavoriteType.OFFICE]: <IconFavoriteOffice />,
  [EFavoriteType.POI]: <IconFavoritePoi />,
};

const FavoriteItem = ({
  data,
  inSuggest,
  onClick,
  onClickDetail,
  type = EFavoriteType.POI,
  highlightRegExp,
}: TProps) => {
  const {fullAddress} = useAddress(data);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (data.navX && data.centerX) {
        onClick?.({
          pkey: `${data.pkey || ''}`,
          poiId: `${data.poiId || ''}`,
          navSeq: `${data.navSeq || ''}`,
          poiName: data.customName,
          navX: `${data.navX}`,
          navY: `${data.navY}`,
          centerX: `${data.centerX}`,
          centerY: `${data.centerY}`,
          address: data.addInfo || '',
          tel: '',
          rpFlag: data.rpFlag || ERPFlag.N_G000,
        });
      }
    },
    [onClick, data]
  );

  const handleClickDetail = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (data.pkey || data.poiId || data.navX) {
        onClickDetail?.({
          pkey: `${data.pkey || ''}`,
          poiId: `${data.poiId || ''}`,
          navX: `${data.navX}`,
          navY: `${data.navY}`,
        });
      }
    },
    [onClickDetail, data]
  );

  const typeLabel = useMemo(
    () =>
      ({
        [EFavoriteType.HOME]: '집',
        [EFavoriteType.OFFICE]: '회사',
      }[type] || ''),
    [type]
  );

  return (
    <div className={s.search_favorite_item} onClick={handleClick}>
      <div className={s.main_click_wrap}>
        <i
          className={classNames(s.icon, {
            [s.is_star]: type === EFavoriteType.POI,
          })}
        >
          {FAVORITE_ICON[type]}
        </i>
        <div
          className={classNames(s.poi_wrap, {
            [s.ellipsis]: inSuggest,
            [s.ellipsis_two]: !inSuggest,
          })}
          role="button"
          aria-label={`${typeLabel} ${data.customName} 길찾기`}
        >
          <div className={s.name}>{highlightQuery(data.customName, highlightRegExp)}</div>

          {!inSuggest && (fullAddress || data.addInfo) && (
            <div className={s.address}>{fullAddress || data.addInfo}</div>
          )}
        </div>
      </div>

      {!inSuggest && type === EFavoriteType.POI && (
        <div
          className={s.btn_wrap}
          onClick={handleClickDetail}
          role="button"
          aria-label={`${data.customName} 자세히`}
        >
          <div className={s.btn_detail}>자세히</div>
        </div>
      )}
    </div>
  );
};

export default FavoriteItem;
