import {useCallback, useEffect} from 'react';

import actions from 'ducks/actions';

import {ESkeletonType} from 'types/App';

import useTNowSearch from 'hooks/useTNowSearch';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';

import TNowList from 'components/tnow/TNowList';
import Skeleton from 'components/Skeleton';

const TNowListWrap = () => {
  const dispatch = useAppDispatch();
  const {result, baseSpot} = useAppSelector((state) => ({
    result: state.tNow.result,
    baseSpot: state.tNow.baseSpot,
  }));
  const {autoSearch, autoSearchFromNextRadius} = useTNowSearch();

  const onFetchMore = useCallback(() => dispatch(actions.tNow.updateNextPage()), [dispatch]);

  useEffect(() => {
    if (result.loaded) {
      dispatch(actions.userInteraction.setRefreshStart(false));
    }
  }, [dispatch, result.loaded]);

  return (
    <Skeleton type={ESkeletonType.TNOW_LIST} apiStatus={result}>
      <TNowList
        isServiceArea={!!baseSpot.currentAddressName}
        apiStatus={result}
        onClickAutoSearch={autoSearchFromNextRadius}
        onError={() => {
          dispatch(actions.tNow.setBaseSpot({...baseSpot, refreshKey: Date.now()}));
          autoSearch();
        }}
        onFetchMore={onFetchMore}
      />
    </Skeleton>
  );
};

export default TNowListWrap;
