import {useEffect, useRef, useCallback} from 'react';
import TMapSender from '@lcc/tmap-inapp';

import {SEARCH_INPUT_MAX_LENGTH} from 'constant/Search';

import {useAppSelector} from 'ducks/hooks';
import {EHistoryStack} from 'ducks/userInteraction/types';

import {useRecentTabMode} from 'hooks/useRecentTabMode';
import {useAppLocation} from 'hooks/useAppLocation';

import ua from 'utils/uaParser';
import {sendSearchClickLog} from 'utils/logManager';

import {ReactComponent as IconBack} from 'resource/images/@tmds_element/ico_arrow_left.svg';
import {ReactComponent as IconSearch} from 'resource/images/@tmds_basic/ico_search.svg';
import {ReactComponent as IconRemove} from 'resource/images/@tmds_solid/ico_cancel_solid.svg';

import s from 'styles/components/legacySearch/SearchBox.module.scss';

type TProps = {
  query: string;
  onChange?: (query: string) => void;
  onRemove?: VoidFunction;
  onSearch?: VoidFunction;
  onClick?: VoidFunction;
  onClose?: VoidFunction;
  blurKey?: number;
  focusKey?: number;
};

const SearchBox = ({
  query = '',
  onRemove,
  onChange,
  onClick,
  onClose,
  onSearch,
  blurKey,
  focusKey,
}: TProps) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const {isEdit} = useRecentTabMode();
  const {historyStack} = useAppSelector((state) => ({
    historyStack: state.userInteraction.historyStack,
  }));
  const {goBack} = useAppLocation();

  useEffect(() => {
    searchInputRef.current?.focus();
    TMapSender.showSoftKeyboard(true);
  }, []);

  useEffect(() => {
    if (blurKey) {
      searchInputRef.current?.blur();
    }
  }, [blurKey]);

  useEffect(() => {
    if (focusKey) {
      searchInputRef.current?.focus();
      searchInputRef.current?.scrollTo(searchInputRef.current.scrollWidth, 0);
    }
  }, [focusKey]);

  const handleChange = useCallback(
    (changedQuery) => {
      onChange?.(changedQuery.slice(0, SEARCH_INPUT_MAX_LENGTH));
    },
    [onChange]
  );

  const triggerSearch = useCallback(() => {
    if (query) {
      searchInputRef.current?.blur();

      onSearch?.();
    }
  }, [query, onSearch]);

  const handleSubmit = useCallback(
    (e) => {
      e?.preventDefault();

      const inputValue = searchInputRef.current?.value || '';

      sendSearchClickLog('ime_tap.searchbtn', {
        search_query: inputValue,
        user_query: inputValue,
      });

      triggerSearch();
    },
    [triggerSearch]
  );

  const handleClose = useCallback(
    (e) => {
      e.preventDefault();

      onClose?.();

      if (isEdit || (historyStack?.length && historyStack[0] === EHistoryStack.CALLOUT)) {
        goBack();
        return;
      }

      if (ua.isInApp) {
        TMapSender.onBackKeyPressed();
      }
    },
    [isEdit, onClose, historyStack, goBack]
  );

  const handleRemove = useCallback(() => {
    handleChange('');
    onRemove?.();

    searchInputRef.current?.focus();
  }, [handleChange, onRemove]);

  const handleClickSearch = useCallback(
    (e) => {
      e?.preventDefault();

      sendSearchClickLog('top_tap.searchbtn', {
        user_query: query,
        search_query: query,
      });

      triggerSearch();
    },
    [triggerSearch, query]
  );

  return (
    <div className={s.search_box}>
      <form className={s.form_wrap} onSubmit={handleSubmit}>
        <IconBack className={s.btn_back} onClick={handleClose} />

        <input
          type="text"
          ref={searchInputRef}
          placeholder="장소, 주소, 전화번호 검색"
          onClick={onClick}
          onChange={(e) => handleChange(e.target.value)}
          value={query}
        />

        {query && (
          <button type="button" className={s.btn_remove} onClick={handleRemove}>
            <IconRemove className={s.remove_icon} />
          </button>
        )}

        <button type="button" className={s.btn_search} onClick={handleClickSearch}>
          <IconSearch className={s.search_icon} />
        </button>
      </form>
    </div>
  );
};

export default SearchBox;
