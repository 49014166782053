import {TSKBesselCoordinate, TWgs84Coordinate} from 'types/App';

export enum ELegacyRecentItemType {
  POI = 'POI',
  QUERY = 'QUERY',
}

type TLegacyPersonalPlaceItem = {
  pkey: Nullable<string>;
  poiId: Nullable<string>;
  navSeq: Nullable<string>;
  customName: string;
  rpFlag: number;
  addInfo?: Nullable<string>; // favorite 에서만 내려 옴
  regDateTime?: Nullable<string>; // favorite 에서만 내려 옴
  fullRoadAddr?: Nullable<string>; // favorite 에서만 내려 옴
  fullJibunAddr?: Nullable<string>; // favorite 에서만 내려 옴
  recentDateTime?: Nullable<string>; // recentDestination 에서만 내려 옴
} & TSKBesselCoordinate &
  TWgs84Coordinate & {
    personalPoiKey?: string; // front에서 설정하는 uuid
  };

export type TLegacyRecentItem = {
  id: string;
  type: ELegacyRecentItemType;
  name: string;
  date: string;
  poiInfo?: TLegacyPersonalPlaceItem;
  transportId?: string;
};

export enum ELegacySearchTabs {
  RECENT_EDIT = 'RECENT_EDIT',
  RECENT = 'RECENT',
  FAVORITE = 'FAVORITE',
  ADDRESS = 'ADDRESS',
  MAP = 'MAP',
}

export enum ELegacyFavoriteFilter {
  POI = 'POI',
  ROUTE = 'ROUTE',
}

export enum ELegacyFavoriteSortOption {
  REG_DATE = 'reg_date',
  ALPHABET = 'alphabet',
}
