import {HTMLAttributes, useCallback} from 'react';
import classnames from 'classnames';

import {TLonLat} from 'types/Map';
import useLogger from 'hooks/useLogger';
import useCurrentPosition from 'hooks/useCurrentPosition';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';

import {ReactComponent as IconTarget} from 'resource/images/icon_button_location_ico_target1.svg';

import s from 'styles/components/CurrentPositionButton.module.scss';

type TProps = HTMLAttributes<HTMLButtonElement> & {
  onAfterPosition?: (e: TLonLat) => void;
  disableAutoMove?: boolean;
};

const CurrentPositionButton = ({
  className,
  onAfterPosition,
  disableAutoMove,
  ...buttonProps
}: TProps) => {
  const {queries} = useParseQueryLocation();
  const {getPosition, isPending} = useCurrentPosition();
  const {sendClickLog} = useLogger();

  const handleClick = useCallback(() => {
    getPosition(disableAutoMove).then((center) => onAfterPosition?.(center as TLonLat));
    sendClickLog('tap.compass', {search_query: queries.searchQuery}, {includeTicketId: true});
  }, [getPosition, onAfterPosition, sendClickLog, disableAutoMove, queries.searchQuery]);

  return (
    <button
      className={classnames(s.current_position_button, className, {
        [s.loading]: isPending,
      })}
      {...buttonProps}
      role="button"
      aria-label="현재 위치로 이동"
      onClick={handleClick}
    >
      <IconTarget />
    </button>
  );
};

export default CurrentPositionButton;
