const getNextPage = ({originList, currentPage, pagingCount = 20, maxListCount = 100}) => {
  const listCount = pagingCount * currentPage;

  if (listCount >= maxListCount) {
    return null;
  }

  const nextPage = currentPage + 1;
  const nextListCount = Math.min(pagingCount * nextPage, maxListCount);

  return {
    list: originList.slice(0, nextListCount),
    page: nextPage,
  };
};

const Paging = {
  getNextPage,
};

export default Paging;
