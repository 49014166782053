import mixpanel from 'mixpanel-browser';

import {isProdEnv} from 'constant/Env';
import {EMixPanelToken} from 'constant/Log';

import {devLog} from 'utils/dev';

type TLogOpt = {
  serviceId?: string;
  pageId?: string;
  userKey?: string;
  appVersion?: string;
};

const NOT_SET_STRING = 'not set';

const _logData: TLogOpt = {
  serviceId: NOT_SET_STRING,
  pageId: NOT_SET_STRING,
  userKey: '',
  appVersion: '',
};

// init 전에 이벤트 전송방지
let isInit = false;
const logQueue: Array<{
  actionId: string;
  properties?: object;
}> = [];

const trackEvent = (eventName: string, properties?: object) => {
  devLog('[MIXPANEL] event', eventName, properties);

  mixpanel.track(eventName, properties);
};

export const init = (opt?: TLogOpt) => {
  const token = isProdEnv ? EMixPanelToken.PROD : EMixPanelToken.STAGE;
  const debugMode = !isProdEnv;

  devLog('[MIXPANEL] init', token, debugMode);

  if (opt?.serviceId) {
    _logData.serviceId = opt.serviceId;
  }

  if (opt?.pageId) {
    _logData.pageId = opt.pageId;
  }

  if (opt?.appVersion) {
    _logData.appVersion = opt.appVersion;
  }

  mixpanel.init(token, {
    debug: debugMode,
    loaded: () => {
      if (opt?.userKey) {
        _logData.userKey = opt.userKey;
        mixpanel.identify(opt.userKey);
      }

      isInit = true;

      devLog('[MIXPANEL] init success');

      logQueue.forEach((n) => {
        devLog('[MIXPANEL] send queue', n.actionId, n.properties);

        sendEvent(n.actionId, n.properties);
      });
    },
  });
};

export const setPageId = (pageId: string) => {
  _logData.pageId = pageId;
};

export const sendEvent = (actionId: string, p?: KVObject) => {
  // serviceId:pageId:actionId
  const eventName = [_logData.serviceId, _logData.pageId, actionId].filter((n) => n).join(':');
  const properties = {...p, appVersion: _logData.appVersion};

  if (isInit) {
    trackEvent(eventName, properties);
  } else {
    devLog('[MIXPANEL] add queue', actionId, properties);

    logQueue.push({
      actionId,
      properties,
    });
  }
};
