import ImageLoader from 'components/ImageLoader';
import {useState, useEffect} from 'react';
import s from 'styles/pages/ProtoImagePage.module.scss';

type TTestImage = {
  author: string;
  download_url: string;
  height: number;
  id: string;
  url: string;
  width: number;
  src: string;
};

const ProtoImagePage = () => {
  const [images, setImages] = useState<TTestImage[]>([]);

  useEffect(() => {
    fetch('https://picsum.photos/v2/list?page=2')
      .then((res) => res.json())
      .then((list) =>
        setImages(
          list.map((item) => ({...item, src: item.download_url.replace(/\d+\/\d+$/, '300/200')}))
        )
      );
  }, []);

  return (
    <div className={s.page_wrap}>
      <ul>
        {images.map((v) => (
          <li key={v.id} className={s.item}>
            <ImageLoader src={v.src} alt={v.author} className={s.img} toggleClass={s.fade_in} />
          </li>
        ))}
      </ul>
    </div>
  );
};
export default ProtoImagePage;
