import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {EPageType, TLA_SEARCH_MAIN_FESTIVAL} from 'constant/Log';
import {init as initLog, sendExpose} from 'utils/logManager';
import useUserData from 'hooks/useUserData';
import {getCurrentPackageVersion} from 'utils/tmapUtils';
import BuildInfo from 'components/BuildInfo';
import SearchFestival from 'components/search/SearchFestival';

const FestivalPage = () => {
  const {userInfo} = useAppSelector((state) => ({
    userInfo: state.userInfo,
  }));
  const dispatch = useAppDispatch();
  useUserData();

  useEffect(() => {
    if (!userInfo.accessKey) {
      return;
    }

    initLog({
      sessionId: userInfo.sessionId,
      accessKey: userInfo.accessKey,
      sessionKey: userInfo.sessionKey,
      userKey: userInfo.userKey,
      deviceId: userInfo.device.deviceId,
      carrierName: userInfo.device.carrierName,
      pageId: TLA_SEARCH_MAIN_FESTIVAL,
      pageType: EPageType.SEARCH,
    });

    sendExpose(EPageType.SEARCH, {
      package_version: getCurrentPackageVersion(),
    });

    dispatch(actions.log.setInitialize(true));
  }, [userInfo.accessKey]);

  return (
    <div
      style={{
        height: '100%',
      }}
    >
      <SearchFestival />
      <BuildInfo />
    </div>
  );
};

export default FestivalPage;
