import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {useOnce} from './useOnce';
import {checkExpose} from 'utils/date';

export const useMapMarkerConfig = () => {
  const dispatch = useAppDispatch();
  const {remoteConfig, markerStyleConfig} = useAppSelector((state) => ({
    remoteConfig: state.remote,
    markerStyleConfig: state.map.markerStyleConfig,
  }));

  useOnce(remoteConfig.lastUpdateTime && remoteConfig.themeMarker.length > 0, () => {
    const styleConfig = remoteConfig.themeMarker
      .filter((c) => (c.exposeTime ? checkExpose(c.exposeTime) : true))
      .reduce((prev, cur) => {
        return {
          ...prev,
          [cur.code]: cur,
        };
      }, {});

    dispatch(actions.map.setMarkerStyleConfig(styleConfig));
  });

  return {markerStyleConfig};
};

export default useMapMarkerConfig;
