import {useMemo} from 'react';
import {getDistance, setComma} from 'utils/formatter';
import useAddress from './useAddress';
import {MIN_HEADING_COUNT} from 'constant/Place';
import {generateTagComponentList} from './usePoiMainContentTag';
import {ESearchLocationType} from 'types/Search';
import {useAppSelector} from 'ducks/hooks';

export const useParsePoiData = (poiData) => {
  const {locationType} = useAppSelector((state) => state.search);
  const imageInfo = useMemo(() => {
    return poiData.imageInfo?.filter((img) => !!(img.url || img.url2)) || [];
  }, [poiData.imageInfo]);

  const distance = useMemo(() => {
    const poiDataDistance = {
      [ESearchLocationType.MAP]: poiData.distance,
      [ESearchLocationType.USER]: poiData.userRealDistance,
      [ESearchLocationType.MAP_ON_MAP]: poiData.distance,
      [ESearchLocationType.USER_ON_MAP]: poiData.userRealDistance,
    }[locationType];

    return getDistance(poiDataDistance, 1);
  }, [locationType, poiData?.userRealDistance, poiData?.distance]);

  const {showShortAddress, absoluteFullAddress} = useAddress(poiData);
  const address = useMemo(
    () => (showShortAddress ? absoluteFullAddress.depth2Addr : absoluteFullAddress.fullAddress),
    [showShortAddress, absoluteFullAddress]
  );

  const headingScore = useMemo(() => {
    return poiData.headingForScore > MIN_HEADING_COUNT ? setComma(poiData.headingForScore) : '';
  }, [poiData.headingForScore]);

  const tags = useMemo(() => {
    return generateTagComponentList(poiData);
  }, [poiData]);

  const coupon = useMemo(() => {
    if (poiData.tag.isDiscountCoupon && poiData.tag.isTmapPayment) {
      return {label: '주차권'};
    }

    if (poiData.tag.isDiscountTicket) {
      return {label: '쿠폰'};
    }

    return null;
  }, [poiData.tag]);

  return {
    distance,
    address,
    imageInfo,
    headingScore,
    tags,
    coupon,
  };
};
