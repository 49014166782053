import {ECVType, EEVStationType, EOilCompany, EOilType, EPlaceCategoryType} from 'types/App';

import gs01x3 from 'resource/pubImages/bi/ci-bi-gasstation-01@3x.jpg';
import gs02x3 from 'resource/pubImages/bi/ci-bi-gasstation-02@3x.jpg';
import gs03x3 from 'resource/pubImages/bi/bi_partnership_gasstation_03@3x.png';
import gs04x3 from 'resource/pubImages/bi/ci-bi-gasstation-04@3x.png';
import gs05x3 from 'resource/pubImages/bi/ci-bi-gasstation-05@3x.jpg';
import gs06x3 from 'resource/pubImages/bi/ci-bi-gasstation-06@3x.png';

import bank01x3 from 'resource/pubImages/bi/ci-bi-bank-01@3x.png';
import bank02x3 from 'resource/pubImages/bi/ci-bi-bank-02@3x.png';
import bank03x3 from 'resource/pubImages/bi/ci-bi-bank-03@3x.png';
import bank04x3 from 'resource/pubImages/bi/ci-bi-bank-04@3x.png';
import bank05x3 from 'resource/pubImages/bi/ci-bi-bank-05@3x.png';
import bank06x3 from 'resource/pubImages/bi/ci-bi-bank-06@3x.png';
import bank07x3 from 'resource/pubImages/bi/ci-bi-bank-07@3x.png';
import bank08x3 from 'resource/pubImages/bi/ci-bi-bank-08@3x.png';
import bank09x3 from 'resource/pubImages/bi/ci-bi-bank-09@3x.png';
import bank10x3 from 'resource/pubImages/bi/ci-bi-bank-10@3x.png';
import bank11x3 from 'resource/pubImages/bi/ci-bi-bank-11@3x.png';
import bank12x3 from 'resource/pubImages/bi/ci-bi-bank-12@3x.png';
import bank13x3 from 'resource/pubImages/bi/ci-bi-bank-13@3x.png';
import bank14x3 from 'resource/pubImages/bi/ci-bi-bank-14@3x.png';

import conv01x3 from 'resource/pubImages/bi/ci-bi-convenience-01@3x.png';
import conv02x3 from 'resource/pubImages/bi/ci-bi-convenience-02@3x.png';
import conv03x3 from 'resource/pubImages/bi/ci-bi-convenience-03@3x.png';
import conv05x3 from 'resource/pubImages/bi/ci-bi-convenience-05@3x.png';

import ev11x3 from 'resource/pubImages/bi/bi_partnership_evstation_11@3x.png';

import {ECarFuel} from 'ducks/userInfo/types';
import MarkerPath from 'resource/pubImages/markers/@markerPath';

const evPoiMarker = MarkerPath.mapmarker.ev.icon;
const evActiveMarker = MarkerPath.mapmarker.ev.marker;

export const EGasolineCompany = {
  [EOilCompany.SK]: 'OILGASSKE',
  [EOilCompany.S_OIL]: 'OILGASSOIL',
  [EOilCompany.GS]: 'OILGASGSK',
  [EOilCompany.HYUNDAI]: 'OILGASHBK',
  [EOilCompany.ETC]: 'OILGASETC',
};

export const EPremiumOilCompany = {
  [EOilCompany.SK]: 'OILGASPMSKE',
  [EOilCompany.S_OIL]: 'OILGASPMSOIL',
  [EOilCompany.GS]: 'OILGASPMGSK',
  [EOilCompany.HYUNDAI]: 'OILGASPMHBK',
  [EOilCompany.ETC]: 'OILGASPMETC',
};

export const EDieselCompany = {
  [EOilCompany.SK]: 'OILDSLSKE',
  [EOilCompany.S_OIL]: 'OILDSLSOIL',
  [EOilCompany.GS]: 'OILDSLGSK',
  [EOilCompany.HYUNDAI]: 'OILDSLHBK',
  [EOilCompany.ETC]: 'OILDSLETC',
};

export const ELPGCompany = {
  [EOilCompany.SK]: 'OILLPGSKE',
  [EOilCompany.S_OIL]: 'OILLPGSOIL',
  [EOilCompany.GS]: 'OILLPGGSK',
  [EOilCompany.HYUNDAI]: 'OILLPGHBK',
  [EOilCompany.E1]: 'OILLPGE1',
  [EOilCompany.ETC]: 'OILLPGETC',
};

export const EAllOilCompany = {
  [EOilCompany.SK]: 'OILSKE',
  [EOilCompany.S_OIL]: 'OILSOIL',
  [EOilCompany.GS]: 'OILGSK',
  [EOilCompany.HYUNDAI]: 'OILHBK',
  [EOilCompany.E1]: 'OILE1',
  [EOilCompany.ETC]: 'OILETC',
};

export const GasStationOption = [
  {id: EOilCompany.SK, label: 'SK에너지', icon: gs01x3},
  {id: EOilCompany.S_OIL, label: '에쓰오일', icon: gs04x3},
  {id: EOilCompany.GS, label: 'GS칼텍스', icon: gs02x3},
  {id: EOilCompany.HYUNDAI, label: 'HD현대오일뱅크', icon: gs03x3},
  {id: EOilCompany.E1, label: 'E1', icon: gs05x3},
  {id: EOilCompany.ETC, label: '기타', icon: gs06x3},
];

export const RestaurantOption = [
  {label: '한식'},
  {label: '양식'},
  {label: '중식'},
  {label: '일식'},
  {label: '분식'},
  {label: '패스트푸드'},
  {label: '치킨'},
  {label: '술집'},
];

export const BankOption = [
  {label: 'KB국민', icon: bank01x3},
  {label: '신한', icon: bank02x3},
  {label: '하나', icon: bank03x3},
  {label: '우리', icon: bank04x3},
  {label: 'IBK기업', icon: bank05x3},
  {label: 'NH농협', icon: bank06x3},
  {label: 'KDB산업', icon: bank07x3},
  {label: 'SC제일', icon: bank08x3},
  {label: 'BNK경남, 부산', icon: bank09x3},
  {label: '시티', icon: bank10x3},
  {label: 'DGB대구', icon: bank11x3},
  {label: 'SH수협', icon: bank12x3},
  {label: '전북,광주', icon: bank13x3},
  {label: '제주', icon: bank14x3},
];

export const ConvenienceOption = [
  {id: EPlaceCategoryType.CV_ALL, label: '전체'},
  {id: ECVType.CU, label: 'CU', icon: conv01x3},
  {id: ECVType.GS25, label: 'GS25', icon: conv02x3},
  {id: ECVType.SEVEN_ELEVEN, label: '세븐일레븐', icon: conv03x3},
  {id: ECVType.EMART_24, label: '이마트24', icon: conv05x3},
];

export const TESLA_IMAGE_RESOURCES = {
  id: EEVStationType.TESLA,
  label: '테슬라',
  icon: ev11x3,
  poiMarker: evPoiMarker.tesla,
  activeMarker: evActiveMarker.tesla,
};

export const DEFAULT_CPO_IMAGE_RESOURCES = {
  id: EEVStationType.ETC,
  label: '기타',
  poiMarker: evPoiMarker.brandx,
  activeMarker: evActiveMarker.brandx,
};

export const DEFAULT_EV_STATION_LIST = [
  {
    id: EEVStationType.POWERCUBE,
    label: '파워큐브',
  },
  {
    id: EEVStationType.CHARGE_EV,
    label: '차지비',
  },
  {
    id: EEVStationType.KOREA_POWER,
    label: '한국전력',
  },
  {
    id: EEVStationType.STAR_KOFF,
    label: '스타코프',
  },
  {
    id: EEVStationType.EVER_ON,
    label: '에버온',
  },
  {
    id: EEVStationType.GS_CONNECT,
    label: 'GS커넥트',
  },
  {
    id: EEVStationType.MINISTRY,
    label: '환경부',
  },
  {
    id: EEVStationType.KOREA_EV,
    label: '한국전기차충전서비스',
  },

  {
    id: EEVStationType.HUMAX,
    label: '휴맥스EV',
  },
  {
    id: EEVStationType.KLIN_ELEX,
    label: '클린일렉스',
  },
  {
    id: EEVStationType.JEJU_EV,
    label: '제주전기자동차서비스',
  },
  {
    id: EEVStationType.DAYEONG_CHAEVI,
    label: '대영채비',
  },
  {
    id: EEVStationType.ECAR_PLUG,
    label: '이카플러그',
  },
  {
    id: EEVStationType.SK_ELECTLINK,
    label: 'SK일렉링크',
  },
  {
    id: EEVStationType.KEVIT,
    label: '한국전기차인프라기술',
  },
  {
    id: EEVStationType.CHARZ_IN,
    label: '차지인',
  },
  {
    id: EEVStationType.LG_HELLOVISION,
    label: 'LG헬로비전',
  },
];

export const FuelMap = {
  [ECarFuel.GASOLINE]: EOilType.GASOLINE,
  [ECarFuel.GASOLINE_PREMIUM]: EOilType.GASOLINE_PREMIUM,
  [ECarFuel.DIESEL]: EOilType.DIESEL,
  [ECarFuel.LPG]: EOilType.LPG,
};

export const SEJONG_REGION1_NAME = '세종특별자치시';
export const SEJONG_REGION1_SHORTEN_NAME = '세종시';

export const CATEGORY_SHORT_ADDRESS_KEY = 'address_short';
export const CATEGORY_SHORT_ADDRESS_VALUE = '1';
