import {MarkerOptions} from '@vsm/vsm';
import {ReactNode} from 'react';

type North = number;
type South = number;
type East = number;
type West = number;

export type TPosition = {
  center?: TLonLat;
  bounds?: TBounds;
  isCenterBounds?: boolean;
};

export type TCenterOffset = {x: number; y: number};

export type TBounds = [South, West, North, East];

export type TBoundsPadding = {top: number; left: number; right: number; bottom: number};

export type TLonLat = {
  lon: number;
  lat: number;
};

export type TVSMLngLat = {
  lng: number;
  lat: number;
};

export enum EMarkerType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CURRENT_POSITION = 'CURRENT_POSITION',
  RECENT_DESTINATION = 'RECENT_DESTINATION',
  ACTIVE_RECENT_DESTINATION = 'ACTIVE_RECENT_DESTINATION',
  FAVORITE = 'FAVORITE',
  ACTIVE_FAVORITE = 'ACTIVE_FAVORITE',
  FAVORITE_HOME = 'FAVORITE_HOME',
  ACTIVE_FAVORITE_HOME = 'ACTIVE_FAVORITE_HOME',
  FAVORITE_OFFICE = 'FAVORITE_OFFICE',
  ACTIVE_FAVORITE_OFFICE = 'ACTIVE_FAVORITE_OFFICE',
  FAVORITE_PUBLIC_TRANS = 'FAVORITE_PUBLIC_TRANS',
  ACTIVE_FAVORITE_PUBLIC_TRANS = 'ACTIVE_FAVORITE_PUBLIC_TRANS',
  SHARE_PLACE = 'SHARE_PLACE',
  SHARE_ARRIVAL = 'SHARE_ARRIVAL',
  SHARE_CAR = 'SHARE_CAR',
  SHARE_CAR_SMALL = 'SHARE_CAR_SMALL',
  ACTIVE_BUS_STATION = 'ACTIVE_BUS_STATION',
  ACTIVE_SUBWAY = 'ACTIVE_SUBWAY',
  ACTIVE_RANKING = 'ACTIVE_RANKING',
  INACTIVE_RANKING = 'INACTIVE_RANKING',
  NONE = 'NONE',
  RANKING = 'RANKING',
  SAVE_POI = 'SAVE_POI',
  ACTIVE_SAVE_POI_GROUP01 = 'ACTIVE_SAVE_POI_GROUP01',
  ACTIVE_SAVE_POI_GROUP02 = 'ACTIVE_SAVE_POI_GROUP02',
  ACTIVE_SAVE_POI_GROUP03 = 'ACTIVE_SAVE_POI_GROUP03',
  ACTIVE_SAVE_POI_GROUP04 = 'ACTIVE_SAVE_POI_GROUP04',
  ACTIVE_SAVE_POI_GROUP05 = 'ACTIVE_SAVE_POI_GROUP05',
  ACTIVE_SAVE_POI_GROUP06 = 'ACTIVE_SAVE_POI_GROUP06',
  ACTIVE_SAVE_POI_GROUP07 = 'ACTIVE_SAVE_POI_GROUP07',
  ACTIVE_SAVE_POI_GROUP08 = 'ACTIVE_SAVE_POI_GROUP08',
  ACTIVE_SAVE_POI_GROUP09 = 'ACTIVE_SAVE_POI_GROUP09',
  ACTIVE_SAVE_POI_GROUP10 = 'ACTIVE_SAVE_POI_GROUP10',
  SAVE_CLUSTER = 'SAVE_CLUSTER',
}

export type TMarker<P = KVObject> = {
  type: EMarkerType;
  properties?: P;
  clickable?: boolean;
  description?: string;
  children?: ReactNode;
  rank?: number;
} & Overwrite<
  Omit<MarkerOptions, 'lngLat'>,
  {
    lonLat: TLonLat;
  }
>;

export enum EMapType {
  VECTOR = 'VECTOR',
  RASTER = 'RASTER',
}

export type TStyleSet = {
  code: 'TMAP_RASTER_PUBLIC' | 'SAM' | 'TMAP';
  type: 'DEFAULT' | 'NIGHT';
};
