import {AREA_NAMES} from 'utils/testData';
import {useState, useEffect} from 'react';

import AreaWordsBox from 'components/AreaWordsBox';

import styles from 'styles/pages/ProtoAreaWordsPage.module.scss';
import {getRandom} from 'utils/general';

const getRandomAreas = (arr) => {
  const target = [...arr];
  const result: any[] = [];

  while (result.length < 20) {
    result.push(target.splice(getRandom(target.length), 1)[0]);
  }

  return result;
};

const ProtoAreaWordsPage = () => {
  const [nowResult, setResult] = useState<Array<string[]>>([]);

  useEffect(() => {
    const result: Array<string[]> = [];
    for (let i = 0; i < 100; i++) {
      result.push(getRandomAreas(AREA_NAMES));
    }
    setResult(result);
  }, []);

  return (
    <div className={styles.wrap}>
      {nowResult.map((n, i) => (
        <AreaWordsBox arr={n} key={i} />
      ))}
    </div>
  );
};

export default ProtoAreaWordsPage;
