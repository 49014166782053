import useThrottle from 'hooks/useThrottle';
import {HTMLAttributes, PropsWithChildren, useCallback} from 'react';
import {usePlaceCategoryLink, TPlaceCategoryLinkProps} from 'hooks/usePlaceCategoryLink';
import {moveToUrl} from 'utils/url';
import {getRedirectFromInParam} from 'utils/tmapUtils';

type TProps = PropsWithChildren<
  TPlaceCategoryLinkProps & HTMLAttributes<HTMLAnchorElement> & {landingUrl?: string}
>;

export const PlaceCategoryLink = ({
  children,
  type,
  queries,
  landingUrl,
  ...anchorProps
}: TProps) => {
  const ableToRun = useThrottle();
  const {redirectFrom} = getRedirectFromInParam();
  const url = usePlaceCategoryLink({
    type,
    queries: {
      redirect_from: redirectFrom,
      ...(queries || {}),
    },
  });
  const href = landingUrl ?? url;

  const handleClickLink = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (ableToRun()) {
        anchorProps?.onClick?.(e);
        moveToUrl(href);
      }
    },
    [ableToRun, anchorProps, href]
  );

  return (
    <a {...anchorProps} href={href} onClick={handleClickLink}>
      {children}
    </a>
  );
};
