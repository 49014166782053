import {MARKER_LABEL_MAX} from 'constant/Map';
import useMap from 'hooks/useMap';
import React, {CSSProperties, ImgHTMLAttributes, PropsWithChildren, useMemo} from 'react';
import MarkerPath from 'resource/pubImages/markers/@markerPath';
import s from 'styles/components/CustomMarker.module.scss';
import {EMarkerType} from 'types/Map';

export type TProps = PropsWithChildren<{
  active: boolean;
  name: string;
  subName?: React.ReactNode;
  labelStyle?: CSSProperties;
  maxLabelLength?: number;
  activeImageMarker?: ImgHTMLAttributes<HTMLImageElement>;
  iconImageMarker?: ImgHTMLAttributes<HTMLImageElement>;
  useDualMode?: boolean;
  activeType?: EMarkerType;
}>;

export const CustomMarker = ({
  active,
  name,
  subName,
  labelStyle,
  maxLabelLength = MARKER_LABEL_MAX,
  children,
  activeImageMarker,
  iconImageMarker,
  useDualMode,
  activeType,
}: TProps) => {
  const {style: mapStyle} = useMap();
  const customName = useMemo(
    () => ((name.length || 0) > maxLabelLength ? `${name.slice(0, maxLabelLength)}...` : name),
    [name, maxLabelLength]
  );
  const isImageMarker = useMemo(
    () => !!activeImageMarker?.src && !!iconImageMarker?.src,
    [activeImageMarker, iconImageMarker]
  );
  const colorVars = useMemo(() => {
    return {
      '--label-color': labelStyle?.color,
      '--border-color': labelStyle?.borderColor,
    };
  }, [labelStyle]);

  if (!(children || isImageMarker)) {
    return null;
  }

  if (active) {
    return (
      <span
        className={s.wrap}
        data-type={activeType || EMarkerType.ACTIVE}
        style={
          {
            ...colorVars,
            ...(labelStyle || {}),
            ...(isImageMarker
              ? {
                  width: activeImageMarker?.width,
                  height: activeImageMarker?.height,
                }
              : {}),
          } as CSSProperties
        }
      >
        {isImageMarker ? (
          <>
            <img {...activeImageMarker} />
            {!!children && <div>{children}</div>}
          </>
        ) : (
          <>
            <i>{children}</i>
            <img src={MarkerPath.custom.base.marker} className={s.base} />
          </>
        )}

        {useDualMode && (
          <span className={s.label} data-style={mapStyle}>
            {name || ''}
            {name && subName && <br />}
            {subName || ''}
          </span>
        )}

        {!useDualMode && customName && (
          <span className={s.label} data-style={mapStyle}>
            {customName.split('\n').map((t, i) => (
              <React.Fragment key={i}>
                {i !== 0 && <br />}
                {t}
              </React.Fragment>
            ))}
          </span>
        )}
      </span>
    );
  }

  return (
    <span
      className={s.wrap}
      data-type={EMarkerType.INACTIVE}
      style={
        isImageMarker
          ? {
              width: iconImageMarker?.width,
              height: iconImageMarker?.height,
            }
          : {}
      }
    >
      {isImageMarker ? (
        <>
          <img {...iconImageMarker} />
          {!!children && <div>{children}</div>}
        </>
      ) : (
        <>
          <i>{children}</i>
          <img src={MarkerPath.custom.base.icon} className={s.base} />
        </>
      )}
    </span>
  );
};
