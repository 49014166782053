import {ReactComponent as IconSearchCheckBoxOn} from 'resource/images/1-CheckBox_Small_ON.svg';
import {ReactComponent as IconSearchCheckBoxOff} from 'resource/images/1-CheckBox_Small_OFF.svg';

type TProps = {
  on?: boolean;
};

const CheckboxIcon = ({on}: TProps) => {
  return on ? <IconSearchCheckBoxOn /> : <IconSearchCheckBoxOff />;
};

export default CheckboxIcon;
