import {useCallback, useMemo} from 'react';
import TMapSender from '@lcc/tmap-inapp';
import useThrottle from 'hooks/useThrottle';
import {TPoiItem} from 'types/App';
import {IcoArrowRight} from 'components/@tmds/icons/IcoArrowRight';
import {ReactComponent as IconBike} from 'resource/images/Icon_Bike.svg';

import s from 'styles/components/BlossomBikeButton.module.scss';

type TProps = {
  poiData: TPoiItem;
  onClick?: () => void;
};

export const BlossomBikeButton = ({poiData, onClick}: TProps) => {
  const ableToRun = useThrottle();
  const isAvailable = useMemo(() => poiData.tag.isTmapBike, [poiData.tag.isTmapBike]);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!ableToRun()) {
        return;
      }

      onClick?.();
      TMapSender.openServiceByName('kickboard' as any, {
        extra: `position_${poiData.wgs84CenterX},${poiData.wgs84CenterY}`,
        pageid: 'bike',
      });
    },
    [ableToRun, onClick, poiData.wgs84CenterX, poiData.wgs84CenterY]
  );

  if (!isAvailable) {
    return null;
  }

  return (
    <div className={s.wrap} onClick={handleClick}>
      <IconBike />
      <span className={s.highlight}>티맵 바이크</span>
      <span>로 벚꽃 라이딩</span>
      <IcoArrowRight width={12} height={12} />
    </div>
  );
};
