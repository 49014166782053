import s from '../../../styles/components/tplacehome/TPlaceDiscovery.module.scss';

type TProps = {
  height?: number;
};

const Spacer = ({height}: TProps) => {
  return <div className={s.spacer} style={{height: height ? `${height}px` : undefined}} />;
};

export default Spacer;
