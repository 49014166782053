import {EMapStyle} from '@lcc/tmap-inapp';
import {useAppSelector} from 'ducks/hooks';
import {useMemo} from 'react';
import {EMarkerType} from 'types/Map';
import {getMarkerStyles} from 'utils/tmapUtils';

type TProps = {
  type?: EMarkerType;
  imageSrc?: string;
  nightImageSrc?: string;
  width?: number;
  height?: number;
  alt?: string;
};

export const ImageMarker = ({type, alt = '지도마커', ...customStyle}: TProps) => {
  const mapStyle = useAppSelector((state) => state.map.style);
  const imageProps = useMemo(() => {
    const typedStyle = getMarkerStyles(type) || {};
    const {
      width = undefined,
      height = undefined,
      nightImageSrc = undefined,
      imageSrc = undefined,
    } = {
      ...typedStyle,
      ...customStyle,
    };

    return {
      ...(width ? {width} : {}),
      ...(height ? {height} : {}),
      src: mapStyle !== EMapStyle.DAY && nightImageSrc ? nightImageSrc : imageSrc,
    };
  }, [type, customStyle, mapStyle]);

  return imageProps.src ? <img {...imageProps} alt={alt} /> : null;
};
