import {EMarkerType, TBounds, TLonLat} from 'types/Map';
import Vsm, {Point} from '@vsm/vsm';
import {TFavoriteItem, TReverseGeocoding} from 'types/App';
import {DEFAULT_MAX_BOUNDS, MAP_LEVEL_ADJUST_VALUE} from 'constant/Map';
import {EMapFontSize} from '@lcc/tmap-inapp';
import turfDistance from '@turf/distance';

export const getBoundsBox = (coords: TLonLat[]): TBounds => {
  const result = coords.reduce(
    (prev, {lon, lat}) => {
      return lon && lat
        ? {
            s: prev.s > lon ? lon : prev.s,
            w: prev.w < lat ? lat : prev.w,
            n: prev.n < lon ? lon : prev.n,
            e: prev.e > lat ? lat : prev.e,
          }
        : prev;
    },
    {s: 9999, w: 0, n: 0, e: 9999}
  );

  return [result.s, result.w, result.n, result.e];
};

export const getNewBoundsBox = (coords: TLonLat[]): TBounds => {
  const result = coords.reduce(
    (prev, {lon, lat}) => {
      return lon && lat
        ? {
            s: prev.s > lat ? lat : prev.s,
            w: prev.w > lon ? lon : prev.w,
            n: prev.n < lat ? lat : prev.n,
            e: prev.e < lon ? lon : prev.e,
          }
        : prev;
    },
    {
      s: DEFAULT_MAX_BOUNDS.north,
      w: DEFAULT_MAX_BOUNDS.east,
      n: DEFAULT_MAX_BOUNDS.south,
      e: DEFAULT_MAX_BOUNDS.west,
    }
  );

  return [result.w, result.s, result.e, result.n];
};

export const checkInsideBoundsBox = ({bounds, lonlat}: {bounds: TLonLat[]; lonlat: TLonLat}) => {
  const [w, s, e, n] = getNewBoundsBox(bounds);
  const {lat, lon} = lonlat;

  return lat <= n && lat >= s && lon >= w && lon <= e;
};

type TLngLat = {
  lng: number;
  lat: number;
};

export const convertToLonLat = (lngLat?: TLngLat): TLonLat | undefined => {
  if (lngLat) {
    return {lat: lngLat.lat, lon: lngLat.lng};
  }
  return undefined;
};

export const convertToVSMPosition = (lonLat?: TLonLat | undefined): TLngLat | undefined => {
  if (lonLat) {
    return {lat: lonLat.lat, lng: lonLat.lon};
  }
  return undefined;
};

export const getValidLonLat = (
  {lat, lon}: {lat: any; lon: any} = {lat: undefined, lon: undefined}
): TLonLat | undefined => {
  const numberLat = Number(lat);
  const numberLon = Number(lon);

  if (lat && lon && typeof numberLat === 'number' && typeof numberLon === 'number') {
    return {
      lat: numberLat,
      lon: numberLon,
    };
  }
  return undefined;
};

export const getValidBounds = (bounds: TBounds | any[] = []): TBounds | undefined => {
  if (bounds.every((b) => !isNaN(Number(b)))) {
    return bounds as TBounds;
  }

  return undefined;
};

export const getLonLatMoveByOffset = ({
  lonLat,
  offset,
  map,
}: {
  lonLat?: TLonLat;
  offset?: Point;
  map?: Vsm.Map;
}) => {
  if (!(lonLat && offset && map)) {
    return {};
  }
  const vsmLonLat = convertToVSMPosition(lonLat);
  const point = vsmLonLat && map?.getTransform()?.lngLatToScreenPoint(vsmLonLat);
  let offsetCenter: TLngLat | undefined = vsmLonLat;

  if (point && offset) {
    const newPoint = {
      x: point.x + offset.x,
      y: point.y + offset.y,
    };
    const lngLat = map?.getTransform()?.screenPointToLngLat(newPoint);

    offsetCenter = {
      lat: lngLat.lat,
      lng: lngLat.lng,
    };
  }

  return {
    originalLonLat: lonLat,
    offsetLonLat: offsetCenter && convertToLonLat(offsetCenter),
  };
};

export const getOffsetCenter = ({map, offset}) => {
  const lonLat = convertToLonLat(map?.getCamera().getCenter());
  const center = getLonLatMoveByOffset({map, offset, lonLat});

  return center;
};

export const addressInfoFromReverseGeoData = (data: TReverseGeocoding, isUseJibun?: boolean) => {
  const bigRegion = [data?.regionName1 || '', data?.regionName2 || ''].join(' ');

  const jibun = [bigRegion, data?.regionName3 || '', data?.regionName4 || '', data?.bunji || '']
    .filter((v) => !!v.trim())
    .join(' ');

  const road = [bigRegion, data?.roadName || '', data?.roadBunji || '']
    .filter((v) => !!v.trim())
    .join(' ');

  const priority = isUseJibun ? jibun : road;
  const sub = isUseJibun ? road : jibun;
  const absoluteExist = bigRegion !== priority ? priority : sub;

  const depth2Jibun = [
    data?.regionName2 || '',
    data?.regionName3 || '',
    data?.regionName4 || '',
    data?.bunji || '',
  ]
    .filter((v) => !!v.trim())
    .join(' ');

  const depth2Road = [data?.regionName2 || '', data?.roadName || '', data?.roadBunji || '']
    .filter((v) => !!v.trim())
    .join(' ');

  return {
    jibun,
    road,
    depth2Jibun,
    depth2Road,
    hasJibunAddress: bigRegion !== jibun,
    hasRoadAddress: bigRegion !== road,

    priority,
    sub,
    absoluteExist,
  };
};

export const checkIsActiveItem = (a, b) => {
  if (!a || !b) {
    return false;
  }

  return (
    a.personalPoiKey === b.personalPoiKey ||
    (!!a.poiId && !!b.poiId && a.poiId === b.poiId) ||
    (!!a.pkey && !!b.pkey && a.pkey === b.pkey) ||
    (a.centerX === b.centerX && a.centerY === b.centerY)
  );
};

// App <-> Web 간 조정되어야 할 레벨.
// App -> Web일때 +6
// Web -> app -6
// ios 6 ~ 18
// and 0 ~ 12
export const convertWebToAppZoomLevel = (webZoomLevel) => {
  const zoom = Number(webZoomLevel);

  return isNaN(zoom) ? undefined : zoom - MAP_LEVEL_ADJUST_VALUE;
};

export const convertAppToWebZoomLevel = (appZoomLevel) => {
  const zoom = Number(appZoomLevel);

  return isNaN(zoom) ? undefined : zoom + MAP_LEVEL_ADJUST_VALUE;
};

export const getMapScale = (fontSize: EMapFontSize) => {
  const {fontScale, iconScale} =
    {
      [EMapFontSize.LARGE]: Vsm.Extensions.TmapUtils.POI_SCALE.large,
      [EMapFontSize.SMALL]: Vsm.Extensions.TmapUtils.POI_SCALE.small,
      [EMapFontSize.NORMAL]: Vsm.Extensions.TmapUtils.POI_SCALE.normal,
    }[fontSize] || {};

  return {fontScale, iconScale};
};

export const compareFavorite = (a: TFavoriteItem, b: TFavoriteItem) => {
  if (a.stationId && a.poiId) {
    return `${a.poiId}` === `${b.poiId}`;
  }
  // 22.12 앱에서 정의한 pkey 있을때 pkey만. 없을때 입구점만 비교 적용
  else if (a.pkey && b.pkey) {
    return `${a.pkey}` === `${b.pkey}`;
  } else {
    return `${a.navX}` === `${b.navX}` && `${a.navY}` === `${b.navY}`;
  }
};

// https://github.com/Turfjs/turf/tree/master/packages/turf-distance
export const getDistanceBetweenCoords = (from: TLonLat, to: TLonLat) => {
  return turfDistance([from.lon, from.lat], [to.lon, to.lat]);
};

export const parsePersonalMarkerType = (type: EMarkerType) => {
  if ([EMarkerType.FAVORITE, EMarkerType.ACTIVE_FAVORITE].includes(type)) {
    return EMarkerType.ACTIVE_FAVORITE;
  }
  if (
    [EMarkerType.FAVORITE_PUBLIC_TRANS, EMarkerType.ACTIVE_FAVORITE_PUBLIC_TRANS].includes(type)
  ) {
    return EMarkerType.ACTIVE_FAVORITE_PUBLIC_TRANS;
  }
  if ([EMarkerType.FAVORITE_HOME, EMarkerType.ACTIVE_FAVORITE_HOME].includes(type)) {
    return EMarkerType.ACTIVE_FAVORITE_HOME;
  }
  if ([EMarkerType.FAVORITE_OFFICE, EMarkerType.ACTIVE_FAVORITE_OFFICE].includes(type)) {
    return EMarkerType.ACTIVE_FAVORITE_OFFICE;
  }
  if ([EMarkerType.RECENT_DESTINATION, EMarkerType.ACTIVE_RECENT_DESTINATION].includes(type)) {
    return EMarkerType.ACTIVE_RECENT_DESTINATION;
  }

  return EMarkerType.NONE;
};

/**
 * 포인트 기준 확장된 bounds 리턴
 */
export const getExpendedBounds = (map: Vsm.Map, expendValue: number) => {
  const camera = map.getCamera();
  const transform = map.getTransform();
  const {northEast, southWest} = camera.getBounds();
  const screenPoint = {
    northEast: transform.lngLatToScreenPoint(northEast),
    southWest: transform.lngLatToScreenPoint(southWest),
  };
  screenPoint.northEast.x += expendValue;
  screenPoint.northEast.y -= expendValue;
  screenPoint.southWest.x -= expendValue;
  screenPoint.southWest.y += expendValue;
  return {
    northEast: transform.screenPointToLngLat(screenPoint.northEast),
    southWest: transform.screenPointToLngLat(screenPoint.southWest),
  };
};

/**
 * 좌표변환 utils
 * https://github.com/tmobi-internal/tmap-web-lib-utils 에서 필요한 내용 추출
 * SK -> WGS84 좌표 변환.
 * 원본 소스 코드 https://github.com/tmobi-internal/tmap-navigation-engine/blob/develop/TmapEngineCommonData/src/main/java/com/skt/tmap/engine/navigation/coordination/TmapNaviPoint.java
 */
const BESSEL_A = 6377397.155;
const BESSEL_RF = 299.1528128;
const BESSEL_B = BESSEL_A - BESSEL_A / BESSEL_RF;

const WGS84_A = 6378137;
const WGS84_RF = 298.257223563;
const WGS84_B = WGS84_A - WGS84_A / WGS84_RF;

const B2W_DELTAX = -147;
const B2W_DELTAY = 506;
const B2W_DELTAZ = 687;

function convertDegToRad(deg: number) {
  return (deg * Math.PI) / 180;
}

// W2B
const W2B_DELTAX = 128;
const W2B_DELTAY = -481;
const W2B_DELTAZ = -664;
const W2B_DELTAA = -739.845;
const W2B_DELTAF = -0.000010037483;

// WGS84
// const WGS84_A = 6378137.0
const WGS84_F = 1.0 / 298.257223563;
// const WGS84_RF = 298.257223563
// const WGS84_B = WGS84_A - WGS84_A / WGS84_RF
const WGS84_EE = 2.0 * WGS84_F - WGS84_F * WGS84_F;

function besselToWgs84({x, y}: {x: number; y: number}) {
  const xyz = Geod2ECEF(y, x, 0, BESSEL_A, BESSEL_B);
  return ECEF2Geod(xyz.x + B2W_DELTAX, xyz.y + B2W_DELTAY, xyz.z + B2W_DELTAZ, WGS84_A, WGS84_B);
}

function Geod2ECEF(lat: number, lon: number, hei: number, a: number, b: number) {
  const lat_r = (lat * Math.PI) / 180;
  const lon_r = (lon * Math.PI) / 180;

  const f = (a - b) / a;
  const sqre = 2 * f - f * f;
  const N = a / Math.sqrt(1 - sqre * Math.sin(lat_r) * Math.sin(lat_r));

  return {
    x: (N + hei) * Math.cos(lat_r) * Math.cos(lon_r),
    y: (N + hei) * Math.cos(lat_r) * Math.sin(lon_r),
    z: (N * (1 - sqre) + hei) * Math.sin(lat_r),
  };
}

function ECEF2Geod(x: number, y: number, z: number, a: number, b: number) {
  const p = Math.sqrt(x * x + y * y);
  const theta = Math.atan((z * a) / (p * b));
  const sqrep = (a * a - b * b) / (b * b);

  const f = (a - b) / a;
  const sqre = 2 * f - f * f;

  const lat_r = Math.atan(
    (z + sqrep * b * Math.sin(theta) * Math.sin(theta) * Math.sin(theta)) /
      (p - sqre * a * Math.cos(theta) * Math.cos(theta) * Math.cos(theta))
  );
  const lon_r = Math.atan2(y, x);

  return {
    latitude: (lat_r * 180) / Math.PI,
    longitude: (lon_r * 180) / Math.PI,
  };
}

export const convertWgs84ToSk = (wgs84: {longitude: number; latitude: number}) => {
  const longitude = Number(wgs84.longitude);
  const latitude = Number(wgs84.latitude);

  // WGS To Bessel
  const {x, y} = convertWgs84ToBessel({longitude, latitude});

  // Bessel To SK
  return {
    x: Math.floor(x * 36000),
    y: Math.floor(y * 36000),
  };
};

export const convertSkToWgs84 = ({x, y}: {x: number; y: number}) => {
  // SK To Bessel
  let besselX, besselY;
  if (x / 10000000 < 1 && y / 10000000 < 1) {
    // SK 7자리
    besselX = x / 36000;
    besselY = y / 36000;
  } else {
    // SK 8자리
    besselX = x / 360000;
    besselY = y / 360000;
  }

  // Bessel To WGS84
  return besselToWgs84({x: besselX, y: besselY});
};

/**
 * WGS84 -> bessel 좌표 변환. (7자리)
 * 원본 소스 코드 https://github.com/tmobi-internal/tmap-navigation-engine/blob/develop/TmapEngineCommonData/src/main/java/com/skt/tmap/engine/navigation/coordination/TmapNaviPoint.java
 */
export const convertWgs84ToBessel = ({
  longitude,
  latitude,
}: {
  longitude: number;
  latitude: number;
}) => {
  const wx = convertDegToRad(longitude);
  const wy = convertDegToRad(latitude);

  const rn = WGS84_A / Math.sqrt(1 - WGS84_EE * Math.pow(Math.sin(wy), 2));
  const rm =
    (WGS84_A * (1 - WGS84_EE)) / Math.pow(Math.sqrt(1 - WGS84_EE * Math.pow(Math.sin(wy), 2)), 3);
  const d_pi =
    (-W2B_DELTAX * Math.sin(wy) * Math.cos(wx) -
      W2B_DELTAY * Math.sin(wy) * Math.sin(wx) +
      W2B_DELTAZ * Math.cos(wy) +
      (W2B_DELTAA * (rn * WGS84_EE * Math.sin(wy) * Math.cos(wy))) / WGS84_A +
      W2B_DELTAF *
        ((rm * WGS84_A) / WGS84_B + (rn * WGS84_B) / WGS84_A) *
        Math.sin(wy) *
        Math.cos(wy)) /
    (rm * Math.sin(Math.PI / 180 / 3600));
  const d_lamda =
    (-W2B_DELTAX * Math.sin(wx) + W2B_DELTAY * Math.cos(wx)) /
    (rn * Math.cos(wy) * Math.sin(Math.PI / 180 / 3600));

  return {
    x: longitude + d_lamda / 3600,
    y: latitude + d_pi / 3600,
  };
};
