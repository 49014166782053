import {EAddressMode, TAddressItem} from 'types/Search';

export type TAddressMap = Partial<Record<EAddressMode, TAddressItem>>;

export const ADDRESS_MODE_STEP = [
  EAddressMode.CATE1,
  EAddressMode.CATE2,
  EAddressMode.CATE3,
  EAddressMode.DETAIL,
];

export const ADDRESS_LABEL = {
  [EAddressMode.CATE1]: '광역시/도',
  [EAddressMode.CATE2]: '시/군/구',
  [EAddressMode.CATE3]: '도로/읍/면/동',
};

export const ADDRESS_TYPE = [
  {label: '도로명', isRoad: true},
  {label: '지번', isRoad: false},
];
