import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TLogItem, TLogState} from './types';

const ACTION_NAME = 'log';

const initialState: TLogState = {
  pair: {},
  items: {},
  totalCount: 0,
  searchQueryRefreshKey: undefined,
  bypassCustomItems: {},
  isInitialize: false,
};

const logSlice = createSlice({
  name: ACTION_NAME,
  initialState,
  reducers: {
    addStartItem: (state, action: PayloadAction<TLogItem>) => {
      const key = action.payload.key;

      state.totalCount++;
      state.pair[key] = {start: action.payload};
    },
    addEndItem: (state, action: PayloadAction<TLogItem>) => {
      const key = action.payload.key;
      const start = state.pair[key]?.start;

      state.totalCount++;
      state.pair[key] = {
        start,
        end: action.payload,
        duration: start?.time ? action.payload.time - start?.time : 0,
      };
    },
    updateRequest: (state, action: PayloadAction<string>) => {
      const key = action.payload;

      state.pair[key] = {
        ...state.pair[key],
        requested: true,
      };
    },
    addNormalItem: (state, action: PayloadAction<TLogItem>) => {
      state.items[action.payload.key] = action.payload;
    },
    setSearchQueryRefreshKey: (state, action: PayloadAction<number>) => {
      state.searchQueryRefreshKey = action.payload;
    },
    addBypassCustomItems: (state, action: PayloadAction<TLogState['bypassCustomItems']>) => {
      state.bypassCustomItems = action.payload;
    },
    setInitialize: (state, action: PayloadAction<boolean>) => {
      state.isInitialize = action.payload;
    },
  },
});

export default logSlice;
