import useSaveMarker from './useSaveMarker';
import useUserInfoMarker from './useUserInfoMarker';
import {useEffect, useMemo, useState} from 'react';
import compact from 'lodash/compact';
import useLayerManager from './useLayerManager';
import {isOverNewFavoriteVersion} from 'utils/tmapUtils';
import {useAppSelector} from 'ducks/hooks';
import {TMarker} from 'types/Map';
import {useOnce} from 'hooks/useOnce';
import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import Vsm from '@vsm/vsm';

type TProps = {
  ignorePoiIdList?: (string | number | undefined)[];
};

const PersonalMarkerLayer = (props: TProps) => {
  const storeState = useAppSelector((state) => state);
  const {map} = useVSMInterfaceConsumer();
  const userInfoMarker = useUserInfoMarker();
  const saveMarker = useSaveMarker();
  const [layerInited, setLayerInited] = useState(false);

  const layer0 = useLayerManager();
  const layer1 = useLayerManager();
  const layer2 = useLayerManager();

  const isNewVersion = useMemo(() => {
    return isOverNewFavoriteVersion();
  }, []);
  const isViewNew = useMemo(
    () => isNewVersion && storeState.map.saveMarkerDisplay,
    [isNewVersion, storeState.map.saveMarkerDisplay]
  );
  const isViewOld = useMemo(
    () => !isNewVersion && storeState.userInfo.showFavorite,
    [isNewVersion, storeState.userInfo.showFavorite]
  );

  const filterIgnoredPoiId = (result: TMarker[]) => {
    if (props.ignorePoiIdList?.length) {
      const dataType = typeof props.ignorePoiIdList[0];
      result = result.filter((item: any) => {
        const poiId =
          dataType === 'number' ? Number(item.properties.poiId) : String(item.properties.poiId);
        const ignored = props.ignorePoiIdList?.includes(poiId);
        return !ignored;
      });
    }
    return result;
  };

  // 홈/회사
  useEffect(() => {
    if (!layerInited) {
      return;
    }
    let result: TMarker[] =
      isViewNew || isViewOld
        ? compact([userInfoMarker.favoriteHomeMarker, userInfoMarker.favoriteOfficeMarker])
        : [];
    result = filterIgnoredPoiId(result);
    layer2.updateMarkers(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layerInited,
    isViewNew,
    isViewOld,
    userInfoMarker.favoriteHomeMarker,
    userInfoMarker.favoriteOfficeMarker,
  ]);

  // 저장,즐찾,대중교통
  useEffect(() => {
    if (!layerInited) {
      return;
    }
    let result: TMarker[] = [];
    if (isViewNew) {
      result = [
        ...saveMarker.savePoiMarkers,
        ...saveMarker.saveClusterMarkers,
        ...userInfoMarker.favoritePublicMarker,
      ];
    } else if (isViewOld) {
      result = [...userInfoMarker.favoriteMarker, ...userInfoMarker.favoritePublicMarker];
    }
    result = filterIgnoredPoiId(result);
    layer1.updateMarkers(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layerInited,
    isViewNew,
    isViewOld,
    userInfoMarker.favoriteMarker,
    userInfoMarker.favoritePublicMarker,
    saveMarker.savePoiMarkers,
    saveMarker.saveClusterMarkers,
  ]);

  // 최근목적지
  useEffect(() => {
    if (!layerInited) {
      return;
    }
    let result: TMarker[] = storeState.userInfo.showRecentlyDestination
      ? userInfoMarker.recentDestinationMarker
      : [];
    layer0.updateMarkers(result);
    result = filterIgnoredPoiId(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layerInited,
    storeState.userInfo.showRecentlyDestination,
    userInfoMarker.recentDestinationMarker,
  ]);

  useOnce(!!map, () => {
    map?.once(Vsm.Map.EventNames.StyleLoad, async () => {
      await layer0.addImageResource();
      await layer0.addStyle();
      await layer1.addStyle();
      await layer2.addStyle();
      setLayerInited(true);
    });
  });

  return null;
};

export default PersonalMarkerLayer;
