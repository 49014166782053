import classNames from 'classnames';
import TmdsTag from 'components/@tmds/TmdsTag';
import {IcoLineBetween} from 'components/@tmds/icons/IcoLineBetween';
import {BUS_COLOR_MAP} from 'constant/Search';
import {TBusLineItem} from 'ducks/search/types';

import {ReactComponent as IcoLeftRightArrow} from 'resource/images/ico_left_right_arrow.svg';

import s from 'styles/components/PoiListItem.module.scss';

type TProps = {
  item: TBusLineItem;
  onClickItem?: (e) => void;
};

const SearchResultBusLineItem = ({item, onClickItem}: TProps) => {
  return (
    <div className={s.wrap} onClick={onClickItem}>
      <div className={s.title_wrap}>
        <span className={s.title}>
          <TmdsTag
            className={classNames(s.tag, s.tmds_tag)}
            type="bold"
            label={item.busClass?.name || '기타'}
            color="wbWhite"
            backgroundColor={
              (item.busClass?.code && BUS_COLOR_MAP[item.busClass.code]) || 'gray500'
            }
            filled={true}
          />
          {item.busLineName}
        </span>
      </div>

      <div className={s.info_wrap}>
        <p className={s.extra_info}>
          <span className={s.extra_item} data-tmds-color="gray700">
            {item.cityName}
          </span>
          <IcoLineBetween width={16} height={16} color="gray200" />
          <span className={classNames(s.extra_item, s.ellipsis_parent)} data-tmds-color="gray600">
            <span>{item.busStartStation}</span>
            <IcoLeftRightArrow className={s.icon_arrow} />
            <span className={s.ellipsis}>{item.busEndStation}</span>
          </span>
        </p>

        {item.busFirstTime && item.busLastTime && (
          <p className={s.extra_info}>
            <span data-tmds-color="gray500">
              {item.busFirstTime}~{item.busLastTime}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default SearchResultBusLineItem;
