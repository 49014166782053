import TMapSender from '@lcc/tmap-inapp';
import {EAdCode} from 'constant/Ads';
import {
  TLA_SEARCH_MAIN_ADDRESS,
  TLA_SEARCH_MAIN_FAVORITE_POI,
  TLA_SEARCH_MAIN_FAVORITE_ROUTE,
  TLA_SEARCH_MAIN_MAP,
  TLA_SEARCH_MAIN_RECENT,
  TLA_SEARCH_TYPING,
} from 'constant/Log';
import {TActionId} from 'types/Log';

import ua from 'utils/uaParser';

import {devLog} from 'utils/dev';

export const sendAppsFlyerByApp = (name: string, data?: {[key: string]: string}) => {
  devLog(`[AF origin] ${name}`, data);

  if (ua.isInApp) {
    TMapSender.recordEvent(name, data || {});
  }
};

export const afSendPlacePageView = () => {
  sendAppsFlyerByApp('place_view');
};

export const afSendCategoryView = (category?) => {
  sendAppsFlyerByApp('place_category_view', {
    af_category_name: category || '',
  });
};

export const afSendContentsClick = (clickInfo?) => {
  sendAppsFlyerByApp('place_contents_click', {
    af_poi_click: clickInfo?.pkey || '',
  });
};

export const afSendBannerView = (bannerObj: any) => {
  sendAppsFlyerByApp('place_banner_view', {
    af_banner_id: bannerObj.event_id,
  });
};

export const afSendBannerClick = (bannerObj: any) => {
  sendAppsFlyerByApp('place_banner_click', {
    af_banner_id: bannerObj.event_id,
  });
};

export const afSendSearchResultPageLog = ({list_num, search_query}) => {
  const eventValue = list_num ? 'af_search_result' : 'af_search_noresult';

  sendAppsFlyerByApp('web_search_result_page', {
    [eventValue]: search_query || '',
  });
};

export const afSendSearchMainPageView = () => {
  sendAppsFlyerByApp('web_search_initiate');
};

export const afSendSearchLog = (
  actionId: TActionId,
  pageId: string,
  custom?: Record<string, any>
) => {
  const pageIdEventName = {
    [TLA_SEARCH_TYPING]: 'web_search_typing_input',
    [TLA_SEARCH_MAIN_FAVORITE_POI]: 'web_search_bookmark_input',
    [TLA_SEARCH_MAIN_FAVORITE_ROUTE]: 'web_search_bookmark_input',
    [TLA_SEARCH_MAIN_RECENT]: 'web_search_history_input',
    [TLA_SEARCH_MAIN_ADDRESS]: 'web_search_address_input',
    [TLA_SEARCH_MAIN_MAP]: 'web_search_map_input',
  }[pageId];

  switch (actionId) {
    case 'view.ad': {
      const eventName = {
        [EAdCode.SEARCH_MAIN]: 'web_search_view_ad',
        [EAdCode.SEARCH_RESULT]: 'web_search_result_view_ad',
      }[custom?.index];

      if (eventName && custom?.ad_source) {
        sendAppsFlyerByApp(eventName, {af_ad_source: custom.ad_source});
      }
      break;
    }

    case 'tap.ad': {
      const eventName = {
        [EAdCode.SEARCH_MAIN]: 'web_search_click_ad',
        [EAdCode.SEARCH_RESULT]: 'web_search_result_click_ad',
      }[custom?.index];

      if (eventName && custom?.ad_source) {
        sendAppsFlyerByApp(eventName, {af_ad_source: custom.ad_source});
      }

      break;
    }

    case 'list_tap.poi':
    case 'list_tap.poigrp':
    case 'list_tap.poi_detail':
    case 'list_tap.poigrp_detail': {
      if (custom?.pkey) {
        const eventName = {
          'list_tap.poi': 'web_search_click_poi_route',
          'list_tap.poigrp': 'web_search_click_poi_route',
          'list_tap.poi_detail': 'web_search_click_poi_detail',
          'list_tap.poigrp_detail': 'web_search_click_poi_detail',
        }[actionId];

        sendAppsFlyerByApp(eventName, {
          af_poi_pkey: custom.pkey,
        });
      }
      return;
    }

    case 'tap.bookmark':
    case 'tap.lastD':
    case 'tap.auto_poi': {
      const bookmarkEventName = {
        [TLA_SEARCH_TYPING]: 'af_click_auto_bookmark',
        [TLA_SEARCH_MAIN_FAVORITE_POI]: 'af_click_bookmark',
      }[pageId];
      const destEventName = {
        [TLA_SEARCH_TYPING]: 'af_click_auto_lastD',
        [TLA_SEARCH_MAIN_RECENT]: 'af_click_lastD',
      }[pageId];

      const eventValue = {
        'tap.bookmark': bookmarkEventName,
        'tap.lastD': destEventName,
        'tap.auto_poi': 'af_click_auto_poi',
      }[actionId];

      if (pageIdEventName && eventValue && custom?.pkey) {
        sendAppsFlyerByApp(pageIdEventName, {
          [eventValue]: custom.pkey,
        });
      }
      return;
    }

    case 'tap.home':
    case 'tap.office':
    case 'tap.bookmark_route': {
      const eventValue = {
        'tap.home': 'af_click_home',
        'tap.office': 'af_click_office',
        'tap.bookmark_route': 'af_click_bookmark_route',
      }[actionId];

      sendAppsFlyerByApp('web_search_bookmark_input', {
        [eventValue]: '',
      });
      return;
    }

    case 'top_tap.searchbtn':
    case 'ime_tap.searchbtn':
    case 'tap.lastA':
    case 'tap.lastS':
    case 'tap.auto':
    case 'tap.searchbtn': {
      if (pageIdEventName) {
        const searchButtonEventName = {
          [TLA_SEARCH_TYPING]: 'af_click_typing_searchbtn',
          [TLA_SEARCH_MAIN_ADDRESS]: 'af_click_address_searchbtn',
          [TLA_SEARCH_MAIN_MAP]: 'af_click_map_searchbtn',
        }[pageId];

        const eventName = {
          'ime_tap.searchbtn': searchButtonEventName,
          'top_tap.searchbtn': searchButtonEventName,
          'tap.lastA': 'af_click_auto_lastA',
          'tap.lastS': 'af_click_lastS',
          'tap.auto': 'af_click_auto',
          'tap.searchbtn': 'af_click_map_searchbtn',
        }[actionId];

        if (eventName) {
          sendAppsFlyerByApp(pageIdEventName, {
            [eventName]: custom?.search_query || '',
          });
        }
      }
      return;
    }
  }
};
