import classNames from 'classnames';
import s from 'styles/components/ranking/RankingMarker.module.scss';

type TProps = {
  isDay: boolean;
  active: boolean;
  rank: number;
};

const RankingMarker = ({isDay, active, rank}: TProps) => {
  return active ? (
    <div
      className={classNames(s.active, {
        [s.is_day]: isDay,
      })}
    >
      {rank}
    </div>
  ) : (
    <div className={s.label_wrap}>
      <div className={s.normal}>{rank}</div>
    </div>
  );
};

export default RankingMarker;
