import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import qs, {ParsedQuery} from 'query-string';

import {EMPTY_OBJECT} from 'constant/Vars';

import {generateUrl} from 'utils/url';
import {devLog} from 'utils/dev';
import {isEmpty} from 'utils/lodash';

const parseTailParam = (tailParam) => {
  try {
    if (tailParam) {
      return JSON.parse(decodeURIComponent(tailParam));
    }

    return EMPTY_OBJECT;
  } catch (e) {
    devLog(e);

    return EMPTY_OBJECT;
  }
};

export const useParseQueryLocation = (additionalQuery = EMPTY_OBJECT as object) => {
  const {pathname, search} = useLocation();

  const {queries, originQueries, tailParam, location} = useMemo(() => {
    const searchParams = qs.parse(search);
    const nowTailParam = parseTailParam(searchParams.tailParam);
    const newParams = {
      ...searchParams,
      ...nowTailParam,
      ...additionalQuery,
    };

    return {
      queries: isEmpty(newParams) ? EMPTY_OBJECT : newParams,
      originQueries: isEmpty(searchParams) ? (EMPTY_OBJECT as ParsedQuery) : searchParams,
      tailParam: isEmpty(nowTailParam) ? EMPTY_OBJECT : nowTailParam,
      location: generateUrl(pathname, newParams),
    };
  }, [pathname, search, additionalQuery]);

  return {
    search,
    pathname,
    queries,
    location,
    tailParam,
    originQueries,
  };
};
