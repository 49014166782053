import classNames from 'classnames';
import {ReactNode} from 'react';
import s from 'styles/components/NoResult.module.scss';

type TProps = {
  top?: number;
  title: ReactNode;
  description: ReactNode;
  buttonProps?: {
    text: ReactNode;
    onClick?: VoidFunction;
    type?: string;
  };
};

const NoResult = ({top = 50, title, description, buttonProps}: TProps) => {
  return (
    <div className={s.error_wrap}>
      <h2 className={s.title} style={{marginTop: top}}>
        {title}
      </h2>
      <span className={s.desc}>{description}</span>

      {buttonProps?.text && (
        <button
          className={classNames(s.search_button, {
            [s.default]: buttonProps.type === 'default',
          })}
          onClick={buttonProps?.onClick}
        >
          {buttonProps.text}
        </button>
      )}
    </div>
  );
};

export default NoResult;
