import {useCallback, useRef} from 'react';

type TProps = {
  count?: number;
};
export const ForceRefreshButton = ({count = 10}: TProps) => {
  const refCurrentCount = useRef(1);

  const handleClickRefresh = useCallback(() => {
    if (refCurrentCount.current >= count) {
      window.location.reload();
    }
    refCurrentCount.current = refCurrentCount.current + 1;
  }, [count]);

  return (
    <button
      style={{width: '100%', height: '100%', opacity: 0}}
      onClick={handleClickRefresh}
      aria-hidden="true"
    >
      새로고침
    </button>
  );
};
