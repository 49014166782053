import classNames from 'classnames';
import {useMemo} from 'react';
import s from 'styles/components/ItemTag.module.scss';

export type TItemTag = {
  style?: {
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
  };
  icon?: string;
  label: string;
};

export const ItemTag = ({style, label, icon}: TItemTag) => {
  const tagStyle = useMemo(() => {
    const {borderColor, ...others} = style || {};

    return {
      ...(others || {}),
      border: borderColor ? `1px solid ${borderColor}` : undefined,
    };
  }, [style]);

  return label ? (
    <span style={{...tagStyle}} className={classNames(s.tag, {[s.icon]: !!icon})}>
      {icon && <img src={icon} alt={label} />}
      <span>{label}</span>
    </span>
  ) : null;
};
