import {useCallback} from 'react';
import TMapSender, {ECallbackKeys, TMapReceiver, TPoiDataJson} from '@lcc/tmap-inapp';
import {EAppRequestMode} from 'types/App';
import ua from 'utils/uaParser';
import {isOverNewFavoriteVersion, isPublicTransVersion} from 'utils/tmapUtils';
import {APP_VERSION_GUIDE_TEXT} from 'constant/Text';

type TOpenBusDetailProps = {
  name: string;
  poiId: string;
  centerX: string | number;
  centerY: string | number;
  stationId: string;
  reqMode?: EAppRequestMode;
};

const useAppScheme = () => {
  const openBusStationDetail = useCallback(
    ({name, poiId, centerX, centerY, stationId, reqMode}: TOpenBusDetailProps) => {
      if (ua.isInApp && !isPublicTransVersion()) {
        TMapSender.makeToast(APP_VERSION_GUIDE_TEXT);
        return;
      }

      TMapSender.openBusStationDetail({
        name,
        poiId,
        skX: Number(centerX),
        skY: Number(centerY),
        stationId: `${stationId}` || '',
        // @ts-ignore
        reqMode: reqMode || EAppRequestMode.MAIN,
      });
    },
    []
  );

  const toggleFavorite = useCallback((poiDataJson: TPoiDataJson) => {
    return new Promise((resolve, reject) => {
      if (poiDataJson) {
        const isPublicTransPoi = poiDataJson.stationId;

        if (!isPublicTransVersion() && isPublicTransPoi) {
          TMapSender.makeToast(APP_VERSION_GUIDE_TEXT);
          reject();
          return;
        }

        if (isOverNewFavoriteVersion() && !isPublicTransPoi) {
          TMapReceiver.setCallback(ECallbackKeys.OPEN_FAVORITE_GROUP_POPUP, (isOn) => {
            resolve(isOn);
          });
          TMapSender.openFavoriteGroupPopup([poiDataJson]);
        } else {
          TMapReceiver.setCallback(ECallbackKeys.FAVORITE, (isOn) => {
            resolve(isOn);
          });

          TMapSender.toggleFavorite({
            ...poiDataJson,
            ...(poiDataJson && {
              stationId: `${poiDataJson.stationId}`,
              publicTransportType: poiDataJson.publicTransportType,
            }),
          });
        }
      } else {
        reject();
      }
    });
  }, []);

  const getFavoriteGroup = useCallback((poiIdList) => {
    return new Promise((resolve, reject) => {
      TMapReceiver.setCallback(ECallbackKeys.GET_FAVORITE_GROUP, (result) => {
        resolve(result);
      });

      TMapSender.getFavoriteGroup({poiIdList});
    });
  }, []);

  const getFavoriteState = useCallback((data) => {
    return new Promise((resolve, reject) => {
      if (!data) {
        reject();
        return;
      }

      TMapReceiver.setCallback(ECallbackKeys.GET_FAVORITE_STATE, (result) => {
        resolve(result);
      });

      TMapSender.getFavoriteState(data);
    });
  }, []);

  const openBusRouteDetail = useCallback((busLineId) => {
    if (ua.isInApp && !isPublicTransVersion()) {
      TMapSender.makeToast(APP_VERSION_GUIDE_TEXT);
      return;
    }

    TMapSender.openBusRouteDetail(busLineId);
  }, []);

  return {
    openBusStationDetail,
    openBusRouteDetail,
    toggleFavorite,
    getFavoriteGroup,
    getFavoriteState,
  };
};

export default useAppScheme;
