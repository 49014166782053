import {TSubwayArrivalInfo, TSubwaySchedule} from 'types/PublicTrans';
import CalloutInfoSubwayList from 'components/CalloutInfoSubwayList';

import s from 'styles/components/CalloutInfoSubway.module.scss';

type TProps = {
  loading: boolean;
  loaded: boolean;
  error?: any;
  data?: Nullable<TSubwayArrivalInfo>;
};

const checkIsLast = (data?: TSubwaySchedule) => {
  if (!data) {
    return false;
  }

  try {
    const headSign = JSON.parse(data.headSign);
    return data.last && headSign === null;
  } catch {}

  return false;
};

const CalloutInfoSubway = ({data, ...apiStatus}: TProps) => {
  const upList = data?.upRealtimeArrivalList || data?.upScheduleList || [];
  const downList = data?.downRealtimeArrivalList || data?.downScheduleList || [];
  const isLastUpStation = checkIsLast(data?.lastUpSchedule);
  const isLastDownStation = checkIsLast(data?.lastDownSchedule);
  const isRealTime = !!data?.isRealTime;

  return (
    <div className={s.wrap}>
      <ul className={s.list}>
        <CalloutInfoSubwayList
          {...apiStatus}
          list={upList}
          isRealTime={!!isRealTime}
          isLast={isLastUpStation}
        />
      </ul>

      <div className={s.bar} />

      <ul className={s.list}>
        <CalloutInfoSubwayList
          {...apiStatus}
          list={downList}
          isRealTime={!!isRealTime}
          isLast={isLastDownStation}
        />
      </ul>
    </div>
  );
};

export default CalloutInfoSubway;
