import {IdentifiedImage} from '@vsm/vsm';
import MarkerPath from 'resource/pubImages/markers/@markerPath';

export const groupMarkerImageMap = {
  default: 'group01',
  '#FFBD05': 'group01',
  '#F46548': 'group02',
  '#FF61C2': 'group03',
  '#2DCDC7': 'group04',
  '#43C7F9': 'group05',
  '#3ECB71': 'group06',
  '#009494': 'group07',
  '#008EC2': 'group08',
  '#D17600': 'group09',
  '#D30D85': 'group10',
};

export const getGroupCodeByColor = (color: string): string => {
  return groupMarkerImageMap[color.toUpperCase()] || groupMarkerImageMap.default;
};

export const getImageResources = () => {
  const rootData = MarkerPath.mapmarker.save;
  const data = {
    cluster_icon_day: rootData.favorite.icon.day,
    cluster_icon_night: rootData.favorite.icon.night,
    favorite_icon_day: rootData.favorite.icon.day,
    favorite_icon_night: rootData.favorite.icon.night,
    home_icon_day: rootData.home.icon.day,
    home_icon_night: rootData.home.icon.night,
    office_icon_day: rootData.office.icon.day,
    office_icon_night: rootData.office.icon.night,
    trans_icon_day: rootData.trans.icon.day,
    trans_icon_night: rootData.trans.icon.night,
    recent_icon_day: rootData.recent.icon.day,
    recent_icon_night: rootData.recent.icon.night,
    group01_icon_day: rootData.group01.icon.day,
    group01_icon_night: rootData.group01.icon.night,
    group02_icon_day: rootData.group02.icon.day,
    group02_icon_night: rootData.group02.icon.night,
    group03_icon_day: rootData.group03.icon.day,
    group03_icon_night: rootData.group03.icon.night,
    group04_icon_day: rootData.group04.icon.day,
    group04_icon_night: rootData.group04.icon.night,
    group05_icon_day: rootData.group05.icon.day,
    group05_icon_night: rootData.group05.icon.night,
    group06_icon_day: rootData.group06.icon.day,
    group06_icon_night: rootData.group06.icon.night,
    group07_icon_day: rootData.group07.icon.day,
    group07_icon_night: rootData.group07.icon.night,
    group08_icon_day: rootData.group08.icon.day,
    group08_icon_night: rootData.group08.icon.night,
    group09_icon_day: rootData.group09.icon.day,
    group09_icon_night: rootData.group09.icon.night,
    group10_icon_day: rootData.group10.icon.day,
    group10_icon_night: rootData.group10.icon.night,
  };
  const result = [] as IdentifiedImage[];
  Object.keys(data).forEach((id) => {
    const element = document.createElement('img');
    element.id = id;
    element.src = data[id];
    const size = 80;
    element.width = size; // 28 * 3 (ratio) 84
    element.height = size; // 28 * 3 (ratio) 85
    element.setAttribute('crossOrigin', '');
    result.push({
      id,
      image: element,
    });
  });
  return result;
};
