import parseUserAgent, {TTMapBrowserInfo} from '@lcc/tmap-user-agent';
import {isProdEnv} from 'constant/Env';

export const tmapUA: TTMapBrowserInfo = parseUserAgent(navigator.userAgent);
const isTouchDevice = 'ontouchstart' in document.createElement('div');

if (!isProdEnv) {
  // eslint-disable-next-line no-console
  console.log('[UA]', tmapUA);
}

type TServiceUAFlag = {
  isInApp: boolean;
  isTouchDevice: boolean;
  isIos: boolean;
  isAndroid: boolean;
  tmapAppVersion: string;
  osVersion: string;
  isSkIOS: boolean;
  isKuIOS: boolean;
  browser: {
    name: string;
    version: string;
  };
  os: {
    name: string;
    version: string;
  };
  device: {
    model: string;
    vendor: string;
  };
};

const serviceFlag: TServiceUAFlag = {
  isInApp: tmapUA.isInApp,
  isTouchDevice: isTouchDevice,
  isIos: tmapUA.isIos,
  isAndroid: tmapUA.isAndroid,
  tmapAppVersion: tmapUA.tmapInfo?.semver,
  osVersion: tmapUA.osVersion,
  isSkIOS: tmapUA.tmapInfo?.isKuIos,
  isKuIOS: tmapUA.tmapInfo?.isKuIos,
  browser: {
    name: tmapUA.browser?.name || '',
    version: tmapUA.browser?.version || '',
  },
  os: {
    name: tmapUA.os?.name || '',
    version: tmapUA.os?.version || '',
  },
  device: {
    model: tmapUA.device?.model || '',
    vendor: tmapUA.device?.vendor || '',
  },
};

export default serviceFlag;
