import {IcStar} from 'components/@tmds/icons/v1.2/IcStar';
import {IcStarFill} from './@tmds/icons/v1.2/IcStarFill';

import s from 'styles/components/SaveToggleButton.module.scss';
import {useMemo} from 'react';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import TMapSender, {TMapReceiver, ECallbackKeys} from '@lcc/tmap-inapp';
import actions from 'ducks/actions';
import useLogger from 'hooks/useLogger';

type TProps = {
  active?: boolean;
  onClick?: () => any;
};

const SaveToggleButton = (props: TProps) => {
  const storeState = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const active = useMemo(
    () => !!storeState.map.saveMarkerDisplay,
    [storeState.map.saveMarkerDisplay]
  );
  const {sendClickLog} = useLogger();

  return (
    <div className={s.wrap}>
      <button
        className={s.save_toggle_button}
        onClick={() => {
          let value = !active;
          TMapReceiver.setCallback(
            ECallbackKeys.NOTIFY_USER_SETTING_CHANGED,
            (result) => result && dispatch(actions.map.setSaveMarkerDisplay(value))
          );
          TMapSender.notifyUserSettingDataChanged({
            keyFeature: 'feature.mapShowSaveMarker',
            value: value ? 'Y' : 'N',
          });

          sendClickLog('tap.save_onoff', {
            index: value ? 1 : 0,
          });
        }}
      >
        {active ? (
          <IcStarFill width={28} height={28} color="#0064FF" />
        ) : (
          <IcStar width={28} height={28} />
        )}
      </button>
    </div>
  );
};

export default SaveToggleButton;
