import {useCallback, useMemo} from 'react';
import {useParsePoiData} from 'hooks/useParsePoiData';
import {EDetailButtonType, TSpecialData} from 'types/App';
import {getDecoRealTimeParkingLot, getDecoParkingLotFeeInfo} from 'utils/special';
import useMoveToTarget from 'hooks/useMoveToTarget';
import {parsePoiInfoToNavInfo} from 'utils/search';
import useAddress from 'hooks/useAddress';
import {ItemTag} from 'components/ItemTag';
import PoiListItemCouponLink from 'components/PoiListItemCouponLink';
import {PoiListItemSpecialContent} from 'components/PoiListItemSpecialContent';
import {IcoRouteTurnFilled} from 'components/@tmds/icons/IcoRouteTurnFilled';

import s from 'styles/components/search/SearchResultSubPoiItem.module.scss';

type TProps = {
  actionButtonType: EDetailButtonType;
  idx: number;
  parentPoiData: {
    listName?: string;
    poiName?: string;
    fullJibunAddr?: string;
    fullRoadAddr?: string;
  };
  poiData: {
    poiId: number | string;
    listName?: string;
    categoryName?: string;
    special?: Partial<TSpecialData>;
    pkey?: number | string;
    navSeq?: number | string;
    navX?: number;
    navY?: number;
    centerX?: number;
    centerY?: number;
    rpFlag?: string;
    poiName?: string;
    displayPhoneNumber?: string;
  };
  onClick?: () => void;
  onClickRouteButton?: () => void;
  onClickCoupon?: () => void;
};

const SearchResultSubPoiItem = ({
  actionButtonType,
  idx,
  parentPoiData,
  poiData,
  onClick,
  onClickRouteButton,
  onClickCoupon,
}: TProps) => {
  const {selectAddress} = useAddress();
  const {moveToDetail, moveToSelectDestinationAction} = useMoveToTarget({});
  const {tags, coupon} = useParsePoiData(poiData);
  const parkingInfo = useMemo(() => {
    return {
      realTime: getDecoRealTimeParkingLot(poiData.special?.realTimeParkingLotInfo),
      fee: getDecoParkingLotFeeInfo(
        poiData.special?.parkingFeeInfo,
        poiData.special?.tmapParkingLot
      ),
    };
  }, [poiData.special]);

  const poiJsonName = useMemo(() => {
    return `${parentPoiData.listName || parentPoiData.poiName} ${
      poiData.listName || poiData.poiName
    }`;
  }, [parentPoiData.listName, parentPoiData.poiName, poiData.listName, poiData.poiName]);

  const navInfo = parsePoiInfoToNavInfo({
    pkey: `${poiData.pkey || ''}`,
    poiId: `${poiData.poiId || ''}`,
    navSeq: `${poiData.navSeq || ''}`,
    navX: `${poiData?.navX || ''}`,
    navY: `${poiData?.navY || ''}`,
    centerX: `${poiData?.centerX || ''}`,
    centerY: `${poiData?.centerY || ''}`,
    rpFlag: Number(poiData.rpFlag),
    poiName: poiJsonName,
    address: selectAddress({
      jibun: parentPoiData.fullJibunAddr,
      road: parentPoiData.fullRoadAddr,
    }),
    tel: poiData.displayPhoneNumber || '',
  });

  const handleClickDetail = useCallback(() => {
    moveToDetail(navInfo);
  }, [moveToDetail, navInfo]);

  return (
    <div
      className={s.wrap}
      onClick={() => {
        onClick?.();
        poiData && handleClickDetail();
      }}
    >
      <div className={s.order}>
        <span>{idx + 1}</span>
      </div>
      <div className={s.main}>
        <p className={s.name}>{poiData.listName}</p>
        {parkingInfo.realTime && (
          <p className={s.special_info}>
            <PoiListItemSpecialContent items={parkingInfo.realTime} />
          </p>
        )}
        {parkingInfo.fee && (
          <p className={s.special_info}>
            <PoiListItemSpecialContent items={parkingInfo.fee} />
          </p>
        )}
        {(tags.length > 0 || coupon) && (
          <div className={s.special_action}>
            {tags.length > 0 && (
              <p className={s.tag_list}>
                {tags.map((t, tIdx) => {
                  return (
                    <span className={s.tag} key={tIdx}>
                      <ItemTag {...t} />
                    </span>
                  );
                })}
              </p>
            )}
            {coupon && (
              <span className={s.coupon}>
                <PoiListItemCouponLink
                  {...coupon}
                  onClick={() => {
                    onClickCoupon?.();
                    handleClickDetail();
                  }}
                />
              </span>
            )}
          </div>
        )}
      </div>
      <button
        className={s.button_wrap}
        data-action={actionButtonType}
        aria-label="하위장소 길찾기"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          onClickRouteButton?.();
          moveToSelectDestinationAction(navInfo);
        }}
      >
        {actionButtonType === EDetailButtonType.CONFIRM ? (
          <div className={s.confirm}>확인</div>
        ) : (
          <div className={s.route}>
            <IcoRouteTurnFilled width={18} height={18} color="tmobiBlue700" />
          </div>
        )}
      </button>
    </div>
  );
};

export default SearchResultSubPoiItem;
