import {PropsWithChildren, useCallback, CSSProperties} from 'react';
import {createPortal} from 'react-dom';
import classNames from 'classnames';
import {HybridBridge} from 'utils/searchBar';

import actions from 'ducks/actions';

import {LAYER_ID} from 'types/App';
import {EHistoryStack} from 'ducks/userInteraction/types';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';

import useElement from 'hooks/useElement';
import useStartWhenTrue from 'hooks/useStartWhenTrue';

import s from 'styles/components/BottomPopupModal.module.scss';

type TProps = PropsWithChildren<{
  isOpen?: boolean;
}>;

export const BottomPopupModal = ({children, isOpen}: TProps) => {
  const dispatch = useAppDispatch();

  const layerContainer = useElement({id: LAYER_ID});

  const {isHybrid, isLandscape, searchBarHeight, statusBarHeight} = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
    isLandscape: state.layout.appSize.isLandscape,
    searchBarHeight: state.layout.appSize.searchBarHeight,
    statusBarHeight: state.layout.appSize.statusBarHeight,
  }));

  const handleChangeToTrue = useCallback(() => {
    dispatch(actions.userInteraction.pushHistoryStack(EHistoryStack.BOTTOM_POPUP_MODAL));
    isHybrid && HybridBridge.showModal();
  }, [dispatch, isHybrid]);

  const handleChangeToFalse = useCallback(() => {
    dispatch(actions.userInteraction.popHistoryStack());
    isHybrid && HybridBridge.hideModal();
  }, [dispatch, isHybrid]);

  useStartWhenTrue(!!isOpen, handleChangeToTrue, handleChangeToFalse);

  return (
    layerContainer &&
    createPortal(
      <div
        className={classNames(s.bottom_popup_modal, {
          [s.is_open]: isOpen,
          [s.is_landscape]: isLandscape,
        })}
        style={
          isLandscape
            ? ({
                '--hybrid-top': `${searchBarHeight + statusBarHeight}px`,
              } as CSSProperties)
            : {}
        }
      >
        {children}
      </div>,
      layerContainer
    )
  );
};
