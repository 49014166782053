import {useCallback} from 'react';
import {useHistory} from 'react-router';

import TMapSender from '@lcc/tmap-inapp';

import ua from 'utils/uaParser';

export const useAppLocation = () => {
  const history = useHistory();

  const goBack = useCallback(() => {
    let isHistoryBack = false;

    window.onpopstate = () => {
      isHistoryBack = true;
    };

    history.goBack();

    setTimeout(() => {
      if (!isHistoryBack && ua.isInApp) {
        TMapSender.onBackKeyPressed();
      }
    }, 300);
  }, [history]);

  return {goBack};
};
