import {useState, useEffect} from 'react';

import ua from 'utils/uaParser';
import TMapSender from '@lcc/tmap-inapp';
import {devLog} from 'utils/dev';

type WindowSizeResult = {
  width: number;
  height: number;
  isLandscape: Nullable<boolean>;
};

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSizeResult>({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
    isLandscape: ua.isInApp
      ? null
      : document.documentElement.clientWidth > document.documentElement.clientHeight,
  });

  const handleResize = () => {
    const width = document.documentElement.clientWidth;
    const height = document.documentElement.clientHeight;
    let isLandscape: Nullable<boolean> = null;

    devLog('[resize]', width, height);

    if (ua.isInApp) {
      TMapSender.getDisplayInfo();
    } else {
      isLandscape = false;

      if (width > height) {
        isLandscape = true;
      }
    }

    setWindowSize({
      width,
      height,
      isLandscape,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    let repeatCount = 3;

    const intervalId = window.setInterval(() => {
      if (repeatCount === 0) {
        window.clearInterval(intervalId);
        return;
      }

      repeatCount -= 1;

      handleResize();
    }, 300);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
