import {useCallback, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {HybridBridge} from 'utils/searchBar';
import useDimmedQuery from 'hooks/useDimmedQuery';
import useLogger from 'hooks/useLogger';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {CITY_NAME_MAP} from 'constant/Search';
import {IcoArrowDown} from 'components/@tmds/icons/IcoArrowDown';
import CustomSelectLayer from 'components/CustomSelectLayer';
import {TSearchResultSortParam} from 'components/search/SearchResultHeader';

import s from 'styles/components/search/SearchResultSort.module.scss';

type TProps = {
  pageHide?: boolean;
  onChange?: (param: TSearchResultSortParam) => void;
};

const DEFAULT_SORT_ITEM = {id: '', label: '지역'};

const SearchResultSortBus = ({pageHide, onChange}: TProps) => {
  const dispatch = useAppDispatch();
  const {sendClickLog, sendLogWithSearchQuery} = useLogger();
  const cityDimmed = useDimmedQuery({filter: 'city'});
  const {isHybrid, sortCityCodeList, sortCityCode} = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
    sortCityCodeList: state.search.sortCityCodeList,
    sortCityCode: state.search.sortCityCode,
  }));
  const areaSortList = sortCityCodeList.map((v) => ({id: v, label: CITY_NAME_MAP[v]}));
  const sortList = areaSortList.length > 0 ? [DEFAULT_SORT_ITEM, ...areaSortList] : [];
  const nowSortItem = sortCityCode
    ? {id: sortCityCode, label: CITY_NAME_MAP[sortCityCode]}
    : DEFAULT_SORT_ITEM;

  const handleClickItem = useCallback(
    (item) => {
      const id = item.id;

      sendLogWithSearchQuery('tap.select_city', {
        cityCode: id,
        cityName: item.label,
      });
      onChange?.({cityCode: id});
      dispatch(actions.search.updateSortCityCode(id));
      cityDimmed.close();
    },
    [onChange, cityDimmed, dispatch, sendLogWithSearchQuery]
  );

  useEffect(() => {
    return () => {
      // 레이어 닫을 때 검색창 딤드 사라지지 않아 직접 hide
      isHybrid && HybridBridge.hideModal();
    };
  }, []);

  if (sortList.length === 0) {
    return null;
  }

  return (
    <>
      <div className={s.sort_wrap}>
        <Link
          className={s.sort_item}
          to={cityDimmed.openLocation}
          role="button"
          aria-label={`현재 ${nowSortItem?.label} 정렬결과입니다. 정렬결과 변경하기`}
          onClick={() => sendClickLog('filter_tap.city')}
        >
          <span className={s.label}>{nowSortItem?.label}</span>
          <IcoArrowDown width={12} height={12} color="gray600" />
        </Link>
      </div>
      {!pageHide && (
        <CustomSelectLayer
          isOpen={cityDimmed.isOpen}
          options={sortList}
          selectedItem={nowSortItem}
          onClickItem={handleClickItem}
        />
      )}
    </>
  );
};

export default SearchResultSortBus;
