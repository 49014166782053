import {useCallback, useEffect, useState} from 'react';
import LocalStorage from '@lcc/web-storage';
import {StorageKey} from 'constant/Storage';
import {useOnce} from 'hooks/useOnce';

type TProps = {
  storageKey: StorageKey;
  expireTime?: number;
};

const useTooltip = ({storageKey, expireTime}: TProps) => {
  const [isTooltipVisible, setTooltipVisible] = useState(true);

  const hideTooltip = useCallback(() => {
    LocalStorage.setItem(storageKey, Date.now());
    setTooltipVisible(false);
  }, [storageKey]);

  useEffect(() => {
    if (!storageKey) {
      return;
    }

    const lastVisitDate = LocalStorage.getItem(storageKey);

    if (lastVisitDate) {
      hideTooltip();
      return;
    }

    // 만료 시간이 지났다면 툴팁을 보이지 않게 설정
    if (expireTime && expireTime < Date.now()) {
      hideTooltip();
      return;
    }
  }, [storageKey]);

  useOnce(true, () => {
    const handleTouchStart = () => {
      hideTooltip();
    };

    window.addEventListener('touchstart', handleTouchStart, {once: true});
  });

  return isTooltipVisible;
};

export default useTooltip;
