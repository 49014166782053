import {useCallback, useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';

import {TMolocoNativeResponse, TMolocoBannerParam} from 'types/Ads';
import {EAppPackage} from 'types/App';

import {
  EDeviceType,
  EMolocoAdUnit,
  EMolocoDeviceOrientation,
  EMolocoEndPoint,
  EMolocoOs,
  MOLOCO_NATIVE_ASSETS,
} from 'constant/Ads';

import {useAppSelector} from 'ducks/hooks';

import ua from 'utils/uaParser';
import {fetcherExt} from 'utils/fetcher';
import {sendExtUrl} from 'utils/ads';

import s from 'styles/modules/MolocoBanner.module.scss';
import {isLocalOrDev} from 'constant/Env';

type TProps = {
  onStart?: (reqParams?: TMolocoBannerParam) => void;
  onShow?: (data?: TMolocoNativeResponse) => void;
  onError?: (error?: any) => void;
  onClickAd?: (url?: string) => void;
  adUnitId?: EMolocoAdUnit;
  devLoadOptions?: Partial<TMolocoBannerParam>;
};
const TEMP_ADID = uuidv4();

const MolocoBanner = ({
  onStart,
  onShow,
  onError,
  onClickAd,
  adUnitId,
  devLoadOptions = {},
}: TProps) => {
  const {rdLayout, rdUserInfo, adId} = useAppSelector((state) => ({
    rdLayout: state.layout,
    rdUserInfo: state.userInfo,
    adId: state.userInfo.adId || TEMP_ADID,
  }));

  const [nowData, setData] = useState<TMolocoNativeResponse>();

  const getAd = useCallback(() => {
    const id = adUnitId as EMolocoAdUnit;
    const requestParam: TMolocoBannerParam = {
      id,
      adid: adId,
      productid: ua.isIos ? EAppPackage.IOS_CODE : EAppPackage.AOS,
      country: 'KOR',
      w: 640,
      h: 100,
      o: rdLayout.appSize.isLandscape
        ? EMolocoDeviceOrientation.LANDSCAPE
        : EMolocoDeviceOrientation.PORTRAIT,
      deviceType: EDeviceType.PHONE,
      deviceModel: ua.device.model,
      carrier: rdUserInfo.device.carrierName,
      os: ua.isIos ? EMolocoOs.IOS : EMolocoOs.ANDROID,
      osv: ua.osVersion,
      assets: MOLOCO_NATIVE_ASSETS,
      ...(isLocalOrDev && devLoadOptions ? devLoadOptions : {}),
      ...(!adId ? {lmt: 1} : {}), // adid가 설정이 안될 경우 lmt=1 파라미터가 필수. https://tmobi.slack.com/archives/C02TJ3CQYP6/p1668749483321779?thread_ts=1665650580.691039&cid=C02TJ3CQYP6
    };

    onStart?.(requestParam);

    fetcherExt
      .get(EMolocoEndPoint.APAC, {params: requestParam})
      .then((res) => {
        const data = res.data as TMolocoNativeResponse;

        if (data) {
          setData(data);
          onShow?.(data);

          if (data.imptracker && data.imptracker.length > 0) {
            data.imptracker.forEach((url) => sendExtUrl(url));
          }

          return data;
        }

        throw new Error('No Data');
      })
      .catch((error) => {
        onError?.(error);
      });
  }, [
    rdLayout.appSize.isLandscape,
    adId,
    rdUserInfo.device.carrierName,
    onStart,
    onShow,
    onError,
    adUnitId,
    devLoadOptions,
  ]);

  useEffect(() => {
    getAd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return nowData ? (
    <div className={s.moloco_banner} onClick={() => onClickAd?.(nowData.clk)}>
      <img src={nowData.mainimage} alt="광고 이미지" />
    </div>
  ) : null;
};

export default MolocoBanner;
