import {
  GaPvAction,
  GaTNowCategory,
  GA_CODE,
  GA_COOKIE_DOMAIN,
  GA_COOKIE_EXPIRES,
  GA_COOKIE_PREFIX,
} from 'constant/Log';
import {TActionId} from 'types/Log';
import {devLog} from 'utils/dev';

export const init = () => {
  const {gtag} = window as any;

  gtag?.('config', GA_CODE, {
    cookie_prefix: GA_COOKIE_PREFIX,
    cookie_domain: GA_COOKIE_DOMAIN,
    cookie_expires: GA_COOKIE_EXPIRES,
  });
};

export const sendGa = (action: any, opt: {label?: string; value?: any; category?: any}) => {
  const {gtag} = window as any;

  devLog(
    `[GA] C(${opt?.category}) A(${action}) L(${opt?.label || ''}) V(${
      opt?.value !== undefined ? opt.value : ''
    })`
  );

  gtag?.('event', action, {
    event_category: opt.category,
    event_label: opt.label || undefined,
    value: opt.value || undefined,
  });
  // gtag('send', 'event', categoryCode, action, `${action}${detail || ''}`);
};

export const sendTNowPv = (
  action: GaPvAction,
  opt?: {label?: string; value?: any; category?: GaTNowCategory}
) => {
  sendGa(action, {...opt, category: opt?.category || GaTNowCategory.PV_PLACE});
};

export const sendTNowExpose = (opt?: {label?: string; value?: any; category?: GaTNowCategory}) => {
  // expose 로그는 actionId가 항상 ''
  sendGa('', {...opt, category: opt?.category || GaTNowCategory.EXPOSE_T_NOW});
};

export const sendTNowClick = (
  action: TActionId,
  opt?: {label?: string; value?: any; category?: GaTNowCategory}
) => {
  sendGa(action, {...opt, category: opt?.category || GaTNowCategory.CLICK_T_NOW});
};
