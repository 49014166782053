import {EDateFormat} from 'types/DateTime';
import {
  TPersonalPlaceItem,
  TPersonalPublicRecentItem,
  TUserDataResponse,
} from 'ducks/userInfo/types';
import {
  EPublicTransportType,
  TPublicTransPlaceDetail,
  TSubwayDetails,
  TBusStationDetails,
  TPoiRecentDto,
  TPoiFavoriteDto,
  TFavoriteBusDetail,
  TFavoriteSubwayDetail,
  TPlace,
  TPublicTransportRecentDto,
  TPublicTransportFavoriteDto,
} from 'types/PublicTrans';
import {convertStringToDate} from 'utils/date';
import {firstCharToLowerCase, removePrefix} from 'utils/string';
import {ERecentItemType} from 'types/Search';

const parseAddress = (data) => {
  const {
    lcdName,
    mcdName,
    scdName,
    dcdName,
    mlClass,
    primaryBun,
    secondaryBun,
    roadName,
    bldNo1,
    bldNo2,
  } = data;
  const jibunAddr = [lcdName, mcdName, scdName, dcdName].filter((v) => v).join(' ');
  const mount = mlClass === '2' ? '산' : '';
  const jibun = [primaryBun, secondaryBun].filter((v) => v).join('-');
  const fullJibunAddr = [jibunAddr, mount, jibun].filter((v) => v).join(' ');

  const roadAddr = [lcdName, mcdName, dcdName ? scdName : null, roadName]
    .filter((v) => v)
    .join(' ');
  const buildNo = [bldNo1, bldNo2].filter((v) => v && v !== '0').join('-');
  const fullRoadAddr = [roadAddr, buildNo].filter((v) => v).join(' ');

  return {
    ...data,
    fullJibunAddr,
    fullRoadAddr,
  };
};

const convertResponseToStoreData = <T = unknown>(data): Nullable<T & TPersonalPlaceItem> => {
  if (!data) {
    return null;
  }

  if (!data.poiId && !data.pkey && !data.custName) {
    return null;
  }

  const changedKeys = {
    custName: 'customName',
    noorX: 'navX',
    noorY: 'navY',
    svcDate: 'recentDateTime',
  };

  const {fullJibunAddr, fullRoadAddr, ...newData} = parseAddress(data);

  Object.keys(changedKeys).forEach((originKey) => {
    const newKey = changedKeys[originKey];
    newData[newKey] = data[originKey];
  });

  const time = convertStringToDate(
    newData.recentDateTime || newData.insDatetime || newData.updDatetime
  );

  return {
    ...newData,
    name: newData.name || newData.customName || newData.poiName || fullRoadAddr || fullJibunAddr,
    fullJibunAddr,
    fullRoadAddr,
    recentDateTime: time,
    regDateTime: time,
    wgs84NavX: Number(data.noorLon),
    wgs84NavY: Number(data.noorLat),
  };
};

const getSpecificPrefixData = (prefix, data) => {
  return Object.keys(data).reduce((result, key) => {
    if (!key.includes(prefix)) {
      return result;
    }

    const newKey = firstCharToLowerCase(removePrefix(prefix, key));

    return {
      ...result,
      [newKey]: data[key],
    };
  }, {});
};

const convertListToStoreData = <T>(list: T[] = []) =>
  list.map((dto) => convertResponseToStoreData(dto));

// 경로요약 -> 대중교통 탭
const parsePublicRecentDestination = (
  list: TPublicTransportRecentDto[],
  publicTransportFavoriteDtos: TPublicTransportFavoriteDto[]
) => {
  const favoriteMap = publicTransportFavoriteDtos.reduce((obj, p) => {
    const details = p.details as TFavoriteSubwayDetail | TFavoriteBusDetail;
    const stationId = details.stationId;
    if (!stationId) {
      return obj;
    }
    return {
      ...obj,
      [stationId]: p,
    };
  }, {});

  return list.reduce((arr, v) => {
    const {transportType: type, transportId, details} = v;
    const commonData = {
      transportId,
      date: convertStringToDate(v.svcDate, EDateFormat.GENERAL) as string,
    };

    switch (type) {
      case EPublicTransportType.PUBLIC_POI: {
        const detailData = convertResponseToStoreData<TPlace>(
          (details as TPublicTransPlaceDetail).place
        );

        if (!detailData) {
          return arr;
        }

        const name = detailData.customName || detailData.poiName;

        if (!name) {
          return arr;
        }

        const d: TPersonalPublicRecentItem = {
          ...commonData,
          ...detailData,
          name,
          type: ERecentItemType.PUBLIC_POI,
        };
        return [...arr, d];
      }

      case EPublicTransportType.SUBWAY: {
        const detailData = (details as TSubwayDetails).subway;
        const coord = detailData.geoCoordinate;
        const d: TPersonalPublicRecentItem = {
          ...commonData,
          ...detailData,
          centerX: coord.centerX,
          centerY: coord.centerY,
          navX: coord.noorX,
          navY: coord.noorY,
          wgs84CenterX: Number(coord.centerLon),
          wgs84CenterY: Number(coord.centerLat),
          wgs84NavX: Number(coord.noorLon),
          wgs84NavY: Number(coord.noorLat),
          name: detailData.name,
          stationId: detailData.stationId,
          type: favoriteMap[detailData.stationId]
            ? ERecentItemType.FAVORITE_SUBWAY
            : ERecentItemType.SUBWAY,
          publicTransportType: 'subway',
        };
        return [...arr, d];
      }

      case EPublicTransportType.BUS_STATION: {
        const detailData = (details as TBusStationDetails).busStation;
        const coord = detailData.geoCoordinate;
        const d: TPersonalPublicRecentItem = {
          ...commonData,
          ...detailData,
          centerX: coord.centerX,
          centerY: coord.centerY,
          navX: coord.noorX,
          navY: coord.noorY,
          wgs84CenterX: Number(coord.centerLon),
          wgs84CenterY: Number(coord.centerLat),
          wgs84NavX: Number(coord.noorLon),
          wgs84NavY: Number(coord.noorLat),
          name: detailData.name,
          stationId: detailData.stationId,
          type: favoriteMap[detailData.stationId]
            ? ERecentItemType.FAVORITE_STATION
            : ERecentItemType.BUS_STATION,
          publicTransportType: 'busstop',
        };
        return [...arr, d];
      }
      default:
        return arr;
    }
  }, [] as TPersonalPublicRecentItem[]);
};

const parsePublicTransFavorite = (
  list: TPublicTransportFavoriteDto[]
): TPersonalPublicRecentItem[] => {
  return (list || []).reduce((arr, v) => {
    if ([EPublicTransportType.BUS_STATION, EPublicTransportType.SUBWAY].includes(v.favoriteType)) {
      const d = v.details as TFavoriteSubwayDetail | TFavoriteBusDetail;
      const isBusStation = v.favoriteType === EPublicTransportType.BUS_STATION;
      const coord = {
        centerLat: 0,
        centerLon: 0,
        noorLat: 0,
        noorLon: 0,
        ...d.geoCoordinate,
      };

      return [
        ...arr,
        {
          ...d,
          type: isBusStation ? ERecentItemType.FAVORITE_STATION : ERecentItemType.FAVORITE_SUBWAY,
          transportId: '',
          name: '',
          date: '',
          centerX: coord.centerX,
          centerY: coord.centerY,
          navX: coord.noorX,
          navY: coord.noorY,
          wgs84CenterX: Number(coord.centerLon),
          wgs84CenterY: Number(coord.centerLat),
          wgs84NavX: Number(coord.noorLon),
          wgs84NavY: Number(coord.noorLat),
          publicTransportType: isBusStation ? 'busstop' : 'subway',
        } as TPersonalPublicRecentItem,
      ];
    }

    return arr;
  }, [] as TPersonalPublicRecentItem[]);
};

export const parseFrontmanData = (data: TUserDataResponse) => {
  const home = convertResponseToStoreData(getSpecificPrefixData('home', data.poiMyFavoriteDto));
  const office = convertResponseToStoreData(getSpecificPrefixData('office', data.poiMyFavoriteDto));

  // 즐겨찾기
  const favorites = convertListToStoreData<TPoiFavoriteDto>(data.poiFavoriteDtos);
  // 경로 즐겨찾기
  const favoriteRoutes = data.routeFavoriteDtos.map((dto) => {
    return {
      customTitle: dto.routeTitle,
      departure: {
        ...dto.startRouteDto,
        placeName: dto.startRouteDto.name,
      },
      destination: {
        ...dto.endRouteDto,
        placeName: dto.endRouteDto.name,
      },
      routeTrace: '',
    };
  });

  // 대중교통 즐겨찾기
  const favoritesPublic = parsePublicTransFavorite(data.publicTransportFavoriteDtos);

  // 최근 목적지
  const recentDestination = convertListToStoreData<TPoiRecentDto>(data.poiRecentDtos);
  const publicRecentDestination = parsePublicRecentDestination(
    data.publicTransportRecentDtos,
    data.publicTransportFavoriteDtos
  );

  return {
    data: {
      favorites: {
        docs: favorites,
        home,
        office,
      },
      recentDestination: {
        docs: recentDestination,
      },
      favoriteRoutes,
      publicRecentDestination,
      favoritesPublic,
    },
  };
};
