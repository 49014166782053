import {IcoArrowRight} from 'components/@tmds/icons/IcoArrowRight';
import {IcoExit} from 'components/@tmds/icons/IcoExit';
import {IcoInfoFilled} from 'components/@tmds/icons/IcoInfoFilled';
import {ETPlaceTab} from 'ducks/tplacehome/types';
import {useCallback, useState} from 'react';
import {ReactComponent as IconTmapAI} from 'resource/images/icon_tmap_ai.svg';

import s from 'styles/components/tplacehome/TmapAiIcon.module.scss';
import {moveToUrl} from 'utils/url';

type TProps = {
  tab?: ETPlaceTab;
};

const TmapAiIcon = ({tab}: TProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleDetailClick = useCallback(() => {
    // 툴팁 사라지고 이동하기 위한 timer
    setTimeout(() => {
      moveToUrl('tmap://board?pageid=board_detail&boardid=BOARD-MAIN&seq=184');
    }, 0);
  }, []);

  /** https://tmobi.atlassian.net/browse/LOCALQA-381
   * 내 주변 문구: 티맵 AI가 고객의 이동 데이터를 분석해 선정한 인기 장소입니다.
   * 발견 문구: 티맵 AI가 고객의 이동 데이터를 분석해 추천하는 맞춤 장소입니다.
   */
  return (
    <div className={s.wrap} onClick={() => setShowTooltip((prev) => !prev)}>
      <IconTmapAI />
      <div className={s.icon}>
        <IcoInfoFilled width={18} height={18} color="gray300" />
      </div>
      <div className={s.tooltip_wrap}>
        {showTooltip && (
          <div className={s.content_wrap}>
            <div className={s.contents}>
              {tab === ETPlaceTab.DISCOVERY ? (
                <div>
                  티맵 AI가 고객의 이동 데이터를 <br />
                  분석해 추천하는 맞춤 장소입니다.
                </div>
              ) : (
                <div>
                  티맵 AI가 고객의 이동 데이터를 <br />
                  분석해 선정한 인기 장소입니다.
                </div>
              )}

              <div className={s.link} onClick={handleDetailClick}>
                자세히 보기 <IcoArrowRight width={14} height={14} color={'tmobiBlue600'} />
              </div>
            </div>
            <div
              className={s.exit}
              onClick={(e) => {
                e.stopPropagation();
                setShowTooltip(false);
              }}
            >
              <IcoExit width={20} height={20} color="gray500" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TmapAiIcon;
