import {useCallback} from 'react';
import {useAppSelector} from 'ducks/hooks';
import DiscoverySection from './DiscoverySection';
import {isEmpty} from '../../../utils/lodash';
import imgRanking11 from 'resource/images/img_ranking_11.png';
import imgRanking26 from 'resource/images/img_ranking_26.png';
import imgRanking27 from 'resource/images/img_ranking_27.png';
import imgRanking28 from 'resource/images/img_ranking_28.png';
import imgRanking29 from 'resource/images/img_ranking_29.png';
import imgRanking30 from 'resource/images/img_ranking_30.png';
import imgRanking31 from 'resource/images/img_ranking_31.png';
import imgRanking36 from 'resource/images/img_ranking_36.png';
import imgRanking41 from 'resource/images/img_ranking_41.png';
import imgRanking43 from 'resource/images/img_ranking_43.png';
import imgRanking44 from 'resource/images/img_ranking_44.png';
import imgRanking46 from 'resource/images/img_ranking_46.png';
import imgRanking47 from 'resource/images/img_ranking_47.png';
import imgRanking48 from 'resource/images/img_ranking_48.png';
import imgRanking50 from 'resource/images/img_ranking_50.png';
import imgRanking51 from 'resource/images/img_ranking_51.png';
import imgRanking52 from 'resource/images/img_ranking_52.png';

import s from '../../../styles/components/tplacehome/TPlacePopularPlace.module.scss';
import {PlaceCategoryLink} from 'components/PlaceCategoryLink';
import usePlaceHome from 'hooks/usePlaceHome';

// 공통에 있음. 합칠때 다시 정의할 것.
enum ECity {
  SEOUL = 'SEOUL',
  BUSAN = 'BUSAN',
  JEJU = 'JEJU',
  GANGWON = 'GANGWON',
  DAEGU = 'DAEGU',
  DAEJEON = 'DAEJEON',
  GYEONGGI = 'GYEONGGI',
  INCHEON = 'INCHEON',
  SEJONG = 'SEJONG',
  ULSAN = 'ULSAN',
  GWANJU = 'GWANJU',
  CHUNGNAM = 'CHUNGNAM',
  CHUNGBUK = 'CHUNGBUK',
  GYEONGNAM = 'GYEONGNAM',
  GYEONGBUK = 'GYEONGBUK',
  JEONNAM = 'JEONNAM',
  JEONBUK = 'JEONBUK',
}

export const changeKorRegionToEng = (regionName) => {
  switch (regionName) {
    case '서울':
      return ECity.SEOUL;
    case '부산':
      return ECity.BUSAN;
    case '제주':
      return ECity.JEJU;
    case '강원':
      return ECity.GANGWON;
    case '대구':
      return ECity.DAEGU;
    case '대전':
      return ECity.DAEJEON;
    case '경기':
      return ECity.GYEONGGI;
    case '인천':
      return ECity.INCHEON;
    case '세종':
      return ECity.SEJONG;
    case '울산':
      return ECity.ULSAN;
    case '광주':
      return ECity.GWANJU;
    case '충남':
      return ECity.CHUNGNAM;
    case '충북':
      return ECity.CHUNGBUK;
    case '경남':
      return ECity.GYEONGNAM;
    case '경북':
      return ECity.GYEONGBUK;
    case '전남':
      return ECity.JEONNAM;
    case '전북':
      return ECity.JEONBUK;
    default:
      return '';
  }
};

const DiscoveryRegionList = () => {
  const {tplacehome} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();

  const getImage = useCallback((id?: string) => {
    switch (id) {
      case '11':
        return imgRanking11;
      case '26':
        return imgRanking26;
      case '27':
        return imgRanking27;
      case '28':
        return imgRanking28;
      case '29':
        return imgRanking29;
      case '30':
        return imgRanking30;
      case '31':
        return imgRanking31;
      case '36':
        return imgRanking36;
      case '41':
        return imgRanking41;
      case '43':
        return imgRanking43;
      case '44':
        return imgRanking44;
      case '46':
        return imgRanking46;
      case '47':
        return imgRanking47;
      case '48':
        return imgRanking48;
      case '50':
        return imgRanking50;
      case '51':
        return imgRanking51;
      case '52':
      default:
        return imgRanking52;
    }
  }, []);

  const getRegionPosition = useCallback((regionName?: string) => {
    return {region: changeKorRegionToEng(regionName || '')};
  }, []);

  if (isEmpty(tplacehome.regionList.depth1)) {
    return null;
  }

  return (
    <DiscoverySection
      mainTitle={'지역별 인기 장소'}
      subTitle={'티맵 사용자들이 많이 방문한'}
      noFirstLetterHighlight
    >
      <ul className={s.popular_places} data-flicking-ignore>
        {tplacehome.regionList.depth1!.map((item) => {
          return (
            <li key={item.areaId}>
              <PlaceCategoryLink
                className={s.item}
                type="RANKING"
                // 링크는 정상동작하나 타입오류가 발생되어 추가. 합친 후 제거해볼 것.
                // @ts-ignore
                queries={getRegionPosition(item.areaName)}
                onClick={() =>
                  placehomeHook.sendEventDiscovery('tap.tmapranking', {region_name: item.areaName})
                }
              >
                <span className={s.text}>{item.areaName}</span>
                <img src={getImage(item.areaDepth1Code)} width="90" height="90" alt="" />
              </PlaceCategoryLink>
            </li>
          );
        })}
      </ul>
    </DiscoverySection>
  );
};

export default DiscoveryRegionList;
