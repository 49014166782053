import {useCallback} from 'react';
import InView from 'react-intersection-observer';
import {useFocusDrawerList} from 'hooks/useFocusDrawerList';
import useLogger from 'hooks/useLogger';
import useAppScheme from 'hooks/useAppScheme';
import {TBusLineItem} from 'ducks/search/types';
import SearchResultBusLineItem from 'components/search/SearchResultBusLineItem';

import s from 'styles/components/search/SearchResultPoiList.module.scss';

type TProps = {
  list: TBusLineItem[];
  nowPage: number;
  searchQuery: string;
};

const SearchResultBusLineList = (props: TProps) => {
  const inApp = useAppScheme();
  const list = (props.list || []).map((v) => ({...v, listId: `${v.pkey}-${v.busLineId}`}));
  const {activePoi, changeViewingItems} = useFocusDrawerList(list);
  const {sendSearchResultItemClickLog} = useLogger();

  const handleClickItem = useCallback(
    (e, v, idx) => {
      sendSearchResultItemClickLog('list_tap.poi_detail', {index: idx});
      inApp.openBusRouteDetail(v.busLineId);
    },
    [sendSearchResultItemClickLog, inApp]
  );

  return (
    <>
      {list.map((v, idx) => {
        return (
          <InView
            as="li"
            className={s.item}
            key={`bus-list-${v.listId}`}
            data-type="poi"
            data-id={v.listId}
            threshold={0.5}
            onChange={(e) => changeViewingItems(e, idx)}
            data-focus={activePoi === v.listId}
          >
            <SearchResultBusLineItem item={v} onClickItem={(e) => handleClickItem(e, v, idx)} />
          </InView>
        );
      })}
    </>
  );
};

export default SearchResultBusLineList;
