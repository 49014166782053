import {PropsWithChildren, useMemo} from 'react';
import classNames from 'classnames';
import ImageLoader from './ImageLoader';

import s from '../styles/components/Thumbnail.module.scss';

type TThumbProps = {
  imgSrc?: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  alt?: string;
};

type TProps = TThumbProps & PropsWithChildren<{}>;

const Thumbnail = (props: TProps) => {
  const {imgSrc, width, height, alt, className, children} = props;

  const styles = useMemo(() => {
    if (width && height) {
      return {
        width: typeof width === 'string' ? width : `${width}px`,
        height: typeof height === 'string' ? height : `${height}px`,
      };
    }
  }, [width, height]);

  return (
    <div className={classNames(s.wrap, className)} style={styles}>
      {imgSrc && <ImageLoader src={imgSrc} toggleClass={s.fade_in} alt={alt || ''} />}
      {children}
    </div>
  );
};

export default Thumbnail;
