import {useState, useMemo, useCallback, useEffect, useRef, forwardRef} from 'react';
import {EAddressMode, TAddressItem} from 'types/Search';
import useSearchAddress from 'hooks/useSearchAddress';
import {IcArrowDownBold} from 'components/@tmds/icons/v1.2/IcArrowDownBold';
import classNames from 'classnames';
import {NATIONAL_REGION, ONE_DEPTH_REGION} from './SearchRankingDropDown';
import {useOnce} from 'hooks/useOnce';
import {useAppSelector} from 'ducks/hooks';
import {TAddressMap} from 'constant/Address';
import {TRankState} from 'ducks/rank/types';
import ua from 'utils/uaParser';
import useLogger from 'hooks/useLogger';

import s from 'styles/components/search/SearchRankingFilter.module.scss';

const REGION_DEFAULT_DEPTH = 1;

const REGION_ORDER = [
  NATIONAL_REGION.areaName,
  '서울',
  '경기',
  '인천',
  '강원',
  '대전',
  '충남',
  '충북',
  '세종',
  '경남',
  '경북',
  '부산',
  '울산',
  '대구',
  '전남',
  '전북',
  '광주',
  '제주',
];

type TProps = {
  filterAddressMap: TAddressMap;
  onClick: React.Dispatch<React.SetStateAction<Partial<Record<EAddressMode, TAddressItem>>>>;
  apiStatus: TRankState;
};

const SearchRankingFilter = forwardRef<HTMLDivElement, TProps>(
  ({filterAddressMap, onClick, apiStatus}, ref) => {
    const {currentAddressMap} = useAppSelector((state) => state.tRank);
    const [cityList, setCityList] = useState<TAddressItem[]>([]);
    const refRegions = useRef({});
    const {sendClickLog} = useLogger();
    const [currentRegionId, setCurrentRegionId] = useState<Nullable<string>>(null);

    const result = useSearchAddress({depth: REGION_DEFAULT_DEPTH});

    const handleClickItem = useCallback(
      (address) => {
        const isSameRegion = currentRegionId === address.areaId;
        const hasFirstCate = !!filterAddressMap[EAddressMode.CATE1];
        sendClickLog('tap.region_chip', {region: address.areaName});

        if (isSameRegion) {
          onClick(hasFirstCate ? {} : {[EAddressMode.CATE1]: address});
        } else {
          setCurrentRegionId(address.areaId);
          onClick({[EAddressMode.CATE1]: address});
        }
      },
      [onClick, currentRegionId, filterAddressMap]
    );

    const getSortRegionsByOrder = useCallback((regions, order) => {
      const regionMap = regions.reduce((acc, region) => {
        acc[region.areaName] = region;
        return acc;
      }, {});

      return order.map((name) => regionMap[name]).filter(Boolean);
    }, []);

    const sortedRegions = useMemo(
      () => getSortRegionsByOrder(cityList, REGION_ORDER),
      [cityList, getSortRegionsByOrder]
    );

    useEffect(() => {
      if (
        apiStatus.loaded &&
        currentRegionId &&
        refRegions.current[currentRegionId] &&
        sortedRegions.length
      ) {
        window.setTimeout(() => {
          refRegions.current[currentRegionId].scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
          });
        }, 5);
      }
    }, [currentRegionId, sortedRegions, apiStatus.loaded]);

    useEffect(() => {
      const {areaId} = currentAddressMap[EAddressMode.CATE1] || {};

      if (areaId) {
        setCurrentRegionId(areaId);
      }
    }, [currentAddressMap]);

    useEffect(() => {
      return () => {
        onClick({});
        setCityList([]);
      };
    }, []);

    useOnce(!!result.data?.length, () => {
      if (!result?.data) {
        return;
      }

      setCityList(result.data);
    });

    return (
      <div className={s.drawer_wrap} ref={ref}>
        <div
          className={classNames(s.item, s.no_icon, {
            [s.clicked]: currentRegionId === NATIONAL_REGION.areaId,
          })}
          onClick={() =>
            handleClickItem({areaName: NATIONAL_REGION.areaName, areaId: NATIONAL_REGION.areaId})
          }
          ref={(el) => {
            refRegions.current[NATIONAL_REGION.areaId] = el;
          }}
        >
          전국
        </div>
        {sortedRegions.map((region) => (
          <div
            key={region.areaId}
            className={classNames(s.item, {
              [s.clicked]: region.areaId === currentRegionId,
              [s.no_icon]: ONE_DEPTH_REGION.includes(region.areaName),
            })}
            onClick={() => handleClickItem(region)}
            ref={(el) => {
              refRegions.current[region.areaId] = el;
            }}
          >
            <div className={s.name}>{region.areaName}</div>
            {!ONE_DEPTH_REGION.includes(region.areaName) && (
              <div className={s.icon}>
                <IcArrowDownBold
                  width={12}
                  height={12}
                  color={region.areaId === currentRegionId ? 'gray200' : 'gray500'}
                />
              </div>
            )}
          </div>
        ))}

        <div
          className={classNames(s.drawer_line, {
            [s.is_ios]: ua.isIos,
          })}
        />
      </div>
    );
  }
);

export default SearchRankingFilter;
