import {useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import useRecentQuery from 'hooks/useRecentQuery';
import useAddress from 'hooks/useAddress';
import {SEARCH_RECENT_MAX_COUNT} from 'constant/Search';
import {ELegacyRecentItemType, TLegacyRecentItem} from 'types/LegacySearch';

type TProps = {
  listLength: number;
};

export const useLegacyRecentList = (
  {listLength}: TProps = {listLength: SEARCH_RECENT_MAX_COUNT}
) => {
  const {recentDestinationApiLoaded, recentDestination} = useAppSelector((state) => ({
    recentDestinationApiLoaded: state.userInfo.personalPlace.loaded,
    recentDestination: state.userInfo.personalPlace.data.recentDestination,
    publicRecentDestination: state.userInfo.personalPlace.data.publicRecentDestination,
  }));
  const {recentQueries} = useRecentQuery();
  const {isUseJibun} = useAddress();

  const recentList: TLegacyRecentItem[] = useMemo(() => {
    return [
      ...(recentDestinationApiLoaded ? recentDestination : []).map(
        (n, i): TLegacyRecentItem => ({
          id: `${n.poiId}-${i}`,
          type: ELegacyRecentItemType.POI,
          name: n.customName,
          date: n.recentDateTime || '',
          poiInfo: {
            ...n,
            addInfo: isUseJibun ? n.fullJibunAddr : n.fullRoadAddr || n.fullJibunAddr,
          },
        })
      ),
      ...recentQueries.map(
        (n): TLegacyRecentItem => ({
          id: n.query,
          type: ELegacyRecentItemType.QUERY,
          name: n.query,
          date: n.date,
        })
      ),
    ]
      .filter((i) => !!i.id)
      .sort((a, b) => (a.date < b.date ? 1 : -1))
      .slice(0, listLength);
  }, [recentDestinationApiLoaded, recentDestination, recentQueries, listLength, isUseJibun]);

  return {
    recentList,
    recentListApiLoaded: recentDestinationApiLoaded,
  };
};

export default useLegacyRecentList;
