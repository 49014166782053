export enum EDataType {
  VALUE,
  DANGER_VALUE,
  ARRAY,
  KEY_VALUE,
  INPUT,
  BUTTON,
  OBJECT,
  CUSTOM,
}

export type TBuildInfoItem = {
  name: string;
  value: any;
  type: EDataType;
  ignoreCopy?: boolean;
  handleCLick?: VoidFunction;
  expandedPaths?: string[];
};

export enum EBuildInfoTabs {
  BASE_INFO = 'BASE',
  ADDITIONAL_INFO = 'INFO',
  DEV = 'DEV',
  LINK = 'LINK',
}
