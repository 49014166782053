export enum EButtonType {
  BACK = 'back',
  CLOSE = 'close',
  SHARE = 'share',
}

export type TButtonAction = {
  onGoBack?: VoidFunction;
  onClose?: VoidFunction;
  onShare?: VoidFunction;
};
