import {BottomPopupModal} from './BottomPopupModal';
import classNames from 'classnames';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {SCROLLABLE_ELEMENT} from './DimmedLayer';
import {useAppSelector} from 'ducks/hooks';
import {ReactComponent as IconReload} from 'resource/images/ico_reload.svg';
import {TFilter} from 'components/search/SearchRankingHeaderQuickFilter';

import s from 'styles/components/FilterBottomPopup.module.scss';

export enum EFilter {
  TMAP_FAMOUS = 'TMAP_FAMOUS',
  OPEN_NOW = 'OPEN_NOW',
  WAITING_RESERVATION = 'WAITING_RESERVATION',
  POI_PARK = 'POI_PARK',
}

type TProps = {
  isOpen: boolean;
  close: () => void;
  filters: any[];
  categories: any[];
  onClickConfirm?: (clickedFilters: any[]) => void;
  onClickReset?: () => void;
};

const initializeClickedState = (
  items: {key: string}[],
  activeFilters: Record<string, {isActive: boolean}>
) =>
  items.reduce((acc, item) => {
    acc[item.key] = activeFilters[item.key]?.isActive || false;
    return acc;
  }, {} as Record<string, boolean>);

const resetClickedState = (items: {key: string}[]) =>
  items.reduce((acc, {key}) => {
    acc[key] = false;
    return acc;
  }, {} as Record<string, boolean>);

const renderFilterItems = (
  items: TFilter[],
  clickedState: Record<string, boolean>,
  setClickedState: (value: React.SetStateAction<Record<string, boolean>>) => void
) =>
  items.map(({key, label}) => (
    <div
      className={classNames(s.filter, {[s.on]: clickedState[key]})}
      onClick={() => setClickedState((prev) => ({...prev, [key]: !prev[key]}))}
      key={key}
    >
      <span className={s.text}>{label}</span>
    </div>
  ));

const FilterBottomPopup = ({
  isOpen,
  close,
  filters,
  categories,
  onClickConfirm,
  onClickReset,
}: TProps) => {
  const {isLandscape, activeFilters} = useAppSelector((state) => ({
    isLandscape: state.layout.appSize.isLandscape,
    activeFilters: state.tRank.activeFilters,
  }));

  const [clickedFilter, setClickedFilter] = useState<Record<string, boolean>>(
    initializeClickedState(filters, activeFilters)
  );
  const [clickedCategory, setClickedCategory] = useState<Record<string, boolean>>(
    initializeClickedState(categories, activeFilters)
  );

  const clickedFilterLength = useMemo(
    () =>
      [...Object.values(clickedFilter), ...Object.values(clickedCategory)].filter((value) => value)
        .length,
    [clickedFilter, clickedCategory]
  );

  const handleClickReset = useCallback(() => {
    setClickedFilter(resetClickedState(filters));
    setClickedCategory(resetClickedState(categories));
    onClickReset?.();
  }, []);

  const handleClickConfirm = useCallback(() => {
    const checkActiveKeys = (state: Record<string, boolean>) =>
      Object.keys(state).filter((key) => state[key]);

    onClickConfirm?.([...checkActiveKeys(clickedFilter), ...checkActiveKeys(clickedCategory)]);
    close();
  }, [clickedFilter, clickedCategory, onClickConfirm, close]);

  useEffect(() => {
    if (isOpen) {
      setClickedFilter(initializeClickedState(filters, activeFilters));
      setClickedCategory(initializeClickedState(categories, activeFilters));
    } else {
      setClickedFilter(resetClickedState(filters));
      setClickedCategory(resetClickedState(categories));
    }
  }, [isOpen, activeFilters]);

  return (
    <BottomPopupModal isOpen={isOpen}>
      <div
        className={classNames(s.modal_wrap, {
          [s.is_landscape]: isLandscape,
        })}
      >
        <div className={classNames(s.content_container, SCROLLABLE_ELEMENT)}>
          <div className={s.container}>
            <div className={s.title}>부가정보</div>
            <div className={classNames(s.filter_wrap, s.top)}>
              {renderFilterItems(filters, clickedFilter, setClickedFilter)}
            </div>
          </div>

          <div className={s.container}>
            <div className={s.title}>업종</div>
            <div className={s.filter_wrap}>
              {renderFilterItems(categories, clickedCategory, setClickedCategory)}
            </div>
          </div>
        </div>

        <div className={s.bottom_area}>
          <div className={s.buttons}>
            <button className={s.reset} onClick={handleClickReset}>
              {!isLandscape && <IconReload />}
              초기화
            </button>
            <button className={s.confirm} onClick={handleClickConfirm}>
              필터 적용
              <span className={s.count}>{clickedFilterLength > 0 && clickedFilterLength}</span>
            </button>
          </div>
        </div>
      </div>
    </BottomPopupModal>
  );
};

export default FilterBottomPopup;
