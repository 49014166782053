import {useCallback, useEffect} from 'react';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import useDimmedQuery from 'hooks/useDimmedQuery';
import useLogger from 'hooks/useLogger';
import {EActionId} from 'constant/Log';

import s from 'styles/components/DimmedLayer.module.scss';

export const SCROLLABLE_ELEMENT = 'scrollable-el';

export const DimmedLayer = () => {
  const {isOpen, close} = useDimmedQuery();
  const {sendClickLogWithMapView} = useLogger();

  const handleClickClose = useCallback(() => {
    close();
    sendClickLogWithMapView(EActionId.DIMMED);
  }, [close, sendClickLogWithMapView]);

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(document.body, {
        allowTouchMove: (el) => {
          const hasDisableClass = el.classList.contains(SCROLLABLE_ELEMENT);
          const hasParents = !!el.closest(`.${SCROLLABLE_ELEMENT}`);
          if (hasDisableClass || hasParents) {
            return true;
          }
        },
      });
    } else {
      enableBodyScroll(document.body);
    }
  }, [isOpen]);

  return isOpen ? <div className={s.dimmed_layer} onClick={handleClickClose} /> : <></>;
};
