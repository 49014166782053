import {useRouteMatch} from 'react-router';
import {Paths, SearchMainPages, SearchResultPages} from 'constant/RoutePath';

const usePageInfo = () => {
  const isSearchMainPage = useRouteMatch(SearchMainPages);
  const isSearchResultPage = useRouteMatch(SearchResultPages);
  const isPlaceMainPage = useRouteMatch(Paths.PlaceMain);
  const isPlaceCategoryPage = useRouteMatch(Paths.PlaceCategory);

  return {
    isSearchMainPage,
    isSearchResultPage,
    isPlaceMainPage,
    isPlaceCategoryPage,

    isSearchPage: isSearchMainPage || isSearchResultPage,
    isPlacePage: isPlaceMainPage || isPlaceCategoryPage,
  };
};

export default usePageInfo;
