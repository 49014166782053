import TMapSender from '@lcc/tmap-inapp';
import {isProdEnv, isLocalEnv} from 'constant/Env';
import ua from 'utils/uaParser';
import LocalStorage from '@lcc/web-storage';

export const DEV_LOG_STACK = 'log_stack';

export const devLog = (...args) => {
  if (!isProdEnv) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

export const devAlert = (...args) => {
  if (!isProdEnv) {
    window.alert(...args);
  }
};

export const localDevToast = (text) => {
  isLocalEnv && ua.isInApp && TMapSender.makeToast(text);
};

export const localDevLog = (...args) => {
  if (!isProdEnv) {
    // eslint-disable-next-line no-console
    ua.isIos ? TMapSender.makeToast(JSON.stringify(args)) : console.log(...args);
  }
};

export const renderStackView = () => {
  let stackView = document.querySelector<HTMLElement>('#' + DEV_LOG_STACK);

  if (!stackView) {
    const initVisible = !!LocalStorage.getItem(DEV_LOG_STACK);

    document.body.insertAdjacentHTML(
      'beforeend',
      `<div id="${DEV_LOG_STACK}" style="display:${initVisible ? 'block' : 'none'};"></div>`
    );
    stackView = document.querySelector('#' + DEV_LOG_STACK);
  }

  return stackView;
};

export const devLogStack = (string) => {
  if (isProdEnv) {
    return;
  }
  const stackView = renderStackView();
  const order = stackView?.querySelectorAll('p').length;

  stackView?.insertAdjacentHTML('afterbegin', `<p>[${order}] ${string}</p>`);
};

export const NEW_HOME_KEY = 'NEW_HOME';
