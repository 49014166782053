import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {useMemo, useCallback} from 'react';
import {EPerformanceLogKey} from 'types/Log';

const LOG_TEXT = {
  [EPerformanceLogKey.LIST_API]: '리스트 API 호출',
  [EPerformanceLogKey.VSM_INIT]: 'VSM 초기화 ~ 첫번째 RenderComplete',
  [EPerformanceLogKey.CALL_POSITION]: '현위치 호출',
  [EPerformanceLogKey.INIT_API_RENDER]: '최초 API ~ 렌더',
  [EPerformanceLogKey.VSM_FIRST_PAINT]: 'VSM 초기화 ~ FirstPaint',
};

export const usePerformanceLog = () => {
  const dispatch = useAppDispatch();
  const {pair, items} = useAppSelector((state) => ({
    pair: state.log.pair,
    items: state.log.items,
  }));

  const viewing = useMemo(() => {
    const arr = Object.keys(pair)
      .reduce((prev, cur) => {
        const {start, end, duration} = pair[cur] || {};
        const viewingStartItem = start ? [{...start, title: `${LOG_TEXT[cur]} 시작`}] : [];
        const viewingEndItem = end ? [{...end, duration, title: `${LOG_TEXT[cur]} 종료`}] : [];

        return [...prev, ...viewingStartItem, ...viewingEndItem];
      }, [] as any[])
      .sort((a, b) => (a.time || 0) - (b.time || 0));
    return {
      startTime: items[EPerformanceLogKey.WEB_LAUNCH]?.time || 0,
      items: arr,
    };
  }, [pair, items]);

  const startLog = useCallback((key: EPerformanceLogKey) => {
    dispatch(actions.log.addStartItem({key, time: Date.now()}));
  }, []);

  const endLog = useCallback((key: EPerformanceLogKey) => {
    dispatch(actions.log.addEndItem({key, time: Date.now()}));
  }, []);

  const normalLog = useCallback((key: EPerformanceLogKey) => {
    dispatch(actions.log.addNormalItem({key, time: Date.now()}));
  }, []);

  return {normalLog, startLog, endLog, viewing};
};
