import {ESearchAppMoveTarget, TQueryItem, TSearchContext} from '@lcc/tmap-inapp';
import {HybridBridge} from 'utils/searchBar';
import {useCallback, useEffect, useState} from 'react';

import s from 'styles/pages/DevHybridBridgePage.module.scss';
import {getGeneralTime} from 'utils/date';
import {EMPTY_ARRAY} from 'constant/Vars';
import classNames from 'classnames';

const fixedDateQuery = {
  query: '고정 일자',
  date: getGeneralTime(),
};

const senderList = [
  {
    name: 'getSearchContext',
    func: () => HybridBridge.getSearchContext(),
  },
  {
    name: 'queryInputTextNoSuggest',
    value: '상세주소 1-1',
    func: () => HybridBridge.queryInputTextNoSuggest('상세주소 1-1'),
  },
  {
    name: 'moveMain',
    func: () => HybridBridge.moveToMain(),
  },
  {
    name: 'moveResult',
    value: '검색어 abcvd 12-2',
    func: () =>
      HybridBridge.moveToResult({
        query: '검색어 abcvd 12-2',
        date: getGeneralTime(),
      }),
  },
  {
    name: 'modalPresented',
    value: '0x59111111',
    func: () => HybridBridge.showModal('0x59111111'),
  },
  {
    name: 'modalPresented',
    value: '0x59000000',
    func: () => HybridBridge.showModal('0x59000000'),
  },
  {
    name: 'modalDismissed',
    func: () => HybridBridge.hideModal(),
  },
  {
    name: 'addRecents',
    value: '최근검색어',
    func: () =>
      HybridBridge.addRecentQuery([
        {
          query: '최근검색어',
          date: getGeneralTime(),
        },
      ]),
  },
  {
    name: 'addRecents',
    value: '최근검색어1, 최근검색어2',
    func: () =>
      HybridBridge.addRecentQuery([
        {
          query: '최근검색어1',
          date: getGeneralTime(),
        },
        {
          query: '최근검색어2',
          date: getGeneralTime(),
        },
      ]),
  },
  {
    name: 'addRecents',
    value: '고정 일자',
    func: () => HybridBridge.addRecentQuery([fixedDateQuery]),
  },
  {
    name: 'deleteRecents',
    value: '최근검색어',
    func: () =>
      HybridBridge.deleteRecentQueries([
        {
          query: '최근검색어',
          date: getGeneralTime(),
        },
      ]),
  },
  {
    name: 'deleteRecents',
    value: '고정 일자',
    func: () => HybridBridge.deleteRecentQueries([fixedDateQuery]),
  },
  {
    name: 'deleteAllRecents',
    func: () => HybridBridge.deleteAllRecentQueries(),
  },
  {
    name: 'showSearchBar',
    value: 'true',
    func: () => HybridBridge.showSearchBar(true),
  },
  {
    name: 'showSearchBar ',
    value: 'false',
    func: () => HybridBridge.showSearchBar(false),
  },
];

const DevHybridBridgePage = () => {
  const [appLogs, setLogs] = useState<Array<{key: number; value: string}>>(EMPTY_ARRAY);

  const onSearch = useCallback((data: TQueryItem) => {
    setLogs((prev) => [{key: prev.length + 1, value: `[onSearch] ${data}`}, ...prev]);
  }, []);

  const onMove = useCallback((target: ESearchAppMoveTarget) => {
    setLogs((prev) => [{key: prev.length + 1, value: `[onMove] ${target}`}, ...prev]);
  }, []);

  const onSearchContext = useCallback((context: TSearchContext) => {
    setLogs((prev) => [
      {key: prev.length + 1, value: `[onSearchContext] ${JSON.stringify(context)}`},
      ...prev,
    ]);
  }, []);

  useEffect(() => {
    HybridBridge.init({
      onSearch,
      onMove,
      onSearchContext,
    });

    HybridBridge.getSearchContext();
  }, []);

  return (
    <div className={s.wrap}>
      <h3>Web to App</h3>
      <div className={s.link_wrap}>
        {senderList.map((n) => (
          <button
            key={`${n.name}-${n.value}`}
            onClick={() => {
              n.func();
              setLogs((prev) => [
                {key: prev.length + 1, value: `[Send] ${n.name} ${n.value || ''}`},
                ...prev,
              ]);
            }}
          >
            {n.name}
            {n.value && <em>{n.value}</em>}
          </button>
        ))}
      </div>

      <h3>App To Web</h3>
      <div className={s.log_wrap}>
        {appLogs.map((n, i) => (
          <div
            key={n.key}
            className={classNames({
              [s.now_item]: i === 0,
            })}
          >
            {n.key} {n.value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevHybridBridgePage;
