import {ERPFlag, ERPFlagCode} from 'types/App';

// MPP NSL MLP DSLP
export const RP_FLAG_CODES = [
  {numCode: ERPFlag.N_0000, strCode: ERPFlagCode.C_0000}, //  0 0 0 0
  {numCode: ERPFlag.N_1100, strCode: ERPFlagCode.C_1100}, //  1 0 0 0
  {numCode: ERPFlag.N_2010, strCode: ERPFlagCode.C_2010}, //  0 1 0 0
  {numCode: ERPFlag.N_3110, strCode: ERPFlagCode.C_3110}, //  1 1 0 0
  {numCode: ERPFlag.N_4001, strCode: ERPFlagCode.C_4001}, //  0 0 1 0
  {numCode: ERPFlag.N_5101, strCode: ERPFlagCode.C_5101}, //  1 0 1 0
  {numCode: ERPFlag.N_6011, strCode: ERPFlagCode.C_6011}, //  0 1 1 0
  {numCode: ERPFlag.N_7111, strCode: ERPFlagCode.C_7111}, //  1 1 1 0
  {numCode: ERPFlag.N_8000, strCode: ERPFlagCode.C_8000}, //  0 0 0 1
  {numCode: ERPFlag.N_9100, strCode: ERPFlagCode.C_9100}, //  1 0 0 1
  {numCode: ERPFlag.N_A010, strCode: ERPFlagCode.C_A010}, // 0 1 0 1
  {numCode: ERPFlag.N_B110, strCode: ERPFlagCode.C_B110}, // 1 1 0 1
  {numCode: ERPFlag.N_C001, strCode: ERPFlagCode.C_C001}, // 0 0 1 1 → 0
  {numCode: ERPFlag.N_D101, strCode: ERPFlagCode.C_D101}, // 1 0 1 1 → 0
  {numCode: ERPFlag.N_E011, strCode: ERPFlagCode.C_E011}, // 0 1 1 1 → 0
  {numCode: ERPFlag.N_F111, strCode: ERPFlagCode.C_F111}, // 1 1 1 1 → 0
  {numCode: ERPFlag.N_G000, strCode: ERPFlagCode.C_G000}, // 0 0 0 null
  {numCode: ERPFlag.N_H100, strCode: ERPFlagCode.C_H100}, // 1 0 0 null
  {numCode: ERPFlag.N_I010, strCode: ERPFlagCode.C_I010}, // 0 1 0 null
  {numCode: ERPFlag.N_J110, strCode: ERPFlagCode.C_J110}, // 1 1 0 null
  {numCode: ERPFlag.N_K001, strCode: ERPFlagCode.C_K001}, // 0 0 1 null → 0
  {numCode: ERPFlag.N_L101, strCode: ERPFlagCode.C_L101}, // 1 0 1 null → 0
  {numCode: ERPFlag.N_M011, strCode: ERPFlagCode.C_M011}, // 0 1 1 null → 0
  {numCode: ERPFlag.N_N111, strCode: ERPFlagCode.C_N111}, // 1 1 1 null → 0
];
