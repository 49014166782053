import {useEffect, useState} from 'react';
import TMapSender from '@lcc/tmap-inapp';
import {EAdCode, EAdType} from 'constant/Ads';
import {useAppSelector} from 'ducks/hooks';
import useLogger from 'hooks/useLogger';
import useNaverKeywordAd from 'hooks/useNaverKeywordAd';
import NaverKeyword from 'modules/NaverKeyword';

type TProps = {
  className?: string;
  searchQuery?: string;
};

const SearchNaverAd = ({className, searchQuery}: TProps) => {
  const {isLogInitialized, rdSearch} = useAppSelector((state) => ({
    isLogInitialized: state.log.isInitialize,
    rdSearch: state.search,
  }));

  const {adData: keywordAdData, adError: keywordAdError} = useNaverKeywordAd({
    query: searchQuery,
  });
  const {sendSearchItemAdAppLog, sendClickLog} = useLogger();
  const [isKeywordAdInit, setKeywordAdInit] = useState(false);

  useEffect(() => {
    if (isLogInitialized && isKeywordAdInit && rdSearch.loaded) {
      sendSearchItemAdAppLog({ad_source: EAdType.NAVER});
    }
  }, [isLogInitialized, isKeywordAdInit, rdSearch.loaded, sendSearchItemAdAppLog]);

  if (keywordAdError) {
    return null;
  }

  return (
    <div className={className} key="naver-keyword-ads">
      <NaverKeyword
        data={keywordAdData}
        onInitAd={() => {
          sendClickLog(
            'view.ad',
            {
              index: EAdCode.SEARCH_RESULT_LIST,
              ad_source: EAdType.NAVER,
              url: keywordAdData?.clickUrl,
              search_query: searchQuery,
            },
            {
              includeTicketId: true,
            }
          );
          setKeywordAdInit(true);
        }}
        // onViewAd={handleViewKeywordAd}
        onClickAd={(url) => {
          sendClickLog(
            'tap.ad',
            {
              index: EAdCode.SEARCH_RESULT_LIST,
              ad_source: EAdType.NAVER,
              url,
              search_query: searchQuery,
            },
            {
              includeTicketId: true,
            }
          );
          url && TMapSender.openBrowser(url);
        }}
      />
    </div>
  );
};

export default SearchNaverAd;
