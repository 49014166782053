import {useCallback, useState} from 'react';
import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';

// import {DEFAULT_ZOOM} from 'constant/Map';

import useMap from 'hooks/useMap';
import * as TMapInApp from 'utils/tmapInApp';

const useCurrentPosition = () => {
  const dispatch = useAppDispatch();
  const {userPosition, centerOffset} = useAppSelector((state) => state.map);
  const {moveToCenter} = useMap();
  const [isPending, setPending] = useState<boolean>(false);

  const updatePosition = useCallback(
    (nowPosition, options = {}, disableAutoMove = false) => {
      const position = {
        ...nowPosition,
        // zoom: DEFAULT_ZOOM,
      };
      const disableAnimation = typeof options.speed === 'undefined';

      dispatch(actions.map.setUserPosition(nowPosition));

      !disableAutoMove &&
        moveToCenter(
          position,
          disableAnimation
            ? {animate: false, offset: centerOffset}
            : {speed: options.speed, offset: centerOffset}
        );
    },
    [dispatch, moveToCenter, centerOffset]
  );

  const getLastPosition = useCallback(() => {
    setPending(true);
    return TMapInApp.getLastPosition()
      .then((nowPosition) => {
        setPending(false);
        updatePosition(nowPosition);

        return nowPosition;
      })
      .catch(() => {
        setPending(false);
        updatePosition(userPosition);
      });
  }, [updatePosition, userPosition]);

  const getPosition = useCallback(
    (disableAutoMove = false) => {
      setPending(true);
      return TMapInApp.getCurrentPosition()
        .then((nowPosition) => {
          setPending(false);
          updatePosition(nowPosition, {speed: 3}, disableAutoMove);

          return nowPosition;
        })
        .catch(() => {
          setPending(false);
          updatePosition(userPosition, {speed: 3}, false);
        });
    },
    [updatePosition, userPosition]
  );

  return {
    currentPosition: userPosition,
    getLastPosition,
    getPosition,
    isPending,
  };
};

export default useCurrentPosition;
