import {useEffect} from 'react';
import {EActionId} from 'constant/Log';
import {useAppSelector} from 'ducks/hooks';
import useLogger from './useLogger';

enum EFocusType {
  RESUME = 0,
  REFRESH = 1,
}

type TProps = {
  isLogInitialized: boolean;
};

const useSendPlacePageRefreshLog = ({isLogInitialized}: TProps) => {
  const {sendClickLogWithMapView} = useLogger();
  const {refreshKey, resumeKey} = useAppSelector((state) => state.userInteraction);

  useEffect(() => {
    if (isLogInitialized) {
      sendClickLogWithMapView(
        EActionId.TAP_PLACE_BUTTON,
        {index: EFocusType.REFRESH},
        {includeTicketId: true}
      );
    }
  }, [refreshKey]);

  useEffect(() => {
    if (isLogInitialized) {
      sendClickLogWithMapView(
        EActionId.TAP_PLACE_BUTTON,
        {index: EFocusType.RESUME},
        {includeTicketId: true}
      );
    }
  }, [resumeKey]);
};

export default useSendPlacePageRefreshLog;
