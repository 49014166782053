import {useCallback, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {useLocalStorage} from '@lcc/web-storage';
import {EFavoriteType} from 'types/Search';
import {TActionId} from 'types/Log';
import {ELegacyFavoriteFilter, ELegacyFavoriteSortOption} from 'types/LegacySearch';
import {fetchFavoriteRoute} from 'ducks/userInfo/slice';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import {useAppSelector, useAppDispatch} from 'ducks/hooks';
import {useOnce} from 'hooks/useOnce';
import {StorageKey} from 'constant/Storage';
import Tabs, {TabContent} from 'modules/Tabs';
import {sendSearchClickLog} from 'utils/logManager';
import FavoriteHeader from 'components/legacySearch/FavoriteHeader';
import FavoriteItem from 'components/legacySearch/FavoriteItem';
import FavoriteRouteItem from 'components/legacySearch/FavoriteRouteItem';

import s from 'styles/components/legacySearch/FavoriteTab.module.scss';

const tabList = [ELegacyFavoriteFilter.POI, ELegacyFavoriteFilter.ROUTE];

const sortActionIdMap: Record<ELegacyFavoriteSortOption, TActionId> = {
  [ELegacyFavoriteSortOption.ALPHABET]: 'tap.textorder',
  [ELegacyFavoriteSortOption.REG_DATE]: 'tap.timeorder',
};

type TNoResultProps = {
  inRoute?: boolean;
  isLandscape: boolean;
};

type TProps = {
  onChangeTab: (tab: ELegacyFavoriteFilter) => void;
};

const FavoriteNoResult = ({inRoute, isLandscape}: TNoResultProps) => {
  return (
    <div
      className={classNames(s.no_result, {
        [s.is_landscape]: isLandscape,
      })}
    >
      {inRoute ? '즐겨찾기한 경로가 없습니다.' : '즐겨찾기한 장소가 없습니다.'}
    </div>
  );
};

const FavoriteTab = ({onChangeTab}: TProps) => {
  const dispatch = useAppDispatch();

  const {home, office, favorites, routes, accessKey, routesNeedCall, rdUserInfo, isLandscape} =
    useAppSelector((state) => ({
      home: state.userInfo.personalPlace.data.home,
      office: state.userInfo.personalPlace.data.office,
      favorites: state.userInfo.personalPlace.data.favorites,
      routes: state.userInfo.favoriteRoutes.data,
      routesNeedCall:
        !state.userInfo.favoriteRoutes.loaded && !state.userInfo.favoriteRoutes.loading,
      accessKey: state.userInfo.accessKey,
      rdUserInfo: state.userInfo,
      isLandscape: state.layout.appSize.isLandscape,
    }));

  const [nowSort, setSort] = useLocalStorage<ELegacyFavoriteSortOption>(
    StorageKey.FAVORITE_SORT,
    ELegacyFavoriteSortOption.REG_DATE,
    (storedData) => !Object.values(ELegacyFavoriteSortOption).includes(storedData)
  );

  const [nowTab, setNowTab] = useState<ELegacyFavoriteFilter>();

  const {moveToDetail, moveToSelectDestinationAction, moveToDepartDestination} = useMoveToTarget({
    from: EFromType.FAVORITE,
  });

  const {orderedFavorite, orderedFavoriteRoute} = useMemo(() => {
    if (nowSort === ELegacyFavoriteSortOption.ALPHABET) {
      return {
        orderedFavorite: [...favorites].sort((a, b) => (a.customName > b.customName ? 1 : -1)),
        orderedFavoriteRoute: [...routes].sort((a, b) => {
          if (a.destination.placeName === b.destination.placeName) {
            return a.departure.placeName > b.departure.placeName ? 1 : -1;
          }
          return a.destination.placeName > b.destination.placeName ? 1 : -1;
        }),
      };
    }

    return {
      orderedFavorite: [...favorites].sort((a, b) =>
        (a.regDateTime || '') > (b.regDateTime || '') ? 1 : -1
      ),
      orderedFavoriteRoute: [...routes],
    };
  }, [favorites, routes, nowSort]);

  const isPoiEmpty = useMemo(
    () => !home && !office && favorites.length === 0,
    [home, office, favorites]
  );
  const isRouteEmpty = useMemo(() => orderedFavoriteRoute.length === 0, [orderedFavoriteRoute]);
  const [initTab, setInitTab] = useState<ELegacyFavoriteFilter>();

  const handleChangeTab = useCallback((tab: ELegacyFavoriteFilter) => {
    setNowTab(tab);
  }, []);
  const handleChangeSort = useCallback((sort: ELegacyFavoriteSortOption) => {
    setSort(sort);
    sendSearchClickLog(sortActionIdMap[sort]);
  }, []);

  useOnce(accessKey && routesNeedCall, () => {
    dispatch(fetchFavoriteRoute({accessKey}));
  });

  useOnce(rdUserInfo.favoriteRoutes.loaded && rdUserInfo.personalPlace.loaded, () => {
    setInitTab(isPoiEmpty && !isRouteEmpty ? tabList[1] : undefined);
  });

  useEffect(() => {
    onChangeTab?.(nowTab || ELegacyFavoriteFilter.POI);
  }, [nowTab]);

  return (
    <Tabs values={tabList} initValue={initTab} onChange={handleChangeTab}>
      <FavoriteHeader
        onChangeSort={handleChangeSort}
        sort={nowSort}
        onClick={() => sendSearchClickLog('tap.order')}
        noResult={nowTab === ELegacyFavoriteFilter.POI ? isPoiEmpty : isRouteEmpty}
      />

      <div className={s.search_favorite_wrap}>
        <TabContent tabKey={ELegacyFavoriteFilter.POI}>
          {isPoiEmpty && rdUserInfo.personalPlace.loaded && (
            <FavoriteNoResult isLandscape={isLandscape} />
          )}

          {home && (
            <FavoriteItem
              key={'home'}
              data={home}
              onClick={(poiDataJson) => {
                sendSearchClickLog('tap.home');

                moveToSelectDestinationAction(poiDataJson);
              }}
              type={EFavoriteType.HOME}
            />
          )}

          {office && (
            <FavoriteItem
              key={'office'}
              data={office}
              onClick={(poiDataJson) => {
                sendSearchClickLog('tap.office');

                moveToSelectDestinationAction(poiDataJson);
              }}
              type={EFavoriteType.OFFICE}
            />
          )}

          {orderedFavorite.map((n, i) => (
            <FavoriteItem
              key={`${n.poiId}-${i}`}
              data={n}
              onClick={(poiDataJson) => {
                sendSearchClickLog('tap.bookmark', {
                  list_seq: i,
                  pkey: poiDataJson.pkey,
                  // 1: 가나다순 0: 등록순
                  index: nowSort === ELegacyFavoriteSortOption.ALPHABET ? 1 : 0,
                });

                moveToSelectDestinationAction(poiDataJson);
              }}
              onClickDetail={(data) => {
                sendSearchClickLog('tap.bookmark_info', {
                  list_seq: i,
                  pkey: data.pkey,
                  // 1: 가나다순 0: 등록순
                  index: nowSort === ELegacyFavoriteSortOption.ALPHABET ? 1 : 0,
                });

                moveToDetail(data);
              }}
            />
          ))}
        </TabContent>

        <TabContent tabKey={ELegacyFavoriteFilter.ROUTE}>
          {isRouteEmpty ? (
            <FavoriteNoResult inRoute={true} isLandscape={isLandscape} />
          ) : (
            orderedFavoriteRoute.map((n, i) => (
              <FavoriteRouteItem
                key={`${n.departure.poiId}-${n.destination.poiId}-${i}`}
                data={n}
                onClick={(departure, destination) => {
                  moveToDepartDestination(departure, destination);
                  sendSearchClickLog('tap.bookmark_route', {
                    list_seq: orderedFavoriteRoute.length,
                  });
                }}
              />
            ))
          )}
        </TabContent>
      </div>
    </Tabs>
  );
};

export default FavoriteTab;
