import TMapSender, {
  ECallbackKeys,
  TMapReceiver,
  ERedDotDisplayType,
  ERedDotItemGroup,
  TRedDotItem,
  EDateFormat,
} from '@lcc/tmap-inapp';
import {useAppDispatch} from 'ducks/hooks';
import actions from 'ducks/actions';
import {useCallback, useEffect} from 'react';
import {getSafeDateFormat} from 'utils/date';

// itemCreateDateTime < 현재시각 < expiredDateTime
// 1. itemReadDateTime 이 없는 경우 : new
// 2. itemReadDateTime < itemCreateDateTime : new

export const isUnReadRedDot = (item: TRedDotItem) => {
  const now = Number(getSafeDateFormat(Date.now(), EDateFormat.REDDOT) || 0);
  const isNotRead = !item.readDateTime;
  const isInTime = Number(item.startDateTime || 0) < now && now < Number(item.expiredDateTime || 0);

  return isNotRead && isInTime;
};

export const usePlaceCategoryRedDot = () => {
  const dispatch = useAppDispatch();

  const updateItemList = useCallback(() => {
    TMapReceiver.setCallback(ECallbackKeys.GET_RED_DOT_LIST, (list: TRedDotItem[]) => {
      const activeRedDotItemList = (list || [])
        .filter(
          (item) =>
            item.itemGroup === ERedDotItemGroup.PLACE &&
            item.displayType === ERedDotDisplayType.REDDOT &&
            isUnReadRedDot(item)
        )
        .map((item) => item.item);

      dispatch(actions.category.setVisibleRedDotItemName(activeRedDotItemList));
    });

    TMapSender.getRedDotList();
  }, [dispatch]);

  const updateItemStatus = useCallback(
    (redDotName: string) => {
      const readDateTime = `${Number(getSafeDateFormat(Date.now(), EDateFormat.REDDOT))}`;

      TMapSender.updateRedDotList([
        {
          itemGroup: ERedDotItemGroup.PLACE,
          item: redDotName,
          readDateTime,
          displayType: ERedDotDisplayType.REDDOT,
        },
      ]);

      setTimeout(() => {
        updateItemList();
      }, 500);
    },
    [updateItemList]
  );

  useEffect(() => {
    updateItemList();
  }, []);

  return {
    updateItemList,
    updateItemStatus,
  };
};
