import {useState, useMemo, useRef, useLayoutEffect, useEffect, useCallback} from 'react';
import {useDrag} from 'react-use-gesture';
import {times} from 'utils/lodash';
import {useAppSelector} from 'ducks/hooks';
import s from 'styles/components/tnow/TNowSlider.module.scss';

const SLIDER_PADDING = 20;
const HANDLE_RADIUS = 10;

type TProps = {
  list: string[];
  initIndex?: number;
  onSelect?: (selectedIndex: number) => void;
};

const TNowSlider = ({list, initIndex = 0, onSelect}: TProps) => {
  const {windowSize, webSize, appSize} = useAppSelector((state) => state.layout);
  const barRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const [posX, setPosX] = useState(0);
  const [barWidth, setBarWidth] = useState(0);

  const maxUnitCount = useMemo(() => list.length - 1, [list]);
  const boundaries = useMemo(
    () => [
      ...times(maxUnitCount).map(
        (_, i) => i * (barWidth / maxUnitCount) + (i === 0 ? HANDLE_RADIUS : 0)
      ),
      barWidth - HANDLE_RADIUS,
    ],
    [barWidth, maxUnitCount]
  );

  const averageBoundaries = useMemo(
    () => boundaries.map((b, i) => (b + boundaries[Math.max(i - 1, 0)]) / 2),
    [boundaries]
  );

  const updateLastPosition = useCallback(
    (x) => {
      let lastIndex = 0;

      averageBoundaries.forEach((boundary, i) => {
        if (boundary < x) {
          lastIndex = i;
        }
      });

      setPosX(boundaries[lastIndex]);
      onSelect?.(lastIndex);
    },
    [onSelect, boundaries, averageBoundaries]
  );

  const barBind = useDrag(
    useCallback(
      ({down, last, xy: [x]}) => {
        const barPosition = Math.min(
          Math.max(x - webSize.listLeft - SLIDER_PADDING, HANDLE_RADIUS),
          barWidth - HANDLE_RADIUS
        );

        setPosX(barPosition);

        if (last || (!down && !last)) {
          updateLastPosition(barPosition);
        }
      },
      [webSize, barWidth, updateLastPosition]
    ),
    {
      axis: 'x',
      initial: () => [posX, 0],
    }
  );

  useLayoutEffect(() => {
    window.setTimeout(() => {
      setBarWidth(barRef.current?.offsetWidth || 0);
    }, 0);
  }, [windowSize, appSize.isLandscape]);

  useEffect(() => {
    setPosX(boundaries[initIndex]);
  }, [initIndex, barWidth, boundaries]);

  const handleClickLabel = useCallback(
    (index) => {
      setPosX(boundaries[index]);
      onSelect?.(index);
    },
    [boundaries, onSelect]
  );

  return (
    <div className={s.slider_container}>
      <div className={s.bar_wrap} {...barBind()}>
        <div className={s.status_wrap}>
          <div className={s.bar} ref={barRef} />
          <div className={s.status} style={{width: posX}} />
          <div className={s.delimiter_wrap}>
            {times(4).map((_, i) => (
              <div key={i} className={s.delimiter} />
            ))}
          </div>
        </div>
        <div ref={handleRef} className={s.handle_wrap} style={{transform: `translateX(${posX}px)`}}>
          <div className={s.handle} />
        </div>
      </div>

      <div className={s.radius_wrap}>
        {list.map((v, i) => (
          <div key={v} className={s.item} onClick={() => handleClickLabel(i)}>
            {v}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TNowSlider;
