import s from 'styles/components/BuildInfo.module.scss';
import LocalStorage from '@lcc/web-storage';
import {useCallback, useEffect, useState} from 'react';
import {DEV_LOG_STACK, renderStackView} from 'utils/dev';

export const BuildInfoStackViewToggle = () => {
  const [isShow, setShow] = useState(!!LocalStorage.getItem(DEV_LOG_STACK));
  const toggleStackView = useCallback((beShow) => {
    const view = renderStackView();

    if (view) {
      view.style.display = beShow ? '' : 'none';
    }
  }, []);

  useEffect(() => {
    toggleStackView(isShow);
  }, []);

  return (
    <div>
      <p className={s.btn_wrap}>
        <button
          onClick={() => {
            const nextVisible = !isShow;

            LocalStorage.setItem(DEV_LOG_STACK, nextVisible);
            toggleStackView(nextVisible);
            setShow(nextVisible);
          }}
        >
          {isShow ? '숨기기' : '보이기'}
        </button>
      </p>
    </div>
  );
};
