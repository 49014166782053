import {times} from 'utils/lodash';
import s from 'styles/components/tplacehome/TPlaceSkeleton.module.scss';

const AroundMeSkeleton = () => {
  return (
    <div className={s.wrap}>
      {times(2).map((n) => (
        <div className={s.around_me_content_item} key={n}>
          <div className={s.title} />
          <div className={s.desc} />
        </div>
      ))}
    </div>
  );
};

export default AroundMeSkeleton;
