import {useEffect, useRef, useState} from 'react';
import axios, {CancelTokenSource} from 'axios';

import {TApiStatus} from 'types/Api';
import {TSuggestResponse} from 'types/Search';

import {useAppSelector} from 'ducks/hooks';

import {SUGGEST_API_PATH, SUGGEST_TIMEOUT} from 'constant/Api';
import {SUGGEST_API_HOST} from 'constant/Path';
import {APP_SERVER_NAME} from 'constant/Env';

import {getDefaultApiStatus} from 'utils/apis';
import {fetcherExt} from 'utils/fetcher';

const DEFAULT_STATUS = getDefaultApiStatus<Nullable<TSuggestResponse>>(null);

const defaultSuggestResult: TSuggestResponse = {
  returnCode: 0,
  totalTime: 0,
  totalCount: 0,
  v1_suggestions: {
    totalCount: 0,
    suggestions: [],
  },
  v2_suggestions: {
    totalCount: 0,
    suggestions: [],
  },
};

type TProps = {
  query: string;
};

export const useSearchSuggest = ({query}: TProps) => {
  const [suggestState, setState] = useState<TApiStatus<Nullable<TSuggestResponse>>>(DEFAULT_STATUS);
  const refCancelToken = useRef<Nullable<CancelTokenSource>>(null);
  const {accessKey, userPosition} = useAppSelector((state) => ({
    accessKey: state.userInfo.accessKey,
    userPosition: state.map.userPosition,
  }));

  useEffect(() => {
    if (!query.trim()) {
      setState({
        loaded: true,
        loading: false,
        data: defaultSuggestResult,
        error: undefined,
      });

      return;
    }

    setState((prev) => ({
      ...prev,
      loading: true,
      loaded: false,
    }));

    refCancelToken.current = axios.CancelToken.source();

    const params = {
      appKey: accessKey,
      client_code: `SEARCH_WEB_${APP_SERVER_NAME}`,
      s: 'tmap',
      q: query.trim(),
      ...(userPosition ? userPosition : {}),
    };

    fetcherExt
      .get(`${SUGGEST_API_HOST}${SUGGEST_API_PATH.GET_SUGGEST}`, {
        params: params,
        cancelToken: refCancelToken.current.token,
        timeout: SUGGEST_TIMEOUT,
      })
      .then((res) => {
        if (res.data) {
          setState({
            loaded: true,
            loading: false,
            data: res.data.response,
            error: undefined,
          });
        } else {
          throw new Error('No Data');
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        setState({
          loaded: true,
          loading: false,
          data: null,
          error: e.message,
        });
      });

    return () => {
      // hide시 query가 ''으로 바뀌면서 cancel 호출됨
      refCancelToken.current?.cancel();
      refCancelToken.current = null;
    };
  }, [query, accessKey, userPosition]);

  return suggestState;
};

export default useSearchSuggest;
