import {PlaceCategoryLink} from 'components/PlaceCategoryLink';
import {useAppSelector} from 'ducks/hooks';
import useLogger from 'hooks/useLogger';
import s from 'styles/components/search/SearchQuickLinkList.module.scss';

const SearchQuickLinkList = () => {
  const {sendQuickLinkClickLog} = useLogger();
  const remoteConfig = useAppSelector((state) => state.remote);

  if ((remoteConfig.lastUpdateTime || 0) < 1) {
    return (
      <div className={s.quick_link}>
        <span className={s.item} />
        <span className={s.item} />
        <span className={s.item} />
      </div>
    );
  }

  return (
    <ul className={s.list}>
      {remoteConfig.searchMainCategoryLink.map(({type, ...c}) => {
        // TODO: type RANKING으로 정하고 빼기
        return (
          <li key={type} className={s.item}>
            <PlaceCategoryLink
              type={type}
              className={s.link}
              onClick={() => {
                sendQuickLinkClickLog({search_query: type});
              }}
              queries={{
                tailParam: {log_all_referrer: 'search_home'},
              }}
            >
              <span>{c.label}</span>
            </PlaceCategoryLink>
          </li>
        );
      })}
    </ul>
  );
};

export default SearchQuickLinkList;
