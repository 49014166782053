import {useState, useCallback, useMemo, useEffect} from 'react';
import classNames from 'classnames';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {getDistance} from 'utils/formatter';
import actions from 'ducks/actions';
import {EDateFormat} from 'types/DateTime';
import {getSafeDateFormat} from 'utils/date';
import useThrottle from 'hooks/useThrottle';
import {NO_ADDRESS} from 'constant/Text';
import {useTooltipContext} from 'context/TooltipContext';
import {getAddressBySejongRule} from 'utils/general';
import {SLIDER_LIST, WHOLE_AREA} from 'ducks/tnow/types';
import TNowSlider from 'components/tnow/TNowSlider';
import Skeleton from 'components/Skeleton';
import {EListMode} from 'types/ListDrawer';
import {EPlaceCategoryType, ESkeletonType} from 'types/App';
import {TActionId} from 'types/Log';
import useTNowSearch from 'hooks/useTNowSearch';
import useLogger from 'hooks/useLogger';
import useReverseGeocoding from 'hooks/useReverseGeocoding';
import {ETNowActionId, NATIONAL_VALUE} from 'constant/Log';

import {ReactComponent as IconArrowDown} from 'resource/images/@tmds_element/ico_arrow_down_bold.svg';
import {ReactComponent as IconRefresh} from 'resource/images/@tmds_basic/ico_refresh.svg';

import s from 'styles/components/tnow/TNowHeader.module.scss';

type TCategoryItem = {
  text: string;
  category?: EPlaceCategoryType;
  actionId: TActionId;
};
const CATEGORY_LIST: TCategoryItem[] = [
  {
    text: '전체',
    category: undefined,
    actionId: 'tap.category.all',
  },
  {
    text: '음식점',
    category: EPlaceCategoryType.FOOD_ALL,
    actionId: 'tap.category.food',
  },
  {
    text: '카페',
    category: EPlaceCategoryType.FOOD_CAFE,
    actionId: 'tap.category.cafe',
  },
  {
    text: '관광명소',
    category: EPlaceCategoryType.TOUR_ALL,
    actionId: 'tap.category.tour',
  },
  {
    text: '숙소',
    category: EPlaceCategoryType.HOTL_ALL,
    actionId: 'tap.category.hotel',
  },
];

type TProps = {
  nowCategory?: EPlaceCategoryType;
  onChangeCategory?: (category?: EPlaceCategoryType) => void;
};

const TNowHeader = ({nowCategory, onChangeCategory}: TProps) => {
  const dispatch = useAppDispatch();
  const {hideTooltip} = useTooltipContext();
  const ableToRun = useThrottle();
  const {sendClickLogWithMapView} = useLogger();
  const {fetchNewRadius, refreshSearch} = useTNowSearch();

  const {
    tNow: {baseSpot, result},
    drawerMode,
    isLandscape,
  } = useAppSelector((state) => ({
    tNow: state.tNow,
    drawerMode: state.userInteraction.drawerMode,
    isLandscape: state.layout.appSize.isLandscape,
  }));
  const {radius, currentAddressName: currentAddress} = baseSpot;
  const [isRadiusOpen, setRadiusOpen] = useState<boolean>(false);

  const geoResult = useReverseGeocoding({
    lat: baseSpot.lat,
    lon: baseSpot.lon,
    refreshKey: baseSpot.refreshKey,
  });

  const initialRadiusIndex = useMemo(() => {
    const index = SLIDER_LIST.findIndex((slider) => slider === radius);

    return index === -1 ? SLIDER_LIST.length - 1 : index;
  }, [radius]);

  useEffect(() => {
    if (isRadiusOpen) {
      dispatch(actions.layout.getListLeft());
    }
  }, [isRadiusOpen, isLandscape, dispatch]);

  useEffect(() => {
    if (geoResult.loading) {
      return;
    }

    const nowAddress = getAddressBySejongRule(geoResult.data);

    dispatch(actions.tNow.setBaseSpot({currentAddressName: nowAddress}));
  }, [geoResult, dispatch]);

  const centerDrawer = useCallback(() => {
    if (drawerMode === EListMode.BOTTOM) {
      dispatch(actions.userInteraction.setInteraction({drawerMode: EListMode.CENTER}));
    }
  }, [drawerMode, dispatch]);

  const handleClickReload = useCallback(() => {
    if (ableToRun()) {
      refreshSearch();
      sendClickLogWithMapView(ETNowActionId.REFRESH, {}, {includeTicketId: true});
    }
  }, [ableToRun, refreshSearch, sendClickLogWithMapView]);

  const handleClickOpenRadius = useCallback(() => {
    currentAddress && setRadiusOpen((prev) => !prev);
    centerDrawer();
    hideTooltip();
    sendClickLogWithMapView(ETNowActionId.RADIUS);
  }, [currentAddress, hideTooltip, centerDrawer, sendClickLogWithMapView]);

  const handleChangeCategory = useCallback(
    ({category, actionId}: TCategoryItem) => {
      sendClickLogWithMapView(actionId);
      onChangeCategory?.(category);
    },
    [sendClickLogWithMapView, onChangeCategory]
  );

  useEffect(() => {
    setRadiusOpen(false);
  }, [baseSpot]);

  return (
    <Skeleton type={ESkeletonType.TNOW_HEADER} apiStatus={result}>
      <div
        className={classNames(s.header_wrap, {
          [s.slider_open]: isRadiusOpen,
        })}
      >
        <div className={s.title_wrap}>
          <div className={s.address_wrap}>
            {radius !== WHOLE_AREA && (
              <span className={classNames(s.title, {[s.no_address]: !currentAddress})}>
                {currentAddress || NO_ADDRESS}
              </span>
            )}

            <button
              className={classNames(s.radius, {[s.no_address]: !currentAddress})}
              onClick={handleClickOpenRadius}
            >
              {radius !== WHOLE_AREA ? (
                <div className={s.text}>
                  주변
                  <span className={s.distance}>{getDistance(radius)}</span>
                </div>
              ) : (
                '전국'
              )}
              <IconArrowDown
                className={classNames(s.fold_icon, {
                  [s.is_open]: isRadiusOpen,
                })}
              />
            </button>
          </div>

          {!result.error && (
            <button
              className={classNames(s.refresh, {[s.is_loading]: result.loading})}
              onClick={handleClickReload}
            >
              {getSafeDateFormat(result.data.latestUpdateTime, EDateFormat.HHmm)}
              <IconRefresh />
            </button>
          )}
        </div>

        {isRadiusOpen && (
          <div className={s.radius_slider_wrap}>
            <TNowSlider
              list={SLIDER_LIST.map((v) => (v === WHOLE_AREA ? '전국' : getDistance(v)))}
              initIndex={initialRadiusIndex}
              onSelect={(selectedIndex) => {
                const newRadius = SLIDER_LIST[selectedIndex];

                fetchNewRadius(newRadius);
                setRadiusOpen(false);
                sendClickLogWithMapView(
                  ETNowActionId.SLIDER,
                  {radius: newRadius === Infinity ? NATIONAL_VALUE : newRadius},
                  {includeTicketId: true}
                );
              }}
            />
          </div>
        )}

        <div className={s.category_wrap}>
          {CATEGORY_LIST.map((v) => (
            <button
              key={v.text}
              className={classNames(s.item, {
                [s.is_selected]: v.category === nowCategory,
              })}
              onTouchEnd={() => handleChangeCategory(v)}
            >
              {v.text}
            </button>
          ))}
        </div>
      </div>
    </Skeleton>
  );
};

export default TNowHeader;
