export const firstCharToUppercase = (str = '') => {
  const [first, ...rest] = str;

  return `${first.toUpperCase()}${rest.join('')}`;
};

export const firstCharToLowerCase = (str = '') => {
  const [first, ...rest] = str;

  return `${first.toLowerCase()}${rest.join('')}`;
};

export const getValueToString = (value: any): string =>
  typeof value === 'boolean' ? value.toString() : `${value}`;

export const filteredJoinText = (
  texts: (string | number | undefined | null)[],
  joinText?: string
) => texts.filter((n) => n).join(joinText || ' ');

export const removeSpecialCharacter = (str) => {
  return str.replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g, '');
};

export const removePrefix = (prefix, str) => {
  return str.replace(prefix, '');
};
