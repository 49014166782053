import {createContext, ReactNode, useContext} from 'react';
import {TListDrawerResult} from 'types/ListDrawer';

type TProps = {
  drawerProps: TListDrawerResult;
  children: ReactNode;
};

type TContextValue = {
  drawerProps?: TListDrawerResult;
};

const DrawerContext = createContext<TContextValue>({
  drawerProps: undefined,
});

export const useDrawerConsumer = () => useContext(DrawerContext);

export const DrawerProvider = ({drawerProps, children}: TProps) => {
  return <DrawerContext.Provider value={{drawerProps}}>{children}</DrawerContext.Provider>;
};
