import {useCallback} from 'react';
import actions from 'ducks/actions';
import LocalStorage from '@lcc/web-storage';
import {HybridBridge} from 'utils/searchBar';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';

import {StorageKey} from 'constant/Storage';
import {EMPTY_ARRAY} from 'constant/Vars';
import {SEARCH_RECENT_MAX_COUNT} from 'constant/Search';

import {getGeneralTime} from 'utils/date';
import {TQueryItem} from '@lcc/tmap-inapp';

const useRecentQuery = () => {
  const dispatch = useAppDispatch();
  const {isHybrid, recentQueryItems} = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
    recentQueryItems: state.userInfo.recentQueries.items,
  }));

  const getLocalItems = useCallback((): TQueryItem[] => {
    const result: Array<{name: string; date: string}> = LocalStorage.getItem(
      StorageKey.RECENT_QUERY
    );

    return result
      ? result
          .filter((n) => n.name)
          .map((n) => ({
            query: n.name,
            date: n.date,
          }))
      : [];
  }, []);

  const getItems = useCallback((): TQueryItem[] => {
    if (isHybrid) {
      return recentQueryItems;
    }

    return getLocalItems();
  }, [isHybrid, recentQueryItems, getLocalItems]);

  const updateLocalItems = useCallback(
    (newQueryList = EMPTY_ARRAY) => {
      if (!isHybrid) {
        dispatch(actions.userInfo.setRecentQueries(newQueryList));
      }
    },
    [isHybrid, dispatch]
  );

  const addItem = useCallback(
    (query: string) => {
      if (!query) {
        return;
      }

      if (isHybrid) {
        HybridBridge.addRecentQuery([
          {
            query,
            date: getGeneralTime(),
          },
        ]);
        HybridBridge.getSearchContext();
      }

      const nextData: TQueryItem[] = getLocalItems();
      let needAdd = true;

      nextData.forEach((n) => {
        if (n.query === query) {
          needAdd = false;
          n.date = getGeneralTime();
        }
      });

      if (needAdd) {
        nextData.push({
          query,
          date: getGeneralTime(),
        });
      }

      const newQueryList = nextData
        .sort((a, b) => (a.date < b.date ? 1 : -1))
        .slice(0, 100)
        .map((n) => ({
          name: n.query,
          date: n.date,
        }));

      LocalStorage.setItem(StorageKey.RECENT_QUERY, newQueryList);

      !isHybrid && updateLocalItems(newQueryList);
    },
    [getLocalItems, isHybrid, updateLocalItems]
  );

  const removeExcessQueries = useCallback((queries) => {
    if (queries.length <= SEARCH_RECENT_MAX_COUNT) {
      return [];
    }

    const excessCount = queries.length - SEARCH_RECENT_MAX_COUNT;
    const sortedQueries = [...queries].sort((a, b) => (a.date > b.date ? 1 : -1));
    const itemsToRemove = sortedQueries.slice(0, excessCount);

    return itemsToRemove;
  }, []);

  const removeItem = useCallback(
    (query) => {
      if (isHybrid) {
        const excessItems = removeExcessQueries(recentQueryItems);
        const targetItem = recentQueryItems.find((n) => n.query === query);

        if (targetItem) {
          HybridBridge.deleteRecentQueries([targetItem, ...excessItems]);
        }
        HybridBridge.getSearchContext();
      }

      const nextData: TQueryItem[] = getLocalItems().filter((n) => n.query && n.query !== query);
      const newQueryList = nextData.map((n) => ({
        query: n.query,
        name: n.query,
        date: n.date,
      }));

      LocalStorage.setItem(StorageKey.RECENT_QUERY, newQueryList);

      !isHybrid && updateLocalItems(newQueryList);
    },
    [getLocalItems, isHybrid, recentQueryItems, updateLocalItems, removeExcessQueries]
  );

  const removeItems = useCallback(
    (queries) => {
      if (isHybrid) {
        const excessItems = removeExcessQueries(recentQueryItems);
        const removedItems = [...queries, ...excessItems];

        HybridBridge.deleteRecentQueries(removedItems);
        HybridBridge.getSearchContext();
      }

      const newQueryList = getLocalItems().reduce(
        (acc: Array<{query: string; name: string; date: string}>, n) => {
          if (n.query && !queries.some((q) => q.query === n.query)) {
            acc.push({
              query: n.query,
              name: n.query,
              date: n.date,
            });
          }

          return acc;
        },
        []
      );

      LocalStorage.setItem(StorageKey.RECENT_QUERY, newQueryList);

      !isHybrid && updateLocalItems(newQueryList);
    },
    [isHybrid, recentQueryItems, getLocalItems, updateLocalItems, removeExcessQueries]
  );

  const removeAll = useCallback(() => {
    if (isHybrid) {
      HybridBridge.deleteAllRecentQueries();
      HybridBridge.getSearchContext();
    }

    LocalStorage.setItem(StorageKey.RECENT_QUERY, EMPTY_ARRAY);

    !isHybrid && updateLocalItems();
  }, [isHybrid, updateLocalItems]);

  return {
    getLocalItems,
    getItems,
    updateLocalItems,
    addItem,
    removeItem,
    removeItems,
    removeAll,

    recentQueries: recentQueryItems,
  };
};

export default useRecentQuery;
