import {AdBanner, TAdOption} from 'components/AdBanner';
import {
  EAdCode,
  EAdType,
  PLACE_BANNER_DEFAULT_AD_STEP,
  TEST_BANNER_PROVIDER_CONFIG,
  isBannerTestEnv,
} from 'constant/Ads';
import {useAppSelector} from 'ducks/hooks';

type TProps = {
  adOption: TAdOption;
  adCode: EAdCode;
  isLogActive: boolean;
  adStep?: EAdType[];
};

const TPlaceAdBanner = ({
  adStep = PLACE_BANNER_DEFAULT_AD_STEP,
  adOption,
  adCode,
  isLogActive,
}: TProps) => {
  const {map} = useAppSelector((state) => state);

  return (
    <AdBanner
      visibleLandscape={true}
      disableCoords={true}
      adCode={adCode}
      adTypeStep={adStep}
      adTypeOption={{...adOption, ...(isBannerTestEnv ? TEST_BANNER_PROVIDER_CONFIG : {})}}
      bannerLonLat={map.lastCachedCenter}
      isLogActive={isLogActive}
    />
  );
};

export default TPlaceAdBanner;
