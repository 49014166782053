import {forwardRef, ForwardedRef, useEffect, useState, SyntheticEvent} from 'react';
import classNames, {Argument as ClassNameType} from 'classnames';
import {DEFAULT_LOZAD_CLASSNAME, observeImage} from 'utils/imageObserver';
import ImgDefaultImage from 'resource/images/default_image@3x.png';
import s from 'styles/components/ImageLoader.module.scss';

type TProps = {
  ref?: ForwardedRef<HTMLImageElement>;
  className?: ClassNameType;
  toggleClass?: ClassNameType;
  src: string;
  alt: string;
  showDefaultOnError?: boolean;
  onError?: VoidFunction;
  onLoad?: (e: SyntheticEvent<HTMLImageElement, Event>) => void;
};

const ImageLoader = ({
  className,
  toggleClass,
  ref,
  src,
  alt,
  showDefaultOnError = false,
  onError,
  onLoad,
}: TProps) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    observeImage(DEFAULT_LOZAD_CLASSNAME);
  }, []);

  useEffect(() => {
    if (!src) {
      onError?.();
      setHasError(true);
    }
  }, []);

  if (hasError && showDefaultOnError) {
    return (
      <img
        className={classNames(DEFAULT_LOZAD_CLASSNAME, toggleClass, className, s.default)}
        src={ImgDefaultImage}
        alt={alt}
      />
    );
  }
  if (hasError) {
    return null;
  }

  return (
    <img
      {...(ref && {ref})}
      className={classNames(DEFAULT_LOZAD_CLASSNAME, className)}
      data-src={src}
      data-toggle-class={toggleClass}
      alt={alt}
      onLoad={(e) => onLoad?.(e)}
      onError={() => {
        onError?.();
        setHasError(true);
      }}
    />
  );
};

export default forwardRef<HTMLImageElement, TProps>((props, ref) => (
  <ImageLoader {...props} ref={ref} />
));
