import {IcoRefresh} from 'components/@tmds/icons/IcoRefresh';
import s from 'styles/components/tplacehome/TPlacePopupBase.module.scss';

type TProps = {
  onClickReset?: VoidFunction;
  onClickConfirm?: VoidFunction;
  resetText?: string;
  confirmText?: string;
};

const BottomSheetPopupButtonSet = ({
  resetText = '초기화',
  confirmText = '적용',
  onClickReset,
  onClickConfirm,
}: TProps) => {
  return (
    <div className={s.bottom_wrap}>
      <div className={s.buttons}>
        <button className={s.reset} onClick={onClickReset}>
          <div style={{marginRight: '8px', display: 'flex'}}>
            <IcoRefresh width={24} height={24} />
          </div>
          {resetText}
        </button>
        <button className={s.confirm} onClick={onClickConfirm}>
          {confirmText}
        </button>
      </div>
    </div>
  );
};

export default BottomSheetPopupButtonSet;
