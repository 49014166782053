import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';

import layoutSlice from 'ducks/layout/slice';
import tNowSlice from 'ducks/tnow/slice';
import mapSlice from 'ducks/map/slice';
import placeSlice from 'ducks/place/slice';
import userInfoSlice from 'ducks/userInfo/slice';
import userInteractionSlice from 'ducks/userInteraction/slice';
import logSlice from 'ducks/log/slice';
import searchSlice from 'ducks/search/slice';
import categorySlice from 'ducks/category/slice';
import remoteSlice from 'ducks/remote/slice';
import searchRecommendSlice from './searchRecommend/slice';
import tRankSlice from './rank/slice';
import tplacehomeSlice from './tplacehome/slice';

export const store = configureStore({
  reducer: {
    layout: layoutSlice.reducer,
    tNow: tNowSlice.reducer,
    tplacehome: tplacehomeSlice.reducer,
    place: placeSlice.reducer,
    map: mapSlice.reducer,
    userInfo: userInfoSlice.reducer,
    userInteraction: userInteractionSlice.reducer,
    log: logSlice.reducer,
    search: searchSlice.reducer,
    category: categorySlice.reducer,
    remote: remoteSlice.reducer,
    searchRecommend: searchRecommendSlice.reducer,
    tRank: tRankSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type StoreState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  StoreState,
  unknown,
  Action<string>
>;
