import {TBusArrivalInfo} from 'types/PublicTrans';
import {TLonLat} from 'types/Map';
import {useAppSelector} from 'ducks/hooks';
import {getDistanceBetweenCoords} from 'utils/map';
import {IcoLineBetween} from 'components/@tmds/icons/IcoLineBetween';

import s from 'styles/components/CalloutInfoBusStation.module.scss';

type TProps = {
  data?: TBusArrivalInfo;
  lonLat: TLonLat;
};

const CalloutInfoBusStation = ({data, lonLat}: TProps) => {
  const userPosition = useAppSelector((state) => state.map.userPosition);
  const {busStationDetail} = data || {};
  const km = userPosition ? getDistanceBetweenCoords(userPosition, lonLat) : 0;
  const distance = km > 1 ? km : km * 1000;

  if (!busStationDetail) {
    return null;
  }

  return (
    <div className={s.wrap}>
      <span>
        {distance.toFixed(km > 1 ? 1 : 0)}
        {km > 1 ? 'km' : 'm'}
      </span>
      <IcoLineBetween color="gray200" width={14} height={14} />
      <span className={s.ellipsis}>
        ({busStationDetail.displayId}) {busStationDetail.direction}
        {busStationDetail.direction && '방면'}
      </span>
    </div>
  );
};

export default CalloutInfoBusStation;
