import {TAccommodationInfo} from 'types/App';
import {TRouter} from 'types/Router';

import {firstCharToUppercase} from 'utils/string';

export const setComma = (inputValue?: number | string | null): string => {
  if (inputValue === null || inputValue === undefined) {
    return '';
  }

  const reg: RegExp = /(^[+-]?\d+)(\d{3})/;
  let result: string = `${inputValue}`;

  while (reg.test(result)) {
    result = result.replace(reg, '$1,$2');
  }

  return result;
};

export const getDistance = (distance, kmFixedDigit = 0) => {
  if (isNaN(distance)) {
    return '';
  }

  const roundDistance = Math.round(distance);
  const isKm = roundDistance >= 1000;
  const unit = isKm ? 'km' : 'm';
  let d: number | string = roundDistance;

  if (isKm) {
    const km = (roundDistance / 1000).toFixed(kmFixedDigit);

    d = km.replace('.0', '');
  }

  return `${setComma(d)}${unit}`;
};

export const getTime = (minute) => {
  if (!minute) {
    return;
  }

  if (minute < 60) {
    return `${minute}분`;
  }

  const hour = parseInt(`${minute / 60}`, 10);
  if (hour < 24) {
    return `${hour}시간`;
  }

  const day = parseInt(`${hour / 24}`, 10);
  const week = parseInt(`${day / 7}`, 10);
  if (day < 7) {
    return `${day}일`;
  }
  if (day < 30) {
    return `${week}주`;
  }

  const month = parseInt(`${day / 30}`, 10);
  if (day < 365) {
    return `${month}개월`;
  }

  const year = parseInt(`${day / 365}`, 10);
  return `${year}년`;
};

export const getPageTitle = (routes: TRouter[] = [], pathname) => {
  const matched = routes.find((r) => pathname === r.path);
  const pathnameToTitle = (p = '') =>
    p
      .split('/')
      .filter((v) => !!v)
      .map((v) => firstCharToUppercase(v))
      .join(' ');

  return matched?.title ?? pathnameToTitle(pathname);
};

export const getComparedPrice = (minPrice, maxPrice, text) =>
  `${text} ${setComma(minPrice)}원${minPrice < maxPrice ? '~' : ''}`;

export const getOndaPrefix = (data: TAccommodationInfo) => {
  if (data?.isTmapOnlyAndLowestPrice) {
    return '티맵단독';
  }

  if (data?.isLowestPrice) {
    return '최저가';
  }

  return '';
};
