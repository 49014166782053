// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcoArrowRightSvg} from 'resource/@tmdsicon/ico_arrow_right.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcoArrowRight = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcoArrowRightSvg {...props} />} />;
};

export {IcoArrowRight};
