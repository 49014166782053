// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcArrowRightBoldSvg} from 'resource/@tmdsicon/v1.2/ic_arrow_right_bold.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcArrowRightBold = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcArrowRightBoldSvg {...props} />} />;
};

export {IcArrowRightBold};
