import imgDomain from 'resource/pubImages/tlogo.png';
import imgDataUri from 'resource/images/tlogo.png';

const ProtoPubImage = () => {
  return (
    <div style={{display: 'flex'}}>
      <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
        <h3>static url방식 pubImages 폴더</h3>
        <img src={imgDomain} width="100" />
        <div>{imgDomain}</div>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
        <h3>dataURI방식 그외모든폴더</h3>
        <img src={imgDataUri} width="100" />
        <p style={{wordBreak: 'break-all'}}>{imgDataUri}</p>
      </div>
    </div>
  );
};

export default ProtoPubImage;
