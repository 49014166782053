import {Fragment, useCallback, useMemo} from 'react';
import {ECategoryType} from 'ducks/search/types';
import classNames from 'classnames';
import {ReactComponent as IconFilterOff} from 'resource/images/icon_filter_off.svg';
import {ReactComponent as IconFilterOn} from 'resource/images/icon_filter_on.svg';
import useDimmedQuery from 'hooks/useDimmedQuery';
import {ReactComponent as IconDivider} from 'resource/images/divider.svg';
import {ECategoryCode} from 'constant/Place';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import FilterBottomPopup, {EFilter} from 'components/FilterBottomPopup';
import {EAddressMode} from 'types/Search';
import {THREE_DEPTH_REGION} from './SearchRankingDropDown';
import useLogger from 'hooks/useLogger';
import {TActionId} from 'types/Log';

import s from 'styles/components/search/SearchRankingHeaderQuickFilter.module.scss';

export type TFilter = {
  label: string;
  key: EFilter | ECategoryCode;
};

const FILTER_MAP: Partial<Record<EFilter | ECategoryCode, TFilter>> = {
  [EFilter.OPEN_NOW]: {
    label: '영업중',
    key: EFilter.OPEN_NOW,
  },
  [EFilter.WAITING_RESERVATION]: {
    label: '예약/웨이팅',
    key: EFilter.WAITING_RESERVATION,
  },
  [EFilter.POI_PARK]: {
    label: '주차',
    key: EFilter.POI_PARK,
  },
  [ECategoryCode.POPULAR_FOOD]: {
    label: '음식점',
    key: ECategoryCode.POPULAR_FOOD,
  },
  [ECategoryCode.POPULAR_CAFE]: {
    label: '카페',
    key: ECategoryCode.POPULAR_CAFE,
  },
  [ECategoryCode.POPULAR_BAR]: {
    label: '술집',
    key: ECategoryCode.POPULAR_BAR,
  },
};

const FILTER_LOG_MAP: Partial<Record<EFilter | ECategoryCode, TActionId>> = {
  [EFilter.OPEN_NOW]: 'tap.filter_open',
  [EFilter.WAITING_RESERVATION]: 'tap.filter_reserve_waiting',
  [EFilter.POI_PARK]: 'tap.filter_parking',
  [ECategoryCode.POPULAR_FOOD]: 'tap.filter_food',
  [ECategoryCode.POPULAR_CAFE]: 'tap.filter_cafe',
  [ECategoryCode.POPULAR_BAR]: 'tap.filter_alcohol',
};

type TProps = {
  onChange?: (filter: string, isOn: boolean) => void;
  onChangeCategory?: (category: ECategoryType[]) => void;
  onClickConfirm?: (
    filters: {[key: string]: boolean},
    categories: ECategoryType[],
    filterLog: {[key: string]: boolean}
  ) => void;
};

export type TFilterProps = {
  label: string;
  apiParam: Nullable<string>;
  category: Nullable<ECategoryType>;
  key: EFilter | ECategoryType;
};

type TFilterComponentProps = Partial<TFilterProps> & {
  filterState: boolean;
  handler: () => void;
};

const getFilterComponent = ({label, key, filterState, handler}: TFilterComponentProps) => {
  return (
    <Fragment key={label}>
      <div
        key={label}
        className={classNames(s.filter, {
          [s.on]: filterState,
        })}
        onClick={handler}
      >
        <span className={s.text}>{label}</span>
      </div>
      {key === EFilter.POI_PARK && (
        <div className={s.divider}>
          <IconDivider />
        </div>
      )}
    </Fragment>
  );
};

const SearchRankingHeaderQuickFilter = ({onChange, onChangeCategory, onClickConfirm}: TProps) => {
  const {activeFilters, currentAddressMap} = useAppSelector((state) => state.tRank);
  const dispatch = useAppDispatch();
  const filterMenuDimmed = useDimmedQuery({place: 'filter_menu'});
  const {sendClickLog} = useLogger();
  const firstDepthAreaName = useMemo(
    () => currentAddressMap[EAddressMode.CATE1]?.areaName,
    [currentAddressMap]
  );
  const clickedFilterLength = useMemo(
    () => Object.values(activeFilters).filter((v) => v.isActive).length,
    [activeFilters]
  );

  const handleClickMenu = useCallback(() => {
    filterMenuDimmed.open();
    sendClickLog('tap.filter_all');
  }, [filterMenuDimmed]);

  const handleClick = useCallback(
    (key) => {
      dispatch(actions.tRank.toggleFilter(key));
      sendClickLog(FILTER_LOG_MAP[key], {
        on: !activeFilters[key]?.isActive,
      });
    },
    [dispatch, activeFilters]
  );

  const handleClickConfirm = useCallback(
    (clickedFilters) => {
      dispatch(actions.tRank.activeFilters(clickedFilters));

      sendClickLog('tap.filter_popup_select', {
        tap_filter: clickedFilters.join(','),
      });
    },
    [dispatch]
  );

  return (
    <>
      <div className={classNames(s.header_wrap, s.has_filter)}>
        <div className={s.filter_wrap}>
          <div
            className={classNames(s.filter, s.menu, {
              [s.on]: clickedFilterLength > 0,
              [s.has_content]: clickedFilterLength > 0,
            })}
            onClick={handleClickMenu}
          >
            {clickedFilterLength > 0 ? <IconFilterOn /> : <IconFilterOff />}
            {clickedFilterLength > 0 && <span className={s.count}>{clickedFilterLength}</span>}
          </div>
          {Object.keys(FILTER_MAP).map((key) => {
            if (
              key === EFilter.WAITING_RESERVATION &&
              !THREE_DEPTH_REGION.includes(firstDepthAreaName || '')
            ) {
              return null;
            }

            return getFilterComponent({
              ...FILTER_MAP[key],
              filterState: activeFilters[key]?.isActive,
              handler: () => handleClick(key),
            });
          })}
        </div>
      </div>

      <FilterBottomPopup
        filters={[
          FILTER_MAP[EFilter.OPEN_NOW],
          ...(THREE_DEPTH_REGION.includes(firstDepthAreaName || '')
            ? [FILTER_MAP[EFilter.WAITING_RESERVATION]]
            : []),
          FILTER_MAP[EFilter.POI_PARK],
        ]}
        categories={[
          FILTER_MAP[ECategoryCode.POPULAR_FOOD],
          FILTER_MAP[ECategoryCode.POPULAR_CAFE],
          FILTER_MAP[ECategoryCode.POPULAR_BAR],
        ]}
        isOpen={filterMenuDimmed.isOpen}
        close={filterMenuDimmed.close}
        onClickConfirm={handleClickConfirm}
        onClickReset={() => {}}
      />
      {/* <SearchRecommendFilterDetailPopup
        isOpen={filterMenuDimmed.isOpen}
        close={filterMenuDimmed.close}
        filterMap={FILTER_MAP}
        onClickConfirm={onClickConfirm}
        onClickReset={() => {
          sendClickLog('tap.reset');
        }}
      /> */}
    </>
  );
};

export default SearchRankingHeaderQuickFilter;
