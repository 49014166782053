import {useEffect, useRef} from 'react';

export const usePrevious = <T>(value: T, updateCondition = (nextValue) => true) => {
  const ref = useRef<T>();
  const refUpdateCondition = useRef(updateCondition);

  useEffect(() => {
    if (refUpdateCondition.current(value)) {
      ref.current = value;
    }
  }, [value]);

  return ref.current;
};

export default usePrevious;
