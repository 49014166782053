import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import TMapSender, {ECallbackKeys, TMapReceiver, sendWithScheme} from '@lcc/tmap-inapp';
import {useAppSelector} from 'ducks/hooks';
import {ETab} from 'types/Search';
import usePrevious from 'hooks/usePrevious';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import {sendSearchClickLog} from 'utils/logManager';
import {parsePoiInfoToNavInfo} from 'utils/search';
import {isOver10_6Version, isOverNewFavoriteVersion, isPublicTransVersion} from 'utils/tmapUtils';
import {TPersonalPlaceItem} from 'ducks/userInfo/types';
import {IcHomeFill} from '../@tmds/icons/v1.2/IcHomeFill';
import {IcCompanyFill} from '../@tmds/icons/v1.2/IcCompanyFill';
import {IcArrowRight} from '../@tmds/icons/v1.2/IcArrowRight';
import {IcoLineBetween} from 'components/@tmds/icons/IcoLineBetween';
import {EAppRequestMode} from 'types/App';
import {TBookmarkPageId} from '@lcc/tmap-inapp/dist/types';

import s from 'styles/components/search/SearchTab.module.scss';

type TProps = {
  type: ETab;
  isConfirmMode?: boolean;
  showFavorite?: boolean;
};

const ICON_MAP = {
  [ETab.HOME]: <IcHomeFill width={16} height={16} color={'gray300'} />,
  [ETab.OFFICE]: <IcCompanyFill width={16} height={16} color={'gray300'} />,
  [ETab.FAVORITE]: null,
};

const parseNavData = (data: TPersonalPlaceItem) => {
  const {fullJibunAddr, fullRoadAddr, customName} = data;
  const address = fullJibunAddr || fullRoadAddr || customName;

  return parsePoiInfoToNavInfo({
    pkey: data.pkey || '',
    poiId: data.poiId || '',
    navSeq: data.navSeq || '',
    navX: `${data?.navX || ''}`,
    navY: `${data?.navY || ''}`,
    centerX: `${data?.centerX || ''}`,
    centerY: `${data?.centerY || ''}`,
    rpFlag: data.rpFlag,
    poiName: customName,
    address,
    tel: '',
  });
};

const SearchTab = ({type, isConfirmMode, showFavorite}: TProps) => {
  const {isUserDataLoading, home, office} = useAppSelector((state) => ({
    isUserDataLoading: state.userInfo.personalPlace.loading,
    home: state.userInfo.personalPlace.data.home,
    office: state.userInfo.personalPlace.data.office,
  }));
  const IconComponent = ICON_MAP[type];

  const {moveToSelectDestinationAction, reqMode, reqType, extra} = useMoveToTarget({
    from: EFromType.SEARCH_MAIN,
  });

  const prevValue = usePrevious({home, office});

  const isNeedOpenFavorite = useMemo(
    () =>
      [
        EAppRequestMode.ROUTE,
        EAppRequestMode.DRIVING,
        EAppRequestMode.REPORT,
        EAppRequestMode.PUBLIC_TRANSPORT_ROUTE,
        EAppRequestMode.WALK_ROUTE,
      ].includes(reqMode),
    [reqMode]
  );
  // openFavorite은 즐겨찾기 편집 없음,  https://tmobi.atlassian.net/wiki/spaces/TMAPCLIENT/pages/673842665/05#openFavorite

  const openBookmark = useCallback(
    (pageId?: TBookmarkPageId) => {
      if (isPublicTransVersion()) {
        if (isOver10_6Version() && isNeedOpenFavorite) {
          TMapReceiver.setCallback(ECallbackKeys.OPEN_FAVORITE, ({result, poi}) => {
            if (!result) {
              return;
            }
            try {
              const poiDataJson = JSON.parse(poi);
              moveToSelectDestinationAction(parsePoiInfoToNavInfo(poiDataJson));
            } catch (e) {
              return;
            }
          });

          TMapSender.openFavorite(pageId ?? 'place');
        } else {
          TMapSender.openBookmark(pageId ?? 'place', {
            reqMode,
            reqType,
            extra,
          });
        }
      } else {
        // @ts-ignore
        sendWithScheme('openFavoriteRoute');
      }
    },
    [reqMode, reqType, extra, moveToSelectDestinationAction, isNeedOpenFavorite]
  );

  const handleClickHome = useCallback(() => {
    sendSearchClickLog('tap.home', {
      pkey: home?.pkey,
    });

    if (!home) {
      if (isConfirmMode) {
        TMapSender.makeToast('집 정보가 등록되어 있지 않습니다.');
        return;
      }
      openBookmark();
      return;
    }

    moveToSelectDestinationAction(parseNavData(home));
  }, [home, moveToSelectDestinationAction, openBookmark, isConfirmMode]);

  const handleClickOffice = useCallback(() => {
    sendSearchClickLog('tap.office', {
      pkey: office?.pkey,
    });

    if (!office) {
      if (isConfirmMode) {
        TMapSender.makeToast('회사 정보가 등록되어 있지 않습니다.');
        return;
      }
      openBookmark();
      return;
    }

    moveToSelectDestinationAction(parseNavData(office));
  }, [office, moveToSelectDestinationAction, openBookmark, isConfirmMode]);

  const handleClickFavorite = useCallback(() => {
    sendSearchClickLog('tap.bookmark');
    // TODO: inApp upgrade, type 추가
    openBookmark(isOverNewFavoriteVersion() ? 'poi' : undefined);
  }, [openBookmark]);

  const handlerMap = useMemo(
    () => ({
      [ETab.HOME]: handleClickHome,
      [ETab.OFFICE]: handleClickOffice,
      [ETab.FAVORITE]: handleClickFavorite,
    }),
    [handleClickHome, handleClickOffice, handleClickFavorite]
  );

  const tabInfo = useMemo(
    () => ({
      [ETab.HOME]: {data: isUserDataLoading ? prevValue?.home : !!home, label: '집'},
      [ETab.OFFICE]: {data: isUserDataLoading ? prevValue?.office : !!office, label: '회사'},
      [ETab.FAVORITE]: {data: true, label: '저장'},
    }),
    [home, office, isUserDataLoading]
  );

  return (
    <div
      className={classNames(s.tab, {
        [s.has_data]: tabInfo[type].data,
        [s.no_gap]: type === ETab.FAVORITE,
      })}
      onClick={handlerMap[type]}
    >
      <div className={s.item}>
        {IconComponent && <i className={s.icon}>{IconComponent}</i>}
        <span>{tabInfo[type].label}</span>
      </div>

      {type === ETab.FAVORITE ? (
        <IcArrowRight width={16} height={16} color={'gray800'} />
      ) : (
        (showFavorite || type !== ETab.OFFICE) && (
          <IcoLineBetween width={16} height={16} color={'gray200'} />
        )
      )}
    </div>
  );
};

export default SearchTab;
