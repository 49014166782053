import {useCallback} from 'react';

import {TSuggestQuery} from 'types/Search';

import {highlightQuery} from 'utils/search';

import s from 'styles/components/legacySearch/SuggestItem.module.scss';

type TProps = {
  data: TSuggestQuery;
  onClick?: (query) => void;
  highlightRegExp?: RegExp;
};

const SuggestItem = ({data, onClick, highlightRegExp}: TProps) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onClick?.(data.keyword);
    },
    [onClick, data]
  );

  return (
    <div className={s.suggest_item} onClick={handleClick}>
      <div className={s.name}>{highlightQuery(data.keyword, highlightRegExp)}</div>
    </div>
  );
};

export default SuggestItem;
