import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import TMapSender, {TPoiDataJson, sendWithScheme} from '@lcc/tmap-inapp';
import {HybridBridge} from 'utils/searchBar';
import actions from 'ducks/actions';
import {fetchUserData} from 'ducks/userInfo/slice';
import {API_PATH} from 'constant/Api';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import useDimmedQuery from 'hooks/useDimmedQuery';
import useRecentQuery from 'hooks/useRecentQuery';
import {useRecentTabMode} from 'hooks/useRecentTabMode';
import useLegacyRecentList from 'hooks/useLegacyRecentList';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import usePrevious from 'hooks/usePrevious';
import {useAppLocation} from 'hooks/useAppLocation';
import {postApi} from 'utils/apis';
import {sendSearchClickLog} from 'utils/logManager';
import {EAppExtraCode} from 'types/App';
import {ELegacyRecentItemType, TLegacyRecentItem} from 'types/LegacySearch';
import {AdBanner} from 'components/AdBanner';
import {
  EAdCode,
  SEARCH_BANNER_AD_STEP,
  TEST_BANNER_PROVIDER_CONFIG,
  isBannerTestEnv,
  SEARCH_MAIN_BANNER_PROVIDER_CONFIG,
} from 'constant/Ads';
import RecentItem from 'components/legacySearch/RecentItem';
import BottomButtons from 'components/BottomButtons';
import PoiItem from 'components/legacySearch/PoiItem';
import CheckboxIcon from 'components/CheckboxIcon';
import ModalPopup from 'components/ModalPopup';

import s from 'styles/components/legacySearch/RecentTab.module.scss';

const RecentNoResult = ({isLandscape}) => {
  return (
    <div
      className={classNames(s.no_result, {
        [s.is_landscape]: isLandscape,
      })}
    >
      최근 검색한 기록이 없습니다.
    </div>
  );
};

const MAX_LIST_COUNT = 100;

type TProps = {
  onEnterEdit?: VoidFunction;
  onAfterEdit?: VoidFunction;
  onExitEdit?: VoidFunction;
  pageHide?: boolean;
  paramPoiInfo?: TPoiDataJson;
};

const RecentTab = ({onEnterEdit, onAfterEdit, onExitEdit, pageHide, paramPoiInfo}: TProps) => {
  const dispatch = useAppDispatch();
  const {removeItem} = useRecentQuery();
  const {goBack} = useAppLocation();

  const {recentApiLoaded, accessKey, isLandscape, isHybrid, isLogInitialized} = useAppSelector(
    (state) => ({
      recentApiLoaded: state.userInfo.personalPlace.loaded,
      accessKey: state.userInfo.accessKey,
      isLandscape: state.layout.appSize.isLandscape,
      isHybrid: state.layout.isHybrid,
      isLogInitialized: state.log.isInitialize,
    })
  );

  const bottomModal = useDimmedQuery({id: 'CONFIRM_APP_SYNC'});

  const {isEdit, enterEditMode, exitEditMode} = useRecentTabMode();

  const prevEdit = usePrevious(isEdit);

  const {moveToSearch, moveToSelectDestinationAction, moveToDetail} = useMoveToTarget({
    from: EFromType.RECENT,
  });

  const {recentList} = useLegacyRecentList({listLength: MAX_LIST_COUNT});

  const refIsEdit = useRef(isEdit);

  useEffect(() => {
    refIsEdit.current = isEdit;

    !isEdit && setSelectItems([]);
  }, [isEdit]);

  const [selectItems, setSelectItems] = useState<Array<TLegacyRecentItem>>([]);

  const isSelectAll = useMemo(
    () => selectItems.length === recentList.length,
    [recentList, selectItems]
  );

  const handleSelect = useCallback(
    (item, index) => {
      const isSelected = selectItems.some((i) => i.id === item.id);

      if (isSelected) {
        setSelectItems(selectItems.filter((n) => n.id !== item.id));
      } else {
        setSelectItems([...selectItems, item]);
      }

      // 0: off, 1: on
      sendSearchClickLog('tap.checkbox', {
        index: isSelected ? 0 : 1,
        list_seq: index,
      });
    },
    [selectItems]
  );

  const handleSelectAll = useCallback(() => {
    if (isSelectAll) {
      setSelectItems([]);
    } else {
      setSelectItems(recentList);
    }

    // 1: 전체 선택 된 상태, 2: 전체 헤제 된 상태
    sendSearchClickLog('tap.selectall', {
      index: isSelectAll ? 1 : 0,
    });
  }, [isSelectAll, recentList]);

  const handlePopupCancel = useCallback(() => {
    bottomModal.close();
    isHybrid && HybridBridge.hideModal();
    sendSearchClickLog('popup_tap.delete_cancel');
  }, [bottomModal]);

  const handleDelete = useCallback(async () => {
    bottomModal.close();
    isHybrid && HybridBridge.hideModal();

    const removeTargets: TLegacyRecentItem[] = [];

    selectItems.forEach((q) => {
      if (q.type === ELegacyRecentItemType.QUERY) {
        removeItem(q.name);
      } else {
        removeTargets.push(q);
      }
    });

    if (removeTargets.length > 0) {
      try {
        const destinations = removeTargets.map((t) => ({
          customName: t.poiInfo?.customName,
          navX: t.poiInfo?.navX,
          navY: t.poiInfo?.navY,
        }));
        await postApi(API_PATH.POST_DELETE_DESTINATION, {destinations, accessKey});

        sendWithScheme('setDirtyRecent');
        dispatch(fetchUserData());
      } catch (e) {
        TMapSender.makeToast('최근목적지 삭제를 실패하였습니다.');
      }
    }

    sendSearchClickLog('popup_tap.delete_ok', {
      list_num: recentList.length,
      delete_cnt: selectItems.length,
    });

    setSelectItems([]);
    onAfterEdit?.();
    setTimeout(() => {
      exitEditMode(); // edit close
      onExitEdit?.();
    }, 100);
  }, [
    bottomModal,
    isHybrid,
    selectItems,
    recentList.length,
    onAfterEdit,
    removeItem,
    accessKey,
    dispatch,
    exitEditMode,
    onExitEdit,
  ]);

  useEffect(() => {
    return () => {
      refIsEdit.current && exitEditMode();
    };
  }, []);

  useEffect(() => {
    if (prevEdit && !isEdit) {
      onExitEdit?.();
    }
  }, [isEdit, onExitEdit, prevEdit]);

  const handleClickParamPoi = useCallback(
    (poiInfo) => {
      sendSearchClickLog('tap.route_poi_detail', {pkey: poiInfo.pkey});
      moveToDetail(poiInfo, {extra: EAppExtraCode.ROUTE_ITEM_DETAIL});
    },
    [moveToDetail]
  );

  return (
    <div className={s.search_recent_wrap}>
      <div className={s.scroll_wrap}>
        {!pageHide && (
          <div className={s.banner}>
            <AdBanner
              visibleLandscape={false}
              adCode={EAdCode.SEARCH_MAIN}
              adTypeStep={SEARCH_BANNER_AD_STEP}
              adTypeOption={{
                ...SEARCH_MAIN_BANNER_PROVIDER_CONFIG,
                ...(isBannerTestEnv ? TEST_BANNER_PROVIDER_CONFIG : {}),
              }}
              isLogInitialized={isLogInitialized}
              logData={{
                index: EAdCode.SEARCH_MAIN,
                custom: {},
                includeTicketId: false,
              }}
            />
          </div>
        )}

        {paramPoiInfo && <PoiItem data={paramPoiInfo} onClickDetail={handleClickParamPoi} />}

        {recentList.length > 0 ? (
          <ul>
            {recentList.map((n, i) => (
              <RecentItem
                key={n.id}
                data={n}
                isEdit={isEdit}
                isSelect={selectItems.some((item) => n.id === item.id)}
                onSelect={() => handleSelect(n, i)}
                onClickSearch={(query) => {
                  sendSearchClickLog('tap.lastS', {
                    list_seq: i,
                    search_query: query,
                  });
                  moveToSearch(query);
                }}
                onClickDestination={(poiDataJson) => {
                  sendSearchClickLog('tap.lastD', {
                    list_seq: i,
                    pkey: poiDataJson.pkey,
                  });
                  moveToSelectDestinationAction(poiDataJson);
                }}
              />
            ))}
          </ul>
        ) : (
          recentApiLoaded && <RecentNoResult isLandscape={isLandscape} />
        )}
      </div>

      {bottomModal.isOpen && (
        <ModalPopup
          titleComponent={
            <>
              홈의 최근목적지에도
              <br />
              함께 반영됩니다.
            </>
          }
          detail="선택한 항목을 삭제하시겠어요?"
          buttons={[
            {
              children: '취소',
              onClick: handlePopupCancel,
            },
            {
              type: 'warning',
              children: '삭제',
              onClick: handleDelete,
            },
          ]}
          onClickClose={handlePopupCancel}
        />
      )}

      {recentList.length > 0 && (
        <BottomButtons
          topArea={
            isEdit ? (
              <div className={s.bottom_opt_wrap}>
                <div
                  className={s.btn_select_all}
                  onClick={handleSelectAll}
                  role="checkbox"
                  aria-checked={isSelectAll}
                >
                  <span>전체선택</span> <CheckboxIcon on={isSelectAll} />
                </div>
              </div>
            ) : undefined
          }
          showGradient={true}
          buttons={
            isEdit
              ? [
                  {
                    title: '편집 취소',
                    onClick: () => {
                      dispatch(actions.userInteraction.popHistoryStack());
                      sendSearchClickLog('tap.cancel_edit');
                      goBack();
                    },
                  },
                  {
                    title: selectItems.length > 0 ? `삭제 ${selectItems.length}` : '삭제',
                    onClick: () => {
                      bottomModal.open();
                      isHybrid && HybridBridge.showModal();
                      sendSearchClickLog('tap.delete');
                    },
                    disabled: selectItems.length === 0,
                    active: selectItems.length > 0,
                    ariaProperties: {
                      'aria-haspopup': 'dialog',
                    },
                  },
                ]
              : [
                  {
                    title: '최근기록 삭제',
                    onClick: () => {
                      enterEditMode();
                      onEnterEdit?.();
                      sendSearchClickLog('tap.delete');
                    },
                  },
                ]
          }
        />
      )}
    </div>
  );
};

export default RecentTab;
