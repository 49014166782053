import {useAppSelector} from 'ducks/hooks';
import {useEffect, useMemo, useState} from 'react';
import {EDurationActionId} from 'constant/Log';
import useLogger from './useLogger';
import {useOnce} from './useOnce';
import {EPerformanceLogKey} from 'types/Log';
import TMapSender, {ECallbackKeys, TMapReceiver} from '@lcc/tmap-inapp';

type TProps = {
  isLogInitialized?: boolean;
};

export const useSendPagePerformanceLog = ({isLogInitialized = true}: TProps) => {
  const {sendApp} = useLogger();
  const pair = useAppSelector((state) => state.log.pair);
  const fireDurationLog = useMemo(() => {
    const getDuration = (key: EPerformanceLogKey) =>
      (pair[key]?.end?.time || 0) - (pair[key]?.start?.time || 1);

    return {
      init_api_render: getDuration(EPerformanceLogKey.INIT_API_RENDER),
      vsm_init: getDuration(EPerformanceLogKey.VSM_INIT),
      call_position: getDuration(EPerformanceLogKey.CALL_POSITION),
      vsm_first_paint: getDuration(EPerformanceLogKey.VSM_FIRST_PAINT),
    };
  }, [pair]);

  const [webViewMountTime, setWebViewMountTime] = useState();
  const pageMountTime = useMemo(() => Date.now(), []);

  useEffect(() => {
    // 9.9 버전부터
    TMapReceiver.setCallback(ECallbackKeys.WEBVIEW_MOUNT_TIME, setWebViewMountTime);
    TMapSender.getWebViewMountTime();
  }, []);

  useOnce(isLogInitialized && webViewMountTime, () => {
    sendApp(EDurationActionId.INIT_WEBVIEW, {
      initiate_web_duration: pageMountTime - (webViewMountTime || 0),
    });
  });

  useOnce(isLogInitialized && fireDurationLog.vsm_first_paint > 0, () => {
    sendApp(EDurationActionId.MAP_FIRST_PAINT_TIME, {
      load_map_background_duration: fireDurationLog.vsm_first_paint,
    });
  });

  useOnce(isLogInitialized && fireDurationLog.init_api_render > 0, () => {
    sendApp(EDurationActionId.INIT_RENDER_LIST_FINISH, {
      render_list_duration: fireDurationLog.init_api_render,
    });
  });
  useOnce(isLogInitialized && fireDurationLog.vsm_init > 0, () => {
    sendApp(EDurationActionId.MAP_LOADING_TIME, {
      load_map_duration: fireDurationLog.vsm_init,
    });
  });
  useOnce(isLogInitialized && fireDurationLog.call_position > 0, () => {
    sendApp(EDurationActionId.INIT_POSITION, {
      init_position_duration: fireDurationLog.call_position,
    });
  });
};

export default useSendPagePerformanceLog;
