import qs from 'query-string';
import packageInfo from '../../package.json';

import {isProdEnv, WEB_ACCESS_KEY} from 'constant/Env';
import {
  EImpressionActionId,
  EListActionId,
  EPageType,
  ETNowActionId,
  GaPvAction,
  GaTNowCategory,
  ETLAServiceId,
} from 'constant/Log';
import {StorageKey} from 'constant/Storage';
import {TActionId} from 'types/Log';

import TlaLogger, {TlaCoreManager} from '@lcc/tla-logger';

import {
  init as GaInit,
  sendTNowPv as GaSendTNowPv,
  sendTNowExpose as GaSendTNowExpose,
  sendTNowClick as GaSendTNowClick,
} from 'utils/gaCode';

import {
  afSendPlacePageView,
  afSendCategoryView,
  afSendBannerView,
  afSendBannerClick,
  afSendContentsClick,
  afSendSearchLog,
} from 'utils/appsFlyer';

import {init as MixPanelInit, sendEvent as sendMixPanelEvent} from 'utils/mixPanel';

type TProps = {
  accessKey?: string;
  sessionKey?: string;
  userKey?: string;
  deviceId?: string;
  sessionId?: string;
  carrierName?: string;
  title?: string;
  pageId?: string;
  pageType: EPageType;
  referrer?: string;
};

let preventSendLog = false;

type TGaSupportPage = EPageType.PLACE_MAIN | EPageType.PLACE_CATEGORY;

const GA_ACTION_MAP: Record<TGaSupportPage, GaPvAction> = {
  [EPageType.PLACE_MAIN]: GaPvAction.T_NOW,
  [EPageType.PLACE_CATEGORY]: GaPvAction.PLACE_CATEGORY,
};

type TGaRecord = Record<TGaSupportPage, GaTNowCategory>;

const GA_EXPOSE_MAP: TGaRecord = {
  [EPageType.PLACE_MAIN]: GaTNowCategory.EXPOSE_T_NOW,
  [EPageType.PLACE_CATEGORY]: GaTNowCategory.EXPOSE_CATEGORY,
};

const GA_CLICK_MAP: TGaRecord = {
  [EPageType.PLACE_MAIN]: GaTNowCategory.CLICK_T_NOW,
  [EPageType.PLACE_CATEGORY]: GaTNowCategory.CLICK_CATEGORY,
};

const TLA_SERVICE_ID_MAP: Record<EPageType, ETLAServiceId> = {
  [EPageType.PLACE_MAIN]: ETLAServiceId.PLACE,
  [EPageType.PLACE_CATEGORY]: ETLAServiceId.PLACE,
  [EPageType.SEARCH]: ETLAServiceId.SEARCH,
};

const getPlaceGaData = (pageType: EPageType) => {
  return {
    nowGaPage: GA_ACTION_MAP[pageType] as GaPvAction,
    nowPageCategory: (qs.parse(window.location.search).type as string) || '',
  };
};

export const init = (props: TProps, sendPv: boolean = false) => {
  if (isProdEnv && props.accessKey === WEB_ACCESS_KEY) {
    preventSendLog = true;
    return;
  }

  const serviceId = TLA_SERVICE_ID_MAP[props.pageType || EPageType.PLACE_MAIN];
  const pageId = props.pageId || '';
  const userKey = props.userKey || '';
  const unsentLogStorageKey = StorageKey.REFRESH_LOG_KEY;
  const appInfo = TlaLogger.appInfo;

  GaInit();

  TlaLogger.init({
    serviceId,
    accessKey: props.accessKey,
    sessionId: props.sessionId,
    sessionKey: props.sessionKey,
    userKey,
    deviceId: props.deviceId,
    carrierName: props.carrierName,
    title: props.title,
    pageId,
    packageVersion: packageInfo.version,
    isProdEnv,
    referrer: props.referrer,
    unsentLogStorageKey,
  });

  MixPanelInit({
    serviceId,
    pageId,
    userKey,
    appVersion: appInfo.tmapAppVersion,
  });

  if (sendPv) {
    sendPageView(props.pageType);
  }
};

export const sendApp = (actionId: TActionId | '', custom?: KVObject) => {
  if (preventSendLog) {
    return;
  }

  TlaLogger.sendApp(actionId, custom);
};

export const sendPageView = (pageType: EPageType, custom?: KVObject) => {
  if (preventSendLog) {
    return;
  }

  TlaLogger.sendExpose(custom);

  sendMixPanelEvent('', custom);

  const {nowGaPage, nowPageCategory} = getPlaceGaData(pageType);

  if (nowGaPage) {
    GaSendTNowPv(nowGaPage, {
      label: nowPageCategory,
    });
  }

  switch (pageType) {
    case EPageType.PLACE_MAIN:
      afSendPlacePageView();
      afSendCategoryView();
      return;

    case EPageType.PLACE_CATEGORY:
      afSendPlacePageView();

      if (nowPageCategory) {
        afSendCategoryView(nowPageCategory);
      }
      return;
  }
};

export const sendExpose = (pageType: EPageType, custom?: KVObject) => {
  if (preventSendLog) {
    return;
  }

  // expose 는 actionId가 항상 '' 임
  const {nowGaPage, nowPageCategory} = getPlaceGaData(pageType);

  TlaLogger.sendExpose(custom);

  sendMixPanelEvent('', custom);

  if (nowGaPage) {
    GaSendTNowExpose({
      category: GA_EXPOSE_MAP[pageType],
      label: nowPageCategory,
    });
  }
};

export const sendClickLog = (
  pageType: EPageType,
  actionId: TActionId,
  custom?: Record<string, any>
) => {
  if (preventSendLog) {
    return;
  }

  TlaLogger.sendClickLog(actionId, custom);

  sendMixPanelEvent(actionId, custom);

  const {nowGaPage, nowPageCategory} = getPlaceGaData(pageType);

  if (nowGaPage) {
    GaSendTNowClick(actionId, {
      category: GA_CLICK_MAP[pageType],
      label: nowPageCategory,
    });
  }

  if ([EPageType.PLACE_MAIN, EPageType.PLACE_CATEGORY].includes(pageType)) {
    switch (actionId) {
      case EListActionId.ITEM:
        afSendContentsClick(custom);
        break;
      case EImpressionActionId.TNOW_BANNER:
        afSendBannerView(custom);
        break;
      case ETNowActionId.BANNER:
        afSendBannerClick(custom);
        break;
    }
  }

  if (pageType === EPageType.SEARCH) {
    const tlaInfos = TlaCoreManager.getInfos();
    const pageId = tlaInfos.meta.page_id || '';

    afSendSearchLog(actionId, pageId, custom);
  }
};

export const sendSearchClickLog = (actionId: TActionId, custom?: KVObject) => {
  sendClickLog(EPageType.SEARCH, actionId, custom);
};

export const updateMeta = (metaData) => {
  TlaCoreManager.updateMeta(metaData);
};

const exportModule = {
  init,

  sendApp,
  sendExpose,
  sendPageView,
  sendClickLog,
  sendSearchClickLog,

  updateMeta,
};

export default exportModule;
