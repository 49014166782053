import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';

import {useCallback, useRef} from 'react';
import {debounce} from 'utils/lodash';
import {useFocusAfterRotate} from './useFocusAfterRotate';
import {useDrawerConsumer} from 'context/DrawerContext';
import {EListMode, EParallaxMoveMode} from 'types/ListDrawer';

const DEBOUNCE_DELAY = 300;

type TPoiList = {
  listId: string;
  groupSubList?: {
    listId: string;
  }[];
};

export const useFocusDrawerList = (
  poiList: TPoiList[],
  {delayDebounce} = {delayDebounce: DEBOUNCE_DELAY}
) => {
  const dispatch = useAppDispatch();
  const {activePoi} = useAppSelector((state) => ({
    activePoi: state.userInteraction.activePoi,
  }));
  const {drawerProps} = useDrawerConsumer();
  const {isRotateChange} = useFocusAfterRotate();
  const refVisibleItemList = useRef<[boolean, boolean[]][]>([]);

  const updateFocusItem = useCallback(
    debounce(() => {
      let subIdx: number | undefined = undefined;
      const idx = refVisibleItemList.current.findIndex((n) => {
        if (!n) {
          return false;
        }

        if (n[0]) {
          return true;
        }

        const nowSubIdx = n[1]?.findIndex((sub) => sub);

        if (nowSubIdx !== undefined && nowSubIdx >= 0) {
          subIdx = nowSubIdx;
          return true;
        }

        return false;
      });

      const item = poiList[idx];

      if (item) {
        const targetListId = item?.groupSubList?.[Number(subIdx)]?.listId || item.listId;

        dispatch(
          actions.userInteraction.setInteraction({
            activePoi: targetListId,
            trigger: 'scroll',
          })
        );
      }
    }, delayDebounce),
    [poiList, delayDebounce]
  );

  const changeViewingItems = useCallback(
    (visible, index, subIndex?) => {
      if (isRotateChange) {
        return;
      }
      if (!refVisibleItemList.current[index]) {
        refVisibleItemList.current[index] = [false, []];
      }

      if (subIndex === undefined) {
        refVisibleItemList.current[index] = [visible, refVisibleItemList.current[index][1]];
      } else {
        const newSub = [...refVisibleItemList.current[index][1]];

        newSub[subIndex] = visible;
        refVisibleItemList.current[index] = [refVisibleItemList.current[index][0], newSub];
      }

      if (
        drawerProps?.mode.moveMode === EParallaxMoveMode.WAIT &&
        drawerProps?.mode.mode !== EListMode.BOTTOM
      ) {
        updateFocusItem();
      }
    },
    [updateFocusItem, drawerProps?.mode, isRotateChange]
  );

  return {
    activePoi,
    changeViewingItems,
  };
};
