import {CSSProperties, ReactNode} from 'react';
import classNames from 'classnames';
import s from 'styles/components/@tmds/TmdsIcon.module.scss';

export type TTmdsIconProps = {
  Icon: ReactNode;
  width?: number;
  height?: number;
  color?: string;
  className?: string;
};

// https://www.figma.com/file/XYc3r90K3z5njGXT6KITfr/TMDS_Core_v1.0?type=design&node-id=819-6048&mode=dev
// exported by SVG Exporter : Layer Names => Classes
const TmdsIcon = ({Icon, width, height, className, color = 'gray900'}: TTmdsIconProps) => {
  return (
    <i
      className={classNames(s.wrap, className)}
      style={
        {
          '--width': width ? `${width}px` : '',
          '--height': height ? `${height}px` : '',
          '--color': color,
        } as CSSProperties
      }
      data-tmds-svg-color={color}
    >
      {Icon}
    </i>
  );
};

export default TmdsIcon;
