// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcoArrowDownSvg} from 'resource/@tmdsicon/ico_arrow_down.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcoArrowDown = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcoArrowDownSvg {...props} />} />;
};

export {IcoArrowDown};
