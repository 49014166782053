import {MouseEventHandler, useMemo} from 'react';
import classNames from 'classnames';
import {EDetailButtonType} from 'types/App';
import {TBusStationItem} from 'ducks/search/types';
import TmdsTag from 'components/@tmds/TmdsTag';
import {IcoRouteTurnFilled} from 'components/@tmds/icons/IcoRouteTurnFilled';
import {IcoLineBetween} from 'components/@tmds/icons/IcoLineBetween';
import {BUS_COLOR_MAP} from 'constant/Search';
import {getDistance} from 'utils/formatter';
import {ESearchLocationType} from 'types/Search';
import {useAppSelector} from 'ducks/hooks';

import {ReactComponent as IconDotBetween} from 'resource/images/@tmds_element/ico_dot_between.svg';

import s from 'styles/components/PoiListItem.module.scss';

type TProps = {
  item: TBusStationItem;
  actionButtonType: EDetailButtonType;
  onClickRouteButton?: MouseEventHandler;
  onClickItem?: (e) => void;
};

const SearchResultBusStationItem = ({
  item,
  actionButtonType,
  onClickRouteButton,
  onClickItem,
}: TProps) => {
  const {locationType} = useAppSelector((state) => state.search);
  const distance = useMemo(
    () =>
      ({
        [ESearchLocationType.MAP]: item.distance,
        [ESearchLocationType.USER]: item.userRealDistance,
        [ESearchLocationType.MAP_ON_MAP]: item.distance,
        [ESearchLocationType.USER_ON_MAP]: item.userRealDistance,
      }[locationType]),
    [item.distance, item.userRealDistance, locationType]
  );

  return (
    <div className={s.wrap} onClick={onClickItem}>
      <div className={classNames(s.title_wrap, s.item)}>
        <span className={s.title} data-style-node="title">
          {item.busStationName}
        </span>
      </div>

      <div className={classNames(s.info_wrap, s.item)}>
        <p className={s.extra_info}>
          {item.busStationDisplayName && (
            <>
              <span data-tmds-color="gray600">{item.busStationDisplayName}</span>
              <IconDotBetween className={s.icon_dot} />
            </>
          )}
          <span data-tmds-color="gray600" className={s.ellipsis}>
            {item.busToStation}방면
          </span>
        </p>

        <p className={s.extra_info}>
          <span className={s.distance}>{getDistance(distance)}</span>
          <IcoLineBetween width={16} height={16} color="gray200" />
          <span data-tmds-color="gray600" className={s.ellipsis}>
            {[item.lcdName, item.mcdName, item.scdName].filter((v) => !!v).join(' ')}
          </span>
        </p>
      </div>

      {item.busClasses.length > 0 && (
        <div className={classNames(s.special_action, s.bus_tag_wrap)}>
          {item.busClasses.map((v) => (
            <TmdsTag
              key={v.code}
              label={v.name}
              color="wbWhite"
              backgroundColor={BUS_COLOR_MAP[v.code]}
              type="bold"
              filled={true}
              className={s.tmds_tag}
            />
          ))}
        </div>
      )}

      {actionButtonType && actionButtonType !== EDetailButtonType.NONE && (
        <button
          className={s.button_wrap}
          data-action={actionButtonType}
          onClick={onClickRouteButton}
        >
          {actionButtonType === EDetailButtonType.CONFIRM ? (
            <div className={s.confirm}>확인</div>
          ) : (
            <span className={s.route}>
              <IcoRouteTurnFilled width={22} height={22} color="wbWhite" />
            </span>
          )}
        </button>
      )}
    </div>
  );
};

export default SearchResultBusStationItem;
