import 'styles/index.scss';
import {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import 'core-js/features/array/flat';
import 'core-js/features/object/entries';

import App from './App';
import 'utils/Datadog';

import {store} from 'ducks/store';
import {EReceiverEmitterActions, ReceiverEmitter} from 'utils/tmapInApp';

ReceiverEmitter.on(EReceiverEmitterActions.DISPATCH_STORE, (action) => {
  if (action) {
    store.dispatch(action);
  }
});

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
