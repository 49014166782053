import useElement from 'hooks/useElement';
import {HTMLAttributes, useMemo, PropsWithChildren, useCallback} from 'react';
import {createPortal} from 'react-dom';
import {LAYER_ID} from 'types/App';
import s from 'styles/components/ModalPopup.module.scss';
import {useJSXMultiline} from 'hooks/useJSXMultiline';

export type TButtonProps = PropsWithChildren<HTMLAttributes<HTMLButtonElement>> & {
  type?: 'confirm' | 'cancel' | 'warning';
};

export type TProps = {
  title?: string;
  titleComponent?: JSX.Element;
  detail?: string;
  detailComponent?: JSX.Element;
  buttons?: Array<TButtonProps>;

  onConfirm?: () => void;
  confirmLabel?: string;
  onCancel?: () => void;
  cancelLabel?: string;
  onClickClose?: () => void;

  onClickWrap?: () => void;
};

const ModalPopup = ({
  title = '',
  detail = '',
  titleComponent,
  detailComponent,
  buttons,
  onClickClose,
  onClickWrap,
  ...defaultButtonProps
}: TProps) => {
  const layerContainer = useElement({id: LAYER_ID});

  const titleJSX = useJSXMultiline(title);
  const detailJSX = useJSXMultiline(detail);

  const defaultButtons: Array<TButtonProps> | null = useMemo(() => {
    const {onConfirm, confirmLabel, onCancel, cancelLabel} = defaultButtonProps;
    const simpleSet: Array<TButtonProps> = [];

    if (onCancel) {
      simpleSet.push({type: 'cancel', children: cancelLabel || '취소', onClick: onCancel});
    }
    if (onConfirm) {
      simpleSet.push({type: 'confirm', children: confirmLabel || '확인', onClick: onConfirm});
    }

    return simpleSet.length > 0 ? simpleSet : null;
  }, [defaultButtonProps]);

  const handleClickWrap = useCallback(
    (e) => {
      e?.preventDefault();
      e?.stopPropagation();

      onClickWrap?.();
    },
    [onClickWrap]
  );

  const handleClickClose = useCallback(
    (e) => {
      e?.preventDefault();
      e.stopPropagation();
      onClickClose?.();
    },
    [onClickClose]
  );

  return (
    layerContainer &&
    createPortal(
      <div
        className={s.wrapper}
        onClick={handleClickClose}
        aria-modal={true}
        role="dialog"
        aria-labelledby="modal_popup_title"
        aria-describedby="modal_popup_desc"
      >
        <div className={s.popup_wrap}>
          <div className={s.content} onClick={handleClickWrap}>
            <div id="modal_popup_title" className={s.title}>
              {titleComponent || titleJSX}
            </div>

            {(detail || detailComponent) && (
              <div className={s.detail} id="modal_popup_desc">
                {detailComponent || detailJSX}
              </div>
            )}
          </div>

          <div className={s.actions}>
            {(buttons || defaultButtons || []).map(({type = '', ...buttonProps}, idx) => (
              <button
                {...buttonProps}
                role="button"
                data-action-type={type}
                key={idx}
                onClick={(e) => {
                  e.stopPropagation();
                  buttonProps?.onClick?.(e);
                }}
              >
                {buttonProps.children}
              </button>
            ))}
          </div>
        </div>
      </div>,
      layerContainer
    )
  );
};

export default ModalPopup;
