// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcoRouteTurnFilledSvg} from 'resource/@tmdsicon/ico_route_turn_f.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcoRouteTurnFilled = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcoRouteTurnFilledSvg {...props} />} />;
};

export {IcoRouteTurnFilled};
