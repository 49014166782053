import {TabHeader} from 'modules/Tabs';
import {sendSearchClickLog} from 'utils/logManager';
import {ELegacySearchTabs} from 'types/LegacySearch';
import {TActionId} from 'types/Log';

import s from 'styles/components/legacySearch/SearchTabHeader.module.scss';

const tabHeaderList = [
  {key: ELegacySearchTabs.RECENT, title: '최근기록'},
  {key: ELegacySearchTabs.FAVORITE, title: '즐겨찾기'},
  {key: ELegacySearchTabs.ADDRESS, title: '주소검색'},
  {key: ELegacySearchTabs.MAP, title: '지도지정'},
];

const TabActionIdMap: Record<ELegacySearchTabs, TActionId> = {
  [ELegacySearchTabs.RECENT_EDIT]: 'tab_tap.history',
  [ELegacySearchTabs.RECENT]: 'tab_tap.history',
  [ELegacySearchTabs.FAVORITE]: 'tab_tap.bookmark',
  [ELegacySearchTabs.ADDRESS]: 'tab_tap.address',
  [ELegacySearchTabs.MAP]: 'tab_tap.map',
};

const SearchTabHeader = () => {
  return (
    <div className={s.search_tab_header} role="tablist">
      {tabHeaderList.map((n) => (
        <TabHeader
          key={n.key}
          className={s.header_item}
          activeClassName={s.active}
          tabKey={n.key}
          onClick={() => sendSearchClickLog(TabActionIdMap[n.key])}
        >
          {n.title}
        </TabHeader>
      ))}
    </div>
  );
};

export default SearchTabHeader;
