import {useEffect, useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import DiscoverySection from './DiscoverySection';
import DiscoveryRowPoiItem from './DiscoveryRowPoiItem';

import s from '../../../styles/components/tplacehome/TPlaceDiscovery.module.scss';
import InView from 'react-intersection-observer';
import usePlaceHome from 'hooks/usePlaceHome';
import useFavorite from 'hooks/useFavorite';
import {WEEKEND_COMPONENT_RENDER_TIMING} from './Discovery';

const DiscoveryWeekend = () => {
  const {tplacehome, userInfo} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();
  const {checkIsFavoriteFromList, getFavoriteGroup} = useFavorite();

  const poiList = useMemo(() => tplacehome.discoveryData.result.data.weekendList, [tplacehome]);

  useEffect(() => {
    if (!poiList || poiList.length === 0) {
      return;
    }

    const list = poiList.map((item) => ({
      poiId: item.poiId.toString(),
      pkey: item.pkey,
    }));

    setTimeout(() => {
      getFavoriteGroup(list);
    }, WEEKEND_COMPONENT_RENDER_TIMING);
  }, [poiList]);

  return (
    <DiscoverySection mainTitle={'#주말에 가볼만한 곳'} subTitle={'내 취향을 담은'} pb={14}>
      <InView
        onChange={(isView) => isView && placehomeHook.sendEventDiscovery('view.weekend_outing_poi')}
      >
        <ul className={s.row_list}>
          {poiList.map((item, index) => {
            return (
              <li key={index} className={s.cell}>
                <DiscoveryRowPoiItem
                  data={item}
                  onClickDetail={() =>
                    placehomeHook.sendEventDiscovery('tap.weekend_outing_poi', {
                      euk: userInfo.euk,
                      pkey: item.pkey,
                    })
                  }
                  hasFavorite={checkIsFavoriteFromList(item)}
                />
              </li>
            );
          })}
        </ul>
      </InView>
    </DiscoverySection>
  );
};

export default DiscoveryWeekend;
