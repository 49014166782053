import {getPlaceQueryList, ECategoryCode} from 'constant/Place';
import {useAppSelector} from 'ducks/hooks';
import {placeFilterInitialState} from 'ducks/place/slice';
import {useMemo} from 'react';
import {EParkingLotType, EPlaceCategoryType, TToggler} from 'types/App';
import {useParseQueryLocation} from './useParseQueryLocation';

export const getResetToggler = (currentParams) => {
  return {
    label: '전체',
    params: [placeFilterInitialState, placeFilterInitialState],
    isOn: !Object.keys(currentParams).find((p) => currentParams[p] !== undefined),
  };
};

export const DEFAULT_FILTER = {id: 'ALL', type: undefined, label: '전체'};
export const DEFAULT_EV_FILTER = {...DEFAULT_FILTER, label: '기관 전체'};

const usePlaceFilter = () => {
  const {queries} = useParseQueryLocation();
  const {
    placeQuery,
    oilCompany,
    evCompany,
    evPublicType,
    evChargerSpeed,
    evChargerStatus,
    evChargerType,
    tmapPaymentYn,
    parkType,
    categoryTypes = [],
  } = useAppSelector((state) => ({
    placeQuery: state.place.placeQuery,
    oilCompany: state.place.oilCompany,
    evCompany: state.place.evSvcOperatorIds,
    evPublicType: state.place.evPublicType,
    evChargerSpeed: state.place.evChargerSpeed,
    evChargerStatus: state.place.evChargerStatus,
    evChargerType: state.place.evChargerType,
    tmapPaymentYn: state.place.tmapPaymentYn,
    parkType: state.place.parkType,
    categoryTypes: state.place.categoryTypes,
  }));

  const nowType = useMemo(() => queries.type as EPlaceCategoryType, [queries]);

  const placeQueryList = useMemo(() => getPlaceQueryList(nowType), [nowType]);
  const nowPlaceQuery = useMemo(() => {
    const placeQueryItem = placeQueryList.find((v) => v.id === placeQuery);

    if (placeQueryItem) {
      return placeQueryItem;
    }

    return undefined;
  }, [placeQuery, placeQueryList]);

  const resetToggler: TToggler = useMemo(() => {
    return getResetToggler({
      placeQuery,
      oilCompany,
      evCompany,
      evPublicType,
      evChargerSpeed,
      evChargerStatus,
      evChargerType,
      parkType,
      tmapPaymentYn,
    });
  }, [
    oilCompany,
    evChargerSpeed,
    evChargerStatus,
    evChargerType,
    evCompany,
    evPublicType,
    placeQuery,
    parkType,
    tmapPaymentYn,
  ]);

  const popularFilterList: TToggler[] | undefined = useMemo(() => {
    if (nowType !== EPlaceCategoryType.POPULAR) {
      return;
    }
    const toggler = (label, cate, clickCode) => {
      const isOn = categoryTypes.includes(cate);

      return {
        label,
        isOn,
        params: [
          {categoryTypes: categoryTypes.filter((i) => i !== cate)},
          {categoryTypes: [...categoryTypes, cate]},
        ],
        clickCode,
      };
    };

    return [
      toggler('음식점', ECategoryCode.POPULAR_FOOD, 'tap.restaurant'),
      toggler('카페', ECategoryCode.POPULAR_CAFE, 'tap.cafe'),
    ];
  }, [nowType, categoryTypes]);

  const tmapPayToggler: TToggler = useMemo(() => {
    return {
      label: '티맵결제',
      params: [{tmapPaymentYn: undefined}, {tmapPaymentYn: 'Y'}],
      isOn: tmapPaymentYn === 'Y',
      clickCode: 'tap.tmap_pay',
    };
  }, [tmapPaymentYn]);

  const parkPublicToggler: TToggler = useMemo(() => {
    return {
      label: '공영주차장',
      params: [{parkType: undefined}, {parkType: EParkingLotType.PUB_PARK}],
      isOn: parkType === EParkingLotType.PUB_PARK,
      clickCode: 'tap.public_parkinglot',
    };
  }, [parkType]);

  const filterOptions = useMemo(() => {
    if (nowType === EPlaceCategoryType.PARKING_LOT) {
      return {resetToggler, tmapPayToggler, parkPublicToggler};
    }

    return {placeQueryList};
  }, [nowType, placeQueryList, resetToggler, tmapPayToggler, parkPublicToggler]);

  return {
    nowType,
    nowPlaceQuery,
    ...filterOptions,
    popularFilterList,
  };
};

export default usePlaceFilter;
