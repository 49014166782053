import {EBusType, ECityCode, EFavoriteType, ESortOption} from 'types/Search';
import {ReactComponent as IconHome} from 'resource/images/@tmds_solid/ico_home_solid.svg';
import {ReactComponent as IconCompany} from 'resource/images/@tmds_solid/ico_company_solid.svg';
import {ReactComponent as IconStar} from 'resource/images/@tmds_solid/ico_star_solid.svg';
import {ReactComponent as IconCar} from 'resource/images/@tmds_solid/ico_vehicle_car_solid.svg';
import {ReactComponent as IconBus} from 'resource/images/@tmds_solid/ico_vehicle_bus_solid.svg';
import {ESearchCollectionType} from 'ducks/search/types';
import cssValues from 'styles/modules/ExportedVariables.module.scss';

export const ESortLabel = {
  [ESortOption.DISTANCE]: '거리순',
  [ESortOption.PRICE]: '가격순',
  [ESortOption.SCORE]: '정확도순',
};

export const SEARCH_COLLECTION_LABEL: Record<ESearchCollectionType, string> = {
  [ESearchCollectionType.POI]: '장소',
  [ESearchCollectionType.BUS_LINE]: '버스',
  [ESearchCollectionType.BUS_STATION]: '정류장',
};

export const EBannerHeight = {
  SEARCH_MAIN: 51,
  SEARCH_RESULT: 56,
};

export const SEARCH_INPUT_MAX_LENGTH = 50;

export const SEARCH_RECENT_MAX_COUNT = 100;

export const TYPE_LABEL = {
  [EFavoriteType.POI]: {
    label: '장소',
    icon: <IconStar />,
  },
  [EFavoriteType.ROUTE]: {
    label: '주행 경로',
    icon: <IconCar />,
  },
  [EFavoriteType.PUBLIC_TRANSPORT]: {
    label: '대중교통 경로',
    icon: <IconBus />,
  },
  [EFavoriteType.HOME]: {
    label: '집',
    icon: <IconHome />,
  },
  [EFavoriteType.OFFICE]: {
    label: '회사',
    icon: <IconCompany />,
  },
};

export const NOW_SCORE_MIN_COUNT = 0;
export const WEEK_SCORE_MIN_COUNT = 5;

export const CITY_NAME_MAP: Record<ECityCode, string> = {
  [ECityCode.SEOUL]: '서울',
  [ECityCode.BUSAN]: '부산',
  [ECityCode.DAEGU]: '대구',
  [ECityCode.INCHEON]: '인천',
  [ECityCode.GWANGJU]: '광주',
  [ECityCode.DAEJEON]: '대전',
  [ECityCode.ULSAN]: '울산',
  [ECityCode.SEJONG]: '세종',
  [ECityCode.GYEONGGI]: '경기',
  [ECityCode.GANGWON]: '강원',
  [ECityCode.CHUNGBUK]: '충북',
  [ECityCode.CHUNGGNAM]: '충남',
  [ECityCode.JEONBUK]: '전북',
  [ECityCode.JEONNAM]: '전남',
  [ECityCode.GYUNGBUK]: '경북',
  [ECityCode.GYUNGNAM]: '경남',
  [ECityCode.JEJU]: '제주',
};

export const BUS_COLOR_MAP: Record<EBusType, string> = {
  [EBusType.NORMAL]: cssValues.ptGreen500,
  [EBusType.SEAT]: cssValues.ptBlue700,
  [EBusType.TOWN]: cssValues.ptGreen400,
  [EBusType.DIRECT]: cssValues.ptRed300,
  [EBusType.AIRPORT]: cssValues.ptBlue100,
  [EBusType.GANSUN_EXPRESS]: cssValues.ptRed300,
  [EBusType.OUTSIDE]: cssValues.ptGreen300,
  [EBusType.GANSUN]: cssValues.ptBlue400,
  [EBusType.JISUN]: cssValues.ptGreen700,
  [EBusType.CIRCULAR]: cssValues.ptYellow100,
  [EBusType.WIDE_AREA]: cssValues.ptRed300,
  [EBusType.EXPRESS]: cssValues.ptRed300,
  [EBusType.TOUR]: cssValues.ptRed200,
  [EBusType.RURAL]: cssValues.ptYellow400,
  [EBusType.INTERCITY]: cssValues.ptRed400,
  [EBusType.EXPRESS_GANSUN]: cssValues.ptRed300,
  [EBusType.WIDE_AREA_EXPRESS]: cssValues.ptBlue150,
};
