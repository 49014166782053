export const updateByIndex = (array = [] as any[], index, item) => {
  return [...array.slice(0, index), item, ...array.slice(index + 1)];
};

export const removeByIndex = (array = [] as any[], index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const isDifferentArray = (arr1, arr2, comparator) => {
  if (arr1.length !== arr2.length) {
    return true;
  }

  return arr1.map(comparator).join('') !== arr2.map(comparator).join('');
};

export const getArraySum = (arr: number[], getValue = (n) => n) =>
  arr.reduce((sum, now) => sum + getValue(now), 0);

export const splitArraySimilarSum = (arr: number[]) => {
  const arr1: number[] = [];
  const arr2: number[] = [];
  let score1 = 0;
  let score2 = 0;

  arr.forEach((n) => {
    if (score1 <= score2) {
      arr1.push(n);
      score1 += n;
    } else {
      arr2.push(n);
      score2 += n;
    }
  });

  return [arr1, arr2];
};

export const isSameArray = (arr1, arr2, key = 'id') => {
  return arr1.length === arr2.length && arr2.every((a2) => arr1.some((a1) => a1[key] === a2[key]));
};

export const removeFromArr = <T>(arr: T[] = [], item: T) => {
  return arr?.filter((i) => i !== item);
};

export const insertToArr = <T>(arr: T[] = [], item: T) => {
  return arr?.includes(item) ? arr : [...arr, item];
};

export const sortByPriority = (targetArr, priorityArr) => {
  if (targetArr && priorityArr) {
    const orderArr = [...targetArr].sort((a, b) => {
      const aScore = priorityArr.includes(a) ? priorityArr.indexOf(a) : 999;
      const bScore = priorityArr.includes(b) ? priorityArr.indexOf(b) : 999;

      return aScore - bScore;
    });

    return orderArr;
  }
  return [];
};

export const hasSameElementArray = <T = string | number>(arr1?: T[], arr2?: T[]) => {
  if (arr1 && arr2 && arr1.length === arr2.length) {
    const passArr1 = arr1.every((item) => arr2.includes(item));
    const passArr2 = arr2.every((item) => arr1.includes(item));

    return passArr1 && passArr2;
  }
  return;
};
