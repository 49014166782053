import {getDefaultStatusBarHeight, getIsHybrid} from 'utils/tmapInApp';

export type WindowSize = {
  width: number;
  height: number;
};

export type AppSize = {
  statusBarHeight: number;
  isLandscape: boolean;
  displayHeight: number;
  searchBarHeight: number;
};

export type RefreshEvent = {
  needReLayout: number;
  closeTooltip: number;
  getListLeft: number;
};

export type WebSize = {
  listLeft: number;
};

export type LayoutState = {
  windowSize: WindowSize;
  appSize: AppSize;
  webSize: WebSize;
  refreshEvent: RefreshEvent;
  loadedFirstBanner: boolean;
  isHybrid: boolean;
};

export const getDefaultState: () => LayoutState = () => ({
  windowSize: {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  },
  appSize: {
    statusBarHeight: getDefaultStatusBarHeight(),
    displayHeight: document.documentElement.clientHeight,
    searchBarHeight: getIsHybrid() ? 58 : 0,
    isLandscape: document.documentElement.clientWidth > document.documentElement.clientHeight,
  },
  webSize: {
    listLeft: 0,
  },
  refreshEvent: {
    needReLayout: 0,
    closeTooltip: 0,
    getListLeft: 0,
  },
  loadedFirstBanner: false,
  isHybrid: getIsHybrid(),
});
