import {useEffect, useMemo} from 'react';
import {LOG_PARAM_ALL, LOG_PARAM_PREFIX, LOG_PARAM_SEPARATOR} from 'constant/Log';
import {useAppDispatch} from 'ducks/hooks';
import {ELogScope, TLogParamQuery} from 'ducks/log/types';
import {getAllParams} from 'utils/apis';
import actions from 'ducks/actions';

export const parseTailLogParam = (
  tailParams: TLogParamQuery
): Record<ELogScope, Record<string, string>> => {
  const logPrefix = `${LOG_PARAM_PREFIX}${LOG_PARAM_SEPARATOR}`;
  const logValue = Object.keys(tailParams)
    .filter((a) => a.includes(logPrefix))
    .sort((a) => (a.includes(`${logPrefix}${LOG_PARAM_ALL}`) ? -1 : 1)) // 개별 scope 가 log_all 을 덮어 씌우도록 우선순위 반영
    .reduce(
      (prev, cur) => {
        const [, scope, keyName] = cur.split(LOG_PARAM_SEPARATOR);

        if (scope === LOG_PARAM_ALL) {
          return {
            app: {...prev.app, [keyName]: tailParams[cur]},
            expose: {...prev.expose, [keyName]: tailParams[cur]},
            click: {...prev.click, [keyName]: tailParams[cur]},
          };
        }

        return {
          ...prev,
          [scope]: {
            ...prev[scope],
            [keyName]: tailParams[cur],
          },
        };
      },
      {app: {}, expose: {}, click: {}}
    );

  return logValue;
};

export const useParamLog = () => {
  const dispatch = useAppDispatch();
  const logParam = useMemo(() => {
    try {
      const params = getAllParams();

      return parseTailLogParam(params.tailParam);
    } catch (e) {
      return {};
    }
  }, []);

  useEffect(() => {
    dispatch(actions.log.addBypassCustomItems(logParam));
  }, [dispatch, logParam]);

  const referrer = useMemo(() => {
    const referrerKey = logParam
      ? Object.values(ELogScope).find((v) => logParam[v]?.referrer)
      : undefined;

    return referrerKey ? logParam[referrerKey].referrer : '';
  }, [logParam]);

  return {
    referrer,
    logParam,
  };
};
