import {useEffect, useRef} from 'react';
import Flicking from '@egjs/react-flicking';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import RankingPoiCardViewItem from './RankingPoiCardViewItem';
import {useSearchImageList} from 'hooks/useSearchImageList';
import {TRankItem} from 'ducks/rank/types';

import s from 'styles/components/ranking/RankingCardListMode.module.scss';

type TProps = {
  list: TRankItem[];
  hide: boolean;
  setCardMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const RankingCardListMode = ({list, hide, setCardMode}: TProps) => {
  const dispatch = useAppDispatch();
  const refFlicking = useRef<Flicking>(null);
  const imageCounter = useSearchImageList();
  const {rdLayout, activePoi, userInteraction} = useAppSelector((state) => ({
    rdLayout: state.layout,
    isHybrid: state.layout.isHybrid,
    userInteraction: state.userInteraction,
    lastCachedCenter: state.map.lastCachedCenter,
    searchBarHeight: state.layout.appSize.searchBarHeight,
    activePoi: state.userInteraction.activeTargetPoi,
    windowSize: state.layout.windowSize,
  }));

  useEffect(() => {
    const landscape = rdLayout.appSize.isLandscape;

    if (landscape) {
      setCardMode(false);
    }
  }, [rdLayout.appSize.isLandscape]);

  useEffect(() => {
    if (!activePoi || hide) {
      return;
    }
    const index = list.findIndex((v) => v.listId === activePoi);

    if (index === -1) {
      setCardMode(false);
      return;
    }

    refFlicking.current?.moveTo(index, 0, false);
  }, [activePoi, list, hide]);

  useEffect(() => {
    if (list.length === 0) {
      setCardMode(false);
    }
  }, [list]);

  if (hide || userInteraction.calloutInfo || list.length === 0) {
    return null;
  }

  return (
    <div className={s.wrap}>
      <div className={s.list_wrap}>
        <div className={s.flicking_wrap}>
          <Flicking
            circular={true}
            panelsPerView={1.1}
            moveType="strict"
            onChanged={(e) => {
              const index = e.index;

              dispatch(
                actions.userInteraction.setInteraction({
                  activePoi: list[index]?.listId,
                  trigger: 'scroll',
                })
              );
            }}
            ref={refFlicking}
          >
            {list.map((v, i) => (
              <div className={s.card} key={`${v.pkey}-${i}`}>
                <RankingPoiCardViewItem
                  key={`${v.pkey}-${i}`}
                  idx={i}
                  poiData={{
                    ...v,
                    listName: v.poiName,
                    imageInfo: imageCounter.getTmapRankingListByViewPort(v.imageInfo),
                  }}
                />
              </div>
            ))}
          </Flicking>
        </div>
      </div>
    </div>
  );
};

export default RankingCardListMode;
