import {ESortOption} from 'types/Search';
import {
  EBankType,
  EEVChargerType,
  EOilCompany,
  EOilType,
  EParkingLotType,
  EPlaceCategoryType,
  EEVChargerSpeed,
  EPlaceFeature,
  EAccommodationType,
} from 'types/App';
import {
  ConvenienceOption,
  EAllOilCompany,
  EDieselCompany,
  EGasolineCompany,
  ELPGCompany,
  EPremiumOilCompany,
  GasStationOption,
} from './Poi';

export const ESortLabelTNow = {
  [ESortOption.DISTANCE]: '거리순',
  [ESortOption.PRICE]: '가격순',
  [ESortOption.SCORE]: '추천순',
  [ESortOption.DEST_SCORE]: '추천순',
};

const EGasFuelLabel = {
  [EOilType.ALL]: '전체',
  [EOilType.GASOLINE]: '휘발유',
  [EOilType.DIESEL]: '경유',
  [EOilType.GASOLINE_PREMIUM]: '고급휘발유',
  [EOilType.LPG]: 'LPG',
};

const EEVChargerLabel = {
  [EEVChargerType.AC3]: 'AC3상',
  [EEVChargerType.SLOW]: '완속',
  [EEVChargerType.DC_FAST]: 'DC차데모',
  [EEVChargerType.DC_COMBO]: 'DC콤보',
  [EEVChargerType.HYDROGEN]: '수소',
  [EEVChargerType.SUPER_CHARGER]: 'Tesla 수퍼차저',
};

export const EEVChargerSpeedLabel = {
  [EEVChargerSpeed.SLOW]: '완속',
  [EEVChargerSpeed.FAST]: '급속',
  [EEVChargerSpeed.SUPER_FAST]: '초급속',
};

const EBankLabel = {
  [EPlaceCategoryType.BANK_ALL]: '전체',
  [EBankType.BANK]: '은행',
  [EBankType.ATM]: 'ATM',
};

const EParkingLotLabel = {
  [EParkingLotType.PUBLIC]: '공영',
};

const EPlaceFeatureLabel = {
  [EPlaceFeature.CAR_WASH]: '세차장',
  [EPlaceFeature.CAR_TUNING]: '경정비',
  [EPlaceFeature.CONVENIENCE_STORE]: '편의점',
  [EPlaceFeature.SELF_STATION]: '셀프',
  [EPlaceFeature.ALLDAY_HOURS]: '24시간영업',
};

const EAccommodationLabel: Record<EAccommodationType, string> = {
  [EAccommodationType.HOTEL]: '호텔',
  [EAccommodationType.RESORT]: '콘도/리조트',
  [EAccommodationType.PENSION]: '펜션',
  [EAccommodationType.CAMP]: '캠핑',
  [EAccommodationType.MOTEL]: '모텔',
  [EAccommodationType.ETC]: '기타',
};

const parseToOption = (v, ELabel) => ({id: v, label: ELabel[v]});

export const getOilGasPlaceFeatureList = () => {
  return [
    EPlaceFeature.ALLDAY_HOURS,
    EPlaceFeature.SELF_STATION,
    EPlaceFeature.CAR_WASH,
    EPlaceFeature.CAR_TUNING,
    EPlaceFeature.CONVENIENCE_STORE,
  ].map((t) => parseToOption(t, EPlaceFeatureLabel));
};

export const getOilGasPlaceQueryList = () => {
  return [
    EOilType.ALL,
    EOilType.GASOLINE,
    EOilType.DIESEL,
    EOilType.GASOLINE_PREMIUM,
    EOilType.LPG,
  ].map((t) => parseToOption(t, EGasFuelLabel));
};

export const getAccommodationPlaceQueryList = () => {
  return Object.values(EAccommodationType).map((t) => parseToOption(t, EAccommodationLabel));
};

export const getPlaceQueryList = (type?: string) => {
  switch (type) {
    case EPlaceCategoryType.CV_ALL:
      return ConvenienceOption;

    case EPlaceCategoryType.BANK_ALL:
      return [EPlaceCategoryType.BANK_ALL, EBankType.BANK, EBankType.ATM].map((t) =>
        parseToOption(t, EBankLabel)
      );

    case EPlaceCategoryType.PARKING_LOT:
      return [EParkingLotType.PUBLIC].map((t) => parseToOption(t, EParkingLotLabel));

    case EPlaceCategoryType.MART_ALL:
    default:
      return [];
  }
};

export const getOilCompanyList = (type?: EOilType) => {
  const onlyOilList = GasStationOption.filter((v) => v?.id !== EOilCompany.E1);
  const parseList = (arr, categoryIdsMap) => {
    const list = arr.map((v) => {
      const categoryId = v?.id && categoryIdsMap[v.id];

      return {
        ...v,
        brandId: v?.id,
        id: categoryId,
      };
    });

    return list;
  };

  switch (type) {
    case EOilType.GASOLINE:
      return parseList(onlyOilList, EGasolineCompany);

    case EOilType.GASOLINE_PREMIUM:
      return parseList(onlyOilList, EPremiumOilCompany);

    case EOilType.LPG:
      return parseList(GasStationOption, ELPGCompany);

    case EOilType.DIESEL:
      return parseList(onlyOilList, EDieselCompany);

    default:
      return parseList(GasStationOption, EAllOilCompany);
  }
};

export enum ECategoryCode {
  POLLING_PLACE = 'BSE06999901',
  PRE_POLLING_PLACE = 'BSE06999902',
  PUBLIC_PARKLOT = 'BSE01020301',
  POPULAR_FOOD = 'FUDALL',
  POPULAR_CAFE = 'CAFEALL',
  POPULAR_BAR = 'FUDBAR',

  RESTAURANT = 'BSE0201',
  CHICKEN = 'BSE020112',
  BAKERY = 'BSE020113',
  CAFE = 'BSE0202',
  BAR = 'BSE0203',

  GAS_STATION = 'BSE010201',
  GAS_CHARGING_STATION = 'BSE010202',
  SK = 'BSE01020101',
  GS = 'BSE01020102',
  S_OIL = 'BSE01020103',
  OIL_BANK = 'BSE01020104',
  E1 = 'BSE01020208',

  CONVENIENCE_STORE = 'BSE080104',
  CU = 'BSE08010403',
  GS25 = 'BSE08010405',
  SEVEN_ELEVEN = 'BSE08010401',
  MINI_STOP = 'BSE08010404',
  EMART = 'BSE08010411',
}

export enum EAddressCode {
  D_2_SEJONG = '3611000000',
}

export const EEVChargerSpeedOptions = [
  EEVChargerSpeed.SUPER_FAST,
  EEVChargerSpeed.FAST,
  EEVChargerSpeed.SLOW,
].map((t) => parseToOption(t, EEVChargerSpeedLabel));

export const EEVChargerTypeOptions = [
  EEVChargerType.DC_FAST,
  EEVChargerType.AC3,
  EEVChargerType.DC_COMBO,
  EEVChargerType.SLOW,
  EEVChargerType.SUPER_CHARGER,
  // EEVChargerType.HYDROGEN,
].map((t) => parseToOption(t, EEVChargerLabel));

export const MIN_HEADING_COUNT = 4;
export const MIN_PARKING_LOT_COUNT = 5;
