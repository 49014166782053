import {EOilCompany, EOilType, EPlaceCategoryType} from 'types/App';
import {DEFAULT_VALID_CATEGORY_PAGE} from './PlaceCategory';
import {EDieselCompany, EGasolineCompany, ELPGCompany} from './Poi';

export const DEEPLINK_PROTOCOL = 'tmap://';

export const POI_DETAIL_SERVICE_NAME = 'poi-detail';

export const DeepLinkCategoryMap = {
  0: {title: '주유', type: EPlaceCategoryType.OIL_GAS_ALL},
  1: {title: '주차장', type: EPlaceCategoryType.PARKING_LOT},
  2: {title: '음식점', type: EPlaceCategoryType.FOOD_ALL},
  3: {title: '카페/디저트', type: EPlaceCategoryType.FOOD_CAFE},
  4: {title: '관광지', type: EPlaceCategoryType.EMPTY},
  5: {title: '숙소', type: EPlaceCategoryType.HOTL_ALL},
  6: {title: '마트', type: EPlaceCategoryType.EMPTY},
  7: {title: '편의점', type: EPlaceCategoryType.CV_ALL},
  8: {title: '은행', type: EPlaceCategoryType.BANK_ALL},
  9: {title: '병원', type: EPlaceCategoryType.EMPTY},
  10: {title: '약국', type: EPlaceCategoryType.EMPTY},
  11: {title: '세차/정비소', type: EPlaceCategoryType.EMPTY},
};

const placeCategoryReqKeyMap = DEFAULT_VALID_CATEGORY_PAGE.reduce((prev, cur) => {
  return {
    ...prev,
    [cur.type]: cur,
  };
}, {});

// reqKey 추가 시 문서에 업데이트
// https://tmobi.atlassian.net/wiki/spaces/TMAPPOINEW/pages/122258969#%ED%8B%B0%EB%A7%B5-%ED%94%8C%EB%9F%AC%EC%8A%A4-%EC%A7%80%EC%9B%90%EC%9A%A9-reqKey-%EC%BF%BC%EB%A6%AC
export const DefaultDeepLinkReqKeyMap = {
  ...placeCategoryReqKeyMap,
  OILALL_OILGASSKE: {
    type: EPlaceCategoryType.OIL_GAS_ALL,
    filter1: EOilType.GASOLINE,
    filter2: EGasolineCompany[EOilCompany.SK],
  },
  OILALL_OILLPGSKE: {
    type: EPlaceCategoryType.OIL_GAS_ALL,
    filter1: EOilType.LPG,
    filter2: ELPGCompany[EOilCompany.SK],
  },
  OILALL_OILDSLSKE: {
    type: EPlaceCategoryType.OIL_GAS_ALL,
    filter1: EOilType.DIESEL,
    filter2: EDieselCompany[EOilCompany.SK],
  },
};
