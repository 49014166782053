import {useState, useEffect} from 'react';
import usePrevious from './usePrevious';

const useStartWhenTrue = (
  validate: boolean,
  onChangeToTrue?: VoidFunction,
  onChangeToFalse?: VoidFunction
) => {
  const prevInfo = usePrevious(validate);
  const [validCallback, setValidCallback] = useState<boolean>(false);

  useEffect(() => {
    if (!prevInfo && validate) {
      setValidCallback(true);
    }
  }, [validate]);

  useEffect(() => {
    if (validCallback) {
      if (validate) {
        onChangeToTrue?.();
      } else {
        onChangeToFalse?.();
        setValidCallback(false);
      }
    }
  }, [validCallback, validate]);
};

export default useStartWhenTrue;
