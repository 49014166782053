import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {API_PATH, FULFILLED_STATE, PENDING_STATE, REJECTED_STATE} from 'constant/Api';
import {ECategoryCode} from 'constant/Place';
import {StoreState} from 'ducks/store';
import {
  EOilType,
  EParkingLotType,
  EPlaceCategoryType,
  EPlaceFeature,
  EPlaceFeatureOperator,
} from 'types/App';
import {getDefaultApiStatus} from 'utils/apis';
import fetcher from 'utils/fetcher';
import {getPlaceTagList} from 'utils/general';
import Paging from 'utils/paging';
import ua from 'utils/uaParser';
import {
  TPlaceState,
  TPlacePayload,
  TPlaceProps,
  TPopularPayload,
  TPopularProps,
  TPlaceApiResponse,
  TPlaceItem,
  TPosProps,
  TPlaceCategoryFilters,
  TBasePosParam,
} from './types';
import actions from 'ducks/actions';
import {EPerformanceLogKey} from 'types/Log';
import {TLonLat} from 'types/Map';
import {getDefaultValidCategoryPage} from 'constant/PlaceCategory';

const ACTION_PREFIX = 'place';
const DEFAULT_PAGING_SIZE = 20;
const MAX_LIST_SIZE = 100;
const DEFAULT_RADIUS = 50;

export const placeFilterInitialState: TPlaceCategoryFilters = {
  placeQuery: undefined,
  oilCompany: undefined,
  accommodationList: undefined,
  evSvcOperatorIds: undefined,
  tmapParkYn: undefined,
  tmapPaymentYn: undefined,
  evPublicType: undefined,
  evChargerSpeed: undefined,
  evChargerStatus: undefined,
  evChargerType: undefined,
  categories: undefined,
  categoryTypes: undefined,
  placeFeatureIds: undefined,
  placeFeatureOperator: undefined,
};

const initialState: TPlaceState = {
  ...getDefaultApiStatus<TPlaceApiResponse>({
    totalCount: 0,
    list: [],
    originList: [],
    currentPage: 0,
    pagingSize: DEFAULT_PAGING_SIZE,
  }),
  sort: undefined,
  dataLonLat: undefined,
  ...placeFilterInitialState,
};

const changeValuesArrayToString = (obj) => {
  const params = Object.keys(obj).reduce((prev, curKey) => {
    if (obj[curKey]?.length > 0) {
      return {
        ...prev,
        [curKey]: obj[curKey].join(','),
      };
    }

    return prev;
  }, {});

  return params;
};

// https://tde.sktelecom.com/wiki/pages/viewpage.action?pageId=381681509
export const fetchPlaceList = createAsyncThunk<TPlacePayload, TPlaceProps>(
  `${ACTION_PREFIX}/list`,
  async (props, creator) => {
    const storeState = creator.getState() as StoreState;

    const {lat: userRealLat, lon: userRealLon} = storeState.map.userPosition || {};

    const initLog = !storeState.log.pair[EPerformanceLogKey.LIST_API];
    const initTime = Date.now();

    if (initLog) {
      creator.dispatch(
        actions.log.addStartItem({key: EPerformanceLogKey.LIST_API, time: initTime})
      );
      creator.dispatch(
        actions.log.addStartItem({key: EPerformanceLogKey.INIT_API_RENDER, time: initTime})
      );
    }

    const sort = storeState.place.sort || getDefaultValidCategoryPage(props.query)?.sort?.default;
    const placeQuery = props.placeQuery || storeState.place.placeQuery;
    const tmapPaymentYn = props.tmapPaymentYn || storeState.place.tmapPaymentYn;
    const tmapParkYn = props.tmapParkYn || storeState.place.tmapParkYn;
    const oilCompany = props.oilCompany || storeState.place.oilCompany;
    const accommodationList = props.accommodationList || storeState.place.accommodationList;
    const evSvcOperatorIds = props.evSvcOperatorIds || storeState.place.evSvcOperatorIds;
    const parkType = props.parkType || storeState.place.parkType;
    const evPublicType = props.evPublicType || storeState.place.evPublicType;
    const chargerSpeed = props.evChargerSpeed || storeState.place.evChargerSpeed;
    const evChargerType = props.evChargerType || storeState.place.evChargerType;
    const evChargeStatus = props.evChargerStatus || storeState.place.evChargerStatus;
    const placeFeatureIds = props.placeFeatureIds || storeState.place.placeFeatureIds || [];
    const placeFeatureOperator =
      props.placeFeatureOperator ||
      storeState.place.placeFeatureOperator ||
      EPlaceFeatureOperator.AND;

    let query = placeQuery || props.query;
    let extraOption: any = {};
    const hasEVFilter = query === EPlaceCategoryType.OIL_EV_ALL;

    if (query === EPlaceCategoryType.SS_HOLIDAY_HSPT) {
      query = EPlaceCategoryType.HSPTALL;
      extraOption.holidayBusinessYn = 'Y';
    }

    const evParam = hasEVFilter
      ? changeValuesArrayToString({
          evSvcOperatorIds,
          chargerSpeed,
          evChargeType: evChargerType,
        })
      : {};

    let param = {
      parkType,
      appVersion: ua.tmapAppVersion,
      page: 1,
      size: MAX_LIST_SIZE,
      lat: props.lat,
      lon: props.lon,
      sort,
      query,
      userRealLat,
      userRealLon,
      ticketId: storeState.userInfo.sessionKey || '',
      feature: placeFeatureIds.join(','),
      featureOperator: placeFeatureOperator,
      ...extraOption,
      ...evParam,
      ...(evChargeStatus ? {evChargeStatus} : {}),
      ...(evPublicType ? {evPublicType} : {}),
      ...(oilCompany ? {categories: oilCompany} : {}),
      ...(accommodationList ? {categories: accommodationList} : {}),
    };

    if (parkType === EParkingLotType.PUB_PARK) {
      param.parkType = undefined;
      param.categories = [ECategoryCode.PUBLIC_PARKLOT];
    }

    if (placeQuery !== EOilType.LPG) {
      param = {
        ...param,
        ...(tmapPaymentYn ? {tmapPaymentYn} : {}),
        ...(tmapParkYn ? {tmapParkYn} : {}),
      };
    }

    if (hasEVFilter) {
      param.query = undefined;
    }

    const response = await fetcher.post(
      hasEVFilter ? API_PATH.POST_EV_PLACE_LIST : API_PATH.POST_PLACE_LIST,
      param
    );

    initLog &&
      creator.dispatch(
        actions.log.addEndItem({key: EPerformanceLogKey.LIST_API, time: Date.now()})
      );

    creator.dispatch(actions.log.addEndItem({key: EPerformanceLogKey.LIST_API, time: Date.now()}));
    creator.dispatch(actions.place.updateDataLonLat({lon: param.lon, lat: param.lat}));

    return response.data;
  }
);

// https://tde.sktelecom.com/wiki/pages/viewpage.action?pageId=375598041
export const fetchPopularList = createAsyncThunk<TPopularPayload, TPopularProps>(
  `${ACTION_PREFIX}/popular-list`,
  async (props, creator) => {
    const storeState = creator.getState() as StoreState;

    const {lat: userRealLat, lon: userRealLon} = storeState.map.userPosition || {};
    const sort = storeState.place.sort;

    const initLog = !storeState.log.pair[EPerformanceLogKey.LIST_API];
    const initTime = Date.now();
    const featureIds =
      props.placeFeatureIds?.join(',') || storeState.place.placeFeatureIds?.join(',');

    if (initLog) {
      creator.dispatch(
        actions.log.addStartItem({key: EPerformanceLogKey.LIST_API, time: initTime})
      );
      creator.dispatch(
        actions.log.addStartItem({key: EPerformanceLogKey.INIT_API_RENDER, time: initTime})
      );
    }

    const response = await fetcher.post(API_PATH.POST_POPULAR_LIST, {
      ...props,
      categoryTypes: props.categoryTypes || storeState.place.categoryTypes,
      userRealLat,
      userRealLon,
      page: 1,
      sort,
      size: MAX_LIST_SIZE,
      feature: featureIds,
    });

    initLog &&
      creator.dispatch(
        actions.log.addEndItem({key: EPerformanceLogKey.LIST_API, time: Date.now()})
      );

    creator.dispatch(actions.place.updateDataLonLat({lon: props.lon, lat: props.lat}));

    return response.data;
  }
);

// https://tde.sktelecom.com/wiki/pages/viewpage.action?pageId=403918511
export const fetchPosList = createAsyncThunk<TPopularPayload, TPosProps>(
  `${ACTION_PREFIX}/pos-list`,
  async (props, creator) => {
    const storeState = creator.getState() as StoreState;
    const initLog = !storeState.log.pair[EPerformanceLogKey.LIST_API];
    const initTime = Date.now();

    if (initLog) {
      creator.dispatch(
        actions.log.addStartItem({key: EPerformanceLogKey.LIST_API, time: initTime})
      );
      creator.dispatch(
        actions.log.addStartItem({key: EPerformanceLogKey.INIT_API_RENDER, time: initTime})
      );
    }

    const basePosParam: TBasePosParam = {
      appVersion: ua.tmapAppVersion,
      sort:
        storeState.place.sort ||
        getDefaultValidCategoryPage(EPlaceCategoryType.POPULAR)?.sort?.default,
      categories: storeState.place.categories,
      filter: storeState.place.placeQuery,
    };

    const reqProps = {
      page: 1,
      size: MAX_LIST_SIZE,
      radius: DEFAULT_RADIUS,
      ticketId: storeState.userInfo.sessionKey,
      userRealLat: storeState.map.userPosition?.lat,
      userRealLon: storeState.map.userPosition?.lon,
      ...basePosParam,
      ...props,
    };

    const response = await fetcher.post(API_PATH.GET_POS_LIST, reqProps);

    initLog &&
      creator.dispatch(
        actions.log.addEndItem({key: EPerformanceLogKey.LIST_API, time: Date.now()})
      );

    return response.data;
  }
);

const getPendingState = (state) => {
  state.loading = PENDING_STATE.loading;
  state.loaded = PENDING_STATE.loaded;
};

const getFulfilledState = (state: TPlaceState, action, pagingSize = DEFAULT_PAGING_SIZE) => {
  const list: TPlaceItem[] = (action.payload.data?.docs || []).map(
    (v: TPlaceItem): TPlaceItem => ({
      ...v,
      poiId: `${v.poiId}`,
      imageInfo: v.imageInfo || [],
      headingForTick: undefined,
      placeTags: getPlaceTagList(v.tag || v),
      distance: v.distance * 1000,
      userRealDistance: v.userRealDistance * 1000,
      listId: `${v.pkey}-${v.poiId}-${v.navSeq}`,
    })
  );

  state.loading = FULFILLED_STATE.loading;
  state.loaded = FULFILLED_STATE.loaded;
  state.error = FULFILLED_STATE.error;

  state.data.currentPage = 1;
  state.data.totalCount = action.payload.data.totalCount;
  state.data.originList = list;
  state.data.list = list.slice(0, pagingSize);
  state.data.isLastPage = list.length <= pagingSize;
};

const getRejectedState = (state, action) => {
  state.loading = REJECTED_STATE.loading;
  state.loaded = REJECTED_STATE.loaded;
  state.data = initialState.data;
  state.error = action.error;
};

const placeSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    resetList: (state) => {
      state.loaded = initialState.loaded;
      state.loading = initialState.loading;
      state.data = initialState.data;
    },
    reset: (state) => {
      state.loaded = initialState.loaded;
      state.loading = initialState.loading;
      state.data = initialState.data;
      state.sort = initialState.sort;
      state.placeQuery = initialState.placeQuery;
      state.evSvcOperatorIds = initialState.evSvcOperatorIds;
      state.tmapParkYn = initialState.tmapParkYn;
      state.tmapPaymentYn = initialState.tmapPaymentYn;
      state.oilCompany = initialState.oilCompany;
      state.accommodationList = initialState.accommodationList;
      state.parkType = initialState.parkType;

      state.evPublicType = initialState.evPublicType;
      state.evChargerSpeed = initialState.evChargerSpeed;
      state.evChargerStatus = initialState.evChargerStatus;
      state.evChargerType = initialState.evChargerType;
      state.categories = initialState.categories;
      state.categoryTypes = initialState.categoryTypes;

      state.placeFeatureIds = initialState.placeFeatureIds;
      state.placeFeatureOperator = initialState.placeFeatureOperator;
    },

    resetAllFilters: (state, action: PayloadAction<TPlaceCategoryFilters>) => {
      const resetTarget = {
        ...initialState,
        ...(action.payload || {}),
      };

      state.placeQuery = resetTarget.placeQuery;
      state.evSvcOperatorIds = resetTarget.evSvcOperatorIds;
      state.tmapParkYn = resetTarget.tmapParkYn;
      state.tmapPaymentYn = resetTarget.tmapPaymentYn;
      state.oilCompany = resetTarget.oilCompany;
      state.accommodationList = resetTarget.accommodationList;
      state.parkType = resetTarget.parkType;

      state.evPublicType = resetTarget.evPublicType;
      state.evChargerSpeed = resetTarget.evChargerSpeed;
      state.evChargerStatus = resetTarget.evChargerStatus;
      state.evChargerType = resetTarget.evChargerType;
      state.categories = resetTarget.categories;
      state.categoryTypes = resetTarget.categoryTypes;

      state.placeFeatureIds = initialState.placeFeatureIds;
      state.placeFeatureOperator = initialState.placeFeatureOperator;
    },

    updateSort: (state, action) => {
      state.sort = action.payload;
    },
    updateFilter: (state, action) => {
      state.placeQuery = action.payload;
    },
    updateParkType: (state, action) => {
      state.parkType = action.payload;
    },
    updateOilCompany: (state, action) => {
      state.oilCompany = action.payload;
    },
    updateEvCompanyList: (state, action) => {
      state.evSvcOperatorIds = action.payload;
    },
    updateAccommodationList: (state, action) => {
      state.accommodationList = action.payload;
    },
    updateEVFilter: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    updateTMapPaymentYn: (state, action) => {
      state.tmapPaymentYn = action.payload;
    },
    updateTMapParkYn: (state, action) => {
      state.tmapParkYn = action.payload;
    },
    updateNextPage: (state) => {
      const {originList, currentPage} = state.data;

      const next = Paging.getNextPage({originList, currentPage});

      if (next) {
        state.data.list = next.list;
        state.data.currentPage = next.page;
      } else {
        state.data.isLastPage = true;
      }
    },
    updateDataLonLat: (state, action: PayloadAction<TLonLat>) => {
      state.dataLonLat = action.payload;
    },
    updateCategories: (state, action) => {
      state.categories = action.payload;
    },
    updateCategoryTypes: (state, action) => {
      state.categoryTypes = action.payload;
    },
    updatePlaceFeatureIds: (state, action: PayloadAction<EPlaceFeature[]>) => {
      state.placeFeatureIds = action.payload;
    },
    updatePlaceFeatureOperator: (state, action: PayloadAction<EPlaceFeatureOperator>) => {
      state.placeFeatureOperator = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlaceList.pending, getPendingState)
      .addCase(fetchPlaceList.fulfilled, getFulfilledState)
      .addCase(fetchPlaceList.rejected, getRejectedState)

      .addCase(fetchPopularList.pending, getPendingState)
      .addCase(fetchPopularList.fulfilled, getFulfilledState)
      .addCase(fetchPopularList.rejected, getRejectedState)

      .addCase(fetchPosList.pending, getPendingState)
      .addCase(fetchPosList.fulfilled, getFulfilledState)
      .addCase(fetchPosList.rejected, getRejectedState);
  },
});

export default placeSlice;
