import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {API_PATH} from 'constant/Api';
import {StoreState} from 'ducks/store';
import {TApiResponse} from 'types/Api';
import {getDefaultApiStatus} from 'utils/apis';
import fetcher from 'utils/fetcher';
import {getPlaceTagList} from 'utils/general';
import Paging from 'utils/paging';
import {
  TNowBaseSpot,
  TNowPayload,
  TNowProps,
  TNowResult,
  TNowState,
  TNowLinks,
  TNowLinkProps,
  SLIDER_LIST,
  TNOW_MAX_COUNT,
  WHOLE_AREA,
  TNowItem,
  TRecommend,
} from './types';
import actions from 'ducks/actions';
import {getLocalLogTime} from 'utils/date';
import {EPerformanceLogKey} from 'types/Log';
import {EPlaceCategoryType} from 'types/App';
import {TNOW_DEFAULT_CATEGORY} from 'constant/PlaceCategory';

const ACTION_PREFIX = 'tnow';
const DEFAULT_PAGING_SIZE = 20;
const RECOMMEND_LIST_LOADING_MAX_TIME = 1500;

const initialState: TNowState = {
  result: getDefaultApiStatus<TNowResult>({
    list: [],
    originList: [],
    latestUpdateTime: '',
    totalCount: 0,
    currentPage: 1,
    pagingSize: DEFAULT_PAGING_SIZE,
    firstRequestTime: '',
  }),
  baseSpot: {
    lat: undefined,
    lon: undefined,
    radius: Number(SLIDER_LIST[0]),
    fixedRadius: undefined,
    focusId: undefined,
    currentAddressName: undefined,
    displayAddressName: undefined,
    refreshKey: undefined,
  },
  links: getDefaultApiStatus<TNowLinks>({
    recommendAreas: [],
    categoryLinks: [],
  }),
  categoryType: TNOW_DEFAULT_CATEGORY,
};

export const fetchSearchRecommendList = async (props): Promise<TRecommend> => {
  try {
    const response = await fetcher.post(API_PATH.POST_SEARCH_MAIN_RECOMMEND, props, {
      timeout: RECOMMEND_LIST_LOADING_MAX_TIME,
    });

    return response.data.data;
  } catch (e) {
    console.warn(e);
  }

  return {docs: []};
};

const searchTNow = async (props, options) => {
  const response = await fetcher.post(API_PATH.POST_TNOW_LIST, props);
  const areaInfo = await fetcher.get(API_PATH.GET_AREA_INFO, {
    params: {
      lat: props.lat,
      lon: props.lon,
    },
  });

  if (!areaInfo?.data?.data?.address) {
    return response.data;
  }

  // 최대 반경일 경우 더 이상 확장하지 않음
  if (!!props.radius && options?.maxRadius && props.radius === options?.maxRadius) {
    return response.data;
  }

  const list = response.data.data.docs || [];

  if (list.length >= options.minLength || !props.radius) {
    return response.data;
  }

  return null;
};

const getTNowAutoSearch = (
  props
): Promise<TNowPayload & {radius?: number; expandCount?: number; maxRadius?: number}> => {
  const {expandCount, maxRadius, ...originProps} = props;
  const startIndex = SLIDER_LIST.indexOf(originProps.radius || WHOLE_AREA);

  return new Promise(async (resolve, reject) => {
    for (let i = startIndex; i < SLIDER_LIST.length; i++) {
      const radius = SLIDER_LIST[i] === WHOLE_AREA ? undefined : SLIDER_LIST[i];
      const minLength = expandCount || (i === SLIDER_LIST.length - 1 ? 1 : 5);

      try {
        const result = await searchTNow({...originProps, radius}, {minLength, maxRadius});

        if (result) {
          resolve({
            ...result,
            radius: radius || WHOLE_AREA,
          });

          break;
        }
      } catch (e) {
        reject(e);
        break;
      }
    }
  });
};

const tnowDefaultParams = {
  playEatYn: 'Y',
  headingScoreFrom: 1,
};

export const fetchTNowAutoSearch = createAsyncThunk<
  TNowPayload,
  TNowProps & {expandCount?: number; updateFixedRadius?: boolean; maxRadius?: number}
>(`${ACTION_PREFIX}/list-auto-search`, async (props, {dispatch, getState}) => {
  const storeState = getState() as StoreState;

  const {lat: userRealLat, lon: userRealLon} = storeState.map.userPosition || {};

  const initLog = !storeState.log.pair[EPerformanceLogKey.LIST_API];
  const initTime = Date.now();

  if (initLog) {
    dispatch(actions.log.addStartItem({key: EPerformanceLogKey.LIST_API, time: initTime}));
    dispatch(actions.log.addStartItem({key: EPerformanceLogKey.INIT_API_RENDER, time: initTime}));
  }

  const page = 1;
  const size = TNOW_MAX_COUNT;

  const result = await getTNowAutoSearch({
    ...props,
    ...tnowDefaultParams,
    categoryTypes: storeState.tNow.categoryType ? [storeState.tNow.categoryType] : undefined,
    userRealLat,
    userRealLon,
    page,
    size,
  });

  initLog && dispatch(actions.log.addEndItem({key: EPerformanceLogKey.LIST_API, time: Date.now()}));

  dispatch(
    tNowSlice.actions.setBaseSpot({
      radius: result.radius,
      ...(!!props.updateFixedRadius && {fixedRadius: result.radius}),
    })
  );

  return result;
});

// https://tde.sktelecom.com/wiki/pages/viewpage.action?pageId=375598041
export const fetchTNowList = createAsyncThunk<TNowPayload, TNowProps>(
  `${ACTION_PREFIX}/list`,
  async (props, creator) => {
    const storeState = creator.getState() as StoreState;

    const {lat: userRealLat, lon: userRealLon} = storeState.map.userPosition || {};

    const page = 1;
    const size = TNOW_MAX_COUNT;

    const response = await fetcher.post(API_PATH.POST_TNOW_LIST, {
      ...props,
      ...tnowDefaultParams,
      categoryTypes: storeState.tNow.categoryType ? [storeState.tNow.categoryType] : undefined,
      userRealLat,
      userRealLon,
      page,
      size,
    });
    return response.data;
  }
);

export const fetchTNowLinks = createAsyncThunk<TApiResponse<TNowLinks>, TNowLinkProps>(
  `${ACTION_PREFIX}/links`,
  async (props) => {
    const response = await fetcher.get(API_PATH.GET_TNOW_LINK, {
      params: props,
    });
    return response.data;
  }
);

const getPendingState = (state) => {
  state.result.loading = true;
  state.result.loaded = false;
};

const getRejectedState = (state, action) => {
  state.result.loading = false;
  state.result.loaded = true;
  state.result.data = initialState.result.data;
  state.result.error = action.error;
};

const getTNowFulfilledState = (state: TNowState, action) => {
  const data = action.payload.data;
  const list: TNowItem[] = data?.docs.map(
    (v: TNowItem): TNowItem => ({
      ...v,
      imageInfo: v.imageInfo || [],
      distance: v.distance * 1000,
      userRealDistance: v.userRealDistance * 1000,
      placeTags: getPlaceTagList(v.tag || v),
      listId: `${v.pkey}-${v.poiId}`,
    })
  );

  state.result.loading = false;
  state.result.loaded = true;
  state.result.error = undefined;
  state.result.data.currentPage = 1;

  state.result.data.originList = list;
  state.result.data.firstRequestTime = getLocalLogTime();
  state.result.data.list = list.slice(0, DEFAULT_PAGING_SIZE);
  state.result.data.latestUpdateTime = data?.latestUpdateTime;
  state.result.data.totalCount = data?.totalCount;
  state.result.data.isLastPage = list.length <= DEFAULT_PAGING_SIZE;
};

const tNowSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    setBaseSpot: (state, action: PayloadAction<TNowBaseSpot>) => {
      state.baseSpot = {
        ...state.baseSpot,
        ...action.payload,
      };
    },
    resetList: (state) => {
      state.result.loaded = initialState.result.loaded;
      state.result.loading = initialState.result.loading;
      state.result.data.list = initialState.result.data.list;
    },
    updateNextPage: (state) => {
      const {originList, currentPage} = state.result.data;

      const next = Paging.getNextPage({originList, currentPage, maxListCount: TNOW_MAX_COUNT});

      if (next) {
        state.result.data.list = next.list;
        state.result.data.currentPage = next.page;
      } else {
        state.result.data.isLastPage = true;
      }
    },
    updateCategoryType: (state, action: PayloadAction<EPlaceCategoryType | undefined>) => {
      state.categoryType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTNowList.pending, getPendingState)
      .addCase(fetchTNowList.fulfilled, getTNowFulfilledState)
      .addCase(fetchTNowList.rejected, getRejectedState)

      .addCase(fetchTNowAutoSearch.pending, getPendingState)
      .addCase(fetchTNowAutoSearch.fulfilled, getTNowFulfilledState)
      .addCase(fetchTNowAutoSearch.rejected, getRejectedState)

      .addCase(fetchTNowLinks.pending, (state) => {
        state.links.loading = true;
        state.links.loaded = false;
      })
      .addCase(fetchTNowLinks.fulfilled, (state, action) => {
        state.links.loading = false;
        state.links.loaded = true;
        state.links.data = action.payload.data;
      })
      .addCase(fetchTNowLinks.rejected, getRejectedState);
  },
});

export default tNowSlice;
