import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {fetchAroundMeList} from 'ducks/tplacehome/slice';
import {TRadius} from 'ducks/tplacehome/types';
import {usePlaceCategoryLink} from 'hooks/usePlaceCategoryLink';
import usePlaceHome from 'hooks/usePlaceHome';
import {useMemo} from 'react';
import s from 'styles/components/tplacehome/TPlaceErrorBase.module.scss';
import {isEmpty} from 'utils/lodash';
import {moveToUrl} from 'utils/url';

const AroundMeEmpty = () => {
  const {tplacehome} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();
  const dispatch = useAppDispatch();
  const aroundMeData = useMemo(() => tplacehome.aroundMeData, [tplacehome.aroundMeData]);
  const radius = useMemo(
    () => aroundMeData.result.data.requestParams?.radius || 0,
    [aroundMeData.result.data.requestParams?.radius]
  );

  const isRadiusExtendable = useMemo(() => radius < 10000, [radius]);
  const hasFilter = useMemo(
    () =>
      !isEmpty(aroundMeData.selectedFilters.additionalInfos) ||
      !isEmpty(aroundMeData.selectedFilters.categoryGroups),
    [aroundMeData.selectedFilters.additionalInfos, aroundMeData.selectedFilters.categoryGroups]
  );

  const rankingUrl = usePlaceCategoryLink({
    type: 'RANKING',
  });

  const renderData = useMemo(() => {
    if (hasFilter) {
      return {
        DescComp: <span>주변에 장소가 없습니다.</span>,
        buttonText: '필터 초기화',
        onClick: () => {
          placehomeHook.sendEventAroundMe('tap.filter_reset');
          dispatch(actions.tplacehome.resetAroundMeSelectedFilters());
        },
      };
    }
    if (isRadiusExtendable) {
      return {
        DescComp: (
          <span>
            지역을 넓히면
            <br />더 많은 장소를 볼 수 있어요.
          </span>
        ),
        buttonText: '지역 넓히기',
        onClick: () => {
          placehomeHook.sendEventAroundMe('tap.depth_up');
          let nextRadius: TRadius = 10000;
          if (radius < 1000) {
            nextRadius = 1000;
          } else if (radius < 5000) {
            nextRadius = 5000;
          } else {
            nextRadius = 10000;
          }
          dispatch(actions.tplacehome.updateAroundMeRequestParams({radius: nextRadius}));
          dispatch(fetchAroundMeList({}));
        },
      };
    }
    return {
      DescComp: (
        <span>
          주변에 장소가 없습니다.
          <br />
          티맵랭킹에서 인기 장소를
          <br />
          알려드릴께요.
        </span>
      ),
      buttonText: '티맵랭킹으로 가기',
      onClick: () => {
        placehomeHook.sendEventAroundMe('tap.go_tmap_ranking');
        moveToUrl(rankingUrl);
      },
    };
  }, [dispatch, hasFilter, isRadiusExtendable, placehomeHook, radius, rankingUrl]);

  return (
    <div className={s.wrap}>
      <div className={s.desc_wrap}>{renderData.DescComp}</div>
      <div className={s.button_wrap}>
        <button className={s.blue_button} onClick={() => renderData.onClick()}>
          {renderData.buttonText}
        </button>
      </div>
    </div>
  );
};

export default AroundMeEmpty;
