import {useCallback} from 'react';
import SearchMainSection from './SearchMainSection';
import {useAppSelector} from '../../ducks/hooks';
import {useSearchMainTab} from '../../hooks/useSearchMainTab';
import {ESearchTabs} from '../../types/Search';
import SearchRecommendFestivalList from './SearchRecommendFestivalList';
import {sendSearchClickLog} from '../../utils/logManager';

const SearchRecommendFestival = () => {
  const {festivalApi, reverseGeoInfo} = useAppSelector((state) => ({
    festivalApi: state.searchRecommend.mainFestivalData.result,
    reverseGeoInfo: state.searchRecommend.reverseGeoInfo,
  }));

  const {changeTab} = useSearchMainTab();

  const handleClickMore = useCallback(() => {
    sendSearchClickLog('tap.festival_viewall', {
      region_1D: reverseGeoInfo.regionName1,
      region_2D: reverseGeoInfo.regionName2,
      region_3D: reverseGeoInfo.regionName3,
    });
    changeTab(ESearchTabs.FESTIVAL, {pushHistory: true});
  }, [reverseGeoInfo, changeTab]);

  if (!festivalApi.data.totalCount) {
    return null;
  }

  return (
    <SearchMainSection
      title={'가볼만한 축제'}
      handleClick={() => festivalApi.data.totalCount >= 3 && handleClickMore()}
    >
      <SearchRecommendFestivalList
        data={festivalApi.data.festivalInfos}
        loaded={festivalApi.loaded}
      />
    </SearchMainSection>
  );
};

export default SearchRecommendFestival;
