import {useEffect, useState} from 'react';
import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';

export const useFocusAfterRotate = () => {
  const [isRotateChange, setRotateChange] = useState(false);
  const dispatch = useAppDispatch();
  const {isLandscape, focusPoiId} = useAppSelector((state) => ({
    isLandscape: state.layout.appSize.isLandscape,
    focusPoiId: state.userInteraction.activePoi,
  }));

  useEffect(() => {
    if (isRotateChange) {
      dispatch(
        actions.userInteraction.setInteraction({
          activePoi: focusPoiId,
          trigger: 'rotate',
        })
      );
      setRotateChange(false);
    }
  }, [dispatch, isRotateChange, focusPoiId]);

  useEffect(() => {
    return () => {
      setRotateChange(true);
    };
  }, [isLandscape]);

  return {isRotateChange};
};
