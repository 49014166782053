import {useEffect, useState} from 'react';

import {splitStringTags} from 'utils/general';

import styles from 'styles/components/AreaWordsBox.module.scss';

type TProps = {
  arr: string[];
};

const AreaWordsBox = ({arr}: TProps) => {
  const [nowResult, setResult] = useState<[string[], string[]]>([[], []]);

  useEffect(() => {
    setResult(splitStringTags(arr));
  }, [arr]);

  return (
    <div className={styles.area_warp}>
      <div className={styles.inner_wrap}>
        {nowResult.map((line, lineIndex) => (
          <ul key={lineIndex} className={styles.line_wrap}>
            {line.map((tag) => (
              <li key={tag} className={styles.item}>
                {tag}
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default AreaWordsBox;
