import {useEffect, useRef, useState} from 'react';
import axios, {CancelTokenSource} from 'axios';

import {TApiStatus} from 'types/Api';
import {TAddressItem} from 'types/Search';

import {API_PATH} from 'constant/Api';

import {getDefaultApiStatus} from 'utils/apis';
import fetcher from 'utils/fetcher';

const DEFAULT_STATUS = getDefaultApiStatus<TAddressItem[]>([]);

type TProps = {
  depth: number;
  regionId?: string;
  isRoad?: boolean;
  isPlace?: boolean;
};

export const useSearchAddress = ({depth, regionId, isRoad, isPlace}: TProps) => {
  const [state, setState] = useState<TApiStatus<Nullable<TAddressItem[]>>>(DEFAULT_STATUS);
  const refCancelToken = useRef<Nullable<CancelTokenSource>>(null);

  useEffect(() => {
    if (depth === 0) {
      setState(DEFAULT_STATUS);
      return;
    }

    setState({
      ...DEFAULT_STATUS,
      loading: true,
    });

    refCancelToken.current = axios.CancelToken.source();

    const params = {
      regionDepth: depth,
    };

    if (depth > 1) {
      if (!regionId) {
        return;
      }

      params['parentRegionId'] = regionId;
    }

    if (depth === 3) {
      params['division'] = isRoad ? 'ROAD' : 'JIBUN';
    }

    if (isPlace) {
      params['placeYn'] = 'Y';
    }

    fetcher
      .get(API_PATH.GET_SEARCH_ADDRESS, {
        params: params,
        cancelToken: refCancelToken.current.token,
      })
      .then((e) => {
        if (e.data.data) {
          setState({
            loaded: true,
            loading: false,
            data: e.data.data,
            error: undefined,
          });
        } else {
          throw new Error('No Data');
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        setState({
          loaded: true,
          loading: false,
          data: null,
          error: e.message,
        });
      });

    return () => {
      refCancelToken.current?.cancel();
      refCancelToken.current = null;
    };
  }, [depth, regionId, isRoad, isPlace]);

  return state;
};

export default useSearchAddress;
