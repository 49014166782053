import {useCallback} from 'react';
import classNames from 'classnames';
import {TPoiDataJson} from '@lcc/tmap-inapp';

import {TFavoriteRouteItem} from 'types/Search';
import {ERPFlag} from 'types/App';

import {ReactComponent as IconRouteArrow} from 'resource/images/@tmds_basic/ico_arrow_tale_right.svg';
import {ReactComponent as IconFavoriteRoute} from 'resource/images/@tmds_basic/ico_route_favorite.svg';

import s from 'styles/components/legacySearch/FavoriteItem.module.scss';

type TProps = {
  data: TFavoriteRouteItem;
  onClick?: (departure: TPoiDataJson, destination: TPoiDataJson) => void;
};

const FavoriteRouteItem = ({data, onClick}: TProps) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onClick?.(
        {
          pkey: `${data.departure.pkey || ''}`,
          poiId: `${data.departure.poiId || ''}`,
          navSeq: `${data.departure.navSeq || ''}`,
          poiName: data.departure.placeName,
          navX: `${data.departure.navX}`,
          navY: `${data.departure.navY}`,
          centerX: `${data.departure.navX}`,
          centerY: `${data.departure.navY}`,
          address: '',
          tel: '',
          rpFlag: ERPFlag.N_G000,
        },
        {
          pkey: `${data.destination.pkey || ''}`,
          poiId: `${data.destination.poiId || ''}`,
          navSeq: `${data.destination.navSeq || ''}`,
          poiName: data.destination.placeName,
          navX: `${data.destination.navX}`,
          navY: `${data.destination.navY}`,
          centerX: `${data.destination.navX}`,
          centerY: `${data.destination.navY}`,
          address: '',
          tel: '',
          rpFlag: ERPFlag.N_G000,
        }
      );
    },
    [onClick, data]
  );

  return (
    <div
      className={classNames(s.search_favorite_item, s.favorite_route_wrap)}
      onClick={handleClick}
    >
      <div className={s.main_click_wrap}>
        <i className={s.icon}>
          <IconFavoriteRoute />
        </i>

        <div className={s.route_wrap}>
          {data.customTitle && (
            <div className={s.route_title_line}>
              <div className={s.route_custom_title}>{data.customTitle}</div>
            </div>
          )}

          <div className={s.route_line}>
            <div className={s.start_name}>{data.departure.placeName}</div>

            <IconRouteArrow className={s.arrow} />

            <div className={s.end_name}>{data.destination.placeName}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoriteRouteItem;
