import lozad from 'lozad';
import {debounce} from 'utils/lodash';

type TLozad = {
  el?: HTMLElement | HTMLElement[];
  options?: {
    rootMargin?: string;
    threshold?: number;
  };
  className: string;
};

export const DEFAULT_LOZAD_CLASSNAME = 'lozad';
const OBSERVE_DELAY = 50;

const ImageObserverMap = {};

export const createObserver = ({el, options, className}: TLozad) => {
  const key = className;

  ImageObserverMap[key] = lozad(el || `.${className}`, options);
};

export const getObserver = (className: string) => {
  const key = className;

  return ImageObserverMap[key];
};

export const observeImage = debounce(
  (className: string) => getObserver(className).observe(),
  OBSERVE_DELAY
);

createObserver({
  className: DEFAULT_LOZAD_CLASSNAME,
});
