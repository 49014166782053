import {SerializedError} from '@reduxjs/toolkit';
import {EApiDataCode} from 'constant/Api';
import {TApiStatus} from 'types/Api';
import fetcher from './fetcher';

export const getDefaultApiStatus = <T, E = SerializedError>(initialState: T): TApiStatus<T, E> => ({
  data: initialState,
  loaded: false,
  loading: false,
  error: undefined,
});

const removeNullableString = (value) => (['null', 'undefined'].includes(value || '') ? '' : value);

export const getParam = (key: string) =>
  removeNullableString(new URLSearchParams(window.location.search).get(key));

export const getAllParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params: any = {};

  for (const [key, value] of searchParams.entries()) {
    const safeValue = removeNullableString(value);

    if (params[key] === undefined) {
      params[key] = safeValue;
    } else if (Array.isArray(params[key])) {
      params[key] = [...params[key], safeValue];
    } else {
      params[key] = [params[key], safeValue];
    }
  }

  let tailParam;

  try {
    tailParam = JSON.parse(decodeURIComponent(params.tailParam));
  } catch (e) {
    tailParam = {};
  }

  return {
    ...params,
    tailParam,
  };
};

const parseResult = (res) => {
  if (`${res.data.code}` === EApiDataCode.OK) {
    return res.data.data;
  }

  return null;
};

export const getApi = <T = any>(apiPath: string, config = {}, parser = parseResult): Promise<T> => {
  return fetcher
    .get(apiPath, config)
    .then(parser)
    .catch((err) => {
      throw err;
    });
};

export const postApi = <T = any>(apiPath: string, param = {}): Promise<T> => {
  return fetcher
    .post(apiPath, param)
    .then(parseResult)
    .catch((err) => {
      throw err;
    });
};
