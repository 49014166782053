import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getDefaultState, LayoutState} from './types';
import Storage from '@lcc/web-storage';
import {StorageKey} from 'constant/Storage';

const initialState: LayoutState = getDefaultState();

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    windowResize: (state, action: PayloadAction<{width: number; height: number}>) => {
      const {width, height} = action.payload;

      state.windowSize = {
        width,
        height,
      };
    },

    setStatusBarHeight: (state, action: PayloadAction<number>) => {
      Storage.setItem(StorageKey.STATUSBAR_HEIGHT, action.payload);
      state.appSize.statusBarHeight = action.payload;
    },
    setLandscape: (state, action: PayloadAction<boolean>) => {
      state.appSize.isLandscape = action.payload;
    },
    setDisplayHeight: (state, action: PayloadAction<number>) => {
      state.appSize.displayHeight = action.payload;
    },

    setSearchBarHeight: (state, action: PayloadAction<number>) => {
      state.appSize.searchBarHeight = action.payload;
    },

    setListLeft: (state, action: PayloadAction<number>) => {
      state.webSize.listLeft = action.payload;
    },

    needReLayout: (state) => {
      state.refreshEvent.needReLayout = Date.now();
    },
    closeTooltip: (state) => {
      state.refreshEvent.closeTooltip = Date.now();
    },
    getListLeft: (state) => {
      state.refreshEvent.getListLeft = Date.now();
    },
    setLoadedFirstBanner: (state, action: PayloadAction<boolean>) => {
      state.loadedFirstBanner = action.payload;
    },
    setHybrid: (state, action: PayloadAction<boolean>) => {
      state.isHybrid = action.payload;
    },
  },
});

export default layoutSlice;
