import {ReactNode} from 'react';

import s from 'styles/components/ErrorReload.module.scss';

type TProps = {
  title?: ReactNode;
  description?: ReactNode;
  onReload?: VoidFunction;
  top?: number;
};

const DEFAULT_TITLE = (
  <>
    일시적으로 서비스를
    <br />
    이용할 수 없습니다.
  </>
);

const DEFAULT_DESCRIPTION = '잠시 후 다시 시도해 주세요.';

const ErrorReload = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  onReload,
  top = 50,
}: TProps) => {
  return (
    <div className={s.error_wrap}>
      <h2 className={s.title} style={{marginTop: top}}>
        {title}
      </h2>
      <span className={s.desc}>{description}</span>

      {onReload && (
        <button className={s.reload_button} onClick={onReload}>
          새로고침
        </button>
      )}
    </div>
  );
};

export default ErrorReload;
