import {useMemo} from 'react';
import {TBounds, TMarker} from 'types/Map';
import {getNewBoundsBox, getValidBounds, getValidLonLat} from 'utils/map';

export const usePlaceBounds = (
  centerMarker: Nullable<Partial<TMarker>>,
  markers: TMarker[]
): {
  centerDistanceBounds?: TBounds;
  bounds?: TBounds;
} => {
  const markersCoords = useMemo(() => markers.map((m) => m.lonLat), [markers]);
  const bounds: [number, number, number, number] | undefined = useMemo(() => {
    const bbox = getNewBoundsBox(markersCoords);

    return markersCoords.length > 0 && centerMarker ? bbox : undefined; //sw, ne
  }, [markersCoords, centerMarker]);

  const newBounds: [number, number, number, number] | undefined = useMemo(() => {
    if (bounds && centerMarker) {
      const {lon, lat} = getValidLonLat(centerMarker.lonLat) || {};
      const [w, s, e, n] = getValidBounds(bounds) || [];

      if (lon && lat && s && w && n && e) {
        const [newW, newE] =
          Math.abs(lon - w) > Math.abs(lon - e) ? [w, lon + (lon - w)] : [lon + (lon - e), e];
        const [newN, newS] =
          Math.abs(lat - n) > Math.abs(lat - s) ? [n, lat + (lat - n)] : [lat + (lat - s), s];

        return [newW, newS, newE, newN];
      }
    }
    return undefined;
  }, [bounds, centerMarker]);

  return {
    centerDistanceBounds: newBounds,
    bounds,
  };
};

export default usePlaceBounds;
