import LocalStorage from '@lcc/web-storage';
import {useAppSelector} from 'ducks/hooks';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {EPlaceCategoryType} from 'types/App';
import {useOnce} from './useOnce';

const STORAGE_KEY = {
  [EPlaceCategoryType.OIL_EV_ALL]: 'OIL_EV_ALL_FILTER',
  [EPlaceCategoryType.PARKING_LOT]: 'PARKING_LOT_FILTER',
};

export const useCategoryStorage = () => {
  const {placeState, currentCategory} = useAppSelector((state) => ({
    placeState: state.place,
    currentCategory: state.category.currentCategory,
  }));
  const [isInit, setIsInit] = useState(false);
  const storageKey = useMemo(
    () => (currentCategory ? STORAGE_KEY[currentCategory] : null),
    [currentCategory]
  );
  const [lastVisitFilter, setLastVisitFilter] = useState(null);
  const filterObject = useMemo(() => {
    const {
      placeQuery,
      evSvcOperatorIds,
      tmapParkYn,
      tmapPaymentYn,
      oilCompany,
      parkType,
      evPublicType,
      evChargerSpeed,
      evChargerStatus,
      evChargerType,
    } = placeState;
    return {
      placeQuery,
      evSvcOperatorIds,
      tmapParkYn,
      tmapPaymentYn,
      oilCompany,
      parkType,
      evPublicType,
      evChargerSpeed,
      evChargerStatus,
      evChargerType,
    };
  }, [placeState]);

  useEffect(() => {
    if (isInit && filterObject && storageKey) {
      LocalStorage.setItem(storageKey, filterObject);
    }
  }, [filterObject, storageKey, isInit]);

  useOnce(placeState.loaded, () => {
    setIsInit(true);
  });

  useOnce(!!storageKey, () => {
    setLastVisitFilter(LocalStorage.getItem(storageKey));
  });

  const getLastVisitFilter = useCallback((category: Nullable<string> = null) => {
    return category ? LocalStorage.getItem(STORAGE_KEY[category]) : undefined;
  }, []);

  return {lastVisitFilter, getLastVisitFilter};
};
