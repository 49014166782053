import {times} from 'utils/lodash';
import s from 'styles/components/tplacehome/TPlaceSkeleton.module.scss';

type TProps = {
  marginTop?: string;
};

const DiscoverySkeleton = ({marginTop = '20px'}: TProps) => {
  return (
    <div className={s.wrap} style={{marginTop}}>
      <div className={s.discovery_content_wrap}>
        {times(4).map((n) => (
          <div className={s.row} key={n}>
            {times(2).map((i) => (
              <div className={s.col} key={`${i}col`}>
                <div className={s.content_img} />
                <div className={s.content_s1} />
                <div className={s.content_s2} />
                <div className={s.content_s3} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiscoverySkeleton;
