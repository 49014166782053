import {useState} from 'react';
import {datadogLogs} from '@datadog/browser-logs';
import {useAppSelector} from 'ducks/hooks';
import {injectFrontmanHeader} from 'utils/fetcher';
import ua from 'utils/uaParser';
import {useOnce} from 'hooks/useOnce';

const useInitApiHeader = () => {
  const userInfo = useAppSelector((state) => state.userInfo);
  const [isHeaderInitialized, setHeaderInitialized] = useState(false);

  useOnce(userInfo.userKey && userInfo.device && userInfo.accessKey, () => {
    const headers = {
      'App-Code': 'tmap',
      'App-Version': ua.tmapAppVersion,
      'Os-Type': ua.os.name || '',
      'Os-Version': ua.os.version || '',
      'Device-Id': userInfo.device.deviceId,
      Carrier: userInfo.device.carrierName,
      'Model-No': ua.device.model,
      AccessKey: userInfo.accessKey,
      userKey: userInfo.userKey,
    };

    injectFrontmanHeader(headers);
    setHeaderInitialized(true);

    datadogLogs.logger.info('Frontman Header', headers);
  });

  return isHeaderInitialized;
};

export default useInitApiHeader;
