import {useAppSelector} from 'ducks/hooks';

const SEARCH_BAR_AREA = 68;

export const useAppLayout = () => {
  const {isLandscape, statusBarHeight} = useAppSelector((state) => ({
    isLandscape: state.layout.appSize.isLandscape,
    statusBarHeight: state.layout.appSize.statusBarHeight,
  }));

  return {
    isLandscape,
    searchBarArea: SEARCH_BAR_AREA,
    statusBarArea: statusBarHeight,
  };
};
