import {useMemo, useCallback, useRef, useEffect, useState} from 'react';
import {IcoDotBetweenBold} from 'components/@tmds/icons/IcoDotBetweenBold';
import {ItemTag} from 'components/ItemTag';
import {EAddressMode, TAddressItem} from 'types/Search';
import {NATIONAL_REGION} from 'components/search/SearchRankingDropDown';

import s from 'styles/components/ranking/RankingPoiSubInfo.module.scss';

const TAG_LIMIT_WINDOW_WIDTH = 390;
const MAX_VISIBLE_TAGS = 3;
const TAG_CONTAINER_DIV_HEIGHT = 20;

export const RankingPoiDistance = ({distance, addressNameDepth2, currentAddressMap}) => {
  const isNationalRegion = useMemo(
    () => currentAddressMap[EAddressMode.CATE1]?.areaName === NATIONAL_REGION.areaName,
    [currentAddressMap]
  );

  return (
    <div className={s.distance}>
      {isNationalRegion ? (
        <span>{addressNameDepth2}</span>
      ) : (
        <>
          현위치에서 <span className={s.number}>{distance}</span>
        </>
      )}
    </div>
  );
};

export const RankingPoiTags = ({tags, windowWidth}) => {
  const visibleTags = useMemo(() => {
    if (windowWidth <= TAG_LIMIT_WINDOW_WIDTH && tags.length > MAX_VISIBLE_TAGS) {
      return [...tags.slice(0, MAX_VISIBLE_TAGS), {label: `+${tags.length - MAX_VISIBLE_TAGS}`}];
    }
    return tags;
  }, [tags, windowWidth]);

  const renderTag = useCallback((tag, index) => {
    const updatedRankingTag = {
      ...tag,
      style: {
        ...tag.style,
        fontSize: '11px',
        borderRadius: '4px',
        paddingTop: '1px',
      },
    };

    return (
      <span key={index} className={s.tag}>
        <ItemTag {...updatedRankingTag} />
      </span>
    );
  }, []);

  return <>{visibleTags.map(renderTag)}</>;
};

type TProps = {
  distance: string;
  addressNameDepth2: string;
  currentAddressMap: Partial<Record<EAddressMode, TAddressItem>>;
  tags: any[];
  windowWidth?: number;
};

const RankingPoiSubInfo = ({
  distance,
  addressNameDepth2,
  currentAddressMap,
  tags,
  windowWidth,
}: TProps) => {
  const hasTags = useMemo(() => tags.length > 0, [tags]);
  const [visibleTags, setVisibleTags] = useState(tags);
  const tagsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateVisibleTags = () => {
      const tagsHeight = tagsRef.current?.clientHeight;
      const isTagsOverflow = tagsHeight && tagsHeight > TAG_CONTAINER_DIV_HEIGHT;

      if (isTagsOverflow && tags.length > MAX_VISIBLE_TAGS) {
        setVisibleTags([
          ...tags.slice(0, MAX_VISIBLE_TAGS),
          {label: `+${tags.length - MAX_VISIBLE_TAGS}`},
        ]);
      } else {
        setVisibleTags(tags);
      }
    };

    updateVisibleTags();
    window.addEventListener('resize', updateVisibleTags);

    return () => {
      window.removeEventListener('resize', updateVisibleTags);
    };
  }, []);

  return (
    <div className={s.sub_info_wrap}>
      <RankingPoiDistance
        distance={distance}
        addressNameDepth2={addressNameDepth2}
        currentAddressMap={currentAddressMap}
      />
      {hasTags && <IcoDotBetweenBold width={12} height={12} color="gray200" />}
      {hasTags && (
        <div ref={tagsRef}>
          <RankingPoiTags tags={visibleTags} windowWidth={windowWidth} />
        </div>
      )}
    </div>
  );
};

export default RankingPoiSubInfo;
