import {StorageKey} from 'constant/Storage';
import {useLocalStorage} from '@lcc/web-storage';
import {
  createContext,
  useMemo,
  useState,
  useCallback,
  ReactNode,
  useEffect,
  useContext,
} from 'react';

type TContextValue = {
  isHidden: boolean;
  isNeverAgain: boolean;
  hideTooltip: (option?: {isNeverAgain: boolean}) => void;
  showTooltip: VoidFunction;
};

type TProps = {
  storageKey: StorageKey;
  scrollElement?: HTMLElement | null;
  hideTimeout?: number;
  children: ReactNode;
};

export const TooltipContext = createContext<TContextValue>({
  isHidden: false,
  isNeverAgain: false,
  hideTooltip: () => {},
  showTooltip: () => {},
});

export const TooltipProvider = ({storageKey, scrollElement, children}: TProps) => {
  const [isNeverAgain, setStorageValue] = useLocalStorage<boolean>(storageKey, false);
  const [isTooltipHidden, setHideTooltip] = useState<boolean>(true);

  const hideTooltip = useCallback(() => setHideTooltip(true), []);

  const value = useMemo<TContextValue>(
    () => ({
      isHidden: isTooltipHidden,
      isNeverAgain: !!isNeverAgain,
      showTooltip: () => setHideTooltip(false),
      hideTooltip: (option) => {
        hideTooltip();
        option?.isNeverAgain && setStorageValue(true);
      },
    }),
    [isNeverAgain, isTooltipHidden, setStorageValue, hideTooltip]
  );

  useEffect(() => {
    setHideTooltip(!!isNeverAgain);
  }, [isNeverAgain]);

  useEffect(() => {
    const removeEventListener = () => {
      scrollElement?.removeEventListener('scroll', handleScroll);
    };

    const handleScroll = () => {
      hideTooltip();
      removeEventListener();
    };

    if (!isNeverAgain) {
      scrollElement?.addEventListener('scroll', handleScroll);
    }

    return () => {
      removeEventListener();
    };
  }, [isNeverAgain, scrollElement, hideTooltip]);

  return <TooltipContext.Provider value={{...value}}>{children}</TooltipContext.Provider>;
};

export const useTooltipContext = () => useContext(TooltipContext);
