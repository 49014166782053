import {getDefaultApiStatus} from 'utils/apis';
import {
  TRANK_MAX_COUNT,
  TRankResult,
  TRankState,
  ETRankSort,
  TRankPayload,
  TRankProps,
} from './types';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import fetcher from 'utils/fetcher';
import {API_PATH} from 'constant/Api';
import {StoreState} from 'ducks/store';
import {EFilter} from 'components/FilterBottomPopup';
import {EBusinessHourStatus} from 'types/App';
import {ECategoryCode} from 'constant/Place';
import {getPlaceTagList} from 'utils/general';
import {ESearchLocationType, ESortOption} from 'types/Search';
import {UserSearchLocationType} from 'utils/search';

const ACTION_PREFIX = 'trank';

const initialState: TRankState = {
  ...getDefaultApiStatus<TRankResult>({
    list: [],
  }),
  currentAddressMap: {},
  activeFilters: {},
  activeSortOption: {},
  displayList: [],
  locationType: UserSearchLocationType.get(),
};

export const fetchTRankList = createAsyncThunk<TRankPayload, TRankProps>(
  `${ACTION_PREFIX}/list`,
  async (props, creator) => {
    const storeState = creator.getState() as StoreState;
    const page = 1;
    const size = TRANK_MAX_COUNT;

    const {lat: userRealLat, lon: userRealLon} = storeState.map.userPosition || {};
    const {lat: nowCenterLat, lon: nowCenterLon} = storeState.map.nowCenter || {};

    const response = await fetcher.post(API_PATH.POST_TRANK_LIST, {
      ...props,
      lat: props.lat || nowCenterLat || 0,
      lon: props.lon || nowCenterLon || 0,
      userRealLat: props.userRealLat || userRealLat || 0,
      userRealLon: props.userRealLon || userRealLon || 0,
      playEatYn: 'Y',
      page: props.page || page,
      size: props.size || size,
      sort: ETRankSort.TMAP_RANK || props.sort,
      areaCode1: props.areaCode1,
      areaCode2: props.areaCode2,
      areaCode3: props.areaCode3,
      featureOr: props.featureOr || '',
      featureAnd: props.featureAnd || '',
    });

    return response.data;
  }
);

const filterOpenNow = (item) => {
  const openStatus = [
    EBusinessHourStatus.OPEN,
    EBusinessHourStatus.BREAK_TIME,
    EBusinessHourStatus.CLOSING_SOON,
  ];
  return openStatus.includes(item.special?.businessHourStatus ?? EBusinessHourStatus.CLOSED);
};

const filterWaitingReservation = (item) => {
  return (
    item.special.catchTableWaitingResponse &&
    (item.special.catchTableWaitingResponse.isAvailableReservation ||
      item.special.catchTableWaitingResponse.isAvailableOnlineWaiting)
  );
};

const filterParking = (item) => {
  return item.tag.isParking || item.tag.isValetParking;
};

const filterCategory = (item, category) => {
  return item.mainCategory.includes(category);
};

const findActiveOptions = (options) => Object.values(options).some(Boolean);

const getCategoryCode = (category: ECategoryCode): ECategoryCode => {
  switch (category) {
    case ECategoryCode.POPULAR_FOOD:
      return ECategoryCode.RESTAURANT;
    case ECategoryCode.POPULAR_CAFE:
      return ECategoryCode.CAFE;
    case ECategoryCode.POPULAR_BAR:
      return ECategoryCode.BAR;
    default:
      return category;
  }
};

const filterItem = (item, activeFilters) => {
  const categoryFilters = [
    ECategoryCode.POPULAR_FOOD,
    ECategoryCode.POPULAR_CAFE,
    ECategoryCode.POPULAR_BAR,
  ];

  const activeCategoryFilters = categoryFilters.filter(
    (category) => activeFilters[category]?.isActive
  );

  // 카테고리 필터 처리 (OR 조건)
  const passesCategoryFilter =
    activeCategoryFilters.length === 0 ||
    activeCategoryFilters.some((category) => filterCategory(item, getCategoryCode(category)));

  // 장소특성 필터 처리 (AND 조건)
  const passesOtherFilters = Object.keys(activeFilters).every((key) => {
    if (categoryFilters.includes(key as ECategoryCode)) {
      return true;
    }

    if (!activeFilters[key]?.isActive) {
      return true;
    }

    switch (key) {
      case EFilter.OPEN_NOW:
        return filterOpenNow(item);
      case EFilter.WAITING_RESERVATION:
        return filterWaitingReservation(item);
      case EFilter.POI_PARK:
        return filterParking(item);
      default:
        return true;
    }
  });

  return passesCategoryFilter && passesOtherFilters;
};

const sortFunctionMap = {
  [ESortOption.SCORE]: (a, b) => b.headingForScore - a.headingForScore,
  [ESortOption.DISTANCE]: (a, b, locationType) => {
    const isMapLocation = [ESearchLocationType.MAP, ESearchLocationType.MAP_ON_MAP].includes(
      locationType
    );

    return isMapLocation ? a.distance - b.distance : a.userRealDistance - b.userRealDistance;
  },
  RESET: (a, b) => a.rank - b.rank,
};

const sortItems = (list, sortOptions, locationType) => {
  const activeSortKey = Object.keys(sortOptions).find((key) => sortOptions[key]);
  list.sort(sortFunctionMap['RESET']);

  if (activeSortKey && sortFunctionMap[activeSortKey]) {
    return list.sort((a, b) => sortFunctionMap[activeSortKey](a, b, locationType));
  }

  return list;
};

const applyDisplayList = (state: TRankState) => {
  const hasSortOption = findActiveOptions(state.activeSortOption);
  const hasFilterOption = findActiveOptions(state.activeFilters);

  let displayList = state.data.list;

  if (hasFilterOption) {
    displayList = displayList.filter((item) => filterItem(item, state.activeFilters));
  }

  if (hasSortOption) {
    displayList = sortItems(displayList, state.activeSortOption, state.locationType);
  } else {
    displayList.sort(sortFunctionMap['RESET']);
  }

  state.displayList = displayList;
};

const getPendingState = (state) => {
  state.loading = true;
  state.loaded = false;
};

const getRejectedState = (state, action) => {
  state.loading = false;
  state.loaded = true;
  state.data = initialState.data;
  state.error = action.error;
};

const getFulfilledState = (state: TRankState, action) => {
  const data = action.payload.data;
  const list = data?.docs.map((v, i) => ({
    ...v,
    distance: v.distance * 1000,
    userRealDistance: v.userRealDistance * 1000,
    listId: `${v.pkey}-${v.poiId}`,
    placeTags: getPlaceTagList(v.tag || v),
    rank: i + 1,
  }));

  state.loading = false;
  state.loaded = true;
  state.error = undefined;
  state.data.list = list;
  state.displayList = list;
};

const tRankSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    updateCurrentAddressMap: (state, action) => {
      state.currentAddressMap = action.payload;
    },
    toggleFilter: (state, action) => {
      const key = action.payload;

      state.activeFilters[key] = {isActive: !state.activeFilters[key]?.isActive};
      applyDisplayList(state);
    },
    resetFilter: (state) => {
      state.activeFilters = {};
      applyDisplayList(state);
    },
    resetSort: (state) => {
      state.activeSortOption = {};
      applyDisplayList(state);
    },
    activeFilters: (state, action) => {
      const keys = action.payload;

      state.activeFilters = {};
      keys.forEach((key) => {
        state.activeFilters[key] = {isActive: true};
      });
      applyDisplayList(state);
    },
    toggleSortOption: (state, action) => {
      const key = action.payload;

      Object.keys(state.activeSortOption).forEach((sortKey) => {
        if (sortKey !== key) {
          state.activeSortOption[sortKey] = false;
        }
      });

      state.activeSortOption[key] = !state.activeSortOption[key];
      applyDisplayList(state);
    },
    resetList: (state) => {
      state.data.list = initialState.data.list;
      state.displayList = initialState.displayList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTRankList.pending, getPendingState)
      .addCase(fetchTRankList.fulfilled, getFulfilledState)
      .addCase(fetchTRankList.rejected, getRejectedState);
  },
});

export default tRankSlice;
