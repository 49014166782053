// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcoCheckBoldSvg} from 'resource/@tmdsicon/ico_check_b.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcoCheckBold = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcoCheckBoldSvg {...props} />} />;
};

export {IcoCheckBold};
