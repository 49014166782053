import {useRef} from 'react';
import classNames from 'classnames';

import {EDataType, TBuildInfoItem} from 'types/BuildInfo';

import s from 'styles/components/BuildInfo.module.scss';
import TMapSender from '@lcc/tmap-inapp';
import ua from 'utils/uaParser';
import ObjectInspector from 'react-object-inspector';
import {moveToUrl} from 'utils/url';

const copyClipboard = (e, key, value) => {
  e.stopPropagation();

  if (value || typeof value === 'boolean') {
    if (ua.isInApp) {
      TMapSender.copyClipboard(key || '', value);
      TMapSender.makeToast(`${key ? key + ' 정보가 ' : ''}클립보드에 복사되었습니다`);
    } else {
      navigator.clipboard.writeText(value);
    }
  }
};

type TProps = {
  data: TBuildInfoItem;
};

const BuildInfoListItem = ({data}: TProps) => {
  const refUrl = useRef<HTMLInputElement>(null);

  return (
    <li
      className={classNames(s.list_item, {
        [s.table_data]: data.type === EDataType.KEY_VALUE,
        [s.array_data]: data.type === EDataType.ARRAY,
        [s.value_data]: [EDataType.DANGER_VALUE, EDataType.VALUE].includes(data.type),
      })}
    >
      <h3>{data.name}</h3>

      <div
        className={s.data_wrap}
        onClick={(e) => {
          !data.ignoreCopy &&
            copyClipboard(
              e,
              data.name,
              data.type === EDataType.VALUE ? data.value : data.value.length > 0 ? data.value : ''
            );
        }}
      >
        {data.type === EDataType.CUSTOM && <>{data.value}</>}
        {data.type === EDataType.ARRAY &&
          (data.value.length > 0
            ? (data.value as any[]).map((v, i) => (
                <div
                  key={i}
                  className={s.array_data}
                  onClick={(e) => {
                    copyClipboard(e, '', v);
                  }}
                >
                  {`${v ?? ''}`}
                </div>
              ))
            : '정보 없음')}

        {data.type === EDataType.KEY_VALUE &&
          (data.value.length > 0
            ? (data.value as any[]).map((v, i) => (
                <div
                  key={i}
                  className={classNames(s.array_data, s.key_value_data)}
                  onClick={(e) => {
                    if (data.ignoreCopy || v.ignoreCopy) {
                      e.stopPropagation();
                      return;
                    }
                    copyClipboard(e, v.name, v.value || v.link || v.pushLink);
                  }}
                >
                  <div className={s.table_header}>{v.name}</div>

                  {v.value && (
                    <div className={s.table_value}>
                      {Array.isArray(v.value)
                        ? v.value.map((sv, svi) => <div key={svi}>{sv}</div>)
                        : `${v.value ?? ''}`}
                    </div>
                  )}

                  {v.link && (
                    <a
                      href={v.link}
                      onClick={(e) => {
                        if (ua.isInApp) {
                          e.preventDefault();
                          moveToUrl(e.currentTarget.href);
                        }
                      }}
                    >
                      {v.link}
                    </a>
                  )}

                  {v.pushLink && (
                    <a
                      href={v.pushLink}
                      onClick={(e) => {
                        if (ua.isInApp) {
                          e.preventDefault();
                          window.location.href = e.currentTarget.href;
                        }
                      }}
                    >
                      {v.pushLink}
                    </a>
                  )}
                </div>
              ))
            : '정보 없음')}

        {data.type === EDataType.INPUT && (
          <>
            <div className={s.input_wrap}>
              <input type="text" ref={refUrl} />
              <button
                className={s.button}
                onClick={() => {
                  if (refUrl.current) {
                    window.location.href = refUrl.current.value;
                  }
                }}
              >
                open
              </button>
            </div>
          </>
        )}

        {data.type === EDataType.OBJECT && (
          <div className={s.btn_wrap}>
            <ObjectInspector
              data={data.value}
              initialExpandedPaths={data.expandedPaths || ['root']}
            />
          </div>
        )}

        {data.type === EDataType.BUTTON && (
          <div className={s.btn_wrap}>
            <button onClick={data.handleCLick}>{data.value}</button>
          </div>
        )}

        {data.type === EDataType.VALUE && (`${data.value ?? ''}` || '정보 없음')}

        {data.type === EDataType.DANGER_VALUE && (
          <span dangerouslySetInnerHTML={{__html: `${data.value ?? ''}` || '정보 없음'}} />
        )}
      </div>
    </li>
  );
};

export default BuildInfoListItem;
