import {EPlaceCategoryType} from 'types/App';

export const EXIST_FILTER_CATEGORY = [
  EPlaceCategoryType.POPULAR,
  EPlaceCategoryType.OIL_GAS_ALL,
  EPlaceCategoryType.PARKING_LOT,
  EPlaceCategoryType.OIL_EV_ALL,
  EPlaceCategoryType.FOOD_CAFE,
  EPlaceCategoryType.CV_ALL,
  EPlaceCategoryType.BANK_ALL,
  EPlaceCategoryType.HOTL_ALL,
  EPlaceCategoryType.FOOD_ALL,
].map((i) => `${i}`);

export const GENERAL_PLACE_FILTER = {
  [EPlaceCategoryType.POPULAR]: {
    categoryTypes: [],
  },
};
