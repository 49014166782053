import s from 'styles/pages/DevAppInterfacePage.module.scss';
import {generateUrl} from 'utils/url';
import TMapSender, {ECallbackKeys, TMapReceiver, sendWithScheme} from '@lcc/tmap-inapp';
import BuildInfo from 'components/BuildInfo';
import {useEffect, useState} from 'react';
import ua from 'utils/uaParser';
import ObjectInspector from 'react-object-inspector';

const TAIL_PARAM = JSON.stringify({param_test: 'dev-app-interface'});
const TEST_SET = [
  {
    description: '명동성당',
    serviceName: 'poi-detail',
    params: {
      poiid: 219308,
      tailParam: TAIL_PARAM,
    },
  },
  {
    description: '티지금',
    serviceName: 'nearby',
    params: {
      reqKey: 'TNOW',
      tailParam: TAIL_PARAM,
    },
  },
  {
    description: '주변 전기차충전소',
    serviceName: 'nearby',
    params: {
      reqKey: 'OILEV',
      tailParam: TAIL_PARAM,
    },
  },
  {
    description: '주변 주차장',
    serviceName: 'nearby',
    params: {
      category: 1,
      tailParam: TAIL_PARAM,
    },
  },
  {
    description: '검색 > 명동성당',
    serviceName: 'search',
    params: {
      name: '명동성당',
      tailParam: TAIL_PARAM,
    },
  },
];

const DevAppInterfacePage = () => {
  const [tmapInfo, setTMapInfo] = useState({});
  const [latestPosition, setLatestPosition] = useState('');
  const [mapContext, setMapContext] = useState('');
  const [currentPosition, setCurrentPosition] = useState('');

  useEffect(() => {
    if (ua.isIos) {
      const target = (window as any).tmapInfo || {};

      setTMapInfo(target);
    } else {
      const resultString = sendWithScheme('getTmapInfo', [], {useReturnValue: true});

      try {
        const result = JSON.parse(resultString || '{}');

        setTMapInfo(result);
      } catch (e) {
        setTMapInfo({});
      }
    }

    TMapReceiver.setCallback(ECallbackKeys.NOW_POSITION, (e) => {
      setCurrentPosition(JSON.stringify(e));
    });
    TMapReceiver.setCallback(ECallbackKeys.CURRENT_MAP_CONTEXT, (e) => {
      setMapContext(JSON.stringify(e));
    });
    TMapReceiver.setCallback(ECallbackKeys.LAST_POSITION, (e) => {
      setLatestPosition(JSON.stringify(e));
    });
  }, []);

  return (
    <div className={s.wrap}>
      <ul>
        <button
          onClick={() => {
            alert((window as any).tmapInfo);

            TMapSender.makeToast('test');

            const start = Date.now();

            (window as any).TMAP_USER_SETTING_IOS = function (e) {
              TMapSender.makeToast(`${e} ${Date.now() - start}`);
            };

            const iframe = document.createElement('iframe');

            iframe.setAttribute(
              'src',
              'tmapweb://getUserSetting?key=local.server_type&callbackJS=TmapWebView.TMAP_USER_SETTING_IOS'
            );
            document.documentElement.appendChild(iframe);
            iframe.parentNode && iframe.parentNode.removeChild(iframe);
          }}
        >
          test1
        </button>

        {TEST_SET.map((item, idx) => {
          const deepLink = generateUrl(`tmap://${item.serviceName}`, item.params);

          return (
            <li key={idx}>
              <h3>{item.description}</h3>
              <p>serviceName : {item.serviceName}</p>
              <div>
                {Object.keys(item.params).map((key, kidx) => {
                  return (
                    <p key={`${idx}_${kidx}`}>
                      {key} : {item.params[key]}
                    </p>
                  );
                })}
              </div>
              <p>
                <button
                  onClick={() => {
                    TMapSender.openServiceByName(item.serviceName as any, item.params);
                  }}
                >
                  openSeviceByName 호출
                </button>
              </p>
              <p>
                <button>openBrowser 호출</button>
              </p>
              <h4>deeplink</h4>
              <div>
                <p>{deepLink}</p>
                <p>{decodeURIComponent(deepLink)}</p>
              </div>
            </li>
          );
        })}
      </ul>
      <div>
        <h3>tmapInfo (앱 호출 원본 결과)</h3>
        <ObjectInspector data={tmapInfo} />
      </div>
      <div>
        <h3>position interface</h3>
        <p>
          <button onClick={() => TMapSender.getCurrentPosition()}>getCurrentPosition</button>
          <span>{currentPosition || '결과없음'}</span>
        </p>
        <p>
          <button onClick={() => TMapSender.getLastPosition()}>getLastPosition</button>
          <span>{latestPosition || '결과없음'}</span>
        </p>
        <p>
          <button onClick={() => TMapSender.getCurrentMapContext()}>getCurrentMapContext</button>
          <span>{mapContext || '결과없음'}</span>
        </p>
      </div>

      <div>isUpdateAvailable 테스트</div>
      <button
        onClick={() => {
          TMapReceiver.setCallback(ECallbackKeys.IS_UPDATE_AVAILABLE, (result) =>
            TMapSender.makeToast(result)
          );
          TMapSender.isUpdateAvailable();
        }}
      >
        호출
      </button>
      <BuildInfo />
    </div>
  );
};

export default DevAppInterfacePage;
