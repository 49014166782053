import {useCallback, useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {EAddressType} from 'ducks/userInfo/types';
import {addressInfoFromReverseGeoData} from 'utils/map';

const useAddress = (data?) => {
  const {addressType, showShortAddress} = useAppSelector((state) => ({
    addressType: state.userInfo.addressType,
    showShortAddress: state.category.showShortAddress,
  }));

  const isUseJibun = useMemo(() => addressType === EAddressType.JIBUN, [addressType]);
  const isUseRoad = useMemo(() => addressType === EAddressType.ROAD, [addressType]);

  const {
    fullRoadAddr,
    fullJibunAddr,
    depth2RoadAddr,
    depth2JibunAddr,
    hasJibunAddress,
    hasRoadAddress,
  } = useMemo(() => {
    if (!data) {
      return {};
    }
    if (data.fullRoadAddr || data.fullJibunAddr) {
      return {
        ...data,
        hasJibunAddress: !!data.fullJibunAddr,
        hasRoadAddress: !!data.fullRoadAddr,
      };
    }

    const revConvert = addressInfoFromReverseGeoData(data);

    return {
      fullRoadAddr: revConvert.road,
      fullJibunAddr: revConvert.jibun,
      depth2RoadAddr: revConvert.depth2Road,
      depth2JibunAddr: revConvert.depth2Jibun,
      hasJibunAddress: revConvert.hasJibunAddress,
      hasRoadAddress: revConvert.hasRoadAddress,
    };
  }, [data]);

  const fullAddress = useMemo(() => {
    if (!data) {
      return '';
    }

    const fst = isUseRoad ? fullRoadAddr : fullJibunAddr;
    const second = isUseRoad ? fullJibunAddr : fullRoadAddr;

    return fst || second || '';
  }, [data, isUseRoad, fullRoadAddr, fullJibunAddr]);

  const depth2Addr = useMemo(() => {
    if (!data) {
      return '';
    }

    const fst = isUseRoad ? depth2RoadAddr : depth2JibunAddr;
    const second = isUseRoad ? depth2JibunAddr : depth2RoadAddr;

    return fst || second || '';
  }, [data, isUseRoad, depth2RoadAddr, depth2JibunAddr]);

  const absoluteFullAddress = useMemo(() => {
    if (isUseJibun && hasJibunAddress) {
      return {
        fullAddress: fullJibunAddr,
        type: EAddressType.JIBUN,
        depth2Addr: depth2JibunAddr || fullJibunAddr,
      };
    }
    if (isUseRoad && hasRoadAddress) {
      return {
        fullAddress: fullRoadAddr,
        type: EAddressType.ROAD,
        depth2Addr: depth2RoadAddr || fullRoadAddr,
      };
    }

    if (hasJibunAddress) {
      return {
        fullAddress: fullJibunAddr,
        type: EAddressType.JIBUN,
        depth2Addr: depth2JibunAddr || fullJibunAddr,
      };
    }

    if (hasRoadAddress) {
      return {
        fullAddress: fullRoadAddr,
        type: EAddressType.ROAD,
        depth2Addr: depth2RoadAddr || fullRoadAddr,
      };
    }

    return {fullAddress: '', type: null};
  }, [
    isUseJibun,
    hasJibunAddress,
    isUseRoad,
    hasRoadAddress,
    fullJibunAddr,
    depth2JibunAddr,
    fullRoadAddr,
    depth2RoadAddr,
  ]);

  const selectAddress = useCallback(
    ({jibun, road}) => {
      if (isUseRoad && road) {
        return road;
      }
      return jibun;
    },
    [isUseRoad]
  );

  return {
    fullAddress,
    depth2Addr,
    showShortAddress,
    isUseJibun,
    isUseRoad,
    absoluteFullAddress,
    selectAddress,
  };
};

export default useAddress;
