// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcLocationNowSvg} from 'resource/@tmdsicon/v1.2/ic_location_now.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcLocationNow = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcLocationNowSvg {...props} />} />;
};

export {IcLocationNow};
