import s from 'styles/components/tplacehome/TPlaceErrorBase.module.scss';

type TProps = {
  onClickRefresh: VoidFunction;
};

const ContentError = ({onClickRefresh}: TProps) => {
  return (
    <div className={s.wrap}>
      <div className={s.desc_wrap}>
        일시적으로 서비스를
        <br />
        이용할 수 없습니다.
      </div>
      <div className={s.button_wrap}>
        <button className={s.gray_button} onClick={onClickRefresh}>
          새로고침
        </button>
      </div>
    </div>
  );
};

export default ContentError;
