import {IcViewList} from 'components/@tmds/icons/v1.2/IcViewList';
import useLogger from 'hooks/useLogger';
import {useCallback} from 'react';

import s from 'styles/components/ranking/RankingListModeButton.module.scss';

const RankingListModeButton = ({onclick}) => {
  const {sendClickLog} = useLogger();

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    sendClickLog('tap.ranking_list');

    onclick?.();
  }, []);

  return (
    <div className={s.wrap} onClick={handleClick}>
      <button className={s.mode_button}>
        <IcViewList width={24} height={24} />
      </button>
    </div>
  );
};

export default RankingListModeButton;
