import Vsm from '@vsm/vsm';
import {useEffect, useCallback, useMemo} from 'react';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {fetchTNowLinks} from 'ducks/tnow/slice';
import actions from 'ducks/actions';

import PlaceTagList from 'components/PlaceTagList';
import TNowListWrap from 'components/tnow/TNowListWrap';
import TNowHeader from 'components/tnow/TNowHeader';
import DrawerContainer from 'components/DrawerContainer';
import PlaceMap from 'components/PlaceMap';
import BuildInfo from 'components/BuildInfo';
import VSMCompass from 'components/VSMCompass';

import {StorageKey} from 'constant/Storage';

import useUserData from 'hooks/useUserData';
import useTNowSearch from 'hooks/useTNowSearch';
import useMapOffset from 'hooks/useMapOffset';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';

import {
  TNOW_CATEGORY_AREA_HEIGHT,
  TNOW_CENTER_POINT_MIN_HEIGHT,
  TNOW_LIST_HANDLE_HEIGHT,
  TNOW_CENTER_MAP_RATIO,
} from 'constant/Size';
import ua from 'utils/uaParser';
import {TListDrawerResult} from 'types/ListDrawer';

import {MOCK_DATA} from 'utils/testPlaceData';
import s from 'styles/pages/PlaceMainPage.module.scss';

const DevVSMTNowTestPage = () => {
  const dispatch = useAppDispatch();
  const {autoSearchWithPosition} = useTNowSearch();
  const {userPosition, userInteraction, rdLayout, data} = useAppSelector((state) => ({
    userPosition: state.map.userPosition,
    userInteraction: state.userInteraction,
    data: state.tNow.result?.data,
    rdLayout: state.layout,
  }));
  const {getBoundsPaddingAndOffset, centerOffset} = useMapOffset({
    offsetTop: TNOW_CATEGORY_AREA_HEIGHT,
  });

  const {queries} = useParseQueryLocation();
  useUserData();

  const list = useMemo(() => (data?.list || []).map((v) => ({...v, id: v.poiId})), [data]);

  useEffect(() => {
    const enoughSpec = Vsm.Extensions.TmapUtils.isEnoughSpec({and: Number(queries.and) || 7});

    dispatch(
      actions.map.setDebugMode({
        enoughSpec,
        options: {
          enlargeScaleFactor: enoughSpec ? 2 : 1,
          config: enoughSpec
            ? 'https://farm41.ids.onestore.co.kr/hub/vsm/config/application/S001/TMAP_2020B.conf'
            : 'https://farm41.ids.onestore.co.kr/hub/vsm/config/application/S001/TMAP_RASTER_PUBLIC.conf',
        },
      })
    );

    const initialize = (nowPosition) => {
      dispatch(actions.map.setUserPosition(nowPosition));
      autoSearchWithPosition(nowPosition);
      dispatch(
        fetchTNowLinks({
          fuelCode: 'FT_GAS',
          lon: nowPosition.lon,
          lat: nowPosition.lat,
          appVersion: ua.tmapAppVersion,
        })
      );
    };
    initialize({
      lat: MOCK_DATA.center.centerY,
      lon: MOCK_DATA.center.centerX,
    });

    // dispatch(mapAction.setStyle(EMapStyle.NIGHT));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickRefresh = useCallback(
    (refreshCenter) => {
      refreshCenter &&
        autoSearchWithPosition({
          lon: refreshCenter.lon,
          lat: refreshCenter.lat,
        });
    },
    [autoSearchWithPosition]
  );

  const drawerCenterPoint = useMemo(
    () =>
      Math.max(
        parseInt(
          `${
            (rdLayout.windowSize.height - rdLayout.appSize.statusBarHeight) *
              TNOW_CENTER_MAP_RATIO -
            TNOW_CATEGORY_AREA_HEIGHT
          }`,
          10
        ),
        TNOW_CENTER_POINT_MIN_HEIGHT
      ),
    [rdLayout.windowSize.height, rdLayout.appSize.statusBarHeight]
  );

  return (
    <>
      <div className={s.header} style={{top: rdLayout.appSize.statusBarHeight}}>
        <PlaceTagList />
        <VSMCompass />
      </div>

      <DrawerContainer
        list={list}
        listMode={userInteraction.drawerMode}
        tooltipStorageKey={StorageKey.TNOW_TOOLTIP}
        onRefresh={handleClickRefresh}
        onChangeListMode={(drawerMode) => {
          dispatch(actions.userInteraction.setInteraction({drawerMode}));
        }}
        mapComponent={(drawerProps: TListDrawerResult) => {
          const {boundOffset, boundsPadding} = getBoundsPaddingAndOffset(drawerProps, {
            headerHeight: TNOW_CATEGORY_AREA_HEIGHT,
          });

          return userPosition && boundsPadding ? (
            <PlaceMap
              defaultCenter={userPosition}
              boundsPadding={boundsPadding}
              list={list}
              markerClickToCenter={true}
              centerOffset={centerOffset}
              boundOffset={boundOffset}
              useUserPositionBound={true}
            />
          ) : (
            <></>
          );
        }}
        listHeaderComponent={<TNowHeader />}
        listComponent={<TNowListWrap />}
        drawerOptions={{
          centerHeight: drawerCenterPoint,
          topAreaHeight: TNOW_CATEGORY_AREA_HEIGHT,
          listHandleHeight: TNOW_LIST_HANDLE_HEIGHT,
        }}
        statusBarHeight={rdLayout.appSize.statusBarHeight}
      />

      <BuildInfo />
    </>
  );
};

export default DevVSMTNowTestPage;
