import {IcStarFill} from '../../@tmds/icons/v1.2/IcStarFill';
import {getDistance, setComma} from '../../../utils/formatter';
import {IcoLineBetween} from '../../@tmds/icons/IcoLineBetween';

import s from '../../../styles/components/tplacehome/TPlaceDiscoveryPoiItemInfo.module.scss';

type TProps = {
  starPoint?: number | null;
  reviewCount?: number | null;
  distance?: number;
  category?: string;
};

const DiscoveryPoiItemInfo = (props: TProps) => {
  const {starPoint, reviewCount, distance, category} = props;

  if (!starPoint && !reviewCount && !distance && !category) {
    return null;
  }

  return (
    <div className={s.wrap}>
      {starPoint && reviewCount && reviewCount >= 10 ? (
        <>
          <em className={s.star_point}>
            <IcStarFill width={16} height={16} color={'tmobiTeal400'} />
            {starPoint.toFixed(1)}
          </em>
          <span className={s.info}>({setComma(reviewCount)})</span>
          {category && (
            <>
              <IcoLineBetween width={14} height={14} color={'gray200'} />
              <span className={s.info}>{category}</span>
            </>
          )}
        </>
      ) : (
        <>
          {distance && category ? (
            <>
              <span className={s.info}>{getDistance(distance)}</span>
              <IcoLineBetween width={14} height={14} color={'gray200'} />
              <span className={s.info}>{category}</span>
            </>
          ) : (
            <>
              {distance && <span className={s.info}>{getDistance(distance)}</span>}
              {category && <span className={s.info}>{category}</span>}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DiscoveryPoiItemInfo;
