import {useState, useEffect, createContext, useMemo, useContext, PropsWithChildren} from 'react';
import classNames from 'classnames';

export const TabContext = createContext({
  value: 0,
  setTabKey: (nowKey) => {},
});

type TProps = PropsWithChildren<{
  values: any[];
  initValue?: any;
  onChange?: (nowKey: any) => void;
}>;

const Tabs = ({values, initValue, onChange, children}: TProps) => {
  const [nowTabKey, setTabKey] = useState<any>();

  useEffect(() => {
    setTabKey(initValue || values?.[0]);
  }, [initValue, values]);

  useEffect(() => {
    onChange?.(nowTabKey);
  }, [nowTabKey, onChange]);

  const value = useMemo(
    () => ({
      value: nowTabKey,
      setTabKey,
    }),
    [nowTabKey]
  );

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};

type TabHederProps = PropsWithChildren<{
  tabKey: any;
  className?: string;
  activeClassName?: string;
  onClick?: (tabKey: any) => void;
}>;

export const TabHeader = ({
  tabKey,
  className = 'tab_header',
  activeClassName = 'active',
  children,
  onClick,
}: TabHederProps) => {
  const {value, setTabKey} = useContext(TabContext);

  return (
    <div
      role="tab"
      aria-selected={value === tabKey}
      onClick={() => {
        setTabKey(tabKey);
        onClick?.(tabKey);
      }}
      className={classNames(className, {[activeClassName]: value === tabKey})}
    >
      {children}
    </div>
  );
};

type TabContentProps = PropsWithChildren<{
  tabKey: any;
  renderChildren?: (isView) => JSX.Element;
}>;

export const TabContent = ({tabKey, renderChildren, children}: TabContentProps) => {
  const {value} = useContext(TabContext);

  if (renderChildren) {
    return renderChildren(value === tabKey) || null;
  }

  return value === tabKey ? <>{children}</> : null;
};

export default Tabs;
