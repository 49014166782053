import {MutableRefObject, useCallback, useEffect, useRef, useState} from 'react';
import {ReactComponent as IconToTop} from 'resource/images/@tmds_basic/ico_arrow_tale_up.svg';

import s from '../../styles/components/search/SearchScrollTop.module.scss';

type TProps = {
  data: any;
  wrapper: MutableRefObject<HTMLDivElement | null>;
};

const SearchScrollTop = ({data, wrapper}: TProps) => {
  const [hasScroll, setHasScroll] = useState(false);

  const refScrollTop = useRef<HTMLDivElement | null>(null);

  const handleScrollToTop = useCallback(() => {
    wrapper.current?.scrollTo(0, 0);
  }, [wrapper]);

  const scrollTopButtonExpose = useCallback(() => {
    const ref = wrapper.current;
    if (ref) {
      setHasScroll((prev) => {
        const topHeight = refScrollTop.current?.clientHeight;
        return ref.scrollHeight - ((prev && topHeight) || 0) > ref.clientHeight;
      });
    }
  }, [wrapper]);

  useEffect(() => {
    window.addEventListener('resize', scrollTopButtonExpose);
    return () => window.removeEventListener('resize', scrollTopButtonExpose);
  }, [scrollTopButtonExpose]);

  useEffect(() => {
    setTimeout(() => scrollTopButtonExpose(), 0);
  }, [data, wrapper.current?.scrollHeight, scrollTopButtonExpose]);

  if (!hasScroll) {
    return null;
  }

  return (
    <div ref={refScrollTop} className={s.wrap}>
      <button type="button" className={s.link} onClick={handleScrollToTop}>
        <IconToTop />
      </button>
    </div>
  );
};

export default SearchScrollTop;
