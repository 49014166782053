import {useEffect, useState} from 'react';

type TProps = {
  id?: string;
  className?: string;
};

const useElement = ({id, className}: TProps) => {
  const [el, setElement] = useState<Element | null>(null);

  useEffect(() => {
    const selector = id ? `#${id}` : className ? `.${className}` : '';

    if (selector) {
      setElement(document.querySelectorAll(selector)?.[0]);
    }
  }, [id, className]);

  return el;
};

export default useElement;
