import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import classNames from 'classnames';
import {HybridBridge} from 'utils/searchBar';
import {EAppExtraCode} from 'types/App';
import {ELegacyFavoriteFilter, ELegacySearchTabs} from 'types/LegacySearch';
import actions from 'ducks/actions';
import {
  TLA_SEARCH_MAIN_RECENT,
  TLA_SEARCH_MAIN_MAP,
  TLA_SEARCH_MAIN_ADDRESS,
  TLA_SEARCH_MAIN_FAVORITE_ROUTE,
  TLA_SEARCH_MAIN_FAVORITE_POI,
  TLA_SEARCH_MAIN_RECENT_EDIT,
  TLA_SEARCH_TYPING,
  EPageType,
} from 'constant/Log';
import {Paths, SearchHybridPages} from 'constant/RoutePath';
import {ETempWindowKey} from 'constant/Storage';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import useSearchSuggest from 'hooks/useSearchSuggest';
import {useOnce} from 'hooks/useOnce';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import useRecentQuery from 'hooks/useRecentQuery';
import useNaverKeywordAd from 'hooks/useNaverKeywordAd';
import {VSMInterfaceProvider} from 'context/VSMInterfaceContext';
import {generateUrl} from 'utils/url';
import {init as initLog, sendExpose, sendSearchClickLog, updateMeta} from 'utils/logManager';
import {afSendSearchMainPageView} from 'utils/appsFlyer';
import ua from 'utils/uaParser';
import {getFirstParam} from 'utils/search';
import Tabs, {TabContent} from 'modules/Tabs';
import SearchBox from 'components/legacySearch/SearchBox';
import SearchTabHeader from 'components/legacySearch/SearchTabHeader';
import RecentTab from 'components/legacySearch/RecentTab';
import FavoriteTab from 'components/legacySearch/FavoriteTab';
import AddressTab from 'components/legacySearch/AddressTab';
import PickOnMapTab from 'components/legacySearch/PickOnMapTab';
import SuggestResult from 'components/legacySearch/SuggestResult';
import RouteAddPopup from 'components/RouteAddPopup';
import {useParamLog} from 'hooks/useParamLog';

import s from 'styles/pages/LegacySearchMainPage.module.scss';

const tabList = [
  ELegacySearchTabs.RECENT,
  ELegacySearchTabs.FAVORITE,
  ELegacySearchTabs.ADDRESS,
  ELegacySearchTabs.MAP,
];
const HYBRID_SEARCHBAR_GAP = 6;

type TProps = {
  hide?: boolean;
};
enum ERecentTab {
  Edit = 'RECENT_EDIT',
}

type TLogTabType = ELegacySearchTabs | ELegacyFavoriteFilter | ERecentTab;

const PageIdMap = {
  [ELegacySearchTabs.RECENT]: TLA_SEARCH_MAIN_RECENT,
  [ERecentTab.Edit]: TLA_SEARCH_MAIN_RECENT_EDIT,
  [ELegacyFavoriteFilter.POI]: TLA_SEARCH_MAIN_FAVORITE_POI,
  [ELegacyFavoriteFilter.ROUTE]: TLA_SEARCH_MAIN_FAVORITE_ROUTE,
  [ELegacySearchTabs.ADDRESS]: TLA_SEARCH_MAIN_ADDRESS,
  [ELegacySearchTabs.MAP]: TLA_SEARCH_MAIN_MAP,
};

const LegacySearchMainPage = ({hide}: TProps) => {
  const dispatch = useAppDispatch();
  const {
    isHybrid,
    favorites,
    recentDestination,
    userInfo,
    resumeKey,
    statusBarHeight,
    searchBarHeight,
  } = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
    userInfo: state.userInfo,
    favorites: state.userInfo.personalPlace.data.favorites,
    recentDestination: state.userInfo.personalPlace.data.recentDestination,
    resumeKey: state.userInteraction.resumeKey,
    statusBarHeight: state.layout.appSize.statusBarHeight,
    searchBarHeight: state.layout.appSize.searchBarHeight,
  }));

  const history = useHistory();
  const {pathname} = useLocation();

  const [nowQuery, setQuery] = useState<string>('');
  const [blurKey, setBlurKey] = useState<number>();
  const [focusKey, setFocusKey] = useState<number>();
  const [loadKey, setLoadKey] = useState<number>();
  const [hideSuggest, setHideSuggest] = useState<boolean>(false);
  const [nowLogTab, setNowLogTab] = useState<TLogTabType>();
  const [forceLogInitKey, setForceLogInitKey] = useState<number>();

  const {originQueries} = useParseQueryLocation();
  const suggestResult = useSearchSuggest({query: nowQuery});
  const {moveToSearch, fromDeepLinkToSearch} = useMoveToTarget({
    from: EFromType.SEARCH_MAIN,
  });
  const {recentQueries, updateLocalItems, getLocalItems} = useRecentQuery();

  const [fromMoveSearch, setFromMoveSearch] = useState(false);
  const refVisitedTab = useRef<TLogTabType>();
  const {referrer} = useParamLog();

  useNaverKeywordAd({holdLoad: !userInfo.accessKey});

  const listCountMap = useMemo(
    () => ({
      [ELegacySearchTabs.RECENT]:
        recentQueries.length + (userInfo.personalPlace.data.recentDestination || []).length,
      [ERecentTab.Edit]:
        recentQueries.length + (userInfo.personalPlace.data.recentDestination || []).length,
      [ELegacyFavoriteFilter.POI]: userInfo.personalPlace.data.favorites.length,
      [ELegacyFavoriteFilter.ROUTE]: userInfo.favoriteRoutes.data.length,
      [ELegacySearchTabs.ADDRESS]: undefined,
      [ELegacySearchTabs.MAP]: undefined,
    }),
    [recentQueries, userInfo]
  );

  const isDataLoaded = useMemo(
    () =>
      nowLogTab
        ? {
            [ELegacySearchTabs.RECENT]: userInfo.personalPlace.loaded,
            [ERecentTab.Edit]: userInfo.personalPlace.loaded,
            [ELegacyFavoriteFilter.POI]: userInfo.personalPlace.loaded,
            [ELegacyFavoriteFilter.ROUTE]: userInfo.favoriteRoutes.loaded,
            [ELegacySearchTabs.ADDRESS]: true,
            [ELegacySearchTabs.MAP]: true,
          }[nowLogTab]
        : false,
    [nowLogTab, userInfo]
  );

  const paramPoiInfo = useMemo(() => {
    if (originQueries.poi_info) {
      const poiInfo = getFirstParam(originQueries.poi_info);

      try {
        const parsedData = JSON.parse(poiInfo);

        return parsedData;
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }, [originQueries.poi_info]);

  useEffect(() => {
    if (!userInfo.accessKey || !nowLogTab || hide) {
      return;
    }

    const pageId = PageIdMap[nowLogTab];

    if (!pageId) {
      return;
    }

    initLog({
      sessionId: userInfo.sessionId,
      accessKey: userInfo.accessKey,
      sessionKey: userInfo.sessionKey,
      userKey: userInfo.userKey,
      deviceId: userInfo.device.deviceId,
      carrierName: userInfo.device.carrierName,
      pageId,
      pageType: EPageType.SEARCH,
      referrer,
    });
  }, [userInfo.accessKey, nowLogTab, hide, forceLogInitKey]);

  useEffect(() => {
    const isFirstVisit = refVisitedTab.current !== nowLogTab;

    if (!isDataLoaded || !nowLogTab || !isFirstVisit || hide) {
      return;
    }

    const list_num = listCountMap[nowLogTab];

    refVisitedTab.current = nowLogTab;

    sendExpose(EPageType.SEARCH, {
      // 0: 재진입, 1: 첫진입
      index: 1,
      ...(list_num ? {list_num} : {}),
    });
  }, [isDataLoaded, nowLogTab]);

  useEffect(() => {
    if (hide || !nowLogTab) {
      return;
    }
    const list_num = listCountMap[nowLogTab];

    sendExpose(EPageType.SEARCH, {
      // 0: 재진입, 1: 첫진입
      index: 0,
      ...(list_num ? {list_num} : {}),
    });
  }, [resumeKey, hide]);

  useEffect(() => {
    if (!hide) {
      afSendSearchMainPageView();
    }
  }, [hide]);

  const handleSearch = useCallback(() => {
    moveToSearch(nowQuery);
  }, [nowQuery, moveToSearch]);

  const handleChangeQuery = useCallback((q) => {
    setHideSuggest(false);
    setQuery(q);
  }, []);

  const handleSetQueryWithoutSuggest = useCallback(
    (q, addressOptions) => {
      if (isHybrid) {
        HybridBridge.queryInputTextNoSuggest(q);
      }

      setHideSuggest(true);
      setQuery(q);

      dispatch(actions.search.setAddressSearch(addressOptions));
    },
    [dispatch, isHybrid]
  );

  const handleSearchBlur = useCallback(() => {
    setBlurKey(Date.now());
  }, []);

  const handleChangeTab = useCallback(
    (tabKey) => {
      tabKey !== ELegacySearchTabs.ADDRESS && dispatch(actions.search.setAddressSearch(null));
      setNowLogTab(tabKey);
    },
    [dispatch]
  );

  const initTypingLog = useCallback(() => {
    if (!userInfo.accessKey) {
      return;
    }

    updateMeta({
      page_id: TLA_SEARCH_TYPING,
    });
  }, [userInfo]);

  const resetAfterTyping = useCallback(() => {
    setForceLogInitKey(Date.now());
  }, []);

  useEffect(() => {
    if (hide) {
      // 화면에서 사라질때 작업
      setQuery('');
    } else {
      isHybrid ? updateLocalItems() : updateLocalItems(getLocalItems());
      setLoadKey(Date.now());

      if (isHybrid && !window[ETempWindowKey.HISTORY_BACK_FROM_APP]) {
        HybridBridge.moveToMain();
      }
      window[ETempWindowKey.HISTORY_BACK_FROM_APP] = false;

      // 현재페이지로 들어올때 작업
      if (window[ETempWindowKey.NOW_QUERY]) {
        if (!isHybrid) {
          setQuery(window[ETempWindowKey.NOW_QUERY]);
          setHideSuggest(true);
          setFocusKey(Date.now());
        }
      } else if (originQueries.query) {
        const query = getFirstParam(originQueries.query);

        if (isHybrid) {
          HybridBridge.queryInputTextNoSuggest(query);
        }

        setQuery(query);
        setHideSuggest(true);

        const mainUrl = generateUrl(
          SearchHybridPages.includes(pathname) ? Paths.SearchHybridMain : Paths.SearchMain,
          {
            ...originQueries,
            query: undefined,
          }
        );
        history.replace(mainUrl);

        if (originQueries.extra === EAppExtraCode.SEARCH_T_MAP) {
          setFromMoveSearch(true);
        }
      }

      window[ETempWindowKey.NOW_QUERY] = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hide]);

  useOnce(fromMoveSearch && !originQueries.query, () => {
    fromDeepLinkToSearch(nowQuery);
  });

  return (
    <VSMInterfaceProvider>
      <div
        className={classNames(s.main_page_warp, {
          [s.hide_page]: hide,
          [s.hybrid_page]: isHybrid,
        })}
        aria-hidden={hide}
        style={{
          visibility:
            isHybrid && ua.isInApp && !statusBarHeight && !searchBarHeight ? 'hidden' : undefined,
          paddingTop: isHybrid
            ? (statusBarHeight || 0) + (searchBarHeight || 0) + HYBRID_SEARCHBAR_GAP
            : 0,
        }}
      >
        {!isHybrid && !hide && (
          <SearchBox
            query={nowQuery}
            onChange={handleChangeQuery}
            blurKey={blurKey}
            focusKey={focusKey}
            onSearch={handleSearch}
            onRemove={() => sendSearchClickLog('top_tap.x')}
            onClick={() => sendSearchClickLog('tap.searchbox')}
            onClose={() => sendSearchClickLog('tap.back')}
          />
        )}

        {!isHybrid && !hideSuggest && (
          <SuggestResult
            query={nowQuery}
            favoriteItems={favorites}
            recentItems={recentDestination}
            recentQueries={recentQueries}
            suggestQueries={suggestResult.data?.v1_suggestions?.suggestions}
            suggestPois={suggestResult.data?.v2_suggestions?.suggestions}
            onSearchBlur={handleSearchBlur}
            onShow={() => initTypingLog()}
            onHide={() => resetAfterTyping()}
          />
        )}

        <div className={s.contents_wrap} onTouchStart={handleSearchBlur}>
          <Tabs values={tabList} onChange={handleChangeTab}>
            <SearchTabHeader />

            <TabContent tabKey={ELegacySearchTabs.RECENT}>
              <RecentTab
                paramPoiInfo={paramPoiInfo}
                onAfterEdit={() => {
                  setNowLogTab(ELegacySearchTabs.RECENT);
                }}
                onExitEdit={() => {
                  setNowLogTab(ELegacySearchTabs.RECENT);
                }}
                onEnterEdit={() => {
                  setNowLogTab(ERecentTab.Edit);
                }}
                pageHide={hide}
              />
            </TabContent>

            <TabContent tabKey={ELegacySearchTabs.FAVORITE}>
              <FavoriteTab onChangeTab={setNowLogTab} />
            </TabContent>

            <TabContent tabKey={ELegacySearchTabs.ADDRESS}>
              <AddressTab
                onChange={handleSetQueryWithoutSuggest}
                onSearch={handleSearch}
                loadKey={loadKey}
              />
            </TabContent>

            <TabContent tabKey={ELegacySearchTabs.MAP}>
              <PickOnMapTab />
            </TabContent>
          </Tabs>
        </div>

        {!hide && <RouteAddPopup />}
      </div>
    </VSMInterfaceProvider>
  );
};

export default LegacySearchMainPage;
