export type TExposeLimitTime = {
  start?: string;
  end?: string;
};

export enum EDateFormat {
  HHmm = 'HH:mm',
  MMddeee = 'MM.dd(eee)',
  Mdd = 'M.dd',
  MMdd = 'MM.dd',
  API = 'yyyyMMddHHmmss',
  LOG = 'yyyyMMddHHmmssSSS',
  GENERAL = 'yyyy-MM-dd HH:mm:ss',
  yyyyMMdd = 'yyyy-MM-dd',
  Mdeee = 'M/d(eee)',
  MMd = 'MM/d',
  YYYYMMDD = 'yyyy.MM.dd',
}
