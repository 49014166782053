import {Fragment, memo, useCallback, useEffect, useMemo, useRef} from 'react';
import classNames, {Argument as ClassNameType} from 'classnames';
import getHours from 'date-fns/getHours';
import InView from 'react-intersection-observer';
import {
  EAdCode,
  PLACE_BANNER_AD_STEP,
  PLACE_BANNER_PROVIDER_CONFIG,
  TEST_BANNER_PROVIDER_CONFIG,
  isBannerTestEnv,
} from 'constant/Ads';
import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {
  EAppExtraCode,
  EAppRequestMode,
  EAppRequestTypeNew,
  EDetailButtonType,
  TPoiItem,
} from 'types/App';
import {TApiStatus} from 'types/Api';
import {EPerformanceLogKey} from 'types/Log';
import {EListMode} from 'types/ListDrawer';
import {debounce} from 'utils/lodash';
import NoResult from 'components/NoResult';
import ErrorReload from 'components/ErrorReload';
import useThrottle from 'hooks/useThrottle';
import useLogger from 'hooks/useLogger';
import {EListActionId} from 'constant/Log';
import {useOnce} from 'hooks/useOnce';
import {usePerformanceLog} from 'hooks/usePerformanceLog';
import {AdBanner} from 'components/AdBanner';
import useMoveToTarget from 'hooks/useMoveToTarget';
import {useFocusAfterRotate} from 'hooks/useFocusAfterRotate';
import {PoiListItem} from 'components/PoiListItem';
import {useSearchImageList} from 'hooks/useSearchImageList';
import {IS_WEEKEND} from 'constant/Date';
import {TNOW_DEFAULT_CATEGORY} from 'constant/PlaceCategory';

import s from 'styles/components/tnow/TNowList.module.scss';

type TProps = {
  isServiceArea?: boolean;
  apiStatus: TApiStatus<{
    list: TPoiItem[];
    currentPage: number;
    pagingSize: number;
    totalCount: number;
  }>;
  itemClass?: ClassNameType;
  activeItemClass?: ClassNameType;
  onFetchMore?: VoidFunction;
  onError?: VoidFunction;
  onClickAutoSearch?: VoidFunction;
};

const DEBOUNCE_DELAY = 300;
const BANNER_INJECT_INDEX = 0;
const TNOW_MAIN_ERROR_TOP = 70;
const FIRST_MARKER = 'first';

const TNowList = memo(
  ({
    isServiceArea,
    apiStatus,
    itemClass,
    activeItemClass,
    onError,
    onFetchMore,
    onClickAutoSearch,
  }: TProps) => {
    const dispatch = useAppDispatch();
    const {
      data: {list, pagingSize, totalCount},
      error,
      loaded,
      loading,
    } = apiStatus;
    const visibleItemList = useRef<(boolean | string)[]>([]);
    const {moveToDetail, reqMode, reqType, extra} = useMoveToTarget({});
    const {sendTNowItemClickLog, sendClickLogWithMapView, sendExtendButtonLog} = useLogger();
    const {activePoi, drawerMode, lastCachedCenter, categoryType, euk} = useAppSelector(
      (state) => ({
        activePoi: state.userInteraction.activePoi,
        drawerMode: state.userInteraction.drawerMode,
        lastCachedCenter: state.map.lastCachedCenter,
        categoryType: state.tNow.categoryType,
        euk: state.userInfo.euk,
      })
    );

    const imageCounter = useSearchImageList();
    const ableToRun = useThrottle();
    const {endLog} = usePerformanceLog();
    const {isRotateChange} = useFocusAfterRotate();

    useOnce(loaded, () => {
      endLog(EPerformanceLogKey.INIT_API_RENDER);
    });

    const updateFocusItem = useCallback(
      debounce(() => {
        const checkValue = drawerMode === EListMode.BOTTOM ? FIRST_MARKER : true;
        const idx = visibleItemList.current.findIndex((visible) => visible === checkValue);

        const item = list[idx];

        if (item) {
          dispatch(
            actions.userInteraction.setInteraction({
              activePoi: item.listId,
              trigger: 'scroll',
            })
          );
        }
      }, DEBOUNCE_DELAY),
      [list, drawerMode]
    );

    const changeViewingItems = useCallback(
      (i, visible) => {
        if (isRotateChange) {
          return;
        }
        if (drawerMode === EListMode.BOTTOM && visibleItemList.current.length === 0) {
          visibleItemList.current[0] = FIRST_MARKER;
        } else {
          visibleItemList.current[i] = visible;
        }

        updateFocusItem();
      },
      [updateFocusItem, drawerMode, isRotateChange]
    );

    const handleClickRadius = useCallback(() => {
      onClickAutoSearch?.();
      sendExtendButtonLog();
    }, [onClickAutoSearch, sendExtendButtonLog]);

    useEffect(() => {
      if (loading && !loaded && list.length < 1) {
        visibleItemList.current = [];
      }
    }, [loading, loaded, list]);

    const handleClickReload = useCallback(() => {
      if (ableToRun()) {
        onError?.();
        sendClickLogWithMapView(EListActionId.FAIL_REFRESH, {}, {includeTicketId: true});
      }
    }, [ableToRun, onError, sendClickLogWithMapView]);

    const getItemLog = useCallback(
      (v, i) => ({
        list_num: totalCount,
        list_seq: i + 1,
        pkey: v.pkey,
        page: Math.floor(i / pagingSize) + 1,
      }),
      [totalCount, pagingSize]
    );

    const handleClickItem = useCallback(
      (e, v: TPoiItem, i) => {
        e.preventDefault();
        e.stopPropagation();

        const param = getItemLog(v, i);
        const categoryTab = categoryType || 'ALL';
        const defaultCategory = TNOW_DEFAULT_CATEGORY;
        const catchTableWaitingResponse = v.special?.catchTableWaitingResponse;
        const {
          isAvailableReservation,
          isAvailableWaiting,
          isAvailableOnlineWaiting,
          unit,
          onlineWaitingDisableReason,
        } = catchTableWaitingResponse || {};

        sendTNowItemClickLog(EListActionId.ITEM, {
          ...param,
          obj_cnt: v.headingForScore,
          category_tab: categoryTab,
          is_weekend: IS_WEEKEND,
          time_type: getHours(new Date()),
          default_tab_category: defaultCategory,
          is_default_tab: categoryTab === defaultCategory,
          euk,
          is_catch: !!catchTableWaitingResponse,
          is_reserve: !!isAvailableReservation,
          is_waiting: !!isAvailableWaiting,
          ...(!!isAvailableWaiting && {
            waiting_available: isAvailableOnlineWaiting,
            waiting_count: unit?.count,
            waiting_not_available_reason: onlineWaitingDisableReason,
          }),
        });
        moveToDetail(v, {
          reqMode: reqMode || EAppRequestMode.MAIN,
          reqType: reqType || EAppRequestTypeNew.NO_DATA,
          extra: extra || EAppExtraCode.SETTING_DEFAULT,
        });
      },
      [getItemLog, sendTNowItemClickLog, moveToDetail, reqMode, reqType, extra, categoryType, euk]
    );

    const errorMessages = useMemo(() => {
      if (!loaded || list.length > 0) {
        return null;
      }

      if (error) {
        return {
          reload: true,
        };
      }

      if (!isServiceArea) {
        return {
          title: '서비스 제공 지역이 아닙니다.',
          description: '다른 위치에서 탐색해 보세요.',
          reload: false,
        };
      }

      return null;
    }, [isServiceArea, loaded, error, list.length]);

    if (errorMessages) {
      return (
        <ErrorReload
          title={errorMessages.title}
          description={errorMessages.description}
          onReload={errorMessages.reload ? handleClickReload : undefined}
          top={TNOW_MAIN_ERROR_TOP}
        />
      );
    }

    if (loaded && list.length === 0) {
      return (
        <NoResult
          top={TNOW_MAIN_ERROR_TOP}
          title={
            <>
              주변에 실시간
              <br />
              인기 주행장소가 없습니다.
            </>
          }
          description={'반경을 넓혀 탐색해 보세요.'}
          buttonProps={{
            text: '반경 확장',
            onClick: handleClickRadius,
          }}
        />
      );
    }

    return (
      <>
        <ul className={classNames(s.list_wrap)}>
          {list.map((v, i) => {
            return (
              <Fragment key={`${v.poiId}-${i}`}>
                <InView
                  as="li"
                  className={classNames(
                    s.item_wrap,
                    itemClass,
                    activePoi === v.listId && activeItemClass
                  )}
                  data-type="poi"
                  data-id={v.listId}
                  threshold={0.5}
                  onChange={(e) => changeViewingItems(i, e)}
                  onClick={(e) => handleClickItem(e, v, i)}
                >
                  <div className={s.rank}>{i + 1}</div>
                  <div className={s.detail}>
                    <PoiListItem
                      idx={i}
                      poiData={{
                        ...v,
                        listName: v.poiName,
                        imageInfo: imageCounter.getTNowListByViewPort(v.imageInfo),
                      }}
                      actionButtonType={EDetailButtonType.NONE}
                      onClickItem={(e) => handleClickItem(e, v, i)}
                    />
                  </div>
                </InView>

                {i === BANNER_INJECT_INDEX && (
                  <div className={s.banner}>
                    <AdBanner
                      visibleLandscape={true}
                      disableCoords={true}
                      adCode={EAdCode.PLACE_MAIN}
                      adTypeStep={PLACE_BANNER_AD_STEP}
                      adTypeOption={{
                        ...PLACE_BANNER_PROVIDER_CONFIG,
                        ...(isBannerTestEnv ? TEST_BANNER_PROVIDER_CONFIG : {}),
                      }}
                      bannerLonLat={lastCachedCenter}
                    />
                  </div>
                )}
              </Fragment>
            );
          })}
        </ul>

        {list.length > 0 && (
          <InView
            onChange={(isVisible) => {
              isVisible && !apiStatus.loading && onFetchMore?.();
            }}
            threshold={0.9}
          >
            <div style={{height: '1px'}} />
          </InView>
        )}
      </>
    );
  }
);

export default TNowList;
