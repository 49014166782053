import {PropsWithChildren, ReactNode, useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import classNames from 'classnames';

import {LAYER_ID} from 'types/App';

import useElement from 'hooks/useElement';

import s from 'styles/components/CustomSelectLayer.module.scss';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

type TProps = PropsWithChildren<{
  isOpen: boolean;
  contentComponent: ReactNode;
  bottomComponent?: ReactNode;
}>;

const BottomSheetPopup = ({isOpen, children, contentComponent, bottomComponent}: TProps) => {
  const layerContainer = useElement({id: LAYER_ID});
  const refScroll = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && refScroll.current) {
      refScroll.current.scrollTop = 0;
    }
  }, [isOpen]);

  // DimmedLayer.tsx 터치 스크롤 무시 로직이 있음.
  useEffect(() => {
    if (!refScroll.current) {
      return;
    }

    if (isOpen) {
      // https://github.com/willmcpo/body-scroll-lock#allowtouchmove
      disableBodyScroll(refScroll.current, {
        allowTouchMove: () => true,
      });
    } else {
      enableBodyScroll(refScroll.current);
    }
  }, [isOpen, layerContainer]);

  return (
    layerContainer &&
    createPortal(
      <div
        className={classNames(s.custom_select_wrapper, {
          [s.is_open]: isOpen,
        })}
        aria-modal={isOpen}
      >
        <div
          className={s.list}
          ref={refScroll}
          role="radiogroup"
          style={{maxHeight: 'calc(100vh - 42px)'}}
        >
          {contentComponent}
        </div>
        {bottomComponent && <div className={s.bottom_area}>{bottomComponent}</div>}
      </div>,
      layerContainer
    )
  );
};

export default BottomSheetPopup;
