import {useCallback} from 'react';
import {ReactComponent as IcoArrowRightBold} from 'resource/images/@tmds_element/ico_arrow_right_bold.svg';
import s from 'styles/components/PoiListItemCouponLink.module.scss';

type TProps = {
  label: string;
  onClick?: (e: any) => void;
};

const PoiListItemCouponLink = ({label, onClick}: TProps) => {
  const handleClickCoupon = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onClick?.(e);
    },
    [onClick]
  );

  return (
    <a className={s.coupon_link} href="#" onClick={handleClickCoupon}>
      <span>할인{label}이 있어요</span>
      <i>
        <IcoArrowRightBold />
      </i>
    </a>
  );
};

export default PoiListItemCouponLink;
