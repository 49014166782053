import TMapSender from '@lcc/tmap-inapp';
import actions from 'ducks/actions';
import {DeepLinkCategoryMap, DefaultDeepLinkReqKeyMap} from 'constant/DeepLink';
import {Paths} from 'constant/RoutePath';
import {MSG_WRONG_ACCESS} from 'constant/Text';
import {useEffect, useMemo, useRef} from 'react';
import ua from 'utils/uaParser';
import {generateUrl} from 'utils/url';
import {DEFAULT_VALID_CATEGORY_PAGE, getDefaultValidCategoryPage} from 'constant/PlaceCategory';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {useOnce} from './useOnce';
import {TCategoryPageProperty} from 'ducks/category/types';
import {checkExpose} from 'utils/date';

export const filterQueryToCategoryParam = (queries) => {
  if (queries.category && parseInt(queries.category as string, 10) >= 0) {
    return DeepLinkCategoryMap[queries.category as string];
  } else if (queries.reqKey) {
    const defaultSupport = DefaultDeepLinkReqKeyMap[queries.reqKey as string];

    return defaultSupport ? defaultSupport : {type: queries.reqKey};
  }
};

export const useValidCategory = (queries: Record<string, string> = {}) => {
  const dispatch = useAppDispatch();

  const {remoteConfig, currentCategory, validCategoryPageList} = useAppSelector((state) => ({
    remoteConfig: state.remote,
    currentCategory: state.category.currentCategory,
    validCategoryPageList: state.category.validCategoryPageList,
  }));

  const categoryProperties = useMemo(
    () => validCategoryPageList.find((i) => i.type === currentCategory),
    [currentCategory, validCategoryPageList]
  );

  const preventHistoryBack = useRef<boolean>(false);
  const isCheckWithType = !queries.category && !queries.reqKEy && !!queries.type;

  useEffect(() => {
    const deepLinkTarget = filterQueryToCategoryParam(queries);

    if (deepLinkTarget?.type) {
      preventHistoryBack.current = true;
      window.location.replace(
        generateUrl(Paths.PlaceCategory, {
          type: deepLinkTarget.type,
          filter1: deepLinkTarget.filter1,
          filter2: deepLinkTarget.filter2,
          tailParam: queries?.tailParam,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnce(!remoteConfig.lastUpdateTime && !currentCategory, () => {
    const targetPage = getDefaultValidCategoryPage(queries.type);

    if (targetPage) {
      dispatch(actions.category.setCurrentCategory(targetPage.type));
      dispatch(actions.category.setValidCategoryPageList(DEFAULT_VALID_CATEGORY_PAGE));
    }
  });

  useOnce(!!remoteConfig.lastUpdateTime && !currentCategory && queries, () => {
    const validCategoryPages: TCategoryPageProperty[] = [
      ...DEFAULT_VALID_CATEGORY_PAGE,
      ...remoteConfig.customCategoryPage,
    ].filter((c) => (c?.exposeTime ? checkExpose(c.exposeTime) : true));

    const targetPage = validCategoryPages.find((i) => i.type === queries.type);

    if (targetPage) {
      dispatch(actions.category.setCurrentCategory(targetPage.type));
      dispatch(actions.category.setValidCategoryPageList(validCategoryPages));

      return;
    } else {
      dispatch(actions.category.setValidCategoryPageList(DEFAULT_VALID_CATEGORY_PAGE));
    }

    if (!preventHistoryBack.current) {
      TMapSender.makeToast(MSG_WRONG_ACCESS);

      if (ua.isInApp) {
        TMapSender.onBackKeyPressed();
      }
    }
  });

  return {
    target: categoryProperties,
    isValidCheckFinish: isCheckWithType && validCategoryPageList.length > 0,
  };
};
