import {useMemo} from 'react';
import {useAppSelector} from '../../../ducks/hooks';

import s from '../../../styles/components/tplacehome/TPlaceDiscovery.module.scss';
import TmapAiIcon from '../shared/TmapAiIcon';
import {ETPlaceTab} from 'ducks/tplacehome/types';

const DiscoveryTmapAI = () => {
  const {tplacehome} = useAppSelector((state) => state);

  const isShow = useMemo(() => {
    const hasSimilarData = tplacehome.discoveryData.result.data.similarList.length > 1;
    const hasWeekendData = tplacehome.discoveryData.result.data.weekendList.length > 2;

    return hasSimilarData || hasWeekendData;
  }, [tplacehome]);

  if (!isShow) {
    return null;
  }

  return (
    <div className={s.tmap_ai_wrap}>
      <TmapAiIcon tab={ETPlaceTab.DISCOVERY} />
    </div>
  );
};

export default DiscoveryTmapAI;
