import SearchBox from 'components/legacySearch/SearchBox';
import SuggestResult from 'components/legacySearch/SuggestResult';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import useRecentQuery from 'hooks/useRecentQuery';
import useSearchSuggest from 'hooks/useSearchSuggest';
import {useCallback, useState} from 'react';

export const SearchBar = () => {
  const [nowQuery, setQuery] = useState<string>('');
  const [hideSuggest, setHideSuggest] = useState<boolean>(false);
  const {recentQueries} = useRecentQuery();
  const {moveToSearch} = useMoveToTarget({from: EFromType.SEARCH_MAIN});
  const suggestResult = useSearchSuggest({query: nowQuery});

  const handleSearch = useCallback(() => {
    moveToSearch(nowQuery);
  }, [nowQuery, moveToSearch]);

  const handleChangeQuery = useCallback((q) => {
    setHideSuggest(false);
    setQuery(q);
  }, []);

  return (
    <div>
      <SearchBox query={nowQuery} onChange={handleChangeQuery} onSearch={handleSearch} />
      {!hideSuggest && (
        <SuggestResult
          query={nowQuery}
          recentQueries={recentQueries}
          suggestQueries={suggestResult.data?.v1_suggestions?.suggestions}
          suggestPois={suggestResult.data?.v2_suggestions?.suggestions}
        />
      )}
    </div>
  );
};
