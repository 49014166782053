import {useEffect} from 'react';
import TMapSender from '@lcc/tmap-inapp';

import useThrottle from 'hooks/useThrottle';
import {useAppSelector} from 'ducks/hooks';

import {convertWebToAppZoomLevel} from 'utils/map';

const useMapContext = () => {
  const {nowCenter, nowPitch, nowBearing, zoom} = useAppSelector((state) => ({
    nowCenter: state.map.nowCenter,
    nowPitch: state.map.nowPitch,
    nowBearing: state.map.nowBearing,
    zoom: state.map.zoom,
  }));
  const ableToRun = useThrottle();

  useEffect(() => {
    if (nowCenter) {
      TMapSender.setCurrentMapContext(
        nowCenter.lon,
        nowCenter.lat,
        nowPitch,
        nowBearing,
        convertWebToAppZoomLevel(zoom)
      );
    }
  }, [ableToRun, nowCenter?.lon, nowCenter?.lat, nowPitch, nowBearing, zoom]);
};

export default useMapContext;
