import {useCallback, useEffect, useState} from 'react';
import TMapSender, {
  ECallbackKeys,
  EDateFormat,
  ERedDotDisplayType,
  ERedDotItemGroup,
  TMapReceiver,
  TRedDotItem,
} from '@lcc/tmap-inapp';
import {isUnReadRedDot} from './usePlaceCategoryRedDot';
import {getSafeDateFormat} from '../utils/date';

type TProps = {
  itemGroup: ERedDotItemGroup;
};

const useRedDot = ({itemGroup}: TProps) => {
  const [redDotList, setRedDotList] = useState<TRedDotItem[]>();
  const [isUpdated, setUpdated] = useState(false);

  const getRedDotList = useCallback((itemGroupName: ERedDotItemGroup) => {
    TMapSender.getRedDotList();
    TMapReceiver.setCallback(ECallbackKeys.GET_RED_DOT_LIST, (list: TRedDotItem[]) => {
      const result = list.filter(
        (item) =>
          item.itemGroup === itemGroupName &&
          item.displayType === ERedDotDisplayType.REDDOT &&
          isUnReadRedDot(item)
      );
      setRedDotList(result);
    });
  }, []);

  const getRedDotItem = useCallback(
    (item: string) => {
      return redDotList?.find((data) => data.item === item);
    },
    [redDotList]
  );

  const updateRedDotList = useCallback(
    (itemName: string) => {
      const readDateTime = `${Number(getSafeDateFormat(Date.now(), EDateFormat.REDDOT))}`;
      setUpdated(false);
      TMapSender.updateRedDotList([
        {
          itemGroup: itemGroup,
          item: itemName,
          displayType: ERedDotDisplayType.REDDOT,
          readDateTime,
        },
      ]);
      setUpdated(true);
    },
    [itemGroup]
  );

  useEffect(() => {
    if (isUpdated) {
      getRedDotList(itemGroup);
    }
  }, [isUpdated, itemGroup, getRedDotList]);

  useEffect(() => {
    if (itemGroup) {
      getRedDotList(itemGroup);
    }
  }, [itemGroup, getRedDotList]);

  return {
    redDotList,
    getRedDotList,
    getRedDotItem,
    updateRedDotList,
  };
};

export default useRedDot;
