import {ReactNode, useCallback} from 'react';
import classNames from 'classnames';
import {ECityCode, ESearchLocationType, ESortOption} from 'types/Search';
import {TActionId} from 'types/Log';
import {SEARCH_COLLECTION_LABEL} from 'constant/Search';
import useLogger from 'hooks/useLogger';
import {useAppSelector} from 'ducks/hooks';
import {ESearchCollectionType} from 'ducks/search/types';
import {IcoMapPinFilled} from 'components/@tmds/icons/IcoMapPinFilled';
import {IcoVehicleBusFilled} from 'components/@tmds/icons/IcoVehicleBusFilled';
import {IcoBusStopFilled} from 'components/@tmds/icons/IcoBusStopFilled';
import SearchResultSortPoi from 'components/search/SearchResultSortPoi';
import SearchResultSortBus from 'components/search/SearchResultSortBus';
import SearchResultSortBusStation from 'components/search/SearchResultSortBusStation';

import s from 'styles/components/search/SearchResultHeader.module.scss';

export type TSearchResultSortParam = {
  sort?: ESortOption;
  locationType?: ESearchLocationType;
  cityCode?: ECityCode;
};

type TProps = {
  pageHide?: boolean;
  collectionList: ESearchCollectionType[];
  onChange?: (param: TSearchResultSortParam) => void;
  onChangeCollectionType?: (param: ESearchCollectionType) => void;
};

const SEARCH_COLLECTION_ICON: Record<ESearchCollectionType, (p) => ReactNode> = {
  [ESearchCollectionType.POI]: (p) => <IcoMapPinFilled {...p} />,
  [ESearchCollectionType.BUS_LINE]: (p) => <IcoVehicleBusFilled {...p} />,
  [ESearchCollectionType.BUS_STATION]: (p) => <IcoBusStopFilled {...p} />,
};

const SEARCH_SORT_COMPONENT: Record<ESearchCollectionType, (p) => ReactNode> = {
  [ESearchCollectionType.POI]: (p) => <SearchResultSortPoi {...p} />,
  [ESearchCollectionType.BUS_LINE]: (p) => <SearchResultSortBus {...p} />,
  [ESearchCollectionType.BUS_STATION]: (p) => <SearchResultSortBusStation {...p} />,
};

const TAB_ACTION_ID_MAP: Record<ESearchCollectionType, TActionId> = {
  [ESearchCollectionType.POI]: 'tab_tap.place',
  [ESearchCollectionType.BUS_LINE]: 'tab_tap.bus',
  [ESearchCollectionType.BUS_STATION]: 'tab_tap.bus_station',
};

const SearchResultHeader = ({
  pageHide,
  collectionList,
  onChange,
  onChangeCollectionType,
}: TProps) => {
  const {isHybrid, isLoaded, hasData, nowCollectionType} = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
    isLoaded: state.search.loaded,
    hasData: !!state.search.data.list.length,
    nowCollectionType: state.search.data.collectionType,
  }));
  const {sendClickLog} = useLogger();

  const getSortComponent = SEARCH_SORT_COMPONENT[nowCollectionType];

  const handleClickTab = useCallback(
    (e, collectionType) => {
      sendClickLog(TAB_ACTION_ID_MAP[collectionType]);
      onChangeCollectionType?.(collectionType);
    },
    [onChangeCollectionType, sendClickLog]
  );

  return (
    <div
      className={classNames(s.result_header, {
        [s.hybrid]: isHybrid,
        [s.is_default]: collectionList.length === 3,
      })}
    >
      <ul className={s.collection_wrap}>
        {collectionList.map((v) => (
          <li
            key={v}
            className={classNames(s.item, {
              [s.is_active]: isLoaded || hasData ? v === nowCollectionType : false,
            })}
            onClick={(e) => handleClickTab(e, v)}
          >
            {SEARCH_COLLECTION_ICON[v]({
              width: 20,
              height: 20,
              color: v === nowCollectionType ? 'gray900' : 'gray400',
            })}
            <span className={s.text}>{SEARCH_COLLECTION_LABEL[v]}</span>
          </li>
        ))}
      </ul>

      {getSortComponent?.({pageHide, onChange})}
    </div>
  );
};

export default SearchResultHeader;
