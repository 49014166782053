// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcMenu1FillSvg} from 'resource/@tmdsicon/v1.2/ic_menu_1_fill.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcMenu1Fill = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcMenu1FillSvg {...props} />} />;
};

export {IcMenu1Fill};
