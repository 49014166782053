import {useEffect, useRef, useState} from 'react';
import axios, {CancelTokenSource} from 'axios';

import {TPoiBasicInfo} from 'types/App';
import {TApiStatus} from 'types/Api';

import {getDefaultApiStatus} from 'utils/apis';
import {fetcherPoi} from 'utils/fetcher';
import {POI_API_PATH} from 'constant/Api';

const DEFAULT_STATUS = getDefaultApiStatus<TPoiBasicInfo>({
  pkey: '',
  poiId: 0,
  navSeq: 0,
  poiNameSvc: '',
  centerX: 0,
  centerY: 0,
  navX: 0,
  navY: 0,
  wgs84CenterX: 0,
  wgs84CenterY: 0,
  wgs84NavX: 0,
  wgs84NavY: 0,
  fullJibunAddr: '',
  fullRoadAddr: '',
  phones: [],
});

const CancelToken = axios.CancelToken;

type TProps = {
  pkey?: string;
  poiId?: string | number;
};

export const usePoiBasicInfo = ({pkey, poiId}: TProps) => {
  const [state, setState] = useState<TApiStatus<Nullable<TPoiBasicInfo>>>(DEFAULT_STATUS);
  const refCancelToken = useRef<Nullable<CancelTokenSource>>(null);

  useEffect(() => {
    if (poiId || pkey) {
      setState({
        ...DEFAULT_STATUS,
        loading: true,
      });

      refCancelToken.current = CancelToken.source();

      fetcherPoi
        .get(POI_API_PATH.GET_POI_BASIC, {
          params: {poiId, pkey},
          cancelToken: refCancelToken.current.token,
        })
        .then((e) => {
          if (e.data.data) {
            setState({
              loaded: true,
              loading: false,
              data: e.data.data,
              error: undefined,
            });
          } else {
            throw new Error('No Data');
          }
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            return;
          }
          setState({
            loaded: true,
            loading: false,
            data: null,
            error: e.message,
          });
        });
    } else {
      setState({
        loaded: true,
        loading: false,
        data: null,
        error: new Error('invalid poi id, pkey'),
      });
    }

    return () => {
      refCancelToken.current?.cancel();
      refCancelToken.current = null;
    };
  }, [poiId, pkey]);

  return state;
};

export default usePoiBasicInfo;
