import TMapSender, {ECallbackKeys, EDateFormat, TMapReceiver, TRedDotItem} from '@lcc/tmap-inapp';
import {isUnReadRedDot} from 'hooks/usePlaceCategoryRedDot';
import {useEffect, useState} from 'react';
import {getSafeDateFormat} from 'utils/date';

const DevRedDotPage = () => {
  const [itemList, setItemList] = useState<TRedDotItem[]>([]);

  useEffect(() => {
    TMapReceiver.setCallback(ECallbackKeys.GET_RED_DOT_LIST, (list: TRedDotItem[]) => {
      setItemList(list);
    });
    TMapSender.getRedDotList();
  }, []);

  return (
    <div>
      <div>
        <button
          onClick={() => {
            TMapSender.onBackKeyPressed();
          }}
        >
          뒤로가기
        </button>
        현재시간 : {Number(getSafeDateFormat(Date.now(), EDateFormat.REDDOT) || 0)}
      </div>
      <ul>
        {itemList.map((item) => (
          <li style={{border: '1px solid red'}}>
            {Object.keys(item).map((key) => (
              <p>
                <span>
                  {key} : {item[key]}
                </span>
              </p>
            ))}
            <p>{isUnReadRedDot(item) ? 'unread' : 'read'}</p>
            <p>
              <button
                onClick={() => {
                  TMapSender.updateRedDotList([
                    {
                      ...item,
                      readDateTime: `${Number(getSafeDateFormat(Date.now(), EDateFormat.REDDOT))}`,
                    },
                  ]);
                  TMapSender.makeToast('3초 후에 새로고침');
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                }}
              >
                읽음처리하기
              </button>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DevRedDotPage;
