import {ECategoryCode} from 'constant/Place';
import {TSearchRecommend} from 'ducks/search/types';
import {TApiResponse, TApiStatus} from 'types/Api';
import {
  ECategoryGroup,
  TSpecialData,
  EPlaceTag,
  TPlaceTagItem,
  TPoiImageInfo,
  EPlaceCategoryType,
} from 'types/App';
import {TLonLat} from 'types/Map';
export type TNowProps = TLonLat & {
  playEatYn?: 'Y' | 'N';
  radius?: number;
  headingScoreFrom?: number;
  headingScoreTo?: number;
  ticketId?: string;
  categoryType?: EPlaceCategoryType;
};

export type TNowLinkProps = TLonLat & {
  fuelCode: string;
  appVersion: string;
};

export enum EHeadingStatus {
  UP = 1,
  DOWN = -1,
  SAME = 0,
}

export const TNOW_MAX_COUNT = 50;

export const WHOLE_AREA = Infinity;
export const SLIDER_LIST = [500, 1000, 3000, 5000, 10000, WHOLE_AREA];

export type TNowItem = {
  pkey: string;
  poiId: string;
  poiName: string;
  distance: number;
  userRealDistance: number;
  fullRoadAddr: string;
  fullJibunAddr: string;
  categoryCode: string; // '8_1_8_9'
  svcCategoryName: string;
  centerX: number;
  centerY: number;
  navX: number;
  navY: number;
  wgs84CenterX: number;
  wgs84CenterY: number;
  wgs84NavX: number;
  wgs84NavY: number;
  imageInfo?: TPoiImageInfo[];
  headingForScore: number;
  headingForTick: EHeadingStatus;

  categoryGroup: ECategoryGroup;
  isParking: boolean;
  isPopular: boolean;
  special: TSpecialData;
  tag: TPlaceTagItem;

  isExposureScore: boolean;
  mainCategory: ECategoryCode | string;
  markerCategory: Nullable<string>;
  markerCategories: string[];
} & {
  placeTags: EPlaceTag[];
  listId: string;
};

export type TRecommendAreaItem = {
  keyword: string;
  wgs84CenterX: number;
  wgs84CenterY: number;
};

export type TNowPayload = TApiResponse<{
  latestUpdateTime: string;
  docs: TNowItem[];
}>;

export type TNowResult = {
  list: TNowItem[];
  originList: TNowItem[];
  latestUpdateTime: string;
  totalCount: number;
  currentPage: number;
  pagingSize: number;
  isLastPage?: boolean;
  firstRequestTime: string;
};

export type TNowBaseSpot = Partial<
  TLonLat & {
    radius: number;
    fixedRadius?: number;
    // zone_list에 없는 지역명 (사용자 지역명)
    currentAddressName?: string;
    //zone_list로 검색한 지역명
    displayAddressName?: string;
    focusId: string;
    refreshKey?: number;
  }
>;

export type TPlaceCategory = {
  linkName: string;
  linkUrl: string;
  linkType: string;
  iconType: Nullable<string>;
};

export type TNowLinks = {
  recommendAreas: TRecommendAreaItem[];
  categoryLinks: TPlaceCategory[];
};

export type TNowState = {
  result: TApiStatus<TNowResult>;
  baseSpot: TNowBaseSpot;
  links: TApiStatus<TNowLinks>;
  categoryType?: EPlaceCategoryType;
};

export type TRecommend = {
  docs: TSearchRecommend[];
  latestUpdateTime?: string;
};
