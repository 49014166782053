import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {useOnce} from './useOnce';
import {fetchRemoteConfigFromFirebase} from 'utils/remote';
import {
  ESearchPlaceHolder,
  TPageConfig,
  TRemoteConfigKeys,
  TRemoteConfigScope,
} from 'constant/Firebase';
import ua from 'utils/uaParser';
import TMapSender from '@lcc/tmap-inapp';
import {
  SEARCH_MAIN_QUICK_LINK,
  SEARCH_RESULT_QUICK_LINK,
  TNOW_MAIN_QUICK_LINK,
} from 'constant/PlaceCategory';
import {useRef} from 'react';
import {checkExpose} from 'utils/date';

const DEFAULT_RAW_DATA: Record<TRemoteConfigKeys, any> = {
  THEME_MARKER: [],
  CUSTOM_CATEGORY_PAGE: [],
  SEARCH_MAIN_CATEGORY_LINK: [],
  SEARCH_RESULT_CATEGORY_LINK: [],
  TNOW_CATEGORY_LINK: [],
  TNOW_POPUP_VERSION: -1,
  TNOW_POPUP_LIST: [],
  EV_FILTER_LIST: [],
  SEARCH_RECOMMEND_KEYWORD: [],
  TPLACEHOME_CONTENTS_LIST: [],
  TOOLTIP_LIST: [],
  [ESearchPlaceHolder.TNOW_PLACEHOLDER]: undefined,
};

const getValidData = (data, defaultValue) => {
  const validData = (data && data.length) > 0 ? data : defaultValue;

  return validData.filter((i) => checkExpose(i.exposeTime || {}));
};

const CONFIG_FETCHING_TIMEOUT = 2000;

export const useRemoteConfig = (config: TPageConfig) => {
  const remote = useAppSelector((state) => state.remote);
  const dispatch = useAppDispatch();
  const {configScope, checkKeys} = config.reduce(
    (prev, cur) => {
      return {
        configScope: [...prev.configScope, cur.scope],
        checkKeys: [...prev.checkKeys, ...cur.keys],
      };
    },
    {configScope: [] as TRemoteConfigScope[], checkKeys: [] as TRemoteConfigKeys[]}
  );
  const pending = (remote.lastUpdateTime || 0) < 1;
  const refTimeout = useRef<Nullable<NodeJS.Timeout>>();

  useOnce(pending, () => {
    refTimeout.current = setTimeout(() => {
      refTimeout.current && dispatch(actions.remote.setRawConfig(DEFAULT_RAW_DATA));
    }, CONFIG_FETCHING_TIMEOUT);

    if (ua.isInApp) {
      configScope.forEach((s) => {
        TMapSender.getRemoteConfig(s);
      });
      return;
    }
    fetchRemoteConfigFromFirebase(configScope)
      .then((data: Record<TRemoteConfigKeys, any>) => {
        dispatch(actions.remote.setRawConfig(data));
      })
      .catch(() => {
        dispatch(actions.remote.setRawConfig(DEFAULT_RAW_DATA));
      })
      .finally(() => {
        refTimeout.current && clearTimeout(refTimeout.current);
      });
  });

  useOnce(pending && checkKeys.every((k) => !!remote.rawRemoteData?.[k]), () => {
    const data = remote.rawRemoteData || {};

    refTimeout.current && clearTimeout(refTimeout.current);

    dispatch(
      actions.remote.setRemoteConfig({
        tnowCategoryLink: getValidData(data.TNOW_CATEGORY_LINK, TNOW_MAIN_QUICK_LINK),
        searchMainCategoryLink: getValidData(
          data.SEARCH_MAIN_CATEGORY_LINK,
          SEARCH_MAIN_QUICK_LINK
        ),
        searchResultCategoryLink: getValidData(
          data.SEARCH_RESULT_CATEGORY_LINK,
          SEARCH_RESULT_QUICK_LINK
        ),
        customCategoryPage: getValidData(data.CUSTOM_CATEGORY_PAGE, []),
        themeMarker: getValidData(data.THEME_MARKER, []),
        tnowPopupVersion: data.TNOW_POPUP_VERSION,
        tnowPopupList: getValidData(data.TNOW_POPUP_LIST, []),
        evFilterList: data.EV_FILTER_LIST,
        searchRecommendKeyword: getValidData(data.SEARCH_RECOMMEND_KEYWORD, []),
        tplaceContentsList: data.TPLACEHOME_CONTENTS_LIST,
        tooltipList: getValidData(data.TOOLTIP_LIST, []),
        tnowPlaceholder: data[ESearchPlaceHolder.TNOW_PLACEHOLDER],
      })
    );
  });

  return remote;
};
