// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcoInfoFilledSvg} from 'resource/@tmdsicon/ico_info_f.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcoInfoFilled = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcoInfoFilledSvg {...props} />} />;
};

export {IcoInfoFilled};
