import classNames from 'classnames';
import {ReactNode} from 'react';

import s from 'styles/components/BottomButtons.module.scss';

export type TBottomButton = {
  title: string;
  onClick?: any;
  disabled?: boolean;
  active?: boolean;
  className?: string;
  ariaProperties?: Record<string, string>;
  count?: number;
};

type TProps = {
  buttons: TBottomButton[];
  isFloating?: boolean;
  isShow?: boolean;
  className?: string;
  topArea?: ReactNode;
  showGradient?: boolean;
};

const BottomButtons = ({
  buttons = [],
  isFloating,
  isShow,
  className,
  topArea,
  showGradient,
}: TProps) => {
  return (
    <div
      className={classNames(
        s.bottom_btn_wrap,
        {
          [s.floating]: isFloating,
          [s.is_show]: isShow,
          [s.show_gradient]: showGradient,
        },
        className || ''
      )}
    >
      {topArea}

      <div className={s.button_wrap}>
        {buttons.map((n, i) => (
          <button
            key={`${n.title}-${i}`}
            role="button"
            className={classNames(
              {
                [s.active]: n.active,
              },
              n.className || ''
            )}
            disabled={n.disabled}
            onClick={n.onClick}
            {...(n.ariaProperties || {})}
          >
            {n.title}
            <span className={s.count}>{n?.count}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default BottomButtons;
