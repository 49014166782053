import {useAppSelector} from 'ducks/hooks';
import {useEffect, useRef} from 'react';
import {TLonLat} from 'types/Map';
import {convertToLonLat} from 'utils/map';
import useMap from './useMap';

export const useMapRotateCenter = (centerOffset) => {
  const {isLandscape} = useAppSelector((state) => ({
    isLandscape: state.layout.appSize.isLandscape,
  }));
  const {moveToCenter, getCenter} = useMap();
  const refCenterOffset = useRef<{x?: number; y?: number} | undefined>(centerOffset);
  const refPrevCenterOffset = useRef<{x?: number; y?: number} | undefined>(centerOffset);
  const refCenter = useRef<TLonLat>();

  useEffect(() => {
    refCenterOffset.current = centerOffset;
    return () => {
      const lonLat = convertToLonLat(getCenter()) as TLonLat;

      refCenter.current = lonLat;
      refPrevCenterOffset.current = centerOffset;
    };
  }, [isLandscape, getCenter, centerOffset]);

  useEffect(() => {
    const center = refCenter.current;
    const x = isLandscape
      ? refCenterOffset.current?.x || 0
      : (refPrevCenterOffset.current?.x || 0) * -1;
    const y = isLandscape
      ? refCenterOffset.current?.y || 0
      : (refPrevCenterOffset.current?.y || 0) * -1;

    if (center && !isNaN(x) && !isNaN(y)) {
      moveToCenter(center, {
        offset: {
          x,
          y,
        },
        animate: false,
        eventData: {
          byRotate: true,
        },
      });
      refCenter.current = undefined;
      refCenterOffset.current = undefined;
    }
  }, [moveToCenter, isLandscape]);
};
