import axios, {CancelTokenSource} from 'axios';
import {API_PATH} from 'constant/Api';
import {useEffect, useRef, useState} from 'react';
import {TApiStatus} from 'types/Api';
import {EReverseGeocodingError, TTplaceReverseGeocoding} from 'types/App';
import {TLonLat} from 'types/Map';
import {getDefaultApiStatus} from 'utils/apis';
import fetcher from 'utils/fetcher';

export type TTPlaceReverseGeocodingApiStatus = TApiStatus<
  Nullable<TTplaceReverseGeocoding>,
  EReverseGeocodingError
>;

const DEFAULT_STATUS = getDefaultApiStatus<TTplaceReverseGeocoding, EReverseGeocodingError>({
  regionFullCode: '',
  region1FullCode: '',
  region2FullCode: '',
  region3FullCode: '',
  region1Code: '',
  region2Code: '',
  region3Code: '',
  region1Name: '',
  region2Name: '',
  region3Name: '',
  foundAreaInfo: {
    defaultRegion1Name: '',
    region2NameList: [],
  },
});

const CancelToken = axios.CancelToken;

type TProps = Partial<TLonLat> & {
  refreshKey?: number;
  isEnable?: boolean;
};

export const useTplaceReverseGeocoding = ({lon, lat, refreshKey, isEnable = true}: TProps = {}) => {
  const [state, setState] = useState<TTPlaceReverseGeocodingApiStatus>(DEFAULT_STATUS);
  const refCancelToken = useRef<Nullable<CancelTokenSource>>(null);

  useEffect(() => {
    if (!isEnable) {
      return;
    }

    if (lon && lat) {
      setState({
        ...DEFAULT_STATUS,
        loading: true,
      });

      refCancelToken.current = CancelToken.source();

      fetcher
        .get(API_PATH.GET_TPLACE_REVERSE_GEOCODE, {
          params: {lon, lat},
          cancelToken: refCancelToken.current.token,
        })
        .then((e) => {
          if (e.data.data) {
            setState({
              loaded: true,
              loading: false,
              data: e.data.data,
              error: undefined,
            });
          } else {
            throw new Error(EReverseGeocodingError.NO_DATA);
          }
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            return;
          }
          setState({
            loaded: true,
            loading: false,
            data: null,
            error: e.message,
          });
        });
    }

    return () => {
      refCancelToken.current?.cancel();
      refCancelToken.current = null;
    };
  }, [lon, lat, refreshKey, isEnable]);

  return state;
};

export default useTplaceReverseGeocoding;
