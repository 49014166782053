import {useCallback, useEffect, useMemo} from 'react';

import {EGuideSearchType, ESuggestSearchType} from 'types/Search';
import {ReactComponent as IcoArrowRightBold} from 'resource/images/@tmds_element/ico_arrow_right_bold.svg';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import useLogger from 'hooks/useLogger';
import actions from 'ducks/actions';
import {fetchSearchList} from 'ducks/search/slice';
import HybridBridge from 'utils/searchBar';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import {EAppRequestMode} from 'types/App';

import s from 'styles/components/search/SearchGuide.module.scss';

type TProps = {
  onClick?: () => void;
};

const DEFAULT_DISPLAY_TEXT: Record<ESuggestSearchType, string> = {
  [ESuggestSearchType.REPLACE]: '(으)로 검색한 결과입니다.',
  [ESuggestSearchType.RECOMMEND]: '(으)로 검색하시겠습니까?',
  [ESuggestSearchType.GUIDE_SEARCH]: '(을)를 검색하시겠습니까?',
  [ESuggestSearchType.DEFAULT]: '',
};

const SearchGuide = ({onClick}: TProps) => {
  const {
    guideSearchType,
    userQuery,
    guideSearchKeyword,
    suggestReplaceKeyword,
    suggestSearchType,
    isLogInitialized,
  } = useAppSelector((state) => ({
    guideSearchType: state.search.data.poi.guideSearchType,
    userQuery: state.search.data.poi.userQuery,
    guideSearchKeyword: state.search.data.poi.guideSearchKeyword,
    suggestReplaceKeyword: state.search.data.poi.suggestReplaceKeyword,
    suggestSearchType: state.search.data.poi.suggestSearchType,
    isLogInitialized: state.log.isInitialize,
  }));
  const dispatch = useAppDispatch();
  const {sendClickLog} = useLogger();
  const {queries} = useParseQueryLocation();

  const displayKeyword = useMemo(
    () =>
      ({
        [ESuggestSearchType.REPLACE]: suggestReplaceKeyword,
        [ESuggestSearchType.RECOMMEND]: null, // 아직 미정
        [ESuggestSearchType.GUIDE_SEARCH]: guideSearchKeyword,
        [EGuideSearchType.DEFAULT]: null,
      }[suggestSearchType]),
    [suggestReplaceKeyword, guideSearchKeyword, suggestSearchType]
  );

  const {locationText, aroundText, keywordText} = useMemo(() => {
    const [location, keyword] = displayKeyword?.split(',') || ['', ''];

    return {
      locationText: location,
      keywordText: keyword,
      aroundText:
        suggestSearchType === ESuggestSearchType.GUIDE_SEARCH &&
        [EGuideSearchType.LOCATED_QUERY, EGuideSearchType.LOCATED_BRANCH].includes(guideSearchType)
          ? '주변'
          : null,
    };
  }, [suggestSearchType, displayKeyword, guideSearchType]);

  const getGuideSearchList = useCallback(() => {
    dispatch(
      actions.search.resetList({
        guideSearchType,
      })
    );

    dispatch(actions.userInfo.setSearchSessionKey());
    dispatch(
      fetchSearchList({
        query: userQuery,
      } as any)
    );

    dispatch(actions.search.updatePrevGuideSearchType(guideSearchType));
    // https://tmobi.atlassian.net/browse/SEARCH-31 추천검색 리스트 필터 사용시 넘기기 위해 저장,(이전 guideSearchType)

    sendClickLog(
      'tap.suggest_query',
      {
        search_query: userQuery,
        suggest_search_type: suggestSearchType,
        guide_search_keyword: guideSearchKeyword,
        guide_search_type: guideSearchType,
      },
      {includeTicketId: true}
    );
  }, [dispatch, guideSearchType, userQuery, suggestSearchType, guideSearchKeyword, sendClickLog]);

  const handleClick = useCallback(() => {
    onClick?.();

    if (suggestSearchType === ESuggestSearchType.GUIDE_SEARCH) {
      getGuideSearchList();
    }
  }, [onClick, suggestSearchType, getGuideSearchList]);

  useEffect(() => {
    if (suggestSearchType !== ESuggestSearchType.REPLACE) {
      return;
    }

    HybridBridge.queryInputTextNoSuggest(suggestReplaceKeyword);
  }, [suggestSearchType]);

  useEffect(() => {
    if (!suggestSearchType || !isLogInitialized) {
      return;
    }

    sendClickLog(
      'impression.suggest_query',
      {
        suggest_search_type: suggestSearchType,
        suggest_replace_keyword: suggestReplaceKeyword,
        user_query: userQuery,
        guide_search_keyword: guideSearchKeyword,
        guide_search_type: guideSearchType,
      },
      {
        includeTicketId: true,
      }
    );
  }, [suggestSearchType, isLogInitialized]);

  if (!locationText || queries.reqMode === EAppRequestMode.EVENT_PAGE) {
    return null;
  }

  return (
    <div className={s.guide_search} onClick={handleClick}>
      <p className={s.text}>
        <span className={s.text_keyword}>'{locationText}'</span>
        {aroundText && <span className={s.text_around}>{aroundText}</span>}
        {keywordText && <span className={s.text_keyword}>'{keywordText}'</span>}
        {DEFAULT_DISPLAY_TEXT[suggestSearchType]}
      </p>
      {suggestSearchType !== ESuggestSearchType.REPLACE && <IcoArrowRightBold />}
    </div>
  );
};

export default SearchGuide;
