import {memo, PropsWithChildren, useEffect, useRef} from 'react';
import classNames from 'classnames';
import Vsm from '@vsm/vsm';

import {EMarkerType, TMarker} from 'types/Map';

import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import s from 'styles/components/VSMMarker.module.scss';
import {MARKER_LABEL_MAX} from 'constant/Map';
import useMap from 'hooks/useMap';
import {ImageMarker} from './ImageMarker';

type TProps = PropsWithChildren<
  SubPartial<TMarker<any>, 'type'> & {
    onClick?: (e) => void;
    className?: string;
    uniqueId?: string;
    label?: string;
  }
>;

const VSMMarker = memo(
  ({
    lonLat: {lat, lon},
    anchor,
    className,
    onClick,
    children,
    type,
    uniqueId,
    label,
    clickable = true,
  }: TProps) => {
    const {map} = useVSMInterfaceConsumer();
    const refMarker = useRef<HTMLDivElement | null>(null);
    const marker = useRef<Vsm.Marker | null>();
    const {style: mapStyle} = useMap();

    useEffect(() => {
      if (refMarker.current && map && !marker.current) {
        marker.current = new Vsm.Marker({
          element: refMarker.current,
          map,
          lngLat: {
            lat,
            lng: lon,
          },
          hideOnUpdate: type && [EMarkerType.INACTIVE].includes(type), // 지도 움직일때 숨길 마커
        });
      }
    }, [map, type]);

    useEffect(() => {
      return () => {
        if (marker.current) {
          marker.current?.destroy();
          marker.current = null;
        }
      };
    }, [type]);

    useEffect(() => {
      if (lat || lon) {
        marker.current?.setLngLat({lat, lng: lon});
      }
    }, [lat, lon]);

    useEffect(() => {
      anchor && marker.current?.setAnchor(anchor);
    }, [anchor]);

    useEffect(() => {
      let nowEventTarget;
      let nowEventHandler;

      if (onClick && refMarker.current) {
        nowEventTarget = refMarker.current;
        nowEventHandler = onClick;

        nowEventTarget.addEventListener('click', nowEventHandler);
      }

      return () => {
        if (nowEventHandler && nowEventTarget) {
          nowEventTarget.removeEventListener('click', nowEventHandler);
        }
      };
    }, [onClick]);

    return (
      <div style={{display: 'none'}}>
        <div
          role="presentation"
          aria-hidden="true"
          ref={refMarker}
          className={classNames(
            'vsm-marker',
            s.marker_wrap,
            {
              [s.disable_click]: !clickable,
            },
            className
          )}
          {...(uniqueId ? {'data-uid': uniqueId} : {})}
          {...(type ? {'data-type': type} : {})}
        >
          {!children && type && <ImageMarker type={type} />}
          {!children && label && (
            <span className={s.label} data-style={mapStyle}>
              {(label.length || 0) > MARKER_LABEL_MAX
                ? `${label.slice(0, MARKER_LABEL_MAX)}...`
                : label}
            </span>
          )}
          {children}
        </div>
      </div>
    );
  }
);

export default VSMMarker;
