import {ReloadResume} from '../ReloadResume';
import {AdBanner} from '../AdBanner';
import {
  EAdCode,
  isBannerTestEnv,
  SEARCH_BANNER_AD_STEP,
  SEARCH_MAIN_BANNER_PROVIDER_CONFIG,
  TEST_BANNER_PROVIDER_CONFIG,
} from '../../constant/Ads';
import SearchQuickLinkList from './SearchQuickLinkList';
import {ESkeletonType} from '../../types/App';
import {SearchRecentList} from './SearchRecentList';
import {useSearchPageVisible} from '../../hooks/useSearchPageVisible';
import {useAppSelector} from '../../ducks/hooks';
import useRecentList from '../../hooks/useRecentList';
import {MutableRefObject, useCallback} from 'react';
import {ESearchTabs} from '../../types/Search';
import {sendSearchClickLog} from '../../utils/logManager';
import {useSearchMainTab} from '../../hooks/useSearchMainTab';
import Skeleton from '../Skeleton';

import s from '../../styles/pages/SearchMainPage.module.scss';

type TProps = {
  hideEdit: boolean;
  wrapper: MutableRefObject<HTMLDivElement | null>;
};

const SearchMainRecent = ({hideEdit, wrapper}: TProps) => {
  const {isLogInitialized, status} = useAppSelector((state) => ({
    isLogInitialized: state.log.isInitialize,
    status: state.userInfo.personalPlace,
  }));

  const {showMain} = useSearchPageVisible();
  const {recentList} = useRecentList();
  const {changeTab} = useSearchMainTab();

  const handleClickRecentEdit = useCallback(() => {
    changeTab(ESearchTabs.RECENT_EDIT, {pushHistory: true});
    sendSearchClickLog('tap.history_edit');
  }, [changeTab]);

  return (
    <Skeleton className={s.skeleton} type={ESkeletonType.SEARCH_MAIN} apiStatus={status}>
      <div className={s.banner_wrap}>
        <ReloadResume active={showMain}>
          <AdBanner
            visibleLandscape={false}
            adCode={EAdCode.SEARCH_MAIN}
            adTypeStep={SEARCH_BANNER_AD_STEP}
            adTypeOption={{
              ...SEARCH_MAIN_BANNER_PROVIDER_CONFIG,
              ...(isBannerTestEnv ? TEST_BANNER_PROVIDER_CONFIG : {}),
            }}
            isLogInitialized={isLogInitialized}
            logData={{index: EAdCode.SEARCH_MAIN, includeTicketId: false}}
          />
        </ReloadResume>
      </div>
      <SearchQuickLinkList />
      {!hideEdit && recentList.length > 0 && (
        <div className={s.user_data_action}>
          <button className={s.recent_edit_btn} onClick={handleClickRecentEdit}>
            최근기록 편집
          </button>
        </div>
      )}
      <SearchRecentList wrapper={wrapper} />
    </Skeleton>
  );
};

export default SearchMainRecent;
