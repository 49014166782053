import {LOG_PARAM_ALL, LOG_PARAM_PREFIX, LOG_PARAM_SEPARATOR} from 'constant/Log';
import {EPerformanceLogKey} from 'types/Log';

export enum ELogScope {
  EXPOSE = 'expose',
  APP = 'app',
  CLICK = 'click',
}

// "log_app_[T]", "log_expose_[T]", "log_click_[T]", "log_all_[T]"
export type TLogParamKeyPrefix = `${typeof LOG_PARAM_PREFIX}${typeof LOG_PARAM_SEPARATOR}${
  | ELogScope
  | typeof LOG_PARAM_ALL}${typeof LOG_PARAM_SEPARATOR}`;

export type TLogParamQuery<T extends string = string> = Partial<
  Record<`${TLogParamKeyPrefix}${T}`, string>
>;

export type TLogItem = {
  key: EPerformanceLogKey;
  time: number;
};

export type TLogState = {
  totalCount: number;
  items: {
    [key: string]: TLogItem;
  };
  pair: {
    [key: string]: {
      start?: TLogItem;
      end?: TLogItem;
      requested?: boolean;
      duration?: number;
    };
  };
  searchQueryRefreshKey?: number;
  bypassCustomItems: Partial<Record<ELogScope, Record<string, string>>>;
  isInitialize: boolean;
};
