import {useCallback, HTMLAttributes, useEffect, useState, CSSProperties, useMemo} from 'react';
import classNames from 'classnames';
import Vsm from '@vsm/vsm';
import {EMapStyle} from '@lcc/tmap-inapp';

import actions from 'ducks/actions';

import {EMapActionId} from 'constant/Log';
import {EMPTY_OBJECT} from 'constant/Vars';

import {useAppDispatch} from 'ducks/hooks';
import useLogger from 'hooks/useLogger';
import useMap from 'hooks/useMap';
import {useOnce} from 'hooks/useOnce';

import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';

import {ReactComponent as IconCompass} from 'resource/images/icon_compass_group_5014.svg';
import {ReactComponent as IconCompassDark} from 'resource/images/icon_compass_dark.svg';

import s from 'styles/components/VSMCompass.module.scss';

type TProps = HTMLAttributes<HTMLDivElement> & {
  initBearing?: number;
  initPitch?: number;
  style?: CSSProperties;
};

export const VSMCompass = ({className, initBearing, initPitch, style}: TProps) => {
  const {map} = useVSMInterfaceConsumer();
  const dispatch = useAppDispatch();
  const {sendClickLogWithMapView} = useLogger();
  const {getBearing, getPitch, setBearing, setPitch, isInitialized, style: mapStyle} = useMap();
  const [rotateInfo, setRotateInfo] = useState({bearing: initBearing || 0, pitch: initPitch || 0});

  useEffect(() => {
    const handleRotate = () => {
      setRotateInfo({
        bearing: getBearing() || 0,
        pitch: getPitch() || 0,
      });
    };

    map?.on(Vsm.Map.EventNames.Rotate, handleRotate);
    map?.on(Vsm.Map.EventNames.Pitch, handleRotate);

    return () => {
      map?.off(Vsm.Map.EventNames.Rotate, handleRotate);
      map?.off(Vsm.Map.EventNames.Pitch, handleRotate);
    };
  }, [map, getBearing, getPitch]);

  useOnce(isInitialized, () => {
    if (isNaN(Number(initPitch)) && isNaN(Number(initPitch))) {
      setRotateInfo({
        bearing: getBearing() || 0,
        pitch: getPitch() || 0,
      });
    } else {
      setBearing(initBearing);
      setPitch(initPitch);
    }
  });

  useEffect(() => {
    dispatch(actions.map.setPitch(rotateInfo.pitch));
    dispatch(actions.map.setBearing(rotateInfo.bearing));
  }, [dispatch, rotateInfo]);

  const handleClickButton = useCallback(() => {
    setBearing(0);
    setPitch(0);

    setRotateInfo({
      bearing: 0,
      pitch: 0,
    });

    sendClickLogWithMapView(EMapActionId.COMPASS);
  }, [sendClickLogWithMapView, setBearing, setPitch]);

  const rotateStr = useMemo(() => {
    return {
      bearing: rotateInfo.bearing ? Number(rotateInfo.bearing).toFixed(2) : 0,
      pitch: Number(rotateInfo.pitch || 0).toFixed(2),
    };
  }, [rotateInfo]);

  return (
    <div
      style={{
        ...(style || EMPTY_OBJECT),
      }}
      className={classNames(s.vsm_compass, className, {
        [s.hide]: !rotateInfo.bearing && !rotateInfo.pitch,
      })}
    >
      <button
        onClick={handleClickButton}
        role="button"
        aria-label={`현재 지도 회전 ${rotateStr.bearing}도, 기울기 ${rotateStr.pitch}도 입니다. 회전 0도, 기울기 0도로 초기화`}
        style={{
          transform: `rotate(${(rotateInfo.bearing || 0) * -1}deg)`,
        }}
      >
        {mapStyle === EMapStyle.NIGHT ? (
          <IconCompassDark />
        ) : (
          <IconCompass width="9px" height="24px" />
        )}
      </button>
    </div>
  );
};

export default VSMCompass;
