// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcArrowRightSvg} from 'resource/@tmdsicon/v1.2/ic_arrow_right.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcArrowRight = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcArrowRightSvg {...props} />} />;
};

export {IcArrowRight};
