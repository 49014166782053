import TMapSender, {
  ESearchWebToAppKey,
  TQueryItem,
  ESearchAppMoveTarget,
  TSearchContext,
  ESearchAppToWebKey,
  CALLBACK_JS_NAMESPACE,
} from '@lcc/tmap-inapp';
import ua from './uaParser';
import {devLog} from './dev';
import {isNewApp} from './tmapUtils';

const getSizeWithPixelRatio = (value?: number) => {
  if (!value) {
    return 0;
  }
  return ua?.isAndroid ? value / devicePixelRatio : value;
};

const DEFAULT_DIMMED_ARGB = '0x99000000';

/*
  hybrid bridge init
*/

type THybridReceiverCallback = {
  onSearch?: (data: TQueryItem) => void;
  onMove?: (target: ESearchAppMoveTarget) => void;
  onMoveBack?: () => void;
  onSearchContext?: (context: TSearchContext) => void;
  onClickMapSelect?: () => void;
};

export const hybridCallbackMap: THybridReceiverCallback = {};

const updateCallback = ({
  onSearch,
  onMove,
  onMoveBack,
  onSearchContext,
  onClickMapSelect,
}: THybridReceiverCallback) => {
  if (onClickMapSelect) {
    hybridCallbackMap.onClickMapSelect = onClickMapSelect;
  }
  if (onSearch) {
    hybridCallbackMap.onSearch = onSearch;
  }

  if (onMove) {
    hybridCallbackMap.onMove = onMove;
  }

  if (onMoveBack) {
    hybridCallbackMap.onMoveBack = onMoveBack;
  }

  if (onSearchContext) {
    hybridCallbackMap.onSearchContext = onSearchContext;
  }
};

const init = (param: THybridReceiverCallback) => {
  updateCallback(param);

  TMapSender.addSearchBridgeCallback('callbackHybridBridge');
};

/*
  hybrid bridge sender
*/

const getSearchContext = () => {
  TMapSender.searchBridgeCommand(ESearchWebToAppKey.GET_SEARCH_CONTEXT);
};

const queryInputTextNoSuggest = (query: string) => {
  TMapSender.searchBridgeCommand(ESearchWebToAppKey.QUERY_INPUT_TEXT_NO_SUGGEST, query);
};

const moveToMain = () => {
  TMapSender.searchBridgeCommand(ESearchWebToAppKey.MOVE_MAIN);
};

const moveToResult = (data: TQueryItem) => {
  TMapSender.searchBridgeCommand(ESearchWebToAppKey.MOVE_RESULT, data);
};

const showModal = (colorCode: string = DEFAULT_DIMMED_ARGB) => {
  if (isNewApp()) {
    TMapSender.searchBridgeCommand(ESearchWebToAppKey.MODAL_PRESENTED, colorCode);
  } else {
    TMapSender.searchBridgeCommand(ESearchWebToAppKey.MODAL_PRESENTED, '0x59111111');
  }
};

const hideModal = () => {
  TMapSender.searchBridgeCommand(ESearchWebToAppKey.MODAL_DISMISSED);
};

const addRecentQuery = (queries: TQueryItem[]) => {
  TMapSender.searchBridgeCommand(ESearchWebToAppKey.ADD_RECENTS, {
    recentQueries: queries,
  });
};

const deleteRecentQueries = (queries: TQueryItem[]) => {
  TMapSender.searchBridgeCommand(ESearchWebToAppKey.DELETE_RECENTS, {
    recentQueries: queries,
  });
};

const deleteAllRecentQueries = () => {
  TMapSender.searchBridgeCommand(ESearchWebToAppKey.DELETE_ALL_RECENTS);
};

const showSearchBar = (show: boolean = true) => {
  TMapSender.searchBridgeCommand(ESearchWebToAppKey.SHOW_SEARCH_BAR, show);
};

const showMapSelectButton = (show: boolean = true) => {
  TMapSender.searchBridgeCommand('showMapSelectButton' as ESearchWebToAppKey, show);
};

/*
  hybrid bridge receiver
*/

export const callbackHybridBridge = (key: ESearchAppToWebKey, value: string) => {
  switch (key) {
    case ESearchAppToWebKey.SEARCH: {
      // value = “{"query":"강남역","date":"2022-07-11 14:08:26"}

      if (!value) {
        return;
      }

      try {
        const parsedValue: TQueryItem = JSON.parse(value);

        hybridCallbackMap.onSearch?.(parsedValue);
      } catch (e) {
        devLog((e as any)?.message);
      }

      break;
    }

    case ESearchAppToWebKey.MOVE: {
      // value = 'main'
      const target = value as ESearchAppMoveTarget;

      if (
        [ESearchAppMoveTarget.SEARCH_MAIN, ESearchAppMoveTarget.SEARCH_MAIN_WITH_QUERY].includes(
          target
        )
      ) {
        hybridCallbackMap.onMove?.(target);
      }

      break;
    }

    case ESearchAppToWebKey.MOVE_BACK: {
      hybridCallbackMap.onMoveBack?.();

      break;
    }

    case ESearchAppToWebKey.SEARCH_CONTEXT: {
      // value = {
      //   "recentQueries": [
      //     {"query":"강남역","date":"2022-07-11 14:08:26"},
      //     {"query":"부산",”date":"2022-07-10 14:08:26"},
      //   ],
      //   "searchBarHeight":"10"
      // }

      try {
        const parsedContext: TSearchContext = JSON.parse(value);
        const searchBarHeight = parseFloat(parsedContext.searchBarHeight);

        parsedContext.numSearchBarHeight = searchBarHeight
          ? getSizeWithPixelRatio(searchBarHeight)
          : 0;

        hybridCallbackMap.onSearchContext?.(parsedContext);
      } catch (e) {
        devLog((e as any)?.message);
      }
      break;
    }

    case 'clickMapSelect' as ESearchAppToWebKey: {
      hybridCallbackMap.onClickMapSelect?.();
      break;
    }

    default: {
      devLog('HybridBridgeReceiver: 잘못된 key입니다');
    }
  }
};

/* 
  hybrid bridge receiver tester
*/

const testHandlers = {
  onSearch: (data: TQueryItem = {query: '테스트쿼리', date: '2022-08-26 13:00:01'}) => {
    hybridCallbackMap.onSearch?.(data);
  },

  onMoveMain: () => {
    hybridCallbackMap.onMove?.(ESearchAppMoveTarget.SEARCH_MAIN);
  },

  onMoveMainWithQuery: () => {
    hybridCallbackMap.onMove?.(ESearchAppMoveTarget.SEARCH_MAIN_WITH_QUERY);
  },

  onMoveBack: () => {
    hybridCallbackMap.onMoveBack?.();
  },

  onSearchContext: (
    context: TSearchContext = {
      recentQueries: [],
      searchBarHeight: '56',
      numSearchBarHeight: 56,
    }
  ) => {
    hybridCallbackMap.onSearchContext?.(context);
  },

  onClickMapSelect: () => {
    hybridCallbackMap.onClickMapSelect?.();
  },
};

// TBD
window[CALLBACK_JS_NAMESPACE].callbackHybridBridge = callbackHybridBridge;

export const HybridBridge = {
  getSearchContext,
  queryInputTextNoSuggest,
  moveToMain,
  moveToResult,
  showModal,
  hideModal,
  addRecentQuery,
  deleteRecentQueries,
  deleteAllRecentQueries,
  showSearchBar,
  showMapSelectButton,

  init,
  updateCallback,

  test: testHandlers,
};

export default HybridBridge;
