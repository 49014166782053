import {useCallback, useEffect, useRef, useState} from 'react';
import Vsm, {CameraOptions, MapEvent, MapOptions} from '@vsm/vsm';
import {EMapFontSize, EMapStyle} from '@lcc/tmap-inapp';
import {useLongPress} from 'use-long-press';

import {EMapType, TLonLat, TStyleSet, TVSMLngLat} from 'types/Map';
import {
  DEFAULT_MAX_BOUNDS,
  DEFAULT_WGS84_LAT_NUM,
  DEFAULT_WGS84_LON_NUM,
  SEARCH_DEFAULT_ZOOM,
  MAP_CONFIG_URL,
  MAP_SUPPORT_STYLE,
  MAP_LIMIT_OPTIONS,
} from 'constant/Map';
import {getMapScale} from 'utils/map';
import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import {usePerformanceLog} from 'hooks/usePerformanceLog';
import useMap from 'hooks/useMap';

import s from 'styles/components/VSMMap.module.scss';
import '@vsm/vsm/dist/vsm.css';
import {EPerformanceLogKey} from 'types/Log';

type TEventCallback = (e: MapEvent & any) => void;

type TProps = {
  size?: {width: number | string; height: number | string};
  mapStyle?: EMapStyle;
  fontSize?: EMapFontSize;
  initOptions?: Partial<MapOptions>;
  enoughSpecOption?: Vsm.Extensions.TmapUtils.isEnoughSpecOptions;
  onClick?: TEventCallback;
  onClickPoi?: (poiFeature, e) => void;
  onMoveEnd?: TEventCallback;
  onDragStart?: TEventCallback;
  onDragEndMap?: TEventCallback;
  onZoomStart?: TEventCallback;
  onZoomEnd?: TEventCallback;
  onConfigLoad?: TEventCallback;
  onLongPressMap?: ({point}: {point: TLonLat}) => void;
  initCameraOptions?: Partial<CameraOptions>;
};

const VSMMap = ({
  size = {width: '100%', height: '100%'},
  mapStyle = EMapStyle.DAY,
  fontSize = EMapFontSize.NORMAL,
  initOptions,
  enoughSpecOption = {and: 7, ios: 12},
  onClick,
  onClickPoi,
  onMoveEnd,
  onDragStart,
  onDragEndMap,
  onZoomStart,
  onZoomEnd,
  onConfigLoad,
  onLongPressMap,
  initCameraOptions,
}: TProps) => {
  const refMap = useRef<Vsm.Map>();
  const refWrapper = useRef<HTMLDivElement>(null);

  const refLongPressPoint = useRef<Nullable<TVSMLngLat>>(null);
  const refStartZoom = useRef<number>();

  const [isStyleLoaded, setStyleLoaded] = useState(false);
  const [mapType, setMapType] = useState<EMapType>(EMapType.VECTOR);
  const {startLog, endLog} = usePerformanceLog();

  const {initialize: initializeVSMInterface, destroy: destroyVSMInterface} =
    useVSMInterfaceConsumer();

  const {getTmapFeature} = useMap();

  const updateMapStyle = useCallback((option) => {
    if (!refMap.current) {
      return;
    }
    const {code, type}: Partial<TStyleSet> =
      MAP_SUPPORT_STYLE[option.mapType]?.[option.mapStyle] || {};

    if (code && type) {
      refMap.current?.loadStyle(code, type);
    }
  }, []);

  const handleClickMap = useCallback(
    (e) => {
      const poiFeature = getTmapFeature(e.data.screenPoint);

      if (poiFeature) {
        onClickPoi?.(poiFeature, e);
      } else {
        onClick?.(e);
      }
    },
    [onClick, getTmapFeature, onClickPoi]
  );
  const handleMoveEndMap = useCallback((e) => onMoveEnd?.(e), [onMoveEnd]);
  const handleDragStart = useCallback((e) => onDragStart?.(e), [onDragStart]);
  const handleDragEndMap = useCallback((e) => onDragEndMap?.(e), [onDragEndMap]);
  const handleConfigLoad = useCallback((e) => onConfigLoad?.(e), [onConfigLoad]);

  const handleZoomStart = useCallback(
    (e) => {
      if (e.data?.domEvent) {
        refStartZoom.current = refMap.current?.getCamera().getZoom();
      }

      onZoomStart?.(e);
    },
    [onZoomStart]
  );
  const handleZoomEnd = useCallback(
    (e) => {
      const currentZoom = refMap.current?.getCamera().getZoom();
      let isZoomIn = false;

      // pinch in => zoom out, pinch out => zoom in
      if (refStartZoom.current !== undefined && currentZoom !== undefined) {
        isZoomIn = refStartZoom.current < currentZoom ? true : false;
      }
      refStartZoom.current = undefined;

      e.isZoomIn = isZoomIn;

      onZoomEnd?.(e);
    },
    [onZoomEnd]
  );

  const bindLongPress = useLongPress<HTMLDivElement>(() => {}, {
    cancelOnMovement: true,
    onFinish: () => {
      if (refLongPressPoint.current) {
        const {lat, lng} = refLongPressPoint.current;

        onLongPressMap?.({point: {lat, lon: lng}});
      }
    },
  });

  useEffect(() => {
    if (refWrapper.current) {
      const enoughSpec = Vsm.Extensions.TmapUtils.isEnoughSpec(enoughSpecOption);
      const loadMapType = enoughSpec ? EMapType.VECTOR : EMapType.RASTER;
      const {fontScale, iconScale} = getMapScale(fontSize);

      const defaultOptions: MapOptions = {
        config: MAP_CONFIG_URL[loadMapType],
        center: [DEFAULT_WGS84_LON_NUM, DEFAULT_WGS84_LAT_NUM],
        zoom: SEARCH_DEFAULT_ZOOM,
        maxBounds: DEFAULT_MAX_BOUNDS,
        container: refWrapper.current,
        emitFirstPaint: true,
        // fadeDuration: 0,
        fontScale,
        iconScale,
        autoStyleLoad: false,
      };

      startLog(EPerformanceLogKey.VSM_INIT);
      startLog(EPerformanceLogKey.VSM_FIRST_PAINT);

      refMap.current = new Vsm.Map({
        customStyleProperties: {SUB_STYLE: 'PB_TRA_NAVI'},
        ...defaultOptions,
        ...MAP_LIMIT_OPTIONS,
        ...initOptions,
        ...initCameraOptions,
      });

      refMap.current.once(Vsm.Map.EventNames.RenderComplete, () => {
        initCameraOptions?.bearing &&
          refMap.current?.getCamera().setBearing(initCameraOptions?.bearing);

        initCameraOptions?.pitch && refMap.current?.getCamera().setPitch(initCameraOptions?.pitch);
      });

      refMap.current.once(Vsm.Map.EventNames.FirstPaint, () => {
        endLog(EPerformanceLogKey.VSM_FIRST_PAINT);
      });

      initializeVSMInterface?.(refMap.current);
      setMapType(loadMapType);

      refMap.current.once(Vsm.Map.EventNames.StyleLoad, () => {
        setStyleLoaded(true);

        // [TODO] 플레이스에서만 적용되는 스펙인지 확인 필요
        if (refMap.current && mapStyle === EMapStyle.SATELLITE) {
          Vsm.Extensions.TmapUtils.setBuildingVisibility(refMap.current, false);
        }
      });

      refMap.current.once(Vsm.Map.EventNames.ConfigLoad, () => {
        updateMapStyle({mapStyle, mapType: loadMapType});
      });
    }

    return () => {
      refMap.current?.destroy();
      destroyVSMInterface?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refMap.current?.on(Vsm.Map.EventNames.Click, handleClickMap);
    refMap.current?.on(Vsm.Map.EventNames.MoveEnd, handleMoveEndMap);
    refMap.current?.on(Vsm.Map.EventNames.DragStart, handleDragStart);
    refMap.current?.on(Vsm.Map.EventNames.ZoomStart, handleZoomStart);
    refMap.current?.on(Vsm.Map.EventNames.ZoomEnd, handleZoomEnd);
    refMap.current?.on(Vsm.Map.EventNames.DragEnd, handleDragEndMap);

    refMap.current?.once(Vsm.Map.EventNames.ConfigLoad, handleConfigLoad);

    return () => {
      refMap.current?.off(Vsm.Map.EventNames.Click, handleClickMap);
      refMap.current?.off(Vsm.Map.EventNames.MoveEnd, handleMoveEndMap);
      refMap.current?.off(Vsm.Map.EventNames.DragStart, handleDragStart);
      refMap.current?.off(Vsm.Map.EventNames.ZoomStart, handleZoomStart);
      refMap.current?.off(Vsm.Map.EventNames.ZoomEnd, handleZoomEnd);

      refMap.current?.off(Vsm.Map.EventNames.DragEnd, handleDragEndMap);
    };
  }, [
    handleClickMap,
    handleMoveEndMap,
    handleDragStart,
    handleZoomStart,
    handleZoomEnd,
    handleDragEndMap,
    handleConfigLoad,
  ]);

  useEffect(() => {
    const longPressSetEvent = [Vsm.Map.EventNames.TouchStart, Vsm.Map.EventNames.MouseDown];
    const longPressClearEvent = [
      Vsm.Map.EventNames.DragStart,
      Vsm.Map.EventNames.PitchStart,
      Vsm.Map.EventNames.ZoomStart,
      Vsm.Map.EventNames.MoveStart,
      Vsm.Map.EventNames.RotateStart,
    ];

    const setPointStart = (e) => (refLongPressPoint.current = e.data.lngLat);
    const clearPointStart = () => (refLongPressPoint.current = null);

    longPressSetEvent.forEach((eventName) => refMap.current?.on(eventName, setPointStart));
    longPressClearEvent.forEach((eventName) => refMap.current?.on(eventName, clearPointStart));

    return () => {
      longPressSetEvent.forEach((eventName) => refMap.current?.off(eventName, setPointStart));
      longPressClearEvent.forEach((eventName) => refMap.current?.off(eventName, clearPointStart));
    };
  }, []);

  useEffect(() => {
    if (isStyleLoaded && mapStyle) {
      updateMapStyle({mapType, mapStyle});
    }
  }, [isStyleLoaded, updateMapStyle, mapType, mapStyle]);

  useEffect(() => {
    if (isStyleLoaded) {
      const poiScale = getMapScale(fontSize);

      refMap.current?.setPoiScale(poiScale);
    }
  }, [isStyleLoaded, fontSize]);

  useEffect(() => {
    refMap.current?.resize();
  }, [size]);

  return <div ref={refWrapper} style={size} className={s.map_wrap} {...bindLongPress} />;
};

export default VSMMap;
