import {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router';

import actions from 'ducks/actions';

import {useAppDispatch} from 'ducks/hooks';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import {EHistoryStack} from 'ducks/userInteraction/types';

export const useRecentTabMode = () => {
  const dispatch = useAppDispatch();

  const history = useHistory();
  const {location: editLocation, originQueries: currentQueries} = useParseQueryLocation({
    edit: true,
  });

  const isEdit = useMemo(() => currentQueries.edit === 'true', [currentQueries]);

  const enterEditMode = useCallback(() => {
    history.push(editLocation);
    dispatch(actions.userInteraction.pushHistoryStack(EHistoryStack.EDIT_RECENT_TAB));
  }, [dispatch, history, editLocation]);

  const exitEditMode = useCallback(() => {
    history.goBack();
    dispatch(actions.userInteraction.popHistoryStack());
  }, [dispatch, history]);

  return {
    isEdit,
    enterEditMode,
    exitEditMode,
  };
};
