import {useCallback} from 'react';
import InView from 'react-intersection-observer';
import {getAppActionButton} from 'utils/tmapUtils';
import {parsePoiInfoToNavInfo} from 'utils/search';
import {useFocusDrawerList} from 'hooks/useFocusDrawerList';
import useMoveToTarget from 'hooks/useMoveToTarget';
import useLogger from 'hooks/useLogger';
import useAppScheme from 'hooks/useAppScheme';
import {EDetailButtonType, ERPFlag} from 'types/App';
import {TBusStationItem} from 'ducks/search/types';
import SearchResultBusStationItem from 'components/search/SearchResultBusStationItem';

import s from 'styles/components/search/SearchResultPoiList.module.scss';

type TProps = {
  list: TBusStationItem[];
  nowPage: number;
  searchQuery: string;
};

const SearchResultBusStationList = ({list}: TProps) => {
  const {activePoi, changeViewingItems} = useFocusDrawerList(list);
  const {moveToSelectDestinationAction, reqMode, reqType, extra} = useMoveToTarget();
  const {sendSearchResultItemClickLog} = useLogger();
  const inApp = useAppScheme();
  const actionButtonType = getAppActionButton({reqMode, reqType, extra});

  const handleClickItem = useCallback(
    (e, v, idx) => {
      e.stopPropagation();

      sendSearchResultItemClickLog('list_tap.poi_detail', {
        index: idx,
      });

      inApp.openBusStationDetail({
        name: v.busStationName,
        centerX: v.centerX,
        centerY: v.centerY,
        poiId: v.pkey,
        stationId: v.busStationId,
        reqMode,
      });
    },
    [sendSearchResultItemClickLog, reqMode, inApp]
  );

  const handleClickRoute = useCallback(
    (e, v, idx) => {
      e.stopPropagation();

      const actionId =
        actionButtonType === EDetailButtonType.CONFIRM
          ? 'list_tap.select_poi'
          : 'list_tap.poi_direction';

      sendSearchResultItemClickLog(actionId, {
        index: idx,
      });

      moveToSelectDestinationAction(
        parsePoiInfoToNavInfo({
          pkey: v.pkey || '',
          poiId: '',
          navSeq: '',
          // 버스정류장은 centerX = navX
          navX: `${v.centerX || ''}`,
          navY: `${v.centerY || ''}`,
          centerX: `${v.centerX || ''}`,
          centerY: `${v.centerY || ''}`,
          rpFlag: ERPFlag.N_G000,
          poiName: v.busStationName,
          address: '',
          tel: '',
          stationId: v.busStationId,
          publicTransportType: 'busstop',
        })
      );
    },
    [moveToSelectDestinationAction, actionButtonType, sendSearchResultItemClickLog]
  );

  return (
    <>
      {list.map((v, idx) => {
        return (
          <InView
            as="li"
            className={s.item}
            key={`bus-station-${v.listId}`}
            data-type="poi"
            data-id={v.listId}
            threshold={0.5}
            onChange={(e) => changeViewingItems(e, idx)}
            data-focus={activePoi === v.listId}
          >
            <SearchResultBusStationItem
              item={v}
              actionButtonType={actionButtonType}
              onClickItem={(e) => handleClickItem(e, v, idx)}
              onClickRouteButton={(e) => handleClickRoute(e, v, idx)}
            />
          </InView>
        );
      })}
    </>
  );
};

export default SearchResultBusStationList;
