import {
  // ERecentItemType,
  EFavoriteType,
  // TRecentItem,
  TSuggestQuery,
  TFavoriteItem,
  TFavoriteRouteItem,
} from 'types/Search';

// export const RECENT_TEST_LIST: TRecentItem[] = [
//   {id: '0', type: ERecentItemType.POI, name: '진진반상', date: '2021-03-01'},
//   {id: '1', type: ERecentItemType.QUERY, name: '맛집', date: '2021-03-01'},
//   {id: '2', type: ERecentItemType.POI, name: '인천국제공항', date: '2021-03-01'},
//   {
//     id: '3',
//     type: ERecentItemType.POI,
//     name: '현재백화점 판교점현대백화점 판교점현대백화점 판교점',
//     date: '2021-02-28',
//   },
//   {id: '4', type: ERecentItemType.QUERY, name: '코로나', date: '2021-03-03'},
//   {id: '5', type: ERecentItemType.QUERY, name: '주유소', date: '2021-01-01'},
//   {id: '6', type: ERecentItemType.POI, name: '맛집임', date: '2020-12-31'},
//   {id: '7', type: ERecentItemType.POI, name: '우리주유소', date: '2021-02-03'},
//   {id: '8', type: ERecentItemType.POI, name: '아이스크림', date: '2021-02-02'},
//   {id: '9', type: ERecentItemType.QUERY, name: '주변 맛집', date: '2021-03-01'},
//   {id: '10', type: ERecentItemType.POI, name: '신세계 이마트', date: '2021-03-01'},
//   {id: '11', type: ERecentItemType.POI, name: '홈플러스', date: '2021-03-01'},
//   {id: '12', type: ERecentItemType.QUERY, name: '마트', date: '2021-03-01'},
//   {id: '13', type: ERecentItemType.POI, name: '코스트코 광명점', date: '2021-03-01'},
//   {id: '14', type: ERecentItemType.POI, name: '이케아 광명점', date: '2021-03-01'},
//   {id: '15', type: ERecentItemType.QUERY, name: '광명 쇼핑몰', date: '2021-03-01'},
// ];

export const FAVORITE_TEST_LIST: TFavoriteItem[] = [
  {
    pkey: null,
    poiId: '994568647',
    navSeq: null,
    customName: '래미안당산1차아파트',
    centerX: 4568647,
    centerY: 1351157,
    navX: 4568647,
    navY: 1351157,
    wgs84CenterX: 126.904767926666,
    wgs84CenterY: 37.534938218939,
    wgs84NavX: 126.904767926666,
    wgs84NavY: 37.534938218939,
    rpFlag: 16,
    addInfo: null,
    type: EFavoriteType.HOME,
  },
  {
    pkey: '1024369101',
    poiId: '10243691',
    navSeq: null,
    customName: '티맵모빌리티',
    centerX: 4571609,
    centerY: 1352247,
    navX: 4571600,
    navY: 1352249,
    wgs84CenterX: 126.987037970684,
    wgs84CenterY: 37.56521300038,
    wgs84NavX: 126.986787992749,
    wgs84NavY: 37.565268547966,
    rpFlag: 17,
    addInfo: '서울 중구 삼일대로 343',
    type: EFavoriteType.OFFICE,
  },
  {
    pkey: '38282101',
    poiId: '382821',
    navSeq: '1',
    customName: '당산래미안1차아파트',
    centerX: 4568684,
    centerY: 1351132,
    navX: 4568648,
    navY: 1351157,
    wgs84CenterX: 126.905795618181,
    wgs84CenterY: 37.534243861077,
    wgs84NavX: 126.904795701932,
    wgs84NavY: 37.534938219109,
    rpFlag: 16,
    addInfo: '서울 영등포구 당산로54길 11',
    type: EFavoriteType.POI,
  },
  {
    pkey: null,
    poiId: null,
    navSeq: null,
    customName: '테스트 즐겨찾ㄱ',
    centerX: 4571685,
    centerY: 1352201,
    navX: 4571685,
    navY: 1352201,
    wgs84CenterX: 126.989148903351,
    wgs84CenterY: 37.563935383429,
    wgs84NavX: 126.989148903351,
    wgs84NavY: 37.563935383429,
    rpFlag: 0,
    addInfo: '서울특별시 중구 저동2가 69-14',
    type: EFavoriteType.POI,
  },
];

export const FAVORITE_ROUTE_TEST_LIST: TFavoriteRouteItem[] = [
  {
    customTitle: '여기저기',
    departure: {
      pkey: null,
      poiId: null,
      navSeq: null,
      placeName: '서울특별시 영등포구 당산동 113-7',
      navX: 4568708,
      navY: 1351117,
      wgs84NavX: 126.906462228569,
      wgs84NavY: 37.533827246664,
    },
    destination: {
      pkey: null,
      poiId: '4085840',
      navSeq: '1',
      placeName: '이마트',
      navX: 4567889,
      navY: 1354637,
      wgs84NavX: 126.883713343579,
      wgs84NavY: 37.631593570831,
    },
    routeTrace: '45686771,13511547:1076,-999:-949,967:-181,121:-364,133:225,-97:193',
  },
  {
    customTitle: null,
    departure: {
      pkey: null,
      poiId: null,
      navSeq: null,
      placeName: '경기도 고양시 덕양구 용두동 2-111',
      navX: 4568397,
      navY: 1354385,
      wgs84NavX: 126.897823244661,
      wgs84NavY: 37.624594467804,
    },
    destination: {
      pkey: null,
      poiId: '994568648',
      navSeq: null,
      placeName: '당산래미안1차아파트',
      navX: 4568648,
      navY: 1351157,
      wgs84NavX: 126.904795701932,
      wgs84NavY: 37.534938219109,
    },
    routeTrace: '45684259,13543632:-817,586:817,-586:-956,776:-153,119:-69',
  },
  {
    customTitle: null,
    departure: {
      pkey: null,
      poiId: null,
      navSeq: null,
      placeName: '경기도 고양시 덕양구 도내동 1107',
      navX: 4567441,
      navY: 1354471,
      wgs84NavX: 126.871270070766,
      wgs84NavY: 37.626982917468,
    },
    destination: {
      pkey: null,
      poiId: '190232',
      navSeq: '0',
      placeName: '선정고등학교',
      navX: 4568814,
      navY: 1354120,
      wgs84NavX: 126.90940560062,
      wgs84NavY: 37.617234279949,
    },
    routeTrace: '45674412,13544612:80,222:508,-117:299,-65:-45,-143:-161,-514:-93,-298:-49',
  },
];

export const SUGGEST_TEST_ITEM: TSuggestQuery[] = [
  {
    keyword: 'ak플라자',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'ak플라자 ak타운점',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'abc마트',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'ak플라자분당점',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'ak&',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'atm',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'aw웨딩컨벤션',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'at센터',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'ak플라자평택점',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'aj파크',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'ak골프',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'apple 가로수길직영스토어',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'aop',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'aj셀카옥션',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'ak&기흥점',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'abl생명',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'ak플라자수원점',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'aw호텔',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'aj파크계양역주차장',
    coordinates: '',
    rpFlag: '',
  },
  {
    keyword: 'ak플라자원주점',
    coordinates: '',
    rpFlag: '',
  },
];

export const ADDRESS_CATE_1 = [
  '강원',
  '경기',
  '경남',
  '경북',
  '광주',
  '대구',
  '대전',
  '부산',
  '서울',
  '세종',
  '울산',
  '인천',
  '전남',
  '전북',
  '제주',
  '충남',
  '충북',
];

export const ADDRESS_CATE_2 = [
  '거제시',
  '거창군',
  '고성군',
  '김해시',
  '남해군',
  '밀양시',
  '창원시 마산합포구',
  '창원시 마산회원구',
  '창원시 성산구',
  '창원시 의창구',
  '창원시 진해구',
  '통영시',
  '하동군',
  '함안군',
  '함양군',
  '합천군',
];

export const ADDRESS_CATE_3 = [
  '가배1길',
  '가배2길',
  '가배3길',
  '가배오송길',
  '가조로',
  '가조로10길',
  '가조로11길',
  '가조로12길',
  '가조로13길',
  '가조로14길',
  '가조로2길',
  '가조로3길',
  '가조로4길',
  '가조로5길',
  '가조로6길',
  '가조로7길',
  '가조로8길',
  '가조로9길',
];

export const AREA_NAMES = [
  '강원 강릉',
  '강원 속초',
  '전남 여수',
  '인천 강화도',
  '거제도',
  '경북 경주',
  '경남 통영',
  '포항 호미곶',
  '포항 구룡포',
  '경북 포항',
  '부산 기장',
  '부산 광안리',
  '강원 양양',
  '전북 군산',
  '부산 해운대',
  '충남 천안',
  '충북 청주 청남대',
  '경기 가평',
  '대전',
  '대구',
  '강원 춘천',
  '전북 전주',
  '인천 송도',
  '강원 고성',
  '광주 화담숲',
  '광주',
  '충남 태안',
  '충남 당진',
  '전남 목포',
  '강원 삼척',
  '양평 두물머리',
  '경기 평택',
  '경기 안산',
  '강원 주문진',
  '경기 일산',
  '제주 서귀포',
  '포천 산정호수',
  '경기 파주',
  '인천 월미도',
  '강원 원주',
  '경남 남해',
  '울산 간절곶',
  '충남 서산',
  '경기 수원',
  '충북 단양',
  '경기 김포',
  '강원 동해',
  '경북 영덕',
  '충북 제천',
  '세종',
  '광교 호수공원',
  '인천 소래포구',
  '동탄 호수공원',
  '파주 마장호수',
  '파주 임진각',
  '담양 죽녹원',
  '수원 화성',
  '부산 자갈치시장',
  '울산 태화강 십리대숲',
  '파주 프로방스',
  '서울 뚝섬유원지',
  '서울 석촌호수',
  '춘천 남이섬',
  '안동 찜닭골목',
  '서울숲',
  '대전 현충원',
  '부산 삼락공원',
  '기흥 호수공원',
  '속초 아바이마을',
  '부천 상동호수공원',
  '서울 신사 가로수길',
  '서울 이태원',
  '서울 노들섬',
  '거제 매미성',
  '안동 하회마을',
  '일산 호수공원',
  '강릉 경포호수',
  '제주 절물자연휴양림',
  '안산 호수공원',
  '인천 차이나타운',
  '서울 도산공원',
  '대구 수성못',
  '남양주 팔당',
  '안양유원지',
  '경북 문경새재',
  '서울 해방촌',
  '서울 잠원한강공원',
  '강원 철원고석정',
  '성남 율동공원',
  '안산 화랑유원지',
  '경기 포천 허브아일랜드',
  '양주 송추계곡',
  '진주 촉석루',
  '파주 헤이리예술마을',
  '고양 행주산성',
  '밀양 영남루',
  '용인민속촌',
  '동탄센트럴파크',
  '제주 거문오름',
  '경기 광명동굴',
  '하동 화개장터',
  '포천 아트밸리',
  '대구 팔공산',
  '서울 낙산공원',
  '서울 송리단길',
  '경남 하동삼성궁',
  '안산 해솔길',
  '경주 불국사',
  '남산 한옥마을',
  '제주 천지연폭포',
  '대구 강정보',
  '경북 대야산 용추계곡',
  '경주 교촌한옥마을',
  '부여 백제문화단지',
  '서울 묵정공원',
  '경기 남한산성',
  '통영 이순신공원',
  '서울 국립현대미술관',
  '충북 대청호',
  '강원 안목해변',
  '인천 을왕리해수욕장',
  '제주 중문색달해변',
  '제주 협재해수욕장',
  '서울 광화문',
  '남양주 한강시민공원',
  '강화도 고인돌광장',
  '경남 하동송림공원',
  '서울 사직공원',
  '광주문화전당',
  '경남 밀양 얼음골',
  '충남 장항송림산림욕장',
  '대구 앞산공원',
  '서울 북악산 팔각정',
  '경남 함양 상림공원',
  '부산 흰여울문화마을',
  '전남 방장산 자연휴양림',
  '경북 구미 낙동강체육공원',
  '전북 무주 덕유산국립공원',
  '부산 사직야구장',
  '경기 한탄강관광지',
  '충남 대천해수욕장',
  '강원 양떼목장',
  '경북 주왕산국립공원',
  '단양 고수동굴',
  '당진 왜목마을',
  '마곡 서울식물원',
  '하남 미사호수공원',
  '충남 아산 지중해마을',
  '가평 아침고요수목원',
  '제주 정방폭포',
  '잠실한강공원',
  '안산 대부도공원',
  '서울 보라매공원',
  '서울 어린이대공원',
  '여의도한강공원',
  '여수 돌산공원',
  '인천 십리포해수욕장',
  '강원 영월 청령포',
  '충북 단양 도담삼봉',
  '강원 양양 휴휴암',
  '전북 남원 광한루',
  '전북 부안 내소사',
  '안성팜랜드',
  '경기 화성 융건능',
  '서울 북촌한옥마을',
  '강원 설악산',
  '의왕 백운호수',
  '제천 청풍문화재단지',
  '제부도',
  '충남 예산 예당호',
  '인천대공원',
  '제주 성산일출봉',
  '서울 익선동',
  '안산 오이도전망대',
  '서울 인왕산공원',
  '경기 의왕 왕송호수',
  '대구 김광석다시그리기길',
  '경북 성주 성밖숲',
  '판교 아브뉴프랑',
  '제주 애월',
  '대전 계룡산 수통골',
  '경기 시흥 물왕저수지',
  '경남 창원 용지호수공원',
  '경남 황매산',
  '서울 경복궁',
  '강원 팔봉산유원지',
  '전남 순천만정원',
  '경남 통영 동피랑마을',
  '서울 연트럴파크',
  '서울 성수카페거리',
  '충남 서산 해미읍성',
  '서울 서래마을',
  '대전 소제카페거리',
  '서울 예술의전당',
  '서울 올림픽공원',
  '서울 DDP',
  '서울 을지로',
  '광교 아브뉴프랑',
  '서울 양재시민의숲',
  '용인 에버랜드',
];

export const TEST_SEARCH_ITEM = {
  poiType: 'poi',
  pkey: '297115400',
  poiId: 2971154,
  navSeq: 0,
  poiName: '스타벅스 정자점',
  poiNameSvc: '스타벅스 정자점',
  svcCategoryName: '카페',
  categoryGroup: 'RESTAURANT',
  centerX: 4575902.0,
  centerY: 1345034.0,
  navX: 4575895.0,
  navY: 1345032.0,
  wgs84CenterX: 127.1062791554,
  wgs84CenterY: 37.3648757665,
  wgs84NavX: 127.106084729,
  wgs84NavY: 37.3648202162,
  rpFlag: 16,
  displayPhoneNumber: '1522-3232',
  fullRoadAddr: '경기 성남시 분당구 정자일로 166',
  fullJibunAddr: '경기 성남시 분당구 정자동 159-3',
  distance: 0.241,
  userRealDistance: 0.241,
  mainCategory: 'BSE02020502',
  markerCategory: null,
  groupSubList: [
    {
      pkey: '297115401',
      poiId: '2971154',
      seq: 1,
      navSeq: 1,
      poiName: '주차장',
      categoryGroup: 'RESTAURANT',
      centerX: 4575895.0,
      centerY: 1345032.0,
      navX: 4575895.0,
      navY: 1345032.0,
      wgs84CenterX: 127.106084729,
      wgs84CenterY: 37.3648202162,
      wgs84NavX: 127.106084729,
      wgs84NavY: 37.3648202162,
      rpFlag: 16,
      isTmapPayment: null,
      isDiscountCoupon: null,
      isTmapPark: false,
      realTimeParkingLotInfo: null,
      tag: {
        isTmapPark: false,
        isTmapPayment: false,
        isDiscountCoupon: false,
        isDiscountTicket: false,
        isAutoPayment: false,
      },
      special: {
        realTimeParkingLotInfo: null,
        parkingFeeInfo: null,
        tmapParkingLot: null,
      },
    },
  ],
  special: {
    realTimeParkingLotInfo: null,
    gasStationInfo: {
      premiumGasolinePrice: 0,
      gasolinePrice: 0,
      dieselPrice: 0,
      lpgPrice: 0,
      oilBrand: null,
    },
    evChargerInfo: {
      superFastChargerCount: 0,
      fastChargerCount: 0,
      slowChargerCount: 0,
      superFastAvailableCount: 0,
      fastAvailableCount: 0,
      slowAvailableCount: 0,
      lastChargingMinuteAgo: null,
      chargerTypes: null,
      operatorIds: null,
    },
    parkingFeeInfo: null,
    accommodationInfo: null,
    offDay: null,
    businessHours: null,
    isTesla: null,
    adBluePrice: null,
    tmapParkingLot: {
      tickets: [],
    },
  },
  tag: {
    isParking: true,
    isPopular: false,
    isTmapPark: false,
    isDayOff: false,
    isAccommodation: false,
    isRefuelDiscountCard: false,
    isTruckGasStation: null,
    isTruckRestArea: null,
    isPickUp: false,
    isTmapPayment: false,
    isDiscountCoupon: false,
    evChargerOpen: null,
    isAdBlueSale: null,
    isDiscountTicket: false,
    isAutoPayment: false,
    cheapestPoi: false,
    nearestPoi: false,
  },
  collectionType: 'poi',
  destinationCount: 1396,
  headingForScore: 0,
  images: ['/brand/d41d8cd98f00b204e9800998ecf8427e6ff1'],
  imageInfo: [
    {
      path: '/brand/d41d8cd98f00b204e9800998ecf8427e6ff1',
      source: 'CATEGORY',
    },
  ],
  isUnderground: false,
  isExposureScore: false,
  isNavPoint: false,
  holidayBusinessDays: [],
  primaryBun: 159,
  secondaryBun: 3,
  isMountain: false,
  roadName: '정자일로',
  roadScdName: '',
  bldNo1: 166,
  bldNo2: null,
  lcdName: '경기',
  mcdName: '성남시 분당구',
  scdName: '정자동',
  dcdName: '',
} as any;

export const TRANS_PUBLIC_FAVORITE = [
  {
    favoriteId: '9dbea76c-f4cc-404f-91c9-9ba4bf6aa9d9',
    favoriteType: 'ROUTE',
    regDateTime: null,
    updateDateTime: null,
    routeOrigin: {
      pkey: '21930800',
      poiId: '219308',
      navSeq: '0',
      poiName: '천주교서울대교구 주교좌명동대성당',
      fullRoadAddr: '서울 중구 명동길 74',
      fullJibunAddr: '서울 중구 명동2가 1-1',
      centerX: 4571616,
      centerY: 1352178,
      wgs84CenterX: 126.987232416233,
      wgs84CenterY: 37.5632965565823,
    },
    routeDestination: {
      pkey: '131923801',
      poiId: '1319238',
      navSeq: '1',
      poiName: '진관사나한전소조석가삼존불상',
      fullRoadAddr: '서울 은평구 진관길 73',
      fullJibunAddr: '서울 은평구 진관동 354',
      centerX: 4570165,
      centerY: 1354868,
      wgs84CenterX: 126.946929778458,
      wgs84CenterY: 37.6380098835571,
    },
  },
  {
    favoriteId: '9dbea76c-f4cc-test-91c9-9ba4bf6aa9d9',
    favoriteType: 'ROUTE',
    regDateTime: null,
    updateDateTime: null,
    routeOrigin: {
      pkey: '193328300',
      poiId: '1933283',
      navSeq: '0',
      poiName: '소노벨 천안',
      fullRoadAddr: '충남 천안시 동남구 성남면 종합휴양지로 200',
      fullJibunAddr: '충남 천안시 동남구 성남면 용원리 672',
      centerX: 4580137,
      centerY: 1323161,
      wgs84CenterX: 127.223913265678,
      wgs84CenterY: 36.7573629489536,
    },
    routeDestination: {
      pkey: '678832401',
      poiId: '6788324',
      navSeq: '1',
      poiName: '서울역한라비발디센트럴아파트',
      fullRoadAddr: '서울 중구 만리재로 177',
      fullJibunAddr: '서울 중구 만리동2가 288',
      centerX: 4570839,
      centerY: 1351872,
      wgs84CenterX: 126.965651117346,
      wgs84CenterY: 37.5547974064529,
    },
  },
];

export const TEST_QUICK_LINK_FIREBASE_DATA = {
  tnow_category_link: [
    {
      label: '🐶 동물원',
      type: 'SS_ZOO',
    },
    {
      label: '물놀이',
      icon: 'https://poi-cdn.tmap.co.kr/static/quicksearch/ico/OILEV.png',
      type: 'SS_WATER_PLAY',
    },
    {
      label: '벚꽃명소',
      icon: 'https://poi-cdn.tmap.co.kr/static/quicksearch/ico/TURALL.png',
      type: 'SS_CHERRY_BLOSSOM',
    },
    {
      label: '맥도날드',
      icon: 'https://poi-cdn.tmap.co.kr/static/quicksearch/ico/CAFEALL.png',
      type: 'AD_MCDONALD',
    },
    {
      label: '가을나들이',
      icon: 'https://poi-cdn.tmap.co.kr/static/quicksearch/ico/SS_AUTUMN_LEAVES.png',
      type: 'SS_AUTUMN_LEAVES',
      exposeTime: {
        start: '20231005T0000',
        end: '20231120T2359',
      },
    },
    {
      label: '명절무료주차장',
      icon: 'https://poi-cdn.tmap.co.kr/static/quicksearch/ico/PARKLOT.png',
      type: 'SS_HOLIDAY_PARK_TEST',
      exposeTime: {
        start: '20230927T0000',
        end: '20230930T2359',
      },
    },
    {label: '주유소', type: 'POPULAR'},
    {label: '전기차충전소', type: 'OILEV'},
    {label: '주차장', type: 'PARKLOT'},
    {label: '음식점', type: 'FUDALL'},
    {label: '카페', type: 'CAFEALL'},
    {label: '편의점', type: 'CVSALL'},
    {label: '관광명소', type: 'TURALL'},
    {label: '은행', type: 'BNKALL'},
  ],
  search_main_category_link: [
    {
      label: '가을나들이',
      icon: 'https://poi-cdn.tmap.co.kr/static/quicksearch/ico/SS_AUTUMN_LEAVES.png',
      type: 'SS_AUTUMN_LEAVES',
      exposeTime: {
        start: '20231005T0000',
        end: '20231120T2359',
      },
    },
    {
      label: '명절무료주차장',
      icon: 'https://poi-cdn.tmap.co.kr/static/quicksearch/ico/PARKLOT.png',
      type: 'SS_HOLIDAY_PARK_TEST',
      exposeTime: {
        start: '20230927T0000',
        end: '20230930T2359',
      },
    },
    {
      label: '주유소',
      type: 'POPULAR',
      icon: 'https://poi-cdn.tmap.co.kr/static/quicksearch/ico/POPULAR.png',
    },
    {label: '전기차충전소', type: 'OILEV'},
    {label: '주차장', type: 'PARKLOT'},
    {label: '음식점', type: 'FUDALL'},
    {label: '카페', type: 'CAFEALL'},
    {label: '편의점', type: 'CVSALL'},
    {label: '관광명소', type: 'TURALL'},
    {label: '은행', type: 'BNKALL'},
  ],
  search_result_category_link: [
    {
      label: '가을나들이',
      icon: 'https://poi-cdn.tmap.co.kr/static/quicksearch/ico/SS_AUTUMN_LEAVES.png',
      type: 'SS_AUTUMN_LEAVES',
      exposeTime: {
        start: '20231005T0000',
        end: '20231120T2359',
      },
    },
    {
      label: '명절무료주차장',
      icon: 'https://poi-cdn.tmap.co.kr/static/quicksearch/ico/PARKLOT.png',
      type: 'SS_HOLIDAY_PARK_TEST',
    },
    {label: '주유소', type: 'POPULAR'},
    {label: '전기차충전소', type: 'OILEV'},
    {label: '주차장', type: 'PARKLOT'},
  ],
  custom_category_page: [
    {
      type: 'SS_HOLIDAY_PARK_TEST',
      title: '명절무료주차장',
      categories: ['BSE01020310'],
      sort: {
        items: ['dest_nearby_score', 'distance'],
        default: 'distance',
      },
      exposeTime: {
        start: '20230927T0000',
        end: '20230930T2359',
      },
    },
    {
      type: 'SS_AUTUMN_LEAVES',
      categories: ['BSE03023502'],
      sort: {
        items: ['DISTANCE', 'SCORE'],
        default: 'DISTANCE',
      },
      exposeTime: {
        start: '20230927T0000',
        end: '20230930T2359',
      },
    },
    {
      type: 'AD_MCDONALD',
      title: '맥도날드',
      categories: ['BSE02010704'],
      sort: {
        items: ['distance'],
        default: 'distance',
      },
    },
    {
      type: 'SS_CHERRY_BLOSSOM',
      title: '근처 벚꽃명소',
      categories: ['BSE03023699'],
      sort: {
        items: ['distance', 'score'],
        default: 'score',
      },
    },
    {
      type: 'SS_WATER_PLAY',
      title: '물놀이 명소',
      categories: ['BSE03023799'],
      sort: {
        items: ['score'],
        default: 'score',
      },
    },
    {
      type: 'SS_ZOO',
      title: '동물농장',
      categories: ['BSE03021201'],
      sort: {
        items: ['distance', 'score'],
        default: 'score',
      },
    },
  ],
  // s3 경로로 변경 필요 - 현재는 테스트용
  theme_marker: [
    {
      code: 'THM080101010',
      description: 'SS_HOLIDAY_PARK',
      icon: [
        // 야간, 주간 마커가 동일하면 동일한 경로를 사용하면 됨..
        // day
        'https://search-stg.tmap.co.kr/static/images/mapmarker_parking_icon_day_general@3x.dc927025.png',
        // night
        'https://search-stg.tmap.co.kr/static/images/mapmarker_parking_icon_night_general@3x.5cbec24d.png',
      ],
      marker: [
        // day
        'https://search-stg.tmap.co.kr/static/images/mapmarker_parking_marker_day_general@3x.fdf32157.png',
        // night
        'https://search-stg.tmap.co.kr/static/images/mapmarker_parking_marker_night_general@3x.fabf2379.png',
      ],
      exposeTime: {
        start: '20230927T0000',
        end: '20230930T2359',
      },
    },

    {
      code: 'THM070101010',
      description: 'AD_MCDONALD',
      icon: [
        // 야간, 주간 마커가 동일하면 동일한 경로를 사용하면 됨..
        // day
        'https://search-stg.tmap.co.kr/static/images/mapmarker_mc_icon_day3x.55be8017.png',
        // night
        'https://search-stg.tmap.co.kr/static/images/mapmarker_mc_icon_night3x.2d51a668.png',
      ],
      marker: [
        // day
        'https://search-stg.tmap.co.kr/static/images/mapmarker_mc_marker_day3x.8548994c.png',
        // night
        'https://search-stg.tmap.co.kr/static/images/mapmarker_mc_marker_night3x.56de8a8d.png',
      ],
    },
    {
      code: 'THM060101990',
      description: 'SS_CHERRY_BLOSSOM',
      icon: [
        'https://search-stg.tmap.co.kr/static/images/mapmarker_blossom_normal_day@3x.f27236e7.png',
        'https://search-stg.tmap.co.kr/static/images/mapmarker_blossom_normal_day@3x.f27236e7.png',
      ],
      marker: [
        'https://search-stg.tmap.co.kr/static/images/mapmarker_blossom_select_day@3x.66d377bd.png',
        'https://search-stg.tmap.co.kr/static/images/mapmarker_blossom_select_day@3x.66d377bd.png',
      ],
    },

    {
      code: 'THM060102990',
      description: 'SS_WATER_PLAY',
      icon: [
        'https://search-stg.tmap.co.kr/static/images/waterpark-icon-day@3x.d6ef2617.png',
        'https://search-stg.tmap.co.kr/static/images/waterpark-icon-night@3x.d0fdda6b.png',
      ],
      marker: [
        'https://search-stg.tmap.co.kr/static/images/waterpark-marker-day@3x.4e5bd461.png',
        'https://search-stg.tmap.co.kr/static/images/waterpark-marker-night@3x.e548ed69.png',
      ],
    },
  ],
};

export const fetchRemoteConfigFromFirebase = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(TEST_QUICK_LINK_FIREBASE_DATA);
    }, 300);
  });
};

export const MOCK_PUBLIC_RECENT = [
  {
    transportId: '311000010038939',
    transportType: 'BUSSTATION',
    details: {
      destinationType: 'BUSSTATION',
      busStation: {
        stationId: 311000010038939,
        poiId: '8715520',
        name: '서울백병원.국가인권위.안중근활동터(중)',
        geoCoordinate: {
          noorX: 4571041,
          noorY: 1352921,
          centerX: 4574680,
          centerY: 1352058,
          noorLon: 126.971261437389,
          noorLat: 37.58393295953,
          centerLon: 127.072335865657,
          centerLat: 37.559964137433,
        },
      },
    },
    svcDate: '2024-01-05T16:00:28',
  },
  {
    transportId: '311000020023516',
    transportType: 'SUBWAY',
    details: {
      destinationType: 'SUBWAY',
      subway: {
        stationId: 311000020023516,
        poiId: '31100002002351600',
        name: '서울역',
        geoCoordinate: {
          noorX: 4571041,
          noorY: 1352921,
          centerX: 4574680,
          centerY: 1352058,
          noorLon: 126.971261437389,
          noorLat: 37.58393295953,
          centerLon: 127.072335865657,
          centerLat: 37.559964137433,
        },
        lineMapTypeName: '2호선',
        lineTypeCode: 102,
      },
    },
    svcDate: '2024-01-05T15:48:32',
  },
  {
    transportId: '4571041_1352921_4571041_1352921',
    transportType: 'ROUTE',
    details: {
      destinationType: 'ROUTE',
      origin: {
        pkey: '43210800',
        poiId: '432108',
        poiName: '강남역',
        noorX: '4571041',
        noorY: '1352921',
        centerX: '4574680',
        centerY: '1352058',
        noorLon: 126.971261437389,
        noorLat: 37.58393295953,
        centerLon: 127.072335865657,
        centerLat: 37.559964137433,
        lcdName: '서울',
        mcdName: '동대문구',
        scdName: '장안동',
        mlClass: '1',
      },
      destination: {
        pkey: '43210800',
        poiId: '432108',
        poiName: '강남역',
        noorX: '4571041',
        noorY: '1352921',
        centerX: '4574680',
        centerY: '1352058',
        noorLon: 126.971261437389,
        noorLat: 37.58393295953,
        centerLon: 127.072335865657,
        centerLat: 37.559964137433,
        lcdName: '서울',
        mcdName: '동대문구',
        scdName: '장안동',
        mlClass: '1',
      },
    },
    svcDate: '2023-12-05T18:31:09',
  },
  {
    transportId: '4574680_1352058',
    transportType: 'PLACE',
    details: {
      destinationType: 'PLACE',
      place: {
        pkey: '43210800',
        poiId: '432108',
        poiName: '강남역',
        noorX: '4571041',
        noorY: '1352921',
        centerX: '4574680',
        centerY: '1352058',
        noorLon: 126.971261437389,
        noorLat: 37.58393295953,
        centerLon: 127.072335865657,
        centerLat: 37.559964137433,
        lcdName: '서울',
        mcdName: '동대문구',
        scdName: '장안동',
        mlClass: '1',
      },
    },
    svcDate: '2023-12-05T18:11:42',
  },
];
