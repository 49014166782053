import {useCallback, useMemo} from 'react';
import classNames from 'classnames';

import {EPlaceCategoryType, EPlaceFeature, EPlaceFeatureOperator} from 'types/App';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import useLogger from 'hooks/useLogger';
import useTooltip from 'hooks/useTooltip';
import {StorageKey} from 'constant/Storage';
import ImgPlaceReservationWaitingTooltip from 'resource/images/place_reservation_waiting_tooltip@3x.png';

import s from 'styles/components/place/PlaceListReservationWaitFilter.module.scss';

type TProps = {
  nowType: EPlaceCategoryType;
  onChange: (param: Record<string, any>) => void;
};

const RESERVATION_WAITING_TOOLTIP_EXPIRE_TIME = '2024/07/24 00:00:00';

export const PlaceListReservationWaitFilter = ({nowType, onChange}: TProps) => {
  const dispatch = useAppDispatch();
  const {sendFilterClickLog} = useLogger();

  const {placeFeatureIds} = useAppSelector((state) => ({
    placeFeatureIds: state.place.placeFeatureIds,
  }));
  const isTooltipVisible = useTooltip({
    storageKey: StorageKey.RESERVATION_WAITIING_TOOLTIP,
    expireTime: new Date(RESERVATION_WAITING_TOOLTIP_EXPIRE_TIME).getTime(),
  });

  const isReservationClicked = useMemo(
    () => placeFeatureIds?.includes(EPlaceFeature.TMAP_RESERVATION),
    [placeFeatureIds]
  );
  const isWaitingClicked = useMemo(
    () => placeFeatureIds?.includes(EPlaceFeature.TMAP_WAITING),
    [placeFeatureIds]
  );

  const handleClickReserve = useCallback(() => {
    const newFeatureIds = isReservationClicked ? [] : [EPlaceFeature.TMAP_RESERVATION];

    if (isWaitingClicked) {
      newFeatureIds.push(EPlaceFeature.TMAP_WAITING);
    }

    sendFilterClickLog('tap.filter.reserve', {
      status: isReservationClicked ? 'off' : 'on',
    });

    dispatch(actions.place.updatePlaceFeatureIds(newFeatureIds));
    dispatch(actions.place.updatePlaceFeatureOperator(EPlaceFeatureOperator.AND));
    onChange({
      feature: newFeatureIds.join(','),
    });
  }, [dispatch, onChange, isReservationClicked, isWaitingClicked, sendFilterClickLog]);

  const handleClickWaiting = useCallback(() => {
    const newFeatureIds = isWaitingClicked ? [] : [EPlaceFeature.TMAP_WAITING];

    if (isReservationClicked) {
      newFeatureIds.push(EPlaceFeature.TMAP_RESERVATION);
    }

    sendFilterClickLog('tap.filter.waiting', {
      status: isWaitingClicked ? 'off' : 'on',
    });

    dispatch(actions.place.updatePlaceFeatureIds(newFeatureIds));
    dispatch(actions.place.updatePlaceFeatureOperator(EPlaceFeatureOperator.AND));
    onChange({
      feature: newFeatureIds.join(','),
    });
  }, [dispatch, onChange, isWaitingClicked, isReservationClicked, sendFilterClickLog]);

  return (
    <>
      <div className={s.wrap}>
        <div
          className={classNames(s.filter, {
            [s.on]: isReservationClicked,
          })}
          onClick={handleClickReserve}
        >
          <span className={s.text}>예약</span>
        </div>
        <div
          className={classNames(s.filter, {
            [s.on]: isWaitingClicked,
          })}
          onClick={handleClickWaiting}
        >
          <span className={s.text}>웨이팅</span>
        </div>
      </div>
      {isTooltipVisible && (
        <img
          src={ImgPlaceReservationWaitingTooltip}
          alt="예약/웨이팅 툴팁"
          className={classNames(s.tooltip, {
            [s.is_tpopular]: nowType === EPlaceCategoryType.POPULAR,
          })}
        />
      )}
    </>
  );
};
