import classNames from 'classnames';
import {ReactComponent as IconArrowDown} from 'resource/images/@tmds_element/ico_arrow_down.svg';
import actions from 'ducks/actions';
import s from 'styles/components/place/PlaceListHeader.module.scss';
import {Link} from 'react-router-dom';
import {EFilterActionId, EToggle} from 'constant/Log';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import useLogger from 'hooks/useLogger';
import {useCallback, useMemo} from 'react';
import {EEVChargerOpen, EEVChargerStatus, EEVChargerType, TToggler} from 'types/App';
import useDimmedQuery from 'hooks/useDimmedQuery';
import CustomSelectLayer, {TOption} from 'components/CustomSelectLayer';
import {EEVChargerSpeedOptions, EEVChargerTypeOptions} from 'constant/Place';
import {DEFAULT_CPO_IMAGE_RESOURCES, DEFAULT_EV_STATION_LIST} from 'constant/Poi';
import {getResetToggler} from 'hooks/usePlaceFilter';
import {checkValidPlaceOptions, getCPOImage, makePlaceOptionLabel} from 'utils/general';

const MAX_EV_COUNT = 10;

export const PlaceListHeaderEVFilter = ({onChange}) => {
  const {
    placeQuery,
    oilCompany,
    evCompany,
    evPublicType,
    evChargerSpeed,
    evChargerStatus,
    evChargerType,
    tmapPaymentYn,
    parkType,
    evFilterList,
  } = useAppSelector((state) => ({
    placeQuery: state.place.placeQuery,
    fuel: state.userInfo.carInfo?.fuel,
    oilCompany: state.place.oilCompany,
    evCompany: state.place.evSvcOperatorIds,
    evPublicType: state.place.evPublicType,
    evChargerSpeed: state.place.evChargerSpeed,
    evChargerStatus: state.place.evChargerStatus,
    evChargerType: state.place.evChargerType,
    tmapPaymentYn: state.place.tmapPaymentYn,
    parkType: state.place.parkType,
    evFilterList: state.remote.evFilterList,
  }));
  const dispatch = useAppDispatch();

  const {sendFilterClickLog, sendClickLogWithMapView, sendClickLog} = useLogger();
  const evCompanyDimmed = useDimmedQuery({place: 'ev_company'});
  const evChargerTypeDimmed = useDimmedQuery({place: 'ev_type'});
  const evSpeedDimmed = useDimmedQuery({place: 'ev_speed'});

  const nowEvFilterList = [
    ...(evFilterList.length > 0 ? evFilterList : DEFAULT_EV_STATION_LIST),
    DEFAULT_CPO_IMAGE_RESOURCES,
  ].map((item) => ({
    ...item,
    icon: item.id !== DEFAULT_CPO_IMAGE_RESOURCES.id ? getCPOImage(item.id) : undefined,
  }));

  const {nowEvChargerType, evChargerTypeList} = useMemo(() => {
    const filtered = EEVChargerTypeOptions.filter((v) => evChargerType?.includes(v.id));

    return {
      nowEvChargerType: filtered.length > 0 ? filtered : [],
      evChargerTypeList: EEVChargerTypeOptions,
    };
  }, [evChargerType]);
  const {nowEvSpeed, evSpeedList} = useMemo(() => {
    const filtered = EEVChargerSpeedOptions.filter((v) => evChargerSpeed?.includes(v.id));

    return {
      nowEvSpeed: filtered.length > 0 ? filtered : [],
      evSpeedList: EEVChargerSpeedOptions,
    };
  }, [evChargerSpeed]);

  const tmapPayToggler: TToggler = useMemo(() => {
    return {
      label: '티맵결제',
      params: [{tmapPaymentYn: undefined}, {tmapPaymentYn: 'Y'}],
      isOn: tmapPaymentYn === 'Y',
      clickCode: 'tap.tmap_pay',
    };
  }, [tmapPaymentYn]);

  const resetToggler: TToggler = useMemo(() => {
    return getResetToggler({
      placeQuery,
      oilCompany,
      evCompany,
      evPublicType,
      evChargerSpeed,
      evChargerStatus,
      evChargerType,
      parkType,
      tmapPaymentYn,
    });
  }, [
    oilCompany,
    evChargerSpeed,
    evChargerStatus,
    evChargerType,
    evCompany,
    evPublicType,
    placeQuery,
    parkType,
    tmapPaymentYn,
  ]);

  const evCompanyList = useMemo(() => {
    if (!!evChargerType?.find((t) => t === EEVChargerType.SUPER_CHARGER)) {
      return undefined;
    }

    return nowEvFilterList;
  }, [evChargerType, nowEvFilterList]);

  const nowEvCompany = useMemo(() => {
    const filtered = (evCompanyList || []).filter((v) => evCompany?.includes(v.id));

    return filtered.length > 0 ? filtered : [];
  }, [evCompanyList, evCompany]);

  const evPublicToggler: TToggler = useMemo(() => {
    return {
      label: '공용',
      params: [{evPublicType: undefined}, {evPublicType: EEVChargerOpen.PUBLIC}],
      isOn: evPublicType === EEVChargerOpen.PUBLIC,
      clickCode: 'tap.public_use',
    };
  }, [evPublicType]);

  const evStatusToggler: TToggler = useMemo(() => {
    return {
      label: '이용가능',
      params: [{evChargerStatus: undefined}, {evChargerStatus: EEVChargerStatus.CHARGING_STANDBY}],
      isOn: evChargerStatus === EEVChargerStatus.CHARGING_STANDBY,
      clickCode: 'tap.select_charger_status',
    };
  }, [evChargerStatus]);

  const handleClickToggle = useCallback(
    (e, toggler: TToggler) => {
      e.preventDefault();

      const param = toggler.params[toggler.isOn ? 0 : 1];

      if (toggler?.clickCode) {
        sendClickLogWithMapView(toggler.clickCode, {
          status: toggler.isOn ? EToggle.OFF : EToggle.ON,
        });
      }

      Object.keys(param).includes('tmapPaymentYn') &&
        dispatch(actions.place.updateTMapPaymentYn(param.tmapPaymentYn));
      Object.keys(param).includes('evPublicType') &&
        dispatch(actions.place.updateEVFilter({evPublicType: param.evPublicType}));
      Object.keys(param).includes('evChargerStatus') &&
        dispatch(actions.place.updateEVFilter({evChargerStatus: param.evChargerStatus}));

      param?.filter && dispatch(actions.place.updateFilter(param.filter));
      onChange(param);
    },
    [dispatch, onChange, sendClickLogWithMapView]
  );

  const handleReset = useCallback(() => {
    sendClickLog('tap.reset');
  }, [sendClickLog]);

  const handleConfirmEvCompany = useCallback(
    (newItemList) => {
      const evList = checkValidPlaceOptions(newItemList);

      dispatch(actions.place.updateEvCompanyList(evList));
      onChange({evSvcOperatorIds: evList});
      evCompanyDimmed.close();

      sendFilterClickLog(EFilterActionId.FILTER_ITEM_3, {
        option: newItemList.map((v) => v.id),
      });
    },
    [evCompanyDimmed, onChange, dispatch, sendFilterClickLog]
  );

  const updateEVFilter = useCallback(
    (obj) => {
      const evFilter = Object.keys(obj).reduce((prev, cur) => {
        return {
          ...prev,
          [cur]: checkValidPlaceOptions(obj[cur]),
        };
      }, {} as any);

      dispatch(actions.place.updateEVFilter(evFilter));
      onChange(evFilter);
    },
    [onChange, dispatch]
  );

  return (
    <>
      <Link
        className={classNames(s.filter, {[s.on]: resetToggler.isOn})}
        to={'#'}
        onClick={(e) => {
          e.preventDefault();

          sendClickLogWithMapView('tap.all', {
            status: EToggle.ON,
          });
          dispatch(actions.place.resetAllFilters({}));
          onChange?.(resetToggler.params[1]);
        }}
      >
        {resetToggler.label}
      </Link>
      <Link
        className={classNames(s.filter, {[s.on]: tmapPayToggler?.isOn})}
        to={'#'}
        onClick={(e) => handleClickToggle(e, tmapPayToggler)}
      >
        {tmapPayToggler.label}
      </Link>
      <Link
        className={classNames(s.filter, {[s.on]: evPublicToggler?.isOn})}
        to={'#'}
        onClick={(e) => handleClickToggle(e, evPublicToggler)}
      >
        {evPublicToggler.label}
      </Link>
      {(evSpeedList || []).length > 0 && (
        <Link
          className={classNames(s.filter, s.multiple, {[s.on]: nowEvSpeed.length > 0})}
          to={evSpeedDimmed.openLocation}
          onClick={() => {
            sendClickLogWithMapView(EFilterActionId.FILTER_1);
          }}
        >
          <span className={s.text}>{makePlaceOptionLabel(nowEvSpeed, '충전속도')}</span>
          <IconArrowDown className={s.icon_fold} />
        </Link>
      )}
      <Link
        className={classNames(s.filter, s.multiple, {
          [s.on]: (nowEvChargerType || []).length > 0,
        })}
        to={evChargerTypeDimmed.openLocation}
        onClick={() => {
          sendClickLogWithMapView(EFilterActionId.FILTER_2);
        }}
      >
        <span className={s.text}>{makePlaceOptionLabel(nowEvChargerType, '충전타입')}</span>
        <IconArrowDown className={s.icon_fold} />
      </Link>
      {evCompanyList && evCompanyList?.length > 0 && (
        <Link
          className={classNames(s.filter, s.multiple, {
            [s.on]: nowEvCompany && nowEvCompany?.length > 0,
          })}
          to={evCompanyDimmed.openLocation}
          onClick={() => {
            sendClickLogWithMapView(EFilterActionId.FILTER_3);
          }}
        >
          <span className={s.text}>{makePlaceOptionLabel(nowEvCompany, '운영기관')}</span>
          <IconArrowDown className={s.icon_fold} />
        </Link>
      )}
      <Link
        className={classNames(s.filter, {[s.on]: evStatusToggler?.isOn})}
        to={'#'}
        onClick={(e) => handleClickToggle(e, evStatusToggler)}
      >
        {evStatusToggler.label}
      </Link>
      {evCompanyList && (
        <CustomSelectLayer
          multiple={true}
          maxSelectCount={MAX_EV_COUNT}
          isOpen={evCompanyDimmed.isOpen}
          options={evCompanyList}
          selectedItem={nowEvCompany}
          onConfirm={handleConfirmEvCompany}
          onReset={handleReset}
        />
      )}
      <CustomSelectLayer
        multiple={true}
        isOpen={evSpeedDimmed.isOpen}
        options={evSpeedList || []}
        selectedItem={nowEvSpeed}
        onReset={handleReset}
        onConfirm={(newItemList) => {
          updateEVFilter({evChargerSpeed: newItemList});
          evSpeedDimmed.close();

          sendFilterClickLog(EFilterActionId.FILTER_ITEM_1, {
            option: checkValidPlaceOptions(newItemList),
          });
        }}
      />
      <CustomSelectLayer
        multiple={true}
        isOpen={evChargerTypeDimmed.isOpen}
        options={evChargerTypeList || []}
        selectedItem={nowEvChargerType}
        onReset={handleReset}
        onConfirm={(newItemList) => {
          const hasExtraEV = (newItemList as TOption[]).find(
            (t) => t.id === EEVChargerType.SUPER_CHARGER
          );

          updateEVFilter({
            evChargerType: newItemList,
            ...(hasExtraEV ? {evSvcOperatorIds: undefined} : {}),
          });

          evChargerTypeDimmed.close();

          sendFilterClickLog(EFilterActionId.FILTER_ITEM_2, {
            option: checkValidPlaceOptions(newItemList),
          });
        }}
      />
    </>
  );
};
