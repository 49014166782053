import {useCallback} from 'react';
import {TPoiDataJson} from '@lcc/tmap-inapp';

import {TPoiDetailParam} from 'types/App';

import s from 'styles/components/legacySearch/PoiItem.module.scss';

type TProps = {
  data: TPoiDataJson;
  onClickDetail?: (data: TPoiDetailParam) => void;
};

const PoiItem = ({data, onClickDetail}: TProps) => {
  const handleClickDetail = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (data.pkey || data.poiId || data.navX) {
        onClickDetail?.({
          pkey: `${data.pkey || ''}`,
          poiId: `${data.poiId || ''}`,
          navX: `${data.navX}`,
          navY: `${data.navY}`,
        });
      }
    },
    [onClickDetail, data]
  );

  return (
    <div className={s.item_wrap} onClick={handleClickDetail}>
      <div className={s.contents_wrap}>
        <div className={s.name}>{data.poiName}</div>
      </div>

      <div className={s.btn_wrap}>
        <div className={s.btn_detail}>상세</div>
      </div>
    </div>
  );
};

export default PoiItem;
