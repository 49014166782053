import {ERPFlag, TPoiImageInfo} from 'types/App';
import {useParsePoiData} from 'hooks/useParsePoiData';
import {useCallback, useEffect, useState} from 'react';
import {BUSINESS_HOUR_LABEL} from 'utils/special';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {TRankItem} from 'ducks/rank/types';
import {parsePoiInfoToNavInfo} from 'utils/search';
import useThrottle from 'hooks/useThrottle';
import useAppScheme from 'hooks/useAppScheme';
import {fetchUserData} from 'ducks/userInfo/slice';
import useFavorite from 'hooks/useFavorite';
import useAddress from 'hooks/useAddress';
import RankingPoiTitle from './RankingPoiTitle';
import RankingPoiInfo from './RankingPoiInfo';
import RankingPoiSubInfo from './RankingPoiSubInfo';
import RankingPoiImageList from './RankingPoiImageList';
import {isOverNewFavoriteVersion} from 'utils/tmapUtils';

import s from 'styles/components/ranking/RankingPoiPhotoViewItem.module.scss';

type TProps = {
  idx: number;
  poiData: {
    imageInfo?: TPoiImageInfo[];
  } & TRankItem;
  hasFavorite?: boolean;
  onClickItem: (e) => void;
  onClickFavorite: (isOn: boolean) => void;
};

const RankingPoiPhotoViewItem = ({
  idx,
  poiData,
  hasFavorite,
  onClickItem,
  onClickFavorite,
}: TProps) => {
  const {
    width: windowWidth,
    currentAddressMap,
    activePoi,
    personalPlace,
  } = useAppSelector((state) => ({
    width: state.layout.windowSize.width,
    currentAddressMap: state.tRank.currentAddressMap,
    activePoi: state.userInteraction.activePoi,
    personalPlace: state.userInfo.personalPlace,
  }));
  const {poiName, categoryName, special, comment, addressNameDepth2} = poiData;
  const {distance, imageInfo: images, headingScore, tags} = useParsePoiData(poiData);
  const ableToRun = useThrottle();
  const {checkIsFavorite} = useFavorite();
  const {fullAddress} = useAddress(poiData);
  const inApp = useAppScheme();
  const dispatch = useAppDispatch();

  const {unit} = special?.catchTableWaitingResponse || {};
  const businessHoursInfo =
    special?.businessHourStatus && BUSINESS_HOUR_LABEL[special.businessHourStatus];

  const [isFavorite, setIsFavorite] = useState<boolean>(
    isOverNewFavoriteVersion() ? !!hasFavorite : checkIsFavorite(poiData)
  );

  const handleClickFavorite = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!poiData || !ableToRun()) {
        return;
      }

      inApp
        .toggleFavorite({
          ...parsePoiInfoToNavInfo({
            ...poiData,
            address: fullAddress,
            tel: '',
            navX: `${poiData.navX}`,
            navY: `${poiData.navY}`,
            centerX: `${poiData.centerX}`,
            centerY: `${poiData.centerY}`,
            rpFlag: ERPFlag.N_0000,
            navSeq: '',
          }),
        })
        .then((isOn) => {
          if (!isOverNewFavoriteVersion()) {
            dispatch(fetchUserData());
          }
          setIsFavorite(isOn ? true : false);
          onClickFavorite(isOn as boolean);
        });
    },
    [ableToRun, dispatch, fullAddress, inApp, poiData, onClickFavorite]
  );

  useEffect(() => {
    if (!poiData || !personalPlace.loaded || isOverNewFavoriteVersion()) {
      return;
    }

    const isFavoriteCheck = checkIsFavorite(poiData);

    if (isFavorite !== isFavoriteCheck) {
      setIsFavorite(isFavoriteCheck);
    }
  }, [personalPlace.loaded]);
  // TODO: 사용자 지정 리스트 노출시 (랭킹 우선, 사진 우선 적용시) setIsFavorite 적용 필요

  return (
    <div
      className={s.wrap}
      onClick={onClickItem}
      data-type="poi"
      data-id={poiData.listId}
      data-focus={poiData.listId === activePoi}
    >
      <span className={s.img_idx}>{poiData.rank}</span>
      <RankingPoiImageList images={images} poiName={poiName} />

      <div className={s.container}>
        <RankingPoiTitle
          poiName={poiName}
          categoryName={categoryName}
          isFavorite={isFavorite}
          handleClickFavorite={handleClickFavorite}
          isPhotoView={true}
        />

        <RankingPoiInfo
          businessHoursInfo={businessHoursInfo}
          special={special}
          headingScore={headingScore}
          unit={unit}
        />

        {!!comment && (
          <div className={s.description}>
            <div className={s.comment}>{comment}</div>
          </div>
        )}

        <RankingPoiSubInfo
          distance={distance}
          addressNameDepth2={addressNameDepth2}
          currentAddressMap={currentAddressMap}
          tags={tags}
          windowWidth={windowWidth}
        />
      </div>
    </div>
  );
};

export default RankingPoiPhotoViewItem;
