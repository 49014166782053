import Vsm, {LabelStyleOptions, StackSpec} from '@vsm/vsm';
import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import {useCallback, useEffect, useRef, useState} from 'react';
import {devLog} from 'utils/dev';
import {useOnce} from './useOnce';

const LABEL_STYLE: LabelStyleOptions = {
  fill: '#000000',
  'font-size': '13px',
  'fill-opacity': '0',
  // 'font-family': 'SKTTmap500',
  placement: [
    {
      'offset-y': '3',
      'align-y': 'top',
    },
  ],
  stroke: '#ffffff',
  'stroke-width': '1',
  'stroke-opacity': '0',
};

const STACK_DEFAULT_SPEC: StackSpec = {
  id: -1,
  code: 'EXP_EXP_LV5',
  name: '주기5-일반주기',
  geometryType: 'POINT',
  properties: {
    sortBy: 'grade',
    poi: '1',
  },
  viewLevels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
};

let LAYER_ID = 10000;
let STACK_ID = 101;

export const useMapTextOverlay = () => {
  const {map, firstRenderTime} = useVSMInterfaceConsumer();
  const refLayerId = useRef(0);
  const refStackId = useRef(0);
  const [overlayText, setOverlayText] = useState({label: '', lat: 0, lon: 0});

  const updateOverlay = useCallback((newOverlay) => {
    setOverlayText(newOverlay);
  }, []);

  const clearOverlay = useCallback(() => {
    setOverlayText({label: '', lat: 0, lon: 0});
  }, []);

  useOnce(map, () => {
    refLayerId.current = LAYER_ID++;
    refStackId.current = STACK_ID++;

    map?.once(Vsm.Map.EventNames.StyleLoad, () => {
      map?.addStyle(refLayerId.current, {
        [refStackId.current]: [
          {
            id: 0,
            condition: '',
            label: LABEL_STYLE,
          },
        ],
      });
    });
  });

  useEffect(() => {
    if (firstRenderTime && overlayText.label && overlayText.lat && overlayText.lon) {
      map?.addLayer({
        id: refLayerId.current,
        name: '커스텀 주기',
        map: {
          format: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [overlayText.lon, overlayText.lat],
            },
            properties: {
              grade: '0', // 우선순위 결정
              maxLevel: '21',
              minLevel: '0',
              name1: overlayText.label,
              type: '1',
              stackId: refStackId.current,
            },
          },
        },
        stacks: [
          {
            ...STACK_DEFAULT_SPEC,
            id: refStackId.current,
          },
        ],
      });
    }

    return () => {
      map?.removeLayer(refLayerId.current).catch((e) => {
        devLog(`fail removeLayer (id: ${refLayerId.current})`);
      });
    };
  }, [firstRenderTime, map, overlayText.label, overlayText.lat, overlayText.lon]);

  return {
    updateOverlay,
    clearOverlay,
  };
};
