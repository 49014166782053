import axios from 'axios';
import {EApiDataCode, NETWORK_TIMEOUT} from 'constant/Api';
import {isRtgEnv} from 'constant/Env';
import {API_HOST, FRONTMAN_HOST, POI_API_HOST} from 'constant/Path';

const apiKeyInfo = {
  default: 'DCD01D63E2AA33D69285EA77B546AD88EAF676CB7175F8B610FE2C1AEC134F8E',
  poi: '88e0202326c1540d139e3f3a423a87fe045bb7e1b05531a1380578738be0a30f',
};

export const fetcher = axios.create({
  baseURL: API_HOST,
  timeout: NETWORK_TIMEOUT,
  headers: {
    crossDomain: true,
    'Content-Type': 'application/json',
  },
});

export const fetcherPoi = axios.create({
  baseURL: POI_API_HOST,
  timeout: NETWORK_TIMEOUT,
  headers: {
    crossDomain: true,
    'Content-Type': 'application/json',
  },
});

export const fetcherExt = axios.create({
  timeout: NETWORK_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

const interceptor = (res) => {
  const resultCode = `${(res.status === 200 && res?.data?.code) || res.status}` as EApiDataCode;

  if ([EApiDataCode.API_ERROR, EApiDataCode.API_ERROR2].includes(resultCode)) {
    const errorObject = new Error(res.data.message);

    errorObject['customCode'] = resultCode;

    throw errorObject;
  }

  return res;
};

const errorInterceptor = (error) => {
  error['customCode'] = EApiDataCode.GENERAL_FAIL;

  return Promise.reject(error);
};

fetcher.defaults.headers.common['api-key'] = apiKeyInfo.default;
fetcher.interceptors.response.use(interceptor, errorInterceptor);

fetcherPoi.defaults.headers.common['api-key'] = apiKeyInfo.poi;
fetcherPoi.interceptors.response.use(interceptor, errorInterceptor);

fetcherExt.interceptors.response.use(interceptor, errorInterceptor);

export const frontmanFetcher = axios.create({
  baseURL: FRONTMAN_HOST,
  timeout: NETWORK_TIMEOUT,
  headers: {
    crossDomain: true,
    'Content-Type': 'application/json',
    // https://tmobi.atlassian.net/wiki/spaces/TMAPWEB/pages/524403947/API+frontman
    Using: 'WEB_SEARCH',
  },
});
// poi-web, poi-search의 rtg는 subpath로 구현됨. https://tmobi.atlassian.net/browse/TSSQUARE-1844
frontmanFetcher.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }
  if (config.url?.startsWith('/poi-web')) {
    config.headers['api-key'] = apiKeyInfo.poi;
    if (isRtgEnv) {
      config.url = config.url.replace('/poi-web', '/poi-web/rtg');
    }
  }
  if (config.url?.startsWith('/poi-search')) {
    config.headers['api-key'] = apiKeyInfo.default;
    if (isRtgEnv) {
      config.url = config.url.replace('/poi-search', '/poi-search/rtg');
    }
  }

  return config;
});

export const injectFrontmanHeader = (params = {}) => {
  frontmanFetcher.defaults.headers = {
    ...(frontmanFetcher.defaults.headers || {}),
    ...params,
  };
};

export default fetcher;
