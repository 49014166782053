export const NO_ADDRESS = '주소 정보 없음';
export const NO_WEATHER_DATA = '날씨 정보 없음';
export const NO_DATA = '정보 없음';

export const SHARE_TITLE = '[TMAP]';

export const MSG_WRONG_ACCESS = '유효하지 않은 접근입니다';

export const PAGE_TITLE_PREFIX = '티맵';

export const APP_VERSION_GUIDE_TEXT = '최신버전 설치 후 이용이 가능합니다.';
