import {useCallback, useMemo} from 'react';
import {TPoiDataJson} from '@lcc/tmap-inapp';

import {useAppSelector} from 'ducks/hooks';
import {EAddressType} from 'ducks/userInfo/types';

import {ERPFlag} from 'types/App';
import {TSuggestPoi} from 'types/Search';

import {getDistance} from 'utils/formatter';
import {highlightQuery} from 'utils/search';
import {rpFlagCodeToNum} from 'utils/tmapUtils';

import s from 'styles/components/legacySearch/SuggestItem.module.scss';

type TProps = {
  data: TSuggestPoi;
  onClick?: (poiDataJson: TPoiDataJson) => void;
  highlightRegExp?: RegExp;
};

const SuggestPoiItem = ({data, onClick, highlightRegExp}: TProps) => {
  const {addressType} = useAppSelector((state) => ({
    addressType: state.userInfo.addressType,
  }));

  const address = useMemo(() => {
    if (
      addressType === EAddressType.ROAD &&
      !data.full_address_jibun.startsWith(data.full_address)
    ) {
      // 지번 주소에 도로명 주소가 전방부터 일치하는 경우 도로명 주소가 없다고 판단하여 지번을 사용
      return data.full_address;
    }
    return data.full_address_jibun;
  }, [addressType, data]);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onClick?.({
        pkey: `${data.pkey || ''}`,
        poiId: `${data.poi_id || ''}`,
        navSeq: '',
        poiName: data.keyword,
        navX: `${data.nav_x || ''}`,
        navY: `${data.nav_y || ''}`,
        centerX: `${data.center_x || ''}`,
        centerY: `${data.center_y || ''}`,
        address: address || '',
        tel: '',
        rpFlag: rpFlagCodeToNum(data.rp_flag) || ERPFlag.N_G000,
      });
    },
    [onClick, data, address]
  );

  return (
    <div className={s.suggest_item} onClick={handleClick}>
      <div className={s.line_wrap}>
        <div className={s.name}>{highlightQuery(data.keyword, highlightRegExp)}</div>

        <div className={s.category}>{data.cate_nick_name}</div>
      </div>

      <div className={s.line_wrap}>
        <div className={s.address}>{address}</div>

        {data.distance && <div className={s.distance}>{getDistance(data.distance * 1000, 1)}</div>}
      </div>
    </div>
  );
};

export default SuggestPoiItem;
