import {useState} from 'react';
import classNames from 'classnames';

import {TNaverSARequest} from 'types/Ads';

import useNaverKeywordAd from 'hooks/useNaverKeywordAd';

import NaverKeyword from 'modules/NaverKeyword';
import ADStateViewer, {DEFAULT_STATE, EBannerState, TBannerState} from 'modules/ADStateViewer';

import s from 'styles/pages/ProtoBannerPage.module.scss';

const DEFAULT_QUERY = '맛집';

const ProtoKeywordPage = () => {
  const [query, setQuery] = useState<string>(DEFAULT_QUERY);
  const [keyword, setKeyword] = useState<string>(DEFAULT_QUERY);

  const [saState, setSAState] = useState<TBannerState>(DEFAULT_STATE);
  const [isMock, setMock] = useState<boolean>(false);
  const [viewDefault, setViewDefault] = useState<boolean>(false);

  const {sdkLoaded, adData} = useNaverKeywordAd({
    query: keyword,
    displayPath: '/tmap/search/proto',
    onStart: (params?: TNaverSARequest) => {
      setSAState((prevState) => ({
        ...prevState,
        state: EBannerState.LOADING,
        param: params,
      }));
    },
    onSuccess: (data) => {
      setSAState((prevState) => ({
        ...prevState,
        state: EBannerState.SUCCESS,
        data,
        error: undefined,
      }));
    },
    onError: (error?: any) => {
      setSAState((prevState) => ({
        ...prevState,
        state: EBannerState.ERROR,
        data: undefined,
        error: error.message,
      }));
    },
    isMock,
  });

  return (
    <div className={s.page_wrap}>
      <div className={s.ad_box}>
        <form
          className={s.input_box}
          onSubmit={(e) => {
            setKeyword(query || '');
            e.preventDefault();
          }}
        >
          <input
            type="text"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            className={s.input_el}
          />
          <input type="submit" value="검색" className={s.btn_submit} />

          <button
            className={classNames(s.btn_toggle, {
              [s.active]: isMock,
            })}
            onClick={() => setMock((prev) => !prev)}
          >
            Mock
          </button>

          <button
            className={classNames(s.btn_toggle, {
              [s.active]: viewDefault,
            })}
            onClick={() => setViewDefault((prev) => !prev)}
          >
            default
          </button>
        </form>

        <h3>type A</h3>
        <div className={classNames(s.banner_box, s.keyword_box)}>
          {adData && <NaverKeyword data={adData} />}
        </div>

        {viewDefault && (
          <>
            <h3>default</h3>
            <div className={s.banner_box}>
              <NaverKeyword />
            </div>
          </>
        )}

        <ADStateViewer title="Naver SA" state={saState} sdkLoaded={sdkLoaded} isColumn={true} />
      </div>
    </div>
  );
};

export default ProtoKeywordPage;
