import {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {ESortOption} from 'types/Search';
import {ESortLabel} from 'constant/Search';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import useLogger from 'hooks/useLogger';
import useDimmedQuery from 'hooks/useDimmedQuery';
import useLocationSort, {LOCATION_SORT_LIST} from 'hooks/useLocationSort';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import CustomSelectLayer from 'components/CustomSelectLayer';
import {IcoArrowDown} from 'components/@tmds/icons/IcoArrowDown';
import {TSearchResultSortParam} from 'components/search/SearchResultHeader';

import s from 'styles/components/search/SearchResultSort.module.scss';

const SEARCH_SORT_LIST = [{id: ESortOption.SCORE, isDefault: true}, {id: ESortOption.DISTANCE}].map(
  (n) => ({...n, label: ESortLabel[n.id]})
);

type TProps = {
  pageHide?: boolean;
  onChange?: (param: TSearchResultSortParam) => void;
};

const SearchResultSortPoi = ({pageHide, onChange}: TProps) => {
  const dispatch = useAppDispatch();
  const {queries} = useParseQueryLocation();
  const {sendClickLogWithMapView, sendClickLog} = useLogger();
  const {nowLocationItem, updateLocationItem} = useLocationSort();

  const {nowSort, searchList} = useAppSelector((state) => ({
    nowSort: state.search.sort,
    searchList: state.search.searchList,
  }));

  const nowSortItem = useMemo(() => SEARCH_SORT_LIST.find((v) => v.id === nowSort), [nowSort]);

  const sortDimmed = useDimmedQuery({filter: 'sort'});
  const locationDimmed = useDimmedQuery({filter: 'location'});

  const handleClickSortItem = useCallback(
    (item) => {
      dispatch(actions.search.updateSort(item.id));
      sortDimmed.close();

      onChange?.({sort: item.id});
    },
    [dispatch, sortDimmed, onChange]
  );

  const handleClickLocationItem = useCallback(
    (item) => {
      const type = item.id;

      sendClickLog(item.actionId);
      onChange?.({locationType: type});
      updateLocationItem(type);
      locationDimmed.close();
    },
    [onChange, locationDimmed, updateLocationItem, sendClickLog]
  );

  const handleClickLocationType = useCallback(() => {
    if (!nowLocationItem) {
      return;
    }
    sendClickLog('tap.search_location');
  }, [nowLocationItem, sendClickLog]);

  const handleClickSort = useCallback(() => {
    sendClickLogWithMapView(
      'tap.order',
      {
        search_query: queries.searchQuery,
      },
      {includeTicketId: true}
    );
  }, [sendClickLogWithMapView, queries]);

  return (
    <>
      <div className={s.sort_wrap}>
        <Link
          className={s.sort_item}
          to={locationDimmed.openLocation}
          role="button"
          onClick={handleClickLocationType}
          aria-label={`현재 ${nowLocationItem!.label} 정렬결과입니다. 정렬결과 변경하기`}
        >
          <span className={s.label}>{nowLocationItem?.label}</span>
          <IcoArrowDown width={12} height={12} color="gray700" />
        </Link>

        {searchList.length > 0 && (
          <Link
            className={s.sort_item}
            to={sortDimmed.openLocation}
            onClick={handleClickSort}
            role="button"
            aria-label={`현재 ${nowSortItem!.label} 정렬결과입니다. 정렬결과 변경하기`}
          >
            <span className={s.label}>{nowSortItem!.label}</span>
            <IcoArrowDown width={12} height={12} color="gray700" />
          </Link>
        )}
      </div>
      {!pageHide && (
        <>
          <CustomSelectLayer
            isOpen={sortDimmed.isOpen}
            options={SEARCH_SORT_LIST}
            selectedItem={nowSortItem}
            onClickItem={handleClickSortItem}
          />
          <CustomSelectLayer
            isOpen={locationDimmed.isOpen}
            options={LOCATION_SORT_LIST}
            selectedItem={nowLocationItem}
            onClickItem={handleClickLocationItem}
          />
        </>
      )}
    </>
  );
};

export default SearchResultSortPoi;
