import {PropsWithChildren} from 'react';
import s from '../../../styles/components/tplacehome/TPlaceDiscoveryColumnPoiItem.module.scss';

const DiscoveryWrap = ({children}: PropsWithChildren<{}>) => {
  return (
    <div className={s.wrap} data-discovery-wrap>
      {children}
    </div>
  );
};

export default DiscoveryWrap;
