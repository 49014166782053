import {useMemo, useState} from 'react';
import classNames from 'classnames';

import {isProdEnv} from 'constant/Env';

import s from 'styles/components/BannerInfo.module.scss';
import {TBannerDebugInfo} from 'types/Ads';
import {EAdType} from 'constant/Ads';
import Storage from '@lcc/web-storage';

type TProps = {
  nowAd: string;
  startTime: number;
  infos?: TBannerDebugInfo[];
  originAdStep: EAdType[];
};

export const getDevAdItem = () => {
  return Storage.getItem('AD_STEP');
};

const BannerInfo = ({nowAd, startTime, infos, originAdStep}: TProps) => {
  const [viewBuildInfo, setViewBuildInfo] = useState<boolean>(false);
  const [viewAdStepInfo, setAdStepInfo] = useState<boolean>(false);
  const [newStep, setNewStep] = useState<EAdType[]>([]);
  const devStepItems = useMemo(() => getDevAdItem() || [], []);

  if (isProdEnv) {
    return null;
  }

  return !viewBuildInfo ? (
    <div
      className={s.debug_info}
      onClick={() => setViewBuildInfo(true)}
      role="presentation"
      aria-hidden="true"
    >
      Banner Info [{nowAd}]
    </div>
  ) : (
    <>
      {viewAdStepInfo && (
        <div className={s.order_info}>
          <button className={s.close} onClick={() => setAdStepInfo(false)}>
            닫기
          </button>
          <h4>기본 설정값</h4>
          <ul className={s.step}>
            <p>{[...originAdStep, EAdType.NONE].join(' > ')}</p>
          </ul>
          <h4>테스트용 설정값</h4>
          <ul className={s.step}>
            <p>{devStepItems.length > 0 ? [...devStepItems, EAdType.NONE].join(' > ') : '없음'}</p>
            {devStepItems.length > 0 && (
              <button
                className={s.reset}
                onClick={() => {
                  Storage.removeItem('AD_STEP');
                  window.location.reload();
                }}
              >
                기본광고 순서로 변경
              </button>
            )}
          </ul>
          <h4>테스트용 설정값 변경</h4>
          <ul className={s.step}>
            {originAdStep.map((type) => (
              <li key={type}>
                <input
                  type="checkbox"
                  onChange={() => {
                    setNewStep((prev) => {
                      if (prev.includes(type)) {
                        return prev.filter((t) => t !== type);
                      }
                      return [...prev, type];
                    });
                  }}
                />
                {type}
              </li>
            ))}
            <li>
              <input type="checkbox" checked disabled />
              {EAdType.NONE}
            </li>
          </ul>
          {newStep.length > 0 && (
            <ul className={s.step}>
              <p>{[...newStep, EAdType.NONE].join(' > ')}</p>
              <button
                onClick={() => {
                  Storage.setItem('AD_STEP', newStep);
                  window.location.reload();
                }}
              >
                현재 순서로 변경하기
              </button>
            </ul>
          )}
        </div>
      )}
      <div className={s.debug_info} onClick={() => setViewBuildInfo(false)}>
        <div className={classNames(s.debug_box, s.header)}>
          <div>타입</div>
          <div>시작</div>
          <div>start</div>
          <div>show</div>
          <div>timeout</div>
          <div>error</div>
        </div>

        {infos?.map((n) => (
          <div className={s.debug_box} key={n.type}>
            <div>{n.type}</div>

            {[n.setTime, n.startTime, n.showTime, n.timeoutTime, n.errorTime].map((t, i) => (
              <div key={i}>
                {t ? (
                  <>
                    {n.startTime ? t - n.startTime : 0}
                    <span className={s.global_time}>{t - startTime}</span>
                  </>
                ) : (
                  '-'
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={s.reload_btn} onClick={() => window.location.reload()}>
        새로고침
      </div>
      <div className={s.order_btn} onClick={() => setAdStepInfo(true)}>
        배너 순서 변경
      </div>
    </>
  );
};

export default BannerInfo;
