import {PropsWithChildren} from 'react';
import s from '../../../styles/components/tplacehome/TPlaceDiscovery.module.scss';

const TopInfoBox = (props: PropsWithChildren<{}>) => {
  return (
    <div className={s.caption}>
      <div className={s.text}>{props.children}</div>
    </div>
  );
};

export default TopInfoBox;
