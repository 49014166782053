import {useAppSelector} from 'ducks/hooks';
import {ETPlaceCurationType} from 'ducks/remote/type';
import TPlaceCurationType1 from 'components/tplacehome/TPlaceCurationType1';
import TPlaceCurationType2 from 'components/tplacehome/TPlaceCurationType2';
import TPlaceCurationType3 from 'components/tplacehome/TPlaceCurationType3';
import {useRemoteConfig} from 'hooks/useRemoteConfig';
import {PLACE_CONFIG} from 'constant/Firebase';

const ProtoCurationPage = () => {
  const {remote} = useAppSelector((state) => state);

  useRemoteConfig(PLACE_CONFIG);

  return (
    <div>
      {remote.tplaceContentsList.map((v, i) => {
        if (v.type === ETPlaceCurationType.TYPE_1) {
          return <TPlaceCurationType1 key={i} item={v} />;
        }

        if (v.type === ETPlaceCurationType.TYPE_2) {
          return <TPlaceCurationType2 key={i} item={v} />;
        }

        if (v.type === ETPlaceCurationType.TYPE_3) {
          return (
            <div style={{marginTop: 100}}>
              <TPlaceCurationType3 key={i} item={v} />
            </div>
          );
        }

        if (v.type === ETPlaceCurationType.TYPE_4) {
          return null;
        }

        return null;
      })}
    </div>
  );
};

export default ProtoCurationPage;
