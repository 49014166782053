import {isProdEnv, isRtgEnv} from 'constant/Env';
import {useAppSelector} from 'ducks/hooks';

const BuildInfoTempZoom = () => {
  const zoom = useAppSelector((state) => state.map.zoom);
  if (isProdEnv || isRtgEnv) {
    return null;
  }
  return (
    <div
      style={{
        fontSize: '10px',
        position: 'absolute',
        left: '10px',
        bottom: '46px',
        zIndex: 1000,
        color: 'red',
        backgroundColor: 'white',
      }}
    >{`ZOOM: ${(zoom && zoom.toFixed(3)) || ''}`}</div>
  );
};

export default BuildInfoTempZoom;
