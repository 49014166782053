import {datadogRum} from '@datadog/browser-rum';
import {datadogLogs} from '@datadog/browser-logs';
import {APP_SERVER_NAME, isLocalEnv, isProdEnv} from 'constant/Env';
import pkg from '../../package.json';

const SITE_NAME = 'datadoghq.com';
const SERVICE_NAME = 'tmap-search-web';
const SESSION_SAMPLE_RATE = 0.05;

if (!isLocalEnv) {
  datadogRum.init({
    applicationId: '668b033b-fefa-4ba2-9265-6ec076377be3',
    clientToken: 'pube10aaef3da26c8cfba43e0381cf23bff',
    site: SITE_NAME,
    service: SERVICE_NAME,
    env: APP_SERVER_NAME,
    version: pkg.version,
    sessionSampleRate: SESSION_SAMPLE_RATE,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogLogs.init({
    clientToken: 'pube83a2fc5c56cd1dbd3dd89084a58b8a3',
    site: SITE_NAME,
    forwardErrorsToLogs: true,
    sessionSampleRate: SESSION_SAMPLE_RATE,
    service: SERVICE_NAME,
    env: APP_SERVER_NAME,
    version: pkg.version,
  });
}

if (isProdEnv) {
  datadogRum.startSessionReplayRecording();
}
