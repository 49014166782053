import {useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {TSearchPoi, TPoiGroupSub} from 'types/App';
import {EMarkerType} from 'types/Map';
import {filterCustomMarker} from 'utils/marker';
import useMapMarkerConfig from './useMapMarkerConfig';
import {ESearchCollectionType} from 'ducks/search/types';
import useCalloutInfoMarker from './useCalloutInfoMarker';

type TItem = TSearchPoi | TPoiGroupSub;

const parseItem = ({type, anchor, ...item}) => ({
  properties: item,
  type,
  description: item.listName,
  label: item.listName || item.customName,
  lonLat: item.lonLat || {
    lon: item.wgs84CenterX,
    lat: item.wgs84CenterY,
  },
  anchor,
  children: item.children,
});

const useSearchResultMarker = () => {
  const {rdSearch, activePoi, mapStyle, collectionType} = useAppSelector((state) => ({
    rdSearch: state.search,
    activePoi: state.userInteraction.activePoi,
    mapStyle: state.map.style,
    collectionType: state.search.data.collectionType,
  }));

  const {markerStyleConfig} = useMapMarkerConfig();

  const calloutInfoMarker = useCalloutInfoMarker();

  const searchResultMarkers = useMemo(
    () =>
      rdSearch.searchList
        // https://tmobi.atlassian.net/browse/SEARCH-21 추천검색 subPoi 마커 미노출
        .reduce((arr, l) => {
          const isLtrYn = rdSearch.data?.ltrYn;
          const groupSubList = isLtrYn ? [] : (l as TSearchPoi)?.groupSubList || [];

          return [...arr, l, ...groupSubList] as TItem[];
        }, [] as TItem[])
        .map((v) => {
          const active = !calloutInfoMarker && v.listId === activePoi;
          const {marker: customMarker, label} = filterCustomMarker(v, active, {
            mapStyle,
            markerConfig: markerStyleConfig,
          });
          const activeResultMarker =
            collectionType === ESearchCollectionType.BUS_STATION
              ? EMarkerType.ACTIVE_BUS_STATION
              : EMarkerType.ACTIVE;

          const item = parseItem({
            ...v,
            description: label || v.listName,
            type: active ? activeResultMarker : EMarkerType.INACTIVE,
            anchor: active ? 'bottom' : 'center',
            children: customMarker,
            isActive: active,
          });

          return item;
        }),
    [
      activePoi,
      calloutInfoMarker,
      collectionType,
      mapStyle,
      markerStyleConfig,
      rdSearch.data?.ltrYn,
      rdSearch.searchList,
    ]
  );

  const activeMarker = useMemo(
    () => calloutInfoMarker || searchResultMarkers.find((item) => item.properties.isActive),
    [calloutInfoMarker, searchResultMarkers]
  );

  return {
    calloutInfoMarker,
    searchResultMarkers,
    activeMarker,
  };
};

export default useSearchResultMarker;
