import {useCallback, useMemo} from 'react';
import actions from 'ducks/actions';
import {useHistory} from 'react-router';
import TMapSender, {TPoiDataJson} from '@lcc/tmap-inapp';
import {HybridBridge} from 'utils/searchBar';

import {
  ERPFlag,
  EAppExtraCode,
  EAppRequestMode,
  EAppRequestTypeNew,
  TPoiDetailParam,
} from 'types/App';

import {Paths} from 'constant/RoutePath';
import {ETLAServiceId} from 'constant/Log';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import useThrottle from 'hooks/useThrottle';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import usePageInfo from 'hooks/usePageInfo';
import useFavorite from 'hooks/useFavorite';
import {
  getDetailPageActionButton,
  getPoiDetailLandingParam,
  isFromEventPage,
  isFromReport,
  isFromRoute,
} from 'utils/tmapUtils';
import {generateUrl, moveToUrl} from 'utils/url';
import {getGeneralTime} from 'utils/date';
import {parseAppAccessParam, parsePoiInfoToNavInfo} from 'utils/search';
import useAppScheme from './useAppScheme';

export enum EFromType {
  SUGGEST = 'SUGGEST',
  FAVORITE = 'FAVORITE',
  RECENT = 'RECENT',
  RESULT = 'RESULT',
  SEARCH_MAIN = 'SEARCH_MAIN',
  PICK_ON_MAP = 'PICK_ON_MAP',
  HYBRID_SEARCH_BAR = 'HYBRID_SEARCH_BAR',
  ADDRESS = 'ADDRESS',
}

export enum EResultType {
  MAIN_ITEM = 'MAIN_ITEM',
  SUB_ITEM = 'SUB_ITEM',
  PARAM_POI = 'PARAM_POI',
  FAVORITE = 'FAVORITE',
}

type TProps = {
  from?: EFromType;
};

const useMoveToTarget = ({from}: TProps = {}) => {
  const ableToRun = useThrottle();
  const history = useHistory();
  const {queries, originQueries, tailParam} = useParseQueryLocation();
  const dispatch = useAppDispatch();
  const {isHybrid, sendCar, userPosition} = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
    sendCar: state.userInfo.sendDestinationToCar,
    userPosition: state.map.userPosition,
  }));
  const {isPlaceMainPage, isPlaceCategoryPage} = usePageInfo();
  const {checkIsFavorite} = useFavorite();
  const inApp = useAppScheme();

  const {reqMode, reqType, extra} = useMemo(() => parseAppAccessParam(queries), [queries]);

  const actionButton = useMemo(
    () => getDetailPageActionButton({extra, reqMode, reqType}),
    [extra, reqMode, reqType]
  );

  const getSearchUrl = useCallback(
    (query, extraParam = {}, moveToHybrid = false) => {
      const resultUrl = generateUrl(moveToHybrid ? Paths.SearchHybridResult : Paths.SearchResult, {
        searchQuery: query.trim(),
        query: originQueries.query,
        reqMode: originQueries.reqMode,
        reqType: originQueries.reqType,
        extra: originQueries.extra,
        tailParam: originQueries.tailParam,
        // 개발용 파라미터도 연동
        tak: originQueries.tak,
        tuk: originQueries.tuk,
        tfav: originQueries.tfav,
        trdes: originQueries.trdes,
        ...extraParam,
      });

      return resultUrl;
    },
    [originQueries]
  );

  const moveToSearch = useCallback(
    (query: string, force: boolean = false, pushHistory: boolean = true) => {
      if (!query.trim()) {
        return;
      }

      if (force || ableToRun()) {
        const resultUrl = getSearchUrl(query, {from}, isHybrid);

        dispatch(actions.userInfo.setSearchSessionKey());

        if (pushHistory) {
          history.push(resultUrl);
        } else {
          history.replace(resultUrl);
        }

        if (isHybrid && from !== EFromType.HYBRID_SEARCH_BAR) {
          HybridBridge.moveToResult({
            query: query,
            date: getGeneralTime(),
          });
        }
      }
    },
    [ableToRun, history, getSearchUrl, dispatch, isHybrid, from]
  );

  // tmap://search?name=
  const fromDeepLinkToSearch = useCallback(
    (query) => {
      if (!query.trim()) {
        return;
      }

      if (ableToRun()) {
        const resultUrl = getSearchUrl(
          query,
          {
            fromDeepLink: true,
          },
          isHybrid
        );

        dispatch(actions.userInfo.setSearchSessionKey());

        history.push(resultUrl);

        if (isHybrid) {
          HybridBridge.moveToResult({
            query: query,
            date: getGeneralTime(),
          });
        }
      }
    },
    [ableToRun, getSearchUrl, isHybrid, dispatch, history]
  );

  // 이동전 케이스별 예외처리
  const moveToDestinationByAction = useCallback(
    (poiDataJson: TPoiDataJson) => {
      if (EAppRequestMode.DRIVING === reqMode) {
        dispatch(actions.userInteraction.setDrivingPoiDataJson(poiDataJson));
        return true;
      }
      if (isFromRoute(reqMode)) {
        if (reqType === EAppRequestTypeNew.DEPARTURE) {
          TMapSender.setDepartureOfRoute(poiDataJson, reqMode);
          return true;
        }

        if (reqType === EAppRequestTypeNew.DESTINATION) {
          TMapSender.setDestinationOfRoute(poiDataJson, reqMode);
          return true;
        }

        if (
          reqType >= EAppRequestTypeNew.MIN_WAYPOINT &&
          reqType <= EAppRequestTypeNew.MAX_WAYPOINT
        ) {
          TMapSender.setViaOfRoute(
            {
              ...poiDataJson,
              rpFlag: from === EFromType.PICK_ON_MAP ? ERPFlag.N_I010 : poiDataJson.rpFlag,
            },
            reqType
          );
          return true;
        }
      }

      // TODO jibun, road 구분
      if (EAppRequestMode.FAVORITE === reqMode) {
        // https://tmobi.atlassian.net/browse/TMAPONE-395
        const p =
          poiDataJson.publicTransportType === 'busstop'
            ? {...poiDataJson, poiId: poiDataJson.pkey}
            : poiDataJson;

        TMapSender.setHomeOffice(p);
        return true;
      }

      if (isFromReport(reqMode, originQueries.extra, originQueries.query)) {
        TMapSender.setSearchResult(poiDataJson);
        return true;
      }

      if (isFromEventPage(reqMode)) {
        TMapSender.setSearchResult(poiDataJson);
        return true;
      }

      return false;
    },
    [dispatch, reqMode, reqType, from, originQueries]
  );

  // 상세페이지
  const moveToDetail = useCallback(
    async (data: TPoiDetailParam, overrideParams: Omit<TPoiDetailParam, 'tailParam'> = {}) => {
      if (ableToRun()) {
        let isFavoriteItem = false;

        try {
          isFavoriteItem = (await inApp.getFavoriteState(data)) as boolean;
        } catch (e) {
          isFavoriteItem = false;
        }

        const nextTailParam = {
          reqMode: originQueries.reqMode,
          reqType: originQueries.reqType || EAppRequestMode.MAIN,
          extra: originQueries.extra || EAppExtraCode.SEARCH_T_MAP,
          favorite: isFavoriteItem ? 'Y' : 'N',
          sendCar,
          ...tailParam,
        };

        if (isPlaceMainPage) {
          nextTailParam.log_all_referrer = ETLAServiceId.PLACE;
        } else if (isPlaceCategoryPage) {
          nextTailParam.log_all_referrer = ETLAServiceId.NEARBY;
        } else {
          nextTailParam.log_all_referrer = ETLAServiceId.SEARCH;
        }

        nextTailParam.query = originQueries.searchQuery;

        const buttonType = getDetailPageActionButton({
          extra: nextTailParam.extra,
          reqType: nextTailParam.reqType,
          reqMode: nextTailParam.reqMode,
        });

        nextTailParam.buttonType = buttonType.join('_');

        const {url} = getPoiDetailLandingParam(data, {
          ...nextTailParam,
          ...overrideParams,
          tailParam: JSON.stringify(nextTailParam),
        });

        moveToUrl(url, true);
      }
    },
    [
      ableToRun,
      originQueries.reqMode,
      originQueries.reqType,
      originQueries.extra,
      originQueries.searchQuery,
      tailParam,
      isPlaceMainPage,
      isPlaceCategoryPage,
      sendCar,
      checkIsFavorite,
    ]
  );

  // 상세페이지: 케이스별 예외처리
  const moveToSelectDetailAction = useCallback(
    (poiJson: TPoiDataJson, overrideParams: Omit<TPoiDetailParam, 'tailParam'> = {}) => {
      if (ableToRun()) {
        const movedByAction = moveToDestinationByAction(poiJson);
        if (!movedByAction) {
          moveToDetail(poiJson, overrideParams);
        }
      }
    },
    [ableToRun, moveToDestinationByAction, moveToDetail]
  );

  // 길찾기
  const moveToDestination = useCallback(
    (poiDataJson: TPoiDataJson, nowReqMode?: EAppRequestMode) => {
      if (ableToRun()) {
        TMapSender.setDestinationOfRoute(poiDataJson, nowReqMode || reqMode);
      }
    },
    [ableToRun, reqMode]
  );

  // 길찾기: 케이스별 예외처리
  const moveToSelectDestinationAction = useCallback(
    (poiJson: TPoiDataJson, nowReqMode?: EAppRequestMode) => {
      if (ableToRun()) {
        const movedByAction = moveToDestinationByAction(poiJson);
        if (!movedByAction) {
          moveToDestination(poiJson, nowReqMode);
        }
      }
    },
    [ableToRun, moveToDestination, moveToDestinationByAction]
  );

  // 즐겨찾기 경로
  const moveToDepartDestination = useCallback(
    (departure: TPoiDataJson, destination: TPoiDataJson) => {
      if (ableToRun()) {
        TMapSender.setFavoriteRoute(departure, destination);
      }
    },
    [ableToRun]
  );

  // 대중교통 길찾기: 케이스별 예외처리
  const moveToPublicTransportDestination = useCallback(
    (poiDataJson: TPoiDataJson) => {
      if (ableToRun()) {
        const movedByAction = moveToDestinationByAction(poiDataJson);
        if (!movedByAction) {
          TMapSender.setPublicTransportRoute(
            parsePoiInfoToNavInfo({
              pkey: '',
              poiId: '',
              navSeq: '',
              navX: `${userPosition?.lon || ''}`,
              navY: `${userPosition?.lat || ''}`,
              centerX: `${userPosition?.lon || ''}`,
              centerY: `${userPosition?.lat || ''}`,
              rpFlag: 0,
              poiName: '',
              address: '',
              tel: '',
            }),
            parsePoiInfoToNavInfo(poiDataJson)
          );
        }
      }
    },
    [ableToRun, moveToDestinationByAction, userPosition]
  );

  // 버스정류장 상세: 케이스별 예외처리
  const moveToBusStationDetail = useCallback(
    (poiDataJson: TPoiDataJson) => {
      if (!ableToRun()) {
        return;
      }

      if (!poiDataJson.stationId) {
        return;
      }

      const movedByAction = moveToDestinationByAction(poiDataJson);
      if (!movedByAction) {
        inApp.openBusStationDetail({
          name: poiDataJson.poiName,
          poiId: poiDataJson.poiId,
          centerX: poiDataJson.centerX,
          centerY: poiDataJson.centerY,
          stationId: poiDataJson.stationId,
          reqMode,
        });
      }
    },
    [moveToDestinationByAction, ableToRun, reqMode, inApp]
  );

  return {
    reqMode,
    reqType,
    extra,

    actionButton,

    moveToSearch,

    moveToDetail,
    moveToSelectDetailAction,

    moveToDestination,
    moveToSelectDestinationAction,
    moveToPublicTransportDestination,
    moveToBusStationDetail,
    moveToDepartDestination,

    fromDeepLinkToSearch,
  };
};

export default useMoveToTarget;
