import {useEffect, useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import DiscoverySection from './DiscoverySection';
import DiscoveryColumnPoiItem from './DiscoveryColumnPoiItem';

import s from '../../../styles/components/tplacehome/TPlaceDiscovery.module.scss';
import InView from 'react-intersection-observer';
import usePlaceHome from 'hooks/usePlaceHome';
import useFavorite from 'hooks/useFavorite';

const DiscoverySimilar = () => {
  const {tplacehome, userInfo} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();
  const {checkIsFavoriteFromList, getFavoriteGroup} = useFavorite();

  const poiList = useMemo(() => {
    const list = tplacehome.discoveryData.result.data.similarList.slice(0, 4);

    if (list.length <= 1) {
      return null;
    }
    return list.length % 2 === 0 ? list : list.slice(0, 2);
  }, [tplacehome]);

  useEffect(() => {
    if (!poiList || poiList.length === 0) {
      return;
    }

    const list = poiList.map((item) => ({
      poiId: item.poiId.toString(),
      pkey: item.pkey,
    }));

    getFavoriteGroup(list);
  }, [poiList]);

  if (!poiList) {
    return null;
  }
  //TODO: 10.10.0 이상부터 groupId 받아서 앱스킴으로 data를 favorite 체크하는 로직 추가
  return (
    <DiscoverySection mainTitle={'#내 취향 맛집'} subTitle={'내가 자주간 곳과 비슷한'}>
      <InView
        onChange={(isView) => isView && placehomeHook.sendEventDiscovery('view.my_taste_poi')}
      >
        <ul className={s.grid_list}>
          {poiList.map((item, index) => {
            return (
              <li key={index} className={s.cell}>
                <DiscoveryColumnPoiItem
                  data={item}
                  thumbHeight={110}
                  onClickDetail={() =>
                    placehomeHook.sendEventDiscovery('tap.my_taste_poi', {
                      euk: userInfo.euk,
                      pkey: item.pkey,
                    })
                  }
                  hasFavorite={checkIsFavoriteFromList(item)}
                />
              </li>
            );
          })}
        </ul>
      </InView>
    </DiscoverySection>
  );
};

export default DiscoverySimilar;
