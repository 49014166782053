import {useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useParseQueryLocation} from './useParseQueryLocation';

const DIMMED_QUERY_KEY = 'dimmed';
const DIMMED_QUERY_VALUE = 'true';

const DIMMED_OPEN_STATE = {[DIMMED_QUERY_KEY]: DIMMED_QUERY_VALUE};

export enum EDimmedLayerId {
  VERTICAL_POPUP = 'VERTICAL_POPUP',
}

const useDimmedQuery = (uniqueQuery = {}) => {
  const history = useHistory();
  const {location: openLocation, originQueries: currentQueries} = useParseQueryLocation({
    ...DIMMED_OPEN_STATE,
    ...uniqueQuery,
  });
  const isOpen = useMemo(() => {
    const isOpenDimmed = currentQueries[DIMMED_QUERY_KEY] === DIMMED_QUERY_VALUE;
    const isUniqueMatch = Object.keys(uniqueQuery).reduce((prev, cur) => {
      return prev && uniqueQuery[cur] === currentQueries[cur];
    }, true);

    return isOpenDimmed && isUniqueMatch;
  }, [currentQueries, uniqueQuery]);

  return {
    openLocation,
    isOpen,
    open: (newQuery = {}) => {
      let updatedUrl = openLocation;

      Object.entries(newQuery).forEach(([key, value]) => {
        updatedUrl += `${updatedUrl.includes('?') ? '&' : '?'}${key}=${value}`;
      });
      history.push(updatedUrl);
    },
    close: () => history.goBack(),
  };
};

export default useDimmedQuery;
