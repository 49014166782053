import {useCallback, useMemo, useRef} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import useDimmedQuery from 'hooks/useDimmedQuery';
import {TabHeader} from 'modules/Tabs';
import {sendSearchClickLog} from 'utils/logManager';
import ua from 'utils/uaParser';
import CustomSelectLayer from 'components/CustomSelectLayer';
import {ELegacyFavoriteFilter, ELegacyFavoriteSortOption} from 'types/LegacySearch';
import {ELegacyFavoriteSortLabel} from 'constant/LegacySearch';

import {ReactComponent as IconSorting} from 'resource/images/@tmds_basic/ico_sort.svg';

import s from 'styles/components/legacySearch/FavoriteHeader.module.scss';

const FAVORITE_SORT_LIST = [
  {id: ELegacyFavoriteSortOption.REG_DATE, isDefault: true},
  {id: ELegacyFavoriteSortOption.ALPHABET},
].map((n) => ({...n, label: ELegacyFavoriteSortLabel[n.id]}));

type TProps = {
  onChangeSort: (sort: ELegacyFavoriteSortOption) => void;
  onClick: VoidFunction;
  sort: Nullable<ELegacyFavoriteSortOption>;
  noResult: boolean;
};

const FavoriteHeader = ({onChangeSort, onClick, sort, noResult}: TProps) => {
  const nowSortItem = useMemo(() => FAVORITE_SORT_LIST.find((v) => v.id === sort), [sort]);

  const sortDimmed = useDimmedQuery({filter: 'favorite-sort'});

  const sortButtonRef = useRef<HTMLAnchorElement>(null);

  const handleClickSortItem = useCallback(
    (item) => {
      sortDimmed.close();
      sortButtonRef?.current?.focus();

      onChangeSort?.(item.id);
    },
    [sortDimmed, onChangeSort]
  );

  return (
    <div
      className={classNames(s.favorite_header, {
        [s.no_result]: noResult,
      })}
    >
      <div
        className={classNames(s.tab_header_wrap, {
          [s.is_ios]: ua.isIos,
        })}
        role="tablist"
      >
        <TabHeader
          className={s.tab_item}
          activeClassName={s.active}
          tabKey={ELegacyFavoriteFilter.POI}
          onClick={() => sendSearchClickLog('tap.poi')}
        >
          장소
        </TabHeader>

        <TabHeader
          className={s.tab_item}
          activeClassName={s.active}
          tabKey={ELegacyFavoriteFilter.ROUTE}
          onClick={() => sendSearchClickLog('tap.route')}
        >
          경로
        </TabHeader>
      </div>
      {!noResult && (
        <Link
          className={s.sort_wrap}
          to={sortDimmed.openLocation}
          onClick={onClick}
          role="button"
          aria-label={`${nowSortItem?.label} 정렬 변경하기`}
          aria-haspopup="true"
          ref={sortButtonRef}
        >
          <IconSorting />
          <span className={s.text}>{nowSortItem?.label}</span>
        </Link>
      )}
      <CustomSelectLayer
        isOpen={sortDimmed.isOpen}
        options={FAVORITE_SORT_LIST}
        selectedItem={nowSortItem}
        onClickItem={handleClickSortItem}
      />
    </div>
  );
};

export default FavoriteHeader;
