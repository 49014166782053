import TMapSender from '@lcc/tmap-inapp';
import ModalPopup from './ModalPopup';
import {useEffect} from 'react';

type TProps = {
  detailComponent: JSX.Element;
  onClickClose: () => void;
  onClickNext?: () => void;
  onClickConfirm?: () => void;
  onMount?: () => void;
};

const TMAP_UPDATE_URL = 'https://www.tmap.co.kr/tmap2/mobile/tmap.jsp';

const AppVersionPopup = ({
  detailComponent,
  onClickClose,
  onClickNext,
  onClickConfirm,
  onMount,
}: TProps) => {
  useEffect(() => {
    onMount?.();
  }, []);

  return (
    <ModalPopup
      title="최신 버전 업데이트"
      detailComponent={detailComponent}
      onClickClose={onClickClose}
      buttons={[
        {
          children: '다음에 하기',
          onClick: onClickNext,
        },
        {
          type: 'confirm',
          children: '업데이트',
          onClick: () => {
            onClickConfirm?.();
            TMapSender.openBrowser(TMAP_UPDATE_URL);
          },
        },
      ]}
    />
  );
};

export default AppVersionPopup;
