import {useCallback} from 'react';
import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {fetchTNowList, fetchTNowAutoSearch} from 'ducks/tnow/slice';
import {SLIDER_LIST, WHOLE_AREA} from 'ducks/tnow/types';

// https://tmobi.atlassian.net/browse/LCC-2138
const MAX_RADIUS = 10000; // 1km
const MAX_EXPAND_COUNT = 10;

const useTNowSearch = () => {
  const dispatch = useAppDispatch();
  const {baseSpot, rdUserInfo} = useAppSelector((state) => ({
    baseSpot: state.tNow.baseSpot,
    rdUserInfo: state.userInfo,
  }));
  const {lon, lat, radius, fixedRadius} = baseSpot;
  const resetList = useCallback(() => {
    dispatch(actions.userInteraction.setDragMap(false));
    dispatch(actions.tNow.resetList());
  }, [dispatch]);

  const autoSearchFromNextRadius = useCallback(() => {
    if (lon && lat) {
      const currentRadius = SLIDER_LIST.indexOf(radius || WHOLE_AREA);
      const nextRadius = Number(SLIDER_LIST[currentRadius + 1]) || undefined;

      resetList();
      dispatch(
        fetchTNowAutoSearch({
          lon,
          lat,
          // updateFixedRadius: true,
          radius: nextRadius,
          expandCount: 1,
          ticketId: rdUserInfo.sessionKey,
        })
      );
    }
  }, [dispatch, radius, lon, lat, resetList, rdUserInfo.sessionKey]);

  const autoSearch = useCallback(() => {
    if (lon && lat) {
      resetList();
      dispatch(
        fixedRadius
          ? fetchTNowList({lon, lat, radius: fixedRadius, ticketId: rdUserInfo.sessionKey})
          : fetchTNowAutoSearch({
              lon,
              lat,
              radius: SLIDER_LIST[1],
              maxRadius: MAX_RADIUS,
              expandCount: MAX_EXPAND_COUNT,
              ticketId: rdUserInfo.sessionKey,
            })
      );
    }
  }, [dispatch, lon, lat, fixedRadius, resetList, rdUserInfo.sessionKey]);

  const refreshSearch = useCallback(() => {
    if (lon && lat) {
      resetList();
      dispatch(fetchTNowList({lon, lat, radius, ticketId: rdUserInfo.sessionKey}));
    }
  }, [dispatch, lon, lat, radius, resetList, rdUserInfo.sessionKey]);

  const autoSearchWithPosition = useCallback(
    (newPosition, newDisplayAddressName?: string) => {
      if (newPosition.lon && newPosition.lat) {
        dispatch(
          actions.tNow.setBaseSpot({...newPosition, displayAddressName: newDisplayAddressName})
        );
        resetList();
        dispatch(
          fixedRadius
            ? fetchTNowList({...newPosition, radius: fixedRadius, ticketId: rdUserInfo.sessionKey})
            : fetchTNowAutoSearch({
                ...newPosition,
                radius: SLIDER_LIST[1],
                ticketId: rdUserInfo.sessionKey,
                maxRadius: MAX_RADIUS,
                expandCount: MAX_EXPAND_COUNT,
              })
        );
      }
    },
    [dispatch, fixedRadius, resetList, rdUserInfo.sessionKey]
  );

  const fetchNewRadius = useCallback(
    (newRadius) => {
      if (lon && lat) {
        const apiRadius = newRadius === WHOLE_AREA ? undefined : newRadius;

        dispatch(
          actions.tNow.setBaseSpot({
            radius: newRadius,
            fixedRadius: newRadius,
          })
        );
        resetList();
        dispatch(fetchTNowList({lon, lat, radius: apiRadius, ticketId: rdUserInfo.sessionKey}));
      }
    },
    [dispatch, lon, lat, resetList, rdUserInfo.sessionKey]
  );

  return {
    fetchNewRadius,
    autoSearch,
    refreshSearch,
    autoSearchWithPosition,
    autoSearchFromNextRadius,
  };
};

export default useTNowSearch;
