import {useState} from 'react';
import {Link} from 'react-router-dom';
import arrTimes from 'lodash/times';
import {HistoryBackLink} from 'components/HistoryBackLink';
import useDimmedQuery from 'hooks/useDimmedQuery';
import {CustomSelectLayer} from 'components/CustomSelectLayer';
import {GasStationOption, RestaurantOption} from 'constant/Poi';

const restaurantList = RestaurantOption.map((i, idx) => ({...i, id: idx}));
const gasList = GasStationOption.map((i, idx) => ({...i, id: idx}));

const defaultMultiItems = restaurantList.slice(0, 2);
const defaultGasItem = gasList[0];

const ProtoCustomSelectPage = () => {
  const test1 = useDimmedQuery({test: '1'});
  const test2 = useDimmedQuery({test: '2'});

  const [multiSelect, setMultiSelect] = useState(defaultMultiItems);
  const [select, setSelect] = useState(defaultGasItem);

  const handleClickItem = (selectedItem) => {
    setSelect(selectedItem);
    test1.close();
  };

  const handleClickConfirm = (selectedList) => {
    setMultiSelect(selectedList);
    test1.close();
  };

  return (
    <div>
      <div style={{position: 'fixed', zIndex: 5001, background: 'rgba(255,255,0, 0.8)'}}>
        {test1.isOpen || test2.isOpen ? (
          <HistoryBackLink>닫기</HistoryBackLink>
        ) : (
          <>
            <Link to={test1.openLocation}>7개이상 멀티셀렉트 열기</Link>
            <Link to={test2.openLocation}>7개미만 단일셀렉트 열기</Link>
          </>
        )}
      </div>
      <ul>
        {arrTimes(20).map((e, idx) => (
          <li style={{height: 300}} key={idx}>
            테스트용 {idx}
          </li>
        ))}
      </ul>
      <CustomSelectLayer
        isOpen={test1.isOpen}
        selectAllOption={{id: 'ALL', label: '전체'}}
        selectedItem={multiSelect}
        options={restaurantList}
        multiple={true}
        onConfirm={handleClickConfirm}
      />
      <CustomSelectLayer
        isOpen={test2.isOpen}
        selectAllOption={{id: 'ALL', label: '전체'}}
        selectedItem={select}
        options={gasList}
        onClickItem={handleClickItem}
      />
    </div>
  );
};

export default ProtoCustomSelectPage;
