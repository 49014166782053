import {useEffect, useRef, useState} from 'react';
import {times} from 'utils/lodash';
import styles from 'styles/pages/ProtoListDrawerPage.module.scss';
import useListDrawer from 'hooks/useListDrawer';
import {useAppSelector} from 'ducks/hooks';
import DevInfo from 'components/DevInfo';

const ProtoListDrawerPage = () => {
  const rdLayout = useAppSelector((state) => state.layout);
  const drawerProps = useListDrawer({
    windowHeight: rdLayout.windowSize.height,
    statusBarHeight: 0,
    isLandscape: false,
    listHandleHeight: 104,
    topAreaHeight: 0,
    topAreaPadding: 30,
  });

  const refList = useRef<HTMLDivElement>(null);
  const refHeader = useRef<HTMLDivElement>(null);
  const [listBottomPadding, setListBottomPadding] = useState<number>(0);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    drawerProps.init();

    return () => {
      document.body.style.overflow = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (refList.current) {
        const lastSearchItem = refList.current.querySelectorAll('[data-type=poi]:last-child')[0];

        if (lastSearchItem) {
          setListBottomPadding(
            rdLayout.windowSize.height -
              (refHeader?.current?.offsetHeight || 0) -
              (lastSearchItem as HTMLLIElement).offsetHeight
          );
        } else {
          setListBottomPadding(0);
        }
      }
    }, 0);
  }, []);

  return (
    <div className={styles.page_wrap}>
      <div
        className={styles.map_wrap}
        style={{
          transform: `translateY(-${drawerProps.position.map}px)`,
          transitionDuration: `${drawerProps.position.moveDelay}ms`,
        }}
      >
        지도임
        {times(30, 0).map((n, i) => (
          <div key={i} className={styles.map_position_info} style={{top: `${i}0%`}}>
            {`${i}0%`}
          </div>
        ))}
      </div>

      <div
        className={styles.list_wrap}
        style={{
          transform: `translateY(-${drawerProps.position.list}px)`,
          transitionDuration: `${drawerProps.position.moveDelay}ms`,
        }}
      >
        <div className={styles.list_header} {...drawerProps.handlers()} ref={refHeader}>
          <div className={styles.handle_wrap}>
            <div className={styles.handle} />
          </div>

          <div className={styles.header_content}>헤더</div>
        </div>

        <div className={styles.scroll_wrap} ref={refList}>
          {times(30, 0).map((n, i) => (
            <div key={i} className={styles.list_item}>
              리스트 아이템{i + 1}
            </div>
          ))}

          <div className="bottom_empty_area" style={{height: listBottomPadding}} />
        </div>
      </div>

      <DevInfo
        left={5}
        bottom={5}
        color="red"
        infos={[
          {value: drawerProps.mode, color: 'blue'},
          {value: {basePoint: drawerProps.basePoint}, color: 'orange'},
          {value: drawerProps.position, color: 'red'},
          {value: drawerProps.size, color: 'green'},
        ]}
      />
    </div>
  );
};

export default ProtoListDrawerPage;
