// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcViewListSvg} from 'resource/@tmdsicon/v1.2/ic_view_list.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcViewList = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcViewListSvg {...props} />} />;
};

export {IcViewList};
